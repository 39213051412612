import { NgModule, importProvidersFrom } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { InstructionsComponent } from './instructions/instructions.component';
import { InteractiveTabFormComponent } from './interactive-tab/interactive-tab-form.component';
import { PurseOpportunityComponent } from './purse-opportunity/purse-opportunity.component';
import { PartServiceInvestmentsComponent } from './part-service-investments/part-service-investments.component';
import { ChargingInvestmentComponent } from './charging-investment/charging-investment.component';
import { FullServiceLeasingComponent } from './full-service-leasing/full-service-leasing.component';
import { ScoringWorksheetComponent } from './scoring-worksheet/scoring-worksheet.component';
import { OutputGraphComponent } from './output-graph/output-graph.component';
import { PointOfContactComponent } from './point-of-contact/point-of-contact.component';
import { LoginComponent } from './shared/login/login.component';
import { ComparionChartComponent } from './comparion-chart/comparion-chart.component';
import { MsalGuard } from '@azure/msal-angular';
import { UserLoggedOutComponent } from './user-logged-out/user-logged-out.component';
import { AdminComponent } from './shared/admin/admin.component';

const routes: Routes = [
  {
    path: 'home',
    component: WelcomeComponent,
    data: { breadcrumb: { label: 'Home', link: 'home' } },
    canActivate:[MsalGuard]
  },
  {
    path: 'home',
    component: WelcomeComponent,
    canActivate:[MsalGuard]
  },

  {
    path: 'instructions',
    component: InstructionsComponent,
    data: { breadcrumb: { label: 'Instructions', link: 'instructions' } },
    canActivate:[MsalGuard]
  },
  {
    path: 'interactive-tab',
    component: InteractiveTabFormComponent,
    data: { breadcrumb: { label: 'Interactive Tab', link: 'interactive-tab' } },
    canActivate:[MsalGuard]
  },
  {
    path: 'pursue-opportunity',
    canActivate:[MsalGuard],
    component: PurseOpportunityComponent,
    data: {
      breadcrumb: { label: 'Pursue Opportunity', link: 'pursue-opportunity' },
    },
  },
  {
    path: 'parts-service-investments',
    component: PartServiceInvestmentsComponent,
    canActivate:[MsalGuard],
    data: {
      breadcrumb: {
        label: 'Parts & Services Investment',
        link: 'parts-service-investments',
      },
    },
  },
  {
    path: 'charging-investment',
    component: ChargingInvestmentComponent,
    canActivate:[MsalGuard],
    data: {
      breadcrumb: { label: 'Charging Investment', link: 'charging-investment' },
    },
  },
  {
    path: 'full-service-leasing',
    component: FullServiceLeasingComponent,
    canActivate:[MsalGuard],
    data: {
      breadcrumb: {
        label: 'Full Service Leasing',
        link: 'full-service-leasing',
      },
    },
  },
  {
    path: 'scoring-worksheet',
    component: ScoringWorksheetComponent,
    canActivate:[MsalGuard],
    data: {
      breadcrumb: { label: 'Scoring Worksheet', link: 'scoring-worksheet' },
    },
  },
  {
    path: 'output-graph',
    component: OutputGraphComponent,
    canActivate:[MsalGuard],
    data: { breadcrumb: { label: 'Output Graph', link: 'output-graph' } },
  },
  { path: 'point-of-contact', component: PointOfContactComponent, canActivate:[MsalGuard] },
  { path: '', component: LoginComponent },
  {path: "status-check", component:UserLoggedOutComponent},
  { path: 'home', component: WelcomeComponent, canActivate:[MsalGuard] },
  { path: 'comparisonwithMarket', component: ComparionChartComponent, canActivate:[MsalGuard]},
  { path: 'admin', component: AdminComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: true },),
  ],
  exports: [RouterModule],
})

export class AppRoutingModule {}
