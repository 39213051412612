import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { AuthService } from 'src/app/services/auth.service'; // Import the correct service

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  userList: any[] = []; 
  isDataFetched = false;
  recentUsers: any[] = []; 
  startDate: string | null = null; 
  endDate: string | null = null;
  toggleSwitch: boolean = true;
  @ViewChild('table') table: ElementRef; 

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
  
  }

  getUserData() {
    this.authService.fetchUserData().subscribe(
      (response: any) => {
        if (response.message === 'User details fetched successfully') {
          console.log(response);
          this.recentUsers = response.recentUsers.map((user: any) => {
            return {
              ...user,
              createdAt: this.formatDate(user.createdAt)
            };
          });
          this.recentUsers.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  
          this.filterUsersByDate();
          this.isDataFetched = true;
          console.log(this.userList);
        } else {
          console.error('Failed to fetch user data');
        }
      },
      (error) => {
        this.isDataFetched = false;
        console.error('Error fetching user data', error);
      }
    );
  }
  

  onToggleChange() {
    console.log("Toggle state:", this.toggleSwitch);
    this.filterUsersByDate(); 
  }

  fetchData() {
    this.getUserData(); 
  }

  clearDates() {
    this.startDate = null;
    this.endDate = null;
    this.isDataFetched = false;
    this.userList = []; 
  }

  filterUsersByDate() {
    const start = this.startDate ? new Date(this.startDate) : null;
    const end = this.endDate ? new Date(this.endDate) : null;
    
    if (end) {
      end.setHours(23, 59, 59, 999); 
    }
    
    this.userList = this.recentUsers.filter((user: any) => {
      const userDate = new Date(user.createdAt);
      
      let isWithinDateRange = true;
      if (start && end) {
        isWithinDateRange = userDate >= start && userDate <= end;
      } else if (start) {
        isWithinDateRange = userDate >= start;
      } else if (end) {
        isWithinDateRange = userDate <= end;
      }
      
      if (this.toggleSwitch) {
        return isWithinDateRange && user.primarybusinessrole === 'Dealer Principal';
      } else {
        return isWithinDateRange;
      }
    });
  }

  selectDate(event: any) {
    event.target.select();
  }

  handleClear(event: any, field: string) {
    if (event.key === 'Backspace' || event.keyCode === 8) {
      if (event.target.value) {
        if (field === 'startDate') {
          this.startDate = null;
        } else if (field === 'endDate') {
          this.endDate = null;
        }
        event.target.value = '';  
        event.preventDefault();   
      }
    }
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];
    const time = date.toISOString().split('T')[1].split('.')[0];
    return `${formattedDate} ${time}`; 
  }

  goBack() {
    this.router.navigate(['/home']); 
  }

  exportToExcel() {
    const formattedUserList = this.userList.map(user => {
      return {
        'Name': user.name,
        'Short ID': user.userid,
        'Email': user.email,
        'Logged On': this.formatDateForExcel(user.createdAt),
        'Saved Scenarios' : user.scenariocount,
      };
    });
  
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(formattedUserList);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
  
    let sheetName = 'User_Details';
    if (this.startDate && this.endDate) {
      sheetName = `${this.startDate} to ${this.endDate}`;
    } else if (this.startDate) {
      sheetName = `From ${this.startDate}`;
    } else if (this.endDate) {
      sheetName = `Until ${this.endDate}`;
    }
  
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, 'DFST_Logged_on_Users_Data.xlsx');
  }

  formatDateForExcel(dateString: string): string {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];
    const time = date.toTimeString().split(' ')[0];
    return `${formattedDate} ${time}`; 
  }
}
