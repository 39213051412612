<div class="mt-5">
    <form [formGroup]="pursueOpportunityForm" class="pursue-opportunity-form" (keydown)="enterSubmitted($event)">
    <div #revenueGrossProjectContent>
        <table class="w-100">
            <thead>
                <th class="dealer-projected-title font-weight-bold text-center text-uppercase">Dealer projected revenue and gross profit</th>
                <th class="dealer-projected-year-section">
                    <p class="dealer-projected-year text-center mx-2 mb-0">2026</p>
                    <div class="dealer-projected-details-section d-flex mx-2 pt-2">
                        <p class="dealer-projected-details mb-0 pl-1">Revenue</p>
                        <p class="dealer-projected-details mb-0">Gross Profit</p>
                        <p class="dealer-projected-details mb-0">Units</p>
                    </div>
                </th>
                <th class="dealer-projected-year-section">
                    <p class="dealer-projected-year text-center mx-2 mb-0">2030</p>
                    <div class="dealer-projected-details-section d-flex mx-2 pt-2">
                        <p class="dealer-projected-details mb-0 pl-1">Revenue</p>
                        <p class="dealer-projected-details mb-0">Gross Profit</p>
                        <p class="dealer-projected-details mb-0">Units</p>
                    </div>
                </th>
                <th class="dealer-projected-opportunity">
                    <p class="dealer-projected-year text-center ml-2 mb-0">Content to hidden</p>
                    <div *ngIf="!removeToggle" class="dealer-projected-details-section d-flex ml-2 pt-2">
                        <p class="dealer-projected-details mb-0 pl-1">Pursue Opportunity?</p>
                    </div>
                </th>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td>
                        <span></span>
                        <span>{{calculatedData?.INTERACTIVETAB_D92 | percent : '1.0-1'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isPercentage]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D92"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span></span>
                        <span>{{calculatedData?.INTERACTIVETAB_H92 | percent : '1.0-1'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isPercentage]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H92"></span> -->
                    <span></span>
                    </td>
                    <td>
                        <button class="btn link-btn ml-3" *ngIf="removeRecalculateButton"  (click)="updateForm()">Re-Calculate<span class="bi-info-circle ml-2 w-auto" placement="top"
                            ngbTooltip="To see the effects on Dealer group total results after toggling between ‘Pursue Opportunity yes/no’, please click on Re-Calculate.">
                        </span></button>
                    </td>
                </tr>
                <tr class="total-price font-weight-500">
                    <td>Dealer group total</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C93  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C93"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D93  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D93"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G93  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G93"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H93  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H93"></span> -->
                        <span></span>
                    </td>
                    <td *ngIf="!removeToggle"></td>
                </tr>
                <tr>
                    <td>BEV-related</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C94  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C94"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D94  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D94"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G94  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G94"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H94  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H94"></span> -->
                        <span></span>
                    </td>
                    <td></td>
                </tr>
                <tr class="total-price font-weight-500">
                    <td>New truck sales</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C95  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C95"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D95  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D95"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_E95  | number: '1.0-0'}}</span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G95  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G95"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H95  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H95"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_I95  | number: '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isNumber]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_I95"></span> -->
                    </td>
                    <td *ngIf="!removeToggle"></td>
                </tr>
                <tr>
                    <td>ICE truck</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C96  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C96"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D96  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D96"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_E96  | number: '1.0-0'}}</span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G96  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G96"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H96  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H96"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_I96  | number: '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isNumber]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_I96"></span> -->
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>BEV truck</td>
                    <td>
                        <span>{{bevNewTruckChecked ? (calculatedData?.INTERACTIVETAB_C97  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C97"></span> -->
                        <span>{{bevNewTruckChecked ? (calculatedData?.INTERACTIVETAB_D97  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D97"></span> -->
                        <span>{{bevNewTruckChecked ? (calculatedData?.INTERACTIVETAB_E97  | number: '1.0-0') : 0}}</span>
                    </td>
                    <td>
                        <span>{{bevNewTruckChecked ? (calculatedData?.INTERACTIVETAB_G97  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G97"></span> -->
                        <span>{{bevNewTruckChecked ? (calculatedData?.INTERACTIVETAB_H97  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H97"></span> -->
                        <span>{{bevNewTruckChecked ? (calculatedData?.INTERACTIVETAB_I97  | number: '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isNumber]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_I97"></span> -->
                    </td>
                    <td *ngIf="!removeToggle" class="text-center">
                        <label class="switch mb-0">
                            <input type="checkbox" id="INTERACTIVETAB_K97" [checked]=bevNewTruckChecked (click)="bevNewTruckOpportunity()">
                            <div class="slider round"></div>
                        </label>
                    </td>
                </tr>
                <tr class="total-price font-weight-500">
                    <td>Used truck sales</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C99  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C99"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D99  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D99"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_E99  | number: '1.0-0'}}</span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G99  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G99"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H99  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H99"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_I99  | number: '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isNumber]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_I99"></span> -->
                    </td>
                    <td *ngIf="!removeToggle"></td>
                </tr>
                <tr>
                    <td>ICE truck</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C100  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C100"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D100  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D100"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_E100  | number: '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isNumber]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_E100"></span> -->
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G100  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G100"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H100  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H100"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_I100  | number: '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isNumber]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_I100"></span> -->
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>BEV truck</td>
                    <td>
                        <span>{{bevUsedTruckChecked ? (calculatedData?.INTERACTIVETAB_C101  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C101"></span> -->
                        <span>{{bevUsedTruckChecked ? (calculatedData?.INTERACTIVETAB_D101  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D101"></span> -->
                        <span>{{bevUsedTruckChecked ? (calculatedData?.INTERACTIVETAB_E101  | number: '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isNumber]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_E101"></span> -->
                    </td>
                    <td>
                        <span>{{bevUsedTruckChecked ? (calculatedData?.INTERACTIVETAB_G101  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G101"></span> -->
                        <span>{{bevUsedTruckChecked ? (calculatedData?.INTERACTIVETAB_H101  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H101"></span> -->
                        <span>{{bevUsedTruckChecked ? (calculatedData?.INTERACTIVETAB_I101  | number: '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isNumber]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_I101"></span> -->
                    </td>
                    <td *ngIf="!removeToggle" class="text-center">
                        <label class="switch mb-0">
                            <input type="checkbox" id="INTERACTIVETAB_K101" [checked]=bevUsedTruckChecked (click)="bevUsedTruckOpportunity()">
                            <div class="slider round"></div>
                        </label>
                    </td>
                </tr>
                <tr class="total-price font-weight-500">
                    <td>Parts</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C103  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C103"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D103  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D103"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G103  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G103"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H103  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H103"></span> -->
                        <span></span>
                    </td>
                    <td *ngIf="!removeToggle"></td>
                </tr>
                <tr>
                    <td>ICE Parts</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C104  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C104"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D104  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D104"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G104  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G104"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H104  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H104"></span> -->
                        <span></span>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>BEV Parts</td>
                    <td>
                        <span>{{bevPartsChecked ? (calculatedData?.INTERACTIVETAB_C105  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C105"></span> -->
                        <span>{{bevPartsChecked ? (calculatedData?.INTERACTIVETAB_D105  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D105"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{bevPartsChecked ? (calculatedData?.INTERACTIVETAB_G105  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G105"></span> -->
                        <span>{{bevPartsChecked ? (calculatedData?.INTERACTIVETAB_H105  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H105"></span> -->
                        <span></span>
                    </td>
                    <td *ngIf="!removeToggle" class="text-center">
                        <label class="switch mb-0">
                            <input type="checkbox" id="INTERACTIVETAB_K105" [checked]=bevPartsChecked (click)="bevPartsOpportunity()">
                            <div class="slider round"></div>
                        </label>
                    </td>
                </tr>
                <tr class="total-price font-weight-500">
                    <td>Service</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C107  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C107"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D107  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D107"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G107  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G107"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H107  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H107"></span> -->
                        <span></span>
                    </td>
                    <td *ngIf="!removeToggle"></td>
                </tr>
                <tr>
                    <td>ICE service</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C108  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C108"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D108  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D108"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G108  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G108"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H108  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H108"></span> -->
                        <span></span>
                    </td>
                    <td ></td>
                </tr>
                <tr>
                    <td>BEV service</td>
                    <td>
                        <span>{{bevServiceChecked ? (calculatedData?.INTERACTIVETAB_C109  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C109"></span> -->
                        <span>{{bevServiceChecked ? (calculatedData?.INTERACTIVETAB_D109  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D109"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{bevServiceChecked ? (calculatedData?.INTERACTIVETAB_G109  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G109"></span> -->
                        <span>{{bevServiceChecked ? (calculatedData?.INTERACTIVETAB_H109  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H109"></span> -->
                        <span></span>
                    </td>
                    <td *ngIf="!removeToggle" class="text-center">
                        <label class="switch mb-0">
                            <input type="checkbox" id="INTERACTIVETAB_K109" [checked]=bevServiceChecked (click)="bevServiceOpportunity()">
                            <div class="slider round"></div>
                        </label>
                    </td>
                </tr>
                <tr class="total-price font-weight-500">
                    <td>Bodyshop</td>
                    <td>
                        <span>{{bodyshopChecked ? (calculatedData?.OTHERS_C14  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C111"></span> -->
                        <span>{{bodyshopChecked ? (calculatedData?.OTHERS_C22  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D111"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{bodyshopChecked ? (calculatedData?.OTHERS_D14  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G111"></span> -->
                        <span>{{bodyshopChecked ? (calculatedData?.OTHERS_D22  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H111"></span> -->
                        <span></span>
                    </td>
                    <td *ngIf="!removeToggle" class="text-center">
                        <label class="switch mb-0">
                            <input type="checkbox" id="INTERACTIVETAB_K111" [checked]=bodyshopChecked (click)="bodyshopOpportunity()">
                            <div class="slider round"></div>
                        </label>
                    </td>
                </tr>
                <tr class="total-price font-weight-500">
                    <td>
                        <span class="w-auto">Finance & Insurance</span>
                        <span class="bi-info-circle ml-2 w-auto" placement="top"
                            ngbTooltip="'Business as usual' F&I income from not changing anything at the dealership, only taking advantage of higher overall revenue to drive F&I income. To view financial uplift from new F&I opportunities, see information in 'New Opportunities Outputs' below.">
                        </span>
                    </td>
                    <td style="color: #808080">
                        <span>{{calculatedData?.OTHERS_C36  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C113"></span> -->
                        <span>{{calculatedData?.OTHERS_C43  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D113"></span> -->
                        <span></span>
                    </td>
                    <td style="color: #808080">
                        <span>{{calculatedData?.OTHERS_D36  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G113"></span> -->
                        <span>{{calculatedData?.OTHERS_D43  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H113"></span> -->
                        <span></span>
                    </td>
                    <td *ngIf="!removeToggle"></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="mt-4" #newOpportunitiesContent>
        <table class="w-100">
            <thead>
                <th class="dealer-projected-title font-weight-bold text-center text-uppercase">New Opportunities</th>
                <th class="dealer-projected-year-section">
                    <p class="dealer-projected-year text-center mx-2 mb-0">2026</p>
                    <div class="dealer-projected-details-section d-flex mx-2 pt-2">
                        <p class="dealer-projected-details mb-0 pl-1">Revenue</p>
                        <p class="dealer-projected-details mb-0">Gross Profit</p>
                        <p class="dealer-projected-details mb-0">Units</p>
                    </div>
                </th>
                <th class="dealer-projected-year-section">
                    <p class="dealer-projected-year text-center mx-2 mb-0">2030</p>
                    <div class="dealer-projected-details-section d-flex mx-2 pt-2">
                        <p class="dealer-projected-details mb-0 pl-1">Revenue</p>
                        <p class="dealer-projected-details mb-0">Gross Profit</p>
                        <p class="dealer-projected-details mb-0">Units</p>
                    </div>
                </th>
                <th class="dealer-projected-opportunity">
                    <p class="dealer-projected-year text-center ml-2 mb-0">Content to hidden</p>
                    <div *ngIf="!removeToggle" class="dealer-projected-details-section d-flex ml-2 pt-2">
                        <p class="dealer-projected-details mb-0 pl-1">Purse Opportunity?</p>
                    </div>
                </th>
            </thead>
            <tbody>
                <tr class="total-price font-weight-500">
                    <td>Public onsite charging</td>
                    <td>
                        <span>{{publicChargingChecked ? (calculatedData?.INTERACTIVETAB_C118  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C118"></span> -->
                        <span>{{publicChargingChecked ? (calculatedData?.INTERACTIVETAB_D118  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D118"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{publicChargingChecked ? (calculatedData?.INTERACTIVETAB_G118  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G118"></span> -->
                        <span>{{publicChargingChecked ? (calculatedData?.INTERACTIVETAB_H118  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H118"></span> -->
                        <span></span>
                    </td>
                    <td *ngIf="!removeToggle" class="text-center">
                        <label class="switch mb-0">
                            <input type="checkbox" id="INTERACTIVETAB_K118" [checked]=publicChargingChecked (click)="publicChargingOpportunity()">
                            <div class="slider round"></div>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>DC Fast Chargers</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C119  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C119"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D119  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D119"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G119  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G119"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H119  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H119"></span> -->
                        <span></span>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>Megawatt Charging</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C120  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C120"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D120  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D120"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G120  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G120"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H120  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H120"></span> -->
                        <span></span>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>Amenity Sales</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C121  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C121"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D121  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D121"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G121  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G121"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H121  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H121"></span> -->
                        <span></span>
                    </td>
                    <td></td>
                </tr>
                <tr class="total-price font-weight-500">
                    <td>Retail Financing and Leasing</td>
                    <td>
                        <span>{{financingLeasingChecked ? (calculatedData?.INTERACTIVETAB_C123  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C123"></span> -->
                        <span>{{financingLeasingChecked ? (calculatedData?.INTERACTIVETAB_D123  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D123"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{financingLeasingChecked ? (calculatedData?.INTERACTIVETAB_G123  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G123"></span> -->
                        <span>{{financingLeasingChecked ? (calculatedData?.INTERACTIVETAB_H123  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H123"></span> -->
                        <span></span>
                    </td>
                    <td *ngIf="!removeToggle" class="text-center">
                        <label class="switch mb-0">
                            <input type="checkbox" id="INTERACTIVETAB_K123" [checked]=financingLeasingChecked (click)="financingLeasingOpportunity()">
                            <div class="slider round"></div>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>ICE Financing</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C124  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C124"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D124  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D124"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G124  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G124"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H124  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H124"></span> -->
                        <span></span>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>BEV Financing</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C125  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C125"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D125  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D125"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G125  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G125"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H125  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H125"></span> -->
                        <span></span>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>ICE Leasing</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C126  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C126"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D126  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D126"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G126  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G126"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H126  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H126"></span> -->
                        <span></span>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>BEV Leasing</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C127  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C127"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D127  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D127"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G127  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G127"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H127  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H127"></span> -->
                        <span></span>
                    </td>
                    <td></td>
                </tr>
                <tr class="total-price font-weight-500">
                    <td>Extended Warranty</td>
                    <td>
                        <span>{{extendedWarrantyChecked ? (calculatedData?.INTERACTIVETAB_C129  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C129"></span> -->
                        <span>{{extendedWarrantyChecked ? (calculatedData?.INTERACTIVETAB_D129  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D129"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{extendedWarrantyChecked ? (calculatedData?.INTERACTIVETAB_G129  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G129"></span> -->
                        <span>{{extendedWarrantyChecked ? (calculatedData?.INTERACTIVETAB_H129  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H129"></span> -->
                        <span></span>
                    </td>
                    <td *ngIf="!removeToggle"  class="text-center">
                        <label class="switch mb-0">
                            <input type="checkbox" id="INTERACTIVETAB_K129" [checked]=extendedWarrantyChecked (click)="extendedWarrantyOpportunity()">
                            <div class="slider round"></div>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>New ICE extended warranty</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C130  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C130"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D130  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D130"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G130  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G130"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H130  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H130"></span> -->
                        <span></span>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>New BEV extended warranty</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C131  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C131"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D131  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D131"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G131  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G131"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H131  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H131"></span> -->
                        <span></span>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>Used ICE extended warranty</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C132  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C132"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D132  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D132"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G132  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G132"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H132  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H132"></span> -->
                        <span></span>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>Used BEV extended warranty</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C133  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C133"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D133  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D133"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G133  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G133"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H133  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H133"></span> -->
                        <span></span>
                    </td>
                    <td></td>
                </tr>
                <tr class="total-price font-weight-500">
                    <td>Full Service Leasing</td>
                    <td>
                        <span>{{fullServiceLeasingChecked ? (calculatedData?.INTERACTIVETAB_C135  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C135"></span> -->
                        <span>{{fullServiceLeasingChecked ? (calculatedData?.INTERACTIVETAB_D135  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D135"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{fullServiceLeasingChecked ? (calculatedData?.INTERACTIVETAB_G135  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G135"></span> -->
                        <span>{{fullServiceLeasingChecked ? (calculatedData?.INTERACTIVETAB_H135  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H135"></span> -->
                        <span></span>
                    </td>
                    <td *ngIf="!removeToggle" class="text-center">
                        <label class="switch mb-0">
                            <input type="checkbox" id="INTERACTIVETAB_K135" [checked]=fullServiceLeasingChecked (click)="fullServiceLeasingOpportunity()">
                            <div class="slider round"></div>
                        </label>
                    </td>
                </tr>
                <tr class="total-price font-weight-500">
                    <td>Charger Retail Sales & Financing</td>
                    <td>
                        <span>{{chargerSalesFinancingChecked ? (calculatedData?.INTERACTIVETAB_C137  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C137"></span> -->
                        <span>{{chargerSalesFinancingChecked ? (calculatedData?.INTERACTIVETAB_D137  | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D137"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{chargerSalesFinancingChecked ? (calculatedData?.INTERACTIVETAB_G137   | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G137"></span> -->
                        <span>{{chargerSalesFinancingChecked ? (calculatedData?.INTERACTIVETAB_H137 | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H137"></span> -->
                        <span></span>
                    </td>
                    <td *ngIf="!removeToggle" class="text-center">
                        <label class="switch mb-0">
                            <input type="checkbox" id="INTERACTIVETAB_K137" [checked]=chargerSalesFinancingChecked (click)="chargerSalesFinancingOpportunity()">
                            <div class="slider round"></div>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td> Charger Retail Sales</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C138  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C138"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D138  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D138"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G138  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G138"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H138  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H138"></span> -->
                        <span></span>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>Charger Financing Income</td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_C139  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C139"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_D139  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D139"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{calculatedData?.INTERACTIVETAB_G139  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G139"></span> -->
                        <span>{{calculatedData?.INTERACTIVETAB_H139  | currency : 'USD' : 'symbol': '1.0-0'}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H139"></span> -->
                        <span></span>
                    </td>
                    <td></td>
                </tr>
                <tr class="total-price font-weight-500">
                    <td>Charger Service & Maintenance</td>
                    <td>
                        <span>{{chargerServiceMaintenanceChecked ? (calculatedData?.INTERACTIVETAB_C141 | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C141"></span> -->
                        <span>{{chargerServiceMaintenanceChecked ? (calculatedData?.INTERACTIVETAB_D141 | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D141"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{chargerServiceMaintenanceChecked ? (calculatedData?.INTERACTIVETAB_G141 | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G141"></span> -->
                        <span>{{chargerServiceMaintenanceChecked ? (calculatedData?.INTERACTIVETAB_H141 | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H141"></span> -->
                        <span></span>
                    </td>
                    <td *ngIf="!removeToggle" class="text-center">
                        <label class="switch mb-0">
                            <input type="checkbox" id="INTERACTIVETAB_K141" [checked]=chargerServiceMaintenanceChecked  (click)="chargerServiceMaintenanceOpportunity()">
                            <div class="slider round"></div>
                        </label>
                    </td>
                </tr>
                <tr class="total-price font-weight-500">
                    <td>Construction Project Financing</td>
                    <td>
                        <span>{{constructionProjectFinancingChecked ? (calculatedData?.INTERACTIVETAB_C143 | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_C143"></span> -->
                        <span>{{constructionProjectFinancingChecked ? (calculatedData?.INTERACTIVETAB_D143 | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_D143"></span> -->
                        <span></span>
                    </td>
                    <td>
                        <span>{{constructionProjectFinancingChecked ? (calculatedData?.INTERACTIVETAB_G143 | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_G143"></span> -->
                        <span>{{constructionProjectFinancingChecked ? (calculatedData?.INTERACTIVETAB_H143 | currency : 'USD' : 'symbol': '1.0-0') : 0}}</span>
                        <!-- <span class="calculated-cell" appFormattedValue [isCurrency]="true" [dataScope]="calculatedData" [formGroup]="pursueOpportunityForm" id="INTERACTIVETAB_H143"></span> -->
                        <span></span>
                    </td>
                    <td *ngIf="!removeToggle" class="text-center">
                        <label class="switch mb-0">
                            <input type="checkbox" id="INTERACTIVETAB_K143" [checked]=constructionProjectFinancingChecked  (click)="constructionProjectFinancingOpportunity()">
                            <div class="slider round"></div>
                        </label>

                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    </form>

    <div class="d-flex justify-content-end mt-4">
        <button class="btn link-btn" (click)="prevPage()">Back</button>
        <button class="btn link-btn ml-3" (click)="resetForm()">Reset</button>
        <button class="btn link-btn ml-3" (click)="updateForm()">Re-Calculate<span class="bi-info-circle ml-2 w-auto" placement="top"
                            ngbTooltip="To see the effects on Dealer group total results after toggling between ‘Pursue Opportunity yes/no’, please click on Re-Calculate.">
                        </span>
            </button>
        <button class="btn link-btn ml-3" (click)="nextPage()">Next Step</button>
    </div>
</div>

<!-- <div #draftModalRef class="modal fade" id="draftModal" tabindex="-1" aria-labelledby="userProfileModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <span type="button" class="close" (click)="draftModal.hide()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="bi-x-circle-fill"></i></span>
                </span>
            </div>
            <div class="modal-body">
                <div>
                    <p class="text-capitalize">Would you like to resume from your Last Draft</p>
                    <div class="d-flex justify-content-end mt-4">
                        <button class="btn link-btn" (click)="yesDraft()">Resume</button>
                        <button class="btn link-btn ml-3" (click)="deleteDraft()">Fresh Input</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
