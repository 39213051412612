<div class="mt-2">
  <table class="w-100 mb-3" *ngIf="scenarioData">
    <thead>
      <th>Scenario Name</th>
      <th>Created</th>
      <th>Updated</th>
      <th>Actions</th>
    </thead>
    <tbody>
      <tr *ngFor="let scenario of scenarioData">
        <td class="text-capitalize">{{scenario?.outputName}}</td>
        <td>{{scenario?.createdAt | date : 'short' }}</td>
        <td>{{scenario?.updatedAt | date : 'short' }}</td>
        <td>
          <span class="bi-upload" (click)="upload(scenario?.id)" style="cursor: pointer"></span>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="!scenarioData">
    <span>No Scenario is saved</span>
  </div>
</div>

