<div class="container mb-4">
  <app-header></app-header>
  <router-outlet></router-outlet>

  <app-footer *ngIf="authService.loggedIn"></app-footer>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="timer"
  [fullScreen]="true"><p style="color: white">Loading...</p></ngx-spinner>
