import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { isBoolean, isEmpty, isNull, isObjectLike, mapValues, merge } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';


const BASE_URL = environment.API_URL;
//const BASE_URL = 'http://localhost:8282'

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  pageTitle = '';
  formData = {};
  saveDraft = false;
  pageScreenshot: any = {};
  previousFormData = {
    interactiveTab: { INTERACTIVETAB_K97:"",
      INTERACTIVETAB_K101: "",
      INTERACTIVETAB_K105: "",
      INTERACTIVETAB_K109: "",
      INTERACTIVETAB_K111: "",
      INTERACTIVETAB_K118: "",
      INTERACTIVETAB_K123: "",
      INTERACTIVETAB_K129: "",
      INTERACTIVETAB_K135: "",
      INTERACTIVETAB_K137: "",
      INTERACTIVETAB_K141: "",
      INTERACTIVETAB_K143: "",},
    partsServiceInvesment: {},
    chargingInvestment: {},
    fullServiceLeasing: {},
    purseOpportunity: {},
    scoringWorksheet: {}
  }
  scenarioData = new BehaviorSubject<Array<any>>([]);

  constructor(private http: HttpClient) {
    console.log(BASE_URL ,"apiservice",environment)
  }

  discardDraft() {
    this.saveDraft = false;
    this.formData = {};
    localStorage.removeItem('loadFromDraft');
  }

  loadFromDraft() {
    this.saveDraft = true;
    localStorage.setItem('loadFromDraft', 'true');
  }

  isLoadedFromDraft() {
    const loadFromDraft = localStorage.getItem('loadFromDraft') ?? false;
    if (loadFromDraft) {
      this.saveDraft = loadFromDraft === 'true' || false;
    } else {
      this.saveDraft = false;
    }
    return this.saveDraft;
  }

  resetPrevFormData() {
    this.previousFormData = {
      interactiveTab: { INTERACTIVETAB_K97:"",
      INTERACTIVETAB_K101: "",
      INTERACTIVETAB_K105: "",
      INTERACTIVETAB_K109: "",
      INTERACTIVETAB_K111: "",
      INTERACTIVETAB_K118: "",
      INTERACTIVETAB_K123: "",
      INTERACTIVETAB_K129: "",
      INTERACTIVETAB_K135: "",
      INTERACTIVETAB_K137: "",
      INTERACTIVETAB_K141: "",
      INTERACTIVETAB_K143: "",},
      partsServiceInvesment: {},
      chargingInvestment: {},
      fullServiceLeasing: {},
      purseOpportunity: {},
      scoringWorksheet: {}
    };
  }

  savePrevFormData(page: any, formValue: any) {
    if(page === 'interactiveTab') {
      this.resetPrevFormData();
      this.previousFormData.interactiveTab = formValue
    } else if(page === 'partsServiceInvestment') {
      this.previousFormData = this.getPrevFormData();
      this.previousFormData.partsServiceInvesment = formValue
    } else if(page === 'chargingInvestment'){
      this.previousFormData = this.getPrevFormData();
      this.previousFormData.chargingInvestment = formValue
    } else if(page === 'fullServiceLeasing') {
      this.previousFormData = this.getPrevFormData();
      this.previousFormData.fullServiceLeasing = formValue
    } else if(page === 'purseOpportunity') {
      this.previousFormData = this.getPrevFormData();
      this.previousFormData.interactiveTab.INTERACTIVETAB_K97 =(formValue?.INTERACTIVETAB_K97==true||formValue?.INTERACTIVETAB_K97=="YES")?"YES":"NO"  ;
      this.previousFormData.interactiveTab.INTERACTIVETAB_K101 =(formValue?.INTERACTIVETAB_K101==true||formValue?.INTERACTIVETAB_K101=="YES")?"YES":"NO"  ;
      this.previousFormData.interactiveTab.INTERACTIVETAB_K105 =(formValue?.INTERACTIVETAB_K105==true||formValue?.INTERACTIVETAB_K105=="YES")?"YES":"NO"  ;
      this.previousFormData.interactiveTab.INTERACTIVETAB_K109 =(formValue?.INTERACTIVETAB_K109==true||formValue?.INTERACTIVETAB_K109=="YES")?"YES":"NO"  ;
      this.previousFormData.interactiveTab.INTERACTIVETAB_K111 =(formValue?.INTERACTIVETAB_K111==true||formValue?.INTERACTIVETAB_K111=="YES")?"YES":"NO"  ;
      this.previousFormData.interactiveTab.INTERACTIVETAB_K118 =(formValue?.INTERACTIVETAB_K118==true||formValue?.INTERACTIVETAB_K118=="YES")?"YES":"NO"  ;
      this.previousFormData.interactiveTab.INTERACTIVETAB_K123 =(formValue?.INTERACTIVETAB_K123==true||formValue?.INTERACTIVETAB_K123=="YES")?"YES":"NO"  ;
      this.previousFormData.interactiveTab.INTERACTIVETAB_K129 =(formValue?.INTERACTIVETAB_K129==true||formValue?.INTERACTIVETAB_K129=="YES")?"YES":"NO"  ;
      this.previousFormData.interactiveTab.INTERACTIVETAB_K135 =(formValue?.INTERACTIVETAB_K135==true||formValue?.INTERACTIVETAB_K135=="YES")?"YES":"NO"  ;
      this.previousFormData.interactiveTab.INTERACTIVETAB_K137 =(formValue?.INTERACTIVETAB_K137==true||formValue?.INTERACTIVETAB_K137=="YES")?"YES":"NO" ;
      this.previousFormData.interactiveTab.INTERACTIVETAB_K141 =(formValue?.INTERACTIVETAB_K141==true||formValue?.INTERACTIVETAB_K141=="YES")?"YES":"NO" ;
      this.previousFormData.interactiveTab.INTERACTIVETAB_K143 =(formValue?.INTERACTIVETAB_K143==true||formValue?.INTERACTIVETAB_K143=="YES")?"YES":"NO" ;
      
      this.previousFormData.purseOpportunity = formValue
    } else if(page === 'scoringWorksheet') {
      this.previousFormData = this.getPrevFormData();
      this.previousFormData.scoringWorksheet = formValue
    } 
      localStorage.setItem('previousFormData', JSON.stringify(this.previousFormData));
  }

  getPrevFormData() {
    let savedPrevFormData;
    let prevFormData;
    if(localStorage.getItem('previousFormData')) {
      savedPrevFormData = localStorage.getItem('previousFormData');
    }

    if(savedPrevFormData) {
      prevFormData = JSON.parse(savedPrevFormData);
    } else {
      prevFormData = {};
    }
    return prevFormData;
  }
  backToMultipleOfHundredAndSave(response: any) {    
    const keys = [
      "INTERACTIVETAB_G22",
      "INTERACTIVETAB_G23",
      "INTERACTIVETAB_G24",
      "INTERACTIVETAB_G26",
      "INTERACTIVETAB_G27",
      "INTERACTIVETAB_J22",
      "INTERACTIVETAB_J23",
      "INTERACTIVETAB_J24",
      "INTERACTIVETAB_J26",
      "INTERACTIVETAB_J27",
      "INTERACTIVETAB_G30",
      "INTERACTIVETAB_J30",
      "INTERACTIVETAB_D47",
      "INTERACTIVETAB_G47",
      "INTERACTIVETAB_J47",
      "INTERACTIVETAB_D48",
      "INTERACTIVETAB_G48",
      "INTERACTIVETAB_J48",
      "INTERACTIVETAB_D49",
      "INTERACTIVETAB_G49",
      "INTERACTIVETAB_J49",
      "INTERACTIVETAB_G52",
      "INTERACTIVETAB_J52",
      "INTERACTIVETAB_G53",
      "INTERACTIVETAB_J53",
      "INTERACTIVETAB_G54",
      "INTERACTIVETAB_J54",
      "INTERACTIVETAB_G57",
      "INTERACTIVETAB_J57",
      "INTERACTIVETAB_G58",
      "INTERACTIVETAB_J58",
      "INTERACTIVETAB_G61",
      "INTERACTIVETAB_J61",
      "INTERACTIVETAB_D66",
      "INTERACTIVETAB_G66",
      "INTERACTIVETAB_J66",
      "INTERACTIVETAB_D67",
      "INTERACTIVETAB_G67",
      "INTERACTIVETAB_J67",
      "INTERACTIVETAB_G70",
      "INTERACTIVETAB_J70",
      "INTERACTIVETAB_G71",
      "INTERACTIVETAB_J71",
      "INTERACTIVETAB_G74",
      "INTERACTIVETAB_J74",
      "INTERACTIVETAB_G75",
      "INTERACTIVETAB_J75",
      "INTERACTIVETAB_G78",
      "INTERACTIVETAB_J78"
    ];
    Object.keys(response).forEach((item:any) => {
      if(keys.includes(item) && response[item]){
         
          response[item] = response[item]*100
          console.log(response[item])
      }
  })    
            this.saveFormData(response);
  }

  saveFormData(formValue: any) {
    this.formData = merge(this.formData, formValue);
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  getFormData() {
    let savedFormData;
    if (localStorage.getItem('formData')) {
      savedFormData = localStorage.getItem('formData');
    }

    if (savedFormData) {
      this.formData = JSON.parse(savedFormData);
    } else {
      this.formData = {};
    }
    return this.formData;
  }

  getData(url: any) {
    return this.http.get<any>(url);
  }

  getReferenceData() {
    return this.http.get<any>(`${BASE_URL}/reference`);
  }

  previousPageData(data: any) {
    let postData = data;
    postData.draft = this.transformNullToEmptyString(postData.draft);
    postData = this.replaceDollarComma(postData);
    postData.draft = this.replaceBoolean(postData.draft);

    return this.http.post<any>(`${BASE_URL}/previous`, postData);
  }
  
  nextPageNavigation(data: any) {
    this.saveFormData(data.draft)
    let postData = data;
    postData.draft = this.transformNullToEmptyString(postData.draft);
    postData = this.replaceDollarComma(postData);
    postData.draft = this.replaceBoolean(postData.draft);

    return this.http.post<any>(`${BASE_URL}/next`, postData);
  }

  scoringWorksheet(data: any) {
    let postData = data;
    postData.draft = this.transformNullToEmptyString(postData.draft);
    postData = this.replaceDollarComma(postData);
    postData.draft = this.replaceBoolean(postData.draft);

    return this.http.post<any>(`${BASE_URL}/scoring`, postData);
  }

  outputGraphData(data: any) {
    let postData = data;
    postData.draft = this.transformNullToEmptyString(postData.draft);
    postData = this.replaceDollarComma(postData);
    postData.draft = this.replaceBoolean(postData.draft);

    return this.http.post<any>(`${BASE_URL}/output`, postData);
  }

  updatePage(data: any) {
    let postData = data;
    postData.draft = this.transformNullToEmptyString(postData.draft);
    postData = this.replaceDollarComma(postData);
    postData.draft = this.replaceBoolean(postData.draft);

    return this.http.post<any>(`${BASE_URL}/update`, postData);
  }

  fetchScenario(data: any) {
    return this.http.post<any>(`${BASE_URL}/fetch`, data)
  }

  saveScenario(data: any) {
    return this.http.post<any>(`${BASE_URL}/save`, data)
  }

  uploadScenario(data: any) {
    return this.http.post<any>(`${BASE_URL}/load`, data);
  }

  private transformNullToEmptyString(obj: any): any {
    if (isEmpty(obj) || isNull(obj)) {
      return "";
    } else if (isObjectLike(obj)) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === null) {
          obj[key] = this.transformNullToEmptyString(obj[key]);
        }
      }
    }

    return obj;
  }

  private replaceDollarComma(data: any): any {
    if (isEmpty(data) || isNull(data)) {
      return '';
    } else if (typeof data === 'object') {
      const modifiedObject = mapValues(data, (value, key) => {

        // Check if the value contains "$" or ","
        if(typeof value === 'object') {
          
          const modifiedObjectValues = mapValues(value, (objectValue, key) => {
            
            if (typeof objectValue === 'string' && (objectValue.includes('$') || objectValue.includes(',') || objectValue.includes('%'))) {
              // Remove "$" and "," from the value and convert it to a number
              const numericValue = parseFloat(objectValue.replace(/[$,%]/g, ''));
              return isNaN(numericValue) ? objectValue : numericValue;
            }
            return objectValue;
          });
          return modifiedObjectValues;
        }
        
        // Default: Keep the original value for other attributes
        return value;
      });
      return modifiedObject;
    } else {
      return data;
    }
  }

  private replaceBoolean(data: any): any {
    if (!data) {
      return '';
    } else if(typeof data === 'object') {
      const modifiedObject = mapValues(data, (value, key) => {
        // Check if the value contains "$" or ","
        if (isBoolean(value)) {
          return value ? 'YES' : 'NO';
        }
      
        // Default: Keep the original value for other attributes
        return value;
      });
      return modifiedObject;
    }
  }
  getUserData(): Observable<any> {
    return this.http.post<any>(`${BASE_URL}/admin/userlist`, {});
  }
}
