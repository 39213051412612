import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { isEmpty } from 'lodash';
import { LoaderService } from '../loader/loader.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  wrongUsernamePassword: boolean = false;
  show: boolean = false;
  isIframe: boolean;
  idtoken:string;
  decodedvalue:any;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    public apiService: ApiService,
    private router: Router,
    private toast: ToastrService,
    private loaderService: LoaderService,
    private msalauthService: MsalService, 
    private msalBroadcastService: MsalBroadcastService
  ) {
    if (this.authService.getCurrentUser()) {
      this.router.navigate(['/']);
      this.authService.loggedIn = true;
    } else {
      this.router.navigate(['/login']);
      this.authService.loggedIn = false;
    }
  }

  ngOnInit() {
    this.apiService.pageTitle = '';
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.loaderService.show()
    this.msalauthService.initialize()
    .subscribe(x => {
      this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
      this.setLoginDisplay();
      var request = {
        scopes: ["User.Read"],
    };
      this.msalauthService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        )
        .subscribe((result: EventMessage) => {
          if (this.msalauthService.instance.getAllAccounts().length === 0) {
            window.location.pathname = "/";
          } else {
            this.setLoginDisplay();
          }
        });
      
      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$)
        )
        .subscribe(() => {
          this.setLoginDisplay();
          //this.checkAndSetActiveAccount();
        }); 
        this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        )
        .subscribe((result: EventMessage) => {
          this.router.navigate(['/home'])
          const payload = result.payload as AuthenticationResult;
          localStorage.setItem("idToken",payload.idToken)
          this.idtoken = payload.idToken
          const decoded = jwtDecode(payload.idToken); 
          this.decodedvalue = decoded;
          // localStorage.setItem("uid",this.decodedvalue.uid)
          localStorage.setItem("primaryBusinessRole",this.decodedvalue.PrimaryBusinessRole)
          // localStorage.setItem("addlfunctionalrole",this.decodedvalue.AdditionalBusinessRoles)
          localStorage.setItem("applicationRole",this.decodedvalue.UserAppRoles)
          localStorage.setItem("name",this.decodedvalue.name)
          localStorage.setItem("userId",this.decodedvalue.UserID)
          localStorage.setItem("email",this.decodedvalue.email)
       // if(uid == "MDERBY" || uid == "AHAYWAR" || uid == "DOUROGE" || uid == "JGEORGO" || uid == "WHOELSC"|| uid == "CGIESEM" || uid == "BARAVIC" || uid == "GTEKKAL"){      
          let response = {"data":{
            "userId":this.decodedvalue.UserID, "username":this.decodedvalue.email
           // "userId":this.decodedvalue.UserID, "username":this.decodedvalue.signinname
          }}
          // switch (this.decodedvalue.uid) {
          //   case "MDERBY":
          //     response.data.userId = 501;
          //     break;
          //   case "AHAYWAR":
          //     response.data.userId = 502;
          //     break;
          //   case "DOUROGE":
          //     response.data.userId = 503;
          //     break;
          //   case "JGEORGO":
          //     response.data.userId = 504;
          //     break;
          //   case "WHOELSC":
          //     response.data.userId = 505;
          //     break;
          //   case "CGIESEM":
          //     response.data.userId = 506;
          //     break;
          //   case "BARAVIC":
          //     response.data.userId = 507;
          //     break;
          //   case "GTEKKAL":
          //     response.data.userId = 508;
          //     break;
          //   case "MUKUNDK":
          //     response.data.userId = 509;
          //     break;

          //   // Add more cases as needed
          //   default:
          //     response.data.userId = 5;
          // }
          this.authService.saveCurrentUser(response);
          this.authService.loggedIn = true;
          this.loaderService.hide()
          if (this.authService.loggedIn) {
            this.router.navigate(['/home'])
          }
          this.msalauthService.instance.setActiveAccount(payload.account);
        });    
        
  });


  }

  setLoginDisplay() {
    if (this.msalauthService.instance.getAllAccounts().length > 0) {
      this.loaderService.hide()
      this.router.navigate(['/home'])
      this.loginDisplay = this.msalauthService.instance.getAllAccounts().length > 0;   
    }else {
      this.router.navigate(['/logout'])
    }
}

  showPassword() {
    if (this.show) {
      this.show = false;
    } else {
      this.show = true;
    }
  }

  loginuser() {
  }

  enterSubmitted(event: any) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }
}
