export const FormulaList: any = {
  INTERACTIVETAB_E11: 'LARGEFUTUREP&L_H4',
  INTERACTIVETAB_E12: 'LARGEFUTUREP&L_H5',
  INTERACTIVETAB_E13: 'LARGEFUTUREP&L_H6',
  INTERACTIVETAB_E15: 'LARGEFUTUREP&L_H8',
  INTERACTIVETAB_E16: 'LARGEFUTUREP&L_H9',
  INTERACTIVETAB_G25:
    'IF((INTERACTIVETAB_G26 + INTERACTIVETAB_G27) == 0)=> 0 ELSE ( INTERACTIVETAB_G26 * NEWTRUCKSALES_H148 ) + ( INTERACTIVETAB_G27 *NEWTRUCKSALES_H149 ))',
  INTERACTIVETAB_J25:
    'IF((INTERACTIVETAB_J26 + INTERACTIVETAB_J27) == 0)=> 0 ELSE ( INTERACTIVETAB_J26 * NEWTRUCKSALES_H148 ) + ( INTERACTIVETAB_J27 *NEWTRUCKSALES_H149 ))',

  INTERACTIVETAB_D41:
    'IF((INTERACTIVETAB_D35 + INTERACTIVETAB_D36 + INTERACTIVETAB_D37 + INTERACTIVETAB_D39 + INTERACTIVETAB_D40) == 0) => 0 ELSE ( INTERACTIVETAB_D35 + INTERACTIVETAB_D36 + INTERACTIVETAB_D37 + INTERACTIVETAB_D39 + INTERACTIVETAB_D40 ))',
  INTERACTIVETAB_E41:
    'INTERACTIVETAB_E35 + INTERACTIVETAB_E36 + INTERACTIVETAB_E37 + INTERACTIVETAB_E39 + INTERACTIVETAB_E40',
  INTERACTIVETAB_D92: 'INTERACTIVETAB_D93 / INTERACTIVETAB_C93',
  INTERACTIVETAB_H92: 'INTERACTIVETAB_H93 / INTERACTIVETAB_G93',
  INTERACTIVETAB_C93:
    'INTERACTIVETAB_C95 + INTERACTIVETAB_C99 + INTERACTIVETAB_C103 + INTERACTIVETAB_C107 + INTERACTIVETAB_C111 +(IF( INTERACTIVETAB_K123 == "NO" )=> INTERACTIVETAB_C113 ELSE (INTERACTIVETAB_C123 + INTERACTIVETAB_C129 + INTERACTIVETAB_C135 )+ INTERACTIVETAB_C118 + INTERACTIVETAB_C137 + INTERACTIVETAB_C141 + INTERACTIVETAB_C143)',
  INTERACTIVETAB_D93:
    'INTERACTIVETAB_D95 + INTERACTIVETAB_D99 + INTERACTIVETAB_D103 + INTERACTIVETAB_D107 + INTERACTIVETAB_D111 +(IF( INTERACTIVETAB_K123 == "NO" )=> INTERACTIVETAB_D113 ELSE (INTERACTIVETAB_D123 + INTERACTIVETAB_D129 + INTERACTIVETAB_D135 )+ INTERACTIVETAB_D118 + INTERACTIVETAB_D137 + INTERACTIVETAB_D141 + INTERACTIVETAB_D143)',
  INTERACTIVETAB_G93:
    'INTERACTIVETAB_G95 + INTERACTIVETAB_G99 + INTERACTIVETAB_G103 + INTERACTIVETAB_G107 + INTERACTIVETAB_G111 +(IF( INTERACTIVETAB_K123 == "NO" )=> INTERACTIVETAB_G113 ELSE (INTERACTIVETAB_G123 + INTERACTIVETAB_G129 + INTERACTIVETAB_G135 )+ INTERACTIVETAB_G118 + INTERACTIVETAB_G137 + INTERACTIVETAB_G141 + INTERACTIVETAB_G143)',
  INTERACTIVETAB_H93:
    'INTERACTIVETAB_H95 + INTERACTIVETAB_H99 + INTERACTIVETAB_H103 + INTERACTIVETAB_H107 + INTERACTIVETAB_H111 +(IF( INTERACTIVETAB_K123 == "NO" )=> INTERACTIVETAB_H113 ELSE (INTERACTIVETAB_H123 + INTERACTIVETAB_H129 + INTERACTIVETAB_H135 )+ INTERACTIVETAB_H118 + INTERACTIVETAB_H137 + INTERACTIVETAB_H141 + INTERACTIVETAB_H143)',
  INTERACTIVETAB_C94:
    'INTERACTIVETAB_C97 + INTERACTIVETAB_C101 + INTERACTIVETAB_C105 + INTERACTIVETAB_C109 + INTERACTIVETAB_C118 + INTERACTIVETAB_C125 + INTERACTIVETAB_C127 + INTERACTIVETAB_C131 + INTERACTIVETAB_C133 + INTERACTIVETAB_C137 + INTERACTIVETAB_C141 + INTERACTIVETAB_C143',
  INTERACTIVETAB_D94:
    'INTERACTIVETAB_D97 + INTERACTIVETAB_D101 + INTERACTIVETAB_D105 + INTERACTIVETAB_D109 + INTERACTIVETAB_D118 + INTERACTIVETAB_D125 + INTERACTIVETAB_D127 + INTERACTIVETAB_D131 + INTERACTIVETAB_D133 + INTERACTIVETAB_D137 + INTERACTIVETAB_D141 + INTERACTIVETAB_D143',
  INTERACTIVETAB_G94:
    'INTERACTIVETAB_G97 + INTERACTIVETAB_G101 + INTERACTIVETAB_G105 + INTERACTIVETAB_G109 + INTERACTIVETAB_G118 + INTERACTIVETAB_G125 + INTERACTIVETAB_G127 + INTERACTIVETAB_G131 + INTERACTIVETAB_G133 + INTERACTIVETAB_G137 + INTERACTIVETAB_G141 + INTERACTIVETAB_G143',
  INTERACTIVETAB_H94:
    'INTERACTIVETAB_H97 + INTERACTIVETAB_H101 + INTERACTIVETAB_H105 + INTERACTIVETAB_H109 + INTERACTIVETAB_H118 + INTERACTIVETAB_H125 + INTERACTIVETAB_H127 + INTERACTIVETAB_H131 + INTERACTIVETAB_H133 + INTERACTIVETAB_H137 + INTERACTIVETAB_H141 + INTERACTIVETAB_H143',
  INTERACTIVETAB_C95: 'INTERACTIVETAB_C96 + INTERACTIVETAB_C97',
  INTERACTIVETAB_D95: 'INTERACTIVETAB_D96 + INTERACTIVETAB_D97',
  INTERACTIVETAB_E95: 'INTERACTIVETAB_E96 + INTERACTIVETAB_E97',
  INTERACTIVETAB_I95: 'INTERACTIVETAB_I96 + INTERACTIVETAB_I97',
  INTERACTIVETAB_C96: 'NEWTRUCKSALES_C200',
  INTERACTIVETAB_D96: 'NEWTRUCKSALES_F200',
  INTERACTIVETAB_E96: 'NEWTRUCKSALES_H164',
  INTERACTIVETAB_I96: 'NEWTRUCKSALES_H25',
  INTERACTIVETAB_C97:
    'IF( INTERACTIVETAB_K97 == "NO" )=> 0 ELSE NEWTRUCKSALES_C201',
  INTERACTIVETAB_D97:
    'IF(INTERACTIVETAB_K97 == "NO" )=> 0 ELSE NEWTRUCKSALES_F201',
  INTERACTIVETAB_E97:
    'IF(INTERACTIVETAB_K97 == "NO" )=> 0 ELSE NEWTRUCKSALES_G164',

  INTERACTIVETAB_I97:
    'IF(INTERACTIVETAB_K97 == "NO" )=> 0 ELSE NEWTRUCKSALES_G25',

  INTERACTIVETAB_C99: 'INTERACTIVETAB_C100 + INTERACTIVETAB_C101',
  INTERACTIVETAB_D99: 'INTERACTIVETAB_D100 + INTERACTIVETAB_D101',
  INTERACTIVETAB_E99: 'INTERACTIVETAB_E100 + INTERACTIVETAB_E101',

  INTERACTIVETAB_I99: 'INTERACTIVETAB_I100 + INTERACTIVETAB_I101',

  INTERACTIVETAB_C100:
    'IF(INTERACTIVETAB_K100 == "NO")=> 0 ELSE USEDTRUCKSALES_C47',
  INTERACTIVETAB_D100:
    'IF(INTERACTIVETAB_K100 == "NO")=> 0 ELSE USEDTRUCKSALES_C51',
  INTERACTIVETAB_E100: 'USEDTRUCKSALES_C39',

  INTERACTIVETAB_I100: 'USEDTRUCKSALES_D39',

  INTERACTIVETAB_C101:
    'IF(INTERACTIVETAB_K101 == "NO" )=> 0 ELSE USEDTRUCKSALES_C48)',
  INTERACTIVETAB_D101:
    'IF(INTERACTIVETAB_K101 == "NO" )=> 0 ELSE USEDTRUCKSALES_C52)',
  INTERACTIVETAB_E101:
    'IF(INTERACTIVETAB_K101 == "NO" )=> 0 ELSE USEDTRUCKSALES_C40)',
  INTERACTIVETAB_I101:
    'IF(INTERACTIVETAB_K101 == "NO" )=> 0 ELSE USEDTRUCKSALES_D40)',

  INTERACTIVETAB_C103: 'INTERACTIVETAB_C104 + INTERACTIVETAB_C105',
  INTERACTIVETAB_D103: 'INTERACTIVETAB_D104 + INTERACTIVETAB_D105',

  INTERACTIVETAB_C104: 'PARTS&SERVICE_C88',
  INTERACTIVETAB_D104: 'PARTS&SERVICE_C98',

  INTERACTIVETAB_C105:
    'IF(INTERACTIVETAB_K105 == "NO" )=> 0 ELSE PARTS&SERVICE_C90)',
  INTERACTIVETAB_D105:
    'IF(INTERACTIVETAB_K105 == "NO" )=> 0 ELSE PARTS&SERVICE_C105)',

  INTERACTIVETAB_C107: 'INTERACTIVETAB_C108 + INTERACTIVETAB_C109',
  INTERACTIVETAB_D107: 'INTERACTIVETAB_D108 + INTERACTIVETAB_D109',

  INTERACTIVETAB_C108: 'PARTS&SERVICE_C178',
  INTERACTIVETAB_D108: 'PARTS&SERVICE_C190',

  INTERACTIVETAB_C109:
    'IF(INTERACTIVETAB_K109 == "NO" )=> 0 ELSE PARTS&SERVICE_C181)',
  INTERACTIVETAB_D109:
    'IF(INTERACTIVETAB_K109 == "NO" )=> 0 ELSE PARTS&SERVICE_C195)',

  INTERACTIVETAB_C118:
    'IF(INTERACTIVETAB_K118 == "NO" )=> 0 ELSE (INTERACTIVETAB_C119 + INTERACTIVETAB_C120 +INTERACTIVETAB_C121 )',
  INTERACTIVETAB_D118:
    'IF(INTERACTIVETAB_K118 == "NO" )=> 0 ELSE (INTERACTIVETAB_D119 + INTERACTIVETAB_D120 + INTERACTIVETAB_D121)',

  INTERACTIVETAB_C119: 'NEWOPSMARKETSIZING_C17',
  INTERACTIVETAB_D119: 'NEWOPSMARKETSIZING_C22',

  INTERACTIVETAB_C120: 'NEWOPSMARKETSIZING_C37',
  INTERACTIVETAB_D120: 'NEWOPSMARKETSIZING_C42',

  INTERACTIVETAB_C121: 'NEWOPSMARKETSIZING_C52',
  INTERACTIVETAB_D121: 'NEWOPSMARKETSIZING_C57',
  INTERACTIVETAB_C123:
    'IF(INTERACTIVETAB_K123 == "NO" )=> 0 ELSE (INTERACTIVETAB_C124 + INTERACTIVETAB_C125 + INTERACTIVETAB_C126 + INTERACTIVETAB_C127)',
  INTERACTIVETAB_D123:
    'IF(INTERACTIVETAB_K123 == "NO" )=> 0 ELSE (INTERACTIVETAB_D124 + INTERACTIVETAB_D125 + INTERACTIVETAB_D126 + INTERACTIVETAB_D127)',

  INTERACTIVETAB_C124: 'RETAILFINANCING+LEASING_D108',
  INTERACTIVETAB_D124: 'INTERACTIVETAB_C124',
  INTERACTIVETAB_C125: 'RETAILFINANCING+LEASING_D111',
  INTERACTIVETAB_D125: 'INTERACTIVETAB_C125',
  INTERACTIVETAB_C126: 'RETAILFINANCING+LEASING_D109',
  INTERACTIVETAB_D126: 'INTERACTIVETAB_C126',
  INTERACTIVETAB_C127: 'RETAILFINANCING+LEASING_D112',
  INTERACTIVETAB_D127: 'INTERACTIVETAB_C127',

  INTERACTIVETAB_C129:
    'IF(INTERACTIVETAB_K129 == "NO" )=> 0 ELSE (INTERACTIVETAB_C130 + INTERACTIVETAB_C131 + INTERACTIVETAB_C132 + INTERACTIVETAB_C133)',
  INTERACTIVETAB_D129:
    'IF(INTERACTIVETAB_K129 == "NO" )=> 0 ELSE (INTERACTIVETAB_C130 + INTERACTIVETAB_C132 + INTERACTIVETAB_C132 + INTERACTIVETAB_D133)',
  INTERACTIVETAB_G129:
    'IF(INTERACTIVETAB_K129 == "NO" )=> 0 ELSE (INTERACTIVETAB_G130 + INTERACTIVETAB_G131 + INTERACTIVETAB_G132 + INTERACTIVETAB_G133)',
  INTERACTIVETAB_H129:
    'IF(INTERACTIVETAB_K129 == "NO" )=> 0 ELSE (INTERACTIVETAB_H130 + INTERACTIVETAB_H131 + INTERACTIVETAB_H132 + INTERACTIVETAB_H133)',

  INTERACTIVETAB_C130: 'EXTWARRANTYSALESNEWTRUCKS_D87',
  INTERACTIVETAB_D130: 'INTERACTIVETAB_C130',
  INTERACTIVETAB_C131: 'EXTWARRANTYSALESNEWTRUCKS_D90',
  INTERACTIVETAB_D131: 'INTERACTIVETAB_C131',
  INTERACTIVETAB_C132: 'EXTWARRANTYSALESUSEDTRUCKS_D63',
  INTERACTIVETAB_D132: 'INTERACTIVETAB_C132',
  INTERACTIVETAB_C133: 'EXTWARRANTYSALESUSEDTRUCKS_D67',
  INTERACTIVETAB_D133: 'INTERACTIVETAB_C133',

  INTERACTIVETAB_C135:
    'IF(INTERACTIVETAB_K135 == "NO" )=> 0 ELSE FULLSERVICELEASING_D134',
  INTERACTIVETAB_D135:
    'IF(INTERACTIVETAB_K135 == "NO" )=> 0 ELSE FULLSERVICELEASING_D261',

  INTERACTIVETAB_C137:
    'IF(INTERACTIVETAB_K137 == "NO" )=> 0 ELSE (INTERACTIVETAB_C138 + INTERACTIVETAB_C139)',
  INTERACTIVETAB_D137:
    'IF(INTERACTIVETAB_K137 == "NO" )=> 0 ELSE (INTERACTIVETAB_D138 + INTERACTIVETAB_D139)',

  INTERACTIVETAB_C138: 'CHARGERSALES+FINANCING_D86',
  INTERACTIVETAB_D138: 'CHARGERSALES+FINANCING_D100',
  INTERACTIVETAB_C139: 'CHARGERSALES+FINANCING_D117',
  INTERACTIVETAB_D139: 'INTERACTIVETAB_C139',
  INTERACTIVETAB_C141:
    'IF(INTERACTIVETAB_K141 == "NO" )=> 0 ELSE NEWOPSMARKETSIZING_C70',
  INTERACTIVETAB_D141:
    'IF(INTERACTIVETAB_K141 == "NO" )=> 0 ELSE NEWOPSMARKETSIZING_C73',

  INTERACTIVETAB_C143:
    'IF(INTERACTIVETAB_K143 == "NO" )=> 0 ELSE RETAILFINANCINGLEASING_D113',
  INTERACTIVETAB_D143: 'INTERACTIVETAB_C143',
  INTERACTIVETAB_H111: 0,
  INTERACTIVETAB_H113: 1770253,
  NEWTRUCKSALES_G100: 1.66,
  NEWTRUCKSALES_G99: 1.59,
  NEWTRUCKSALES_G97: 1.57,
  NEWTRUCKSALES_G96: 1.57,
  NEWTRUCKSALES_G95: 1.57,
  NEWTRUCKSALES_C13:
    '(NEWTRUCKSALES_C10 + NEWTRUCKSALES_C11 + NEWTRUCKSALES_C12)',
  NEWTRUCKSALES_D13:
    '(NEWTRUCKSALES_D10 + NEWTRUCKSALES_D11 + NEWTRUCKSALES_D12)',
  NEWTRUCKSALES_E13:
    'RRI(NEWTRUCKSALES_D9-NEWTRUCKSALES_C9,NEWTRUCKSALES_C13,NEWTRUCKSALES_D13)',
  NEWTRUCKSALES_I17: 'RRI(2030-2021,NEWTRUCKSALES_C17,NEWTRUCKSALES_H17)',
  NEWTRUCKSALES_I18: 'RRI(2030-2021,NEWTRUCKSALES_C18,NEWTRUCKSALES_H18)',
  NEWTRUCKSALES_I19: 'RRI(2030-2021,NEWTRUCKSALES_C19,NEWTRUCKSALES_H19)',
  NEWTRUCKSALES_E20: 'NEWTRUCKSALES_C20*(NEWTRUCKSALES_D20+1)^(2030-2021)',
  NEWTRUCKSALES_F20: 'NEWTRUCKSALES_G20/(NEWTRUCKSALES_G20+NEWTRUCKSALES_H20)',
  NEWTRUCKSALES_I20: 'RRI(2030-2021,NEWTRUCKSALES_C20,NEWTRUCKSALES_H20)',
  NEWTRUCKSALES_I21: 'RRI(2030-2021,NEWTRUCKSALES_C21,NEWTRUCKSALES_H21)',
  NEWTRUCKSALES_I22: 'RRI(2030-2021,NEWTRUCKSALES_C22,NEWTRUCKSALES_H22)',
  NEWTRUCKSALES_E23:
    '(NEWTRUCKSALES_E17 + NEWTRUCKSALES_E18 + NEWTRUCKSALES_E19 + NEWTRUCKSALES_E20)',
  NEWTRUCKSALES_G23:
    '(NEWTRUCKSALES_G17 + NEWTRUCKSALES_G18 + NEWTRUCKSALES_G19 + NEWTRUCKSALES_G20)',
  NEWTRUCKSALES_H23:
    '(NEWTRUCKSALES_H17 + NEWTRUCKSALES_H18 + NEWTRUCKSALES_H19 + NEWTRUCKSALES_H20)',
  NEWTRUCKSALES_I23: 'RRI(2030-2021,NEWTRUCKSALES_C23,NEWTRUCKSALES_H23)',
  NEWTRUCKSALES_F24: 'NEWTRUCKSALES_G23/(NEWTRUCKSALES_G23+NEWTRUCKSALES_H23)',
  NEWTRUCKSALES_C25: 'NEWTRUCKSALES_C23',
  NEWTRUCKSALES_E25: 'NEWTRUCKSALES_E23',
  NEWTRUCKSALES_G25: 'NEWTRUCKSALES_G23',
  NEWTRUCKSALES_H25: 'NEWTRUCKSALES_H23',
  NEWTRUCKSALES_D37:
    "IF(NEWTRUCKSALES_C37 == 'NO' ) => 0% ELSE NEWTRUCKSALES_G37",
  NEWTRUCKSALES_E43: 'NEWTRUCKSALES_D43/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_D44: 'NEWTRUCKSALES_C44*NEWTRUCKSALES_C18',
  NEWTRUCKSALES_E44: 'NEWTRUCKSALES_D44/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_E45: 'NEWTRUCKSALES_D45/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_E46: 'NEWTRUCKSALES_D46/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_E47: 'NEWTRUCKSALES_D47/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_E48: 'NEWTRUCKSALES_D48/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_H51: 'NEWTRUCKSALES_G51/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_H52: 'NEWTRUCKSALES_G52/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_H53: 'NEWTRUCKSALES_G53/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_H54: 'NEWTRUCKSALES_G54/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_E56: 'NEWTRUCKSALES_E55/18',
  NEWTRUCKSALES_F56: 'NEWTRUCKSALES_F55/18',
  NEWTRUCKSALES_F59: 'LARGECURRENTP&L_D22',
  NEWTRUCKSALES_G59: 'NEWTRUCKSALES_F59/NEWTRUCKSALES_C59',
  NEWTRUCKSALES_F60: 'NEWTRUCKSALES_G60*NEWTRUCKSALES_C60',
  NEWTRUCKSALES_H60: '(NEWTRUCKSALES_F60-NEWTRUCKSALES_F59)/NEWTRUCKSALES_F59',
  NEWTRUCKSALES_H61: '(NEWTRUCKSALES_F61-NEWTRUCKSALES_F59)/NEWTRUCKSALES_F59',
  NEWTRUCKSALES_D62: 'NEWTRUCKSALES_C62/(NEWTRUCKSALES_C62+NEWTRUCKSALES_C61)',
  NEWTRUCKSALES_H62: 'NEWTRUCKSALES_F61/(NEWTRUCKSALES_F61+NEWTRUCKSALES_F62)',
  NEWTRUCKSALES_C63: 'EXTWARRANTYSALESNEWTRUCK_F86',
  NEWTRUCKSALES_F63: 'EXTWARRANTYSALESNEWTRUCK_F86',
  NEWTRUCKSALES_H63:
    '(NEWTRUCKSALES_F60+NEWTRUCKSALES_F63-NEWTRUCKSALES_F60)/NEWTRUCKSALES_F60',
  NEWTRUCKSALES_C64: 'CHARGERSALES+FINANCING_E86',
  NEWTRUCKSALES_D64: 'NEWTRUCKSALES_C62+NEWTRUCKSALES_C63+NEWTRUCKSALES_C64',
  NEWTRUCKSALES_F64: 'CHARGERSALES+FINANCING_E119',
  NEWTRUCKSALES_G64: 'NEWTRUCKSALES_F64/NEWTRUCKSALES_C64',
  NEWTRUCKSALES_H64: 'NEWTRUCKSALES_F62+NEWTRUCKSALES_F63+NEWTRUCKSALES_F64',
  NEWTRUCKSALES_C65: 'NEWTRUCKSALES_C60+NEWTRUCKSALES_C64+NEWTRUCKSALES_C63',
  NEWTRUCKSALES_F65: 'NEWTRUCKSALES_F60+NEWTRUCKSALES_F64+NEWTRUCKSALES_F63',
  NEWTRUCKSALES_G65: 'NEWTRUCKSALES_F65/NEWTRUCKSALES_C65',
  NEWTRUCKSALES_C69: 'NEWTRUCKSALES_C61-NEWTRUCKSALES_C59',
  NEWTRUCKSALES_F69: 'NEWTRUCKSALES_F61-NEWTRUCKSALES_F59',
  NEWTRUCKSALES_C70: 'NEWTRUCKSALES_C62',
  NEWTRUCKSALES_D70: 'NEWTRUCKSALES_C70+NEWTRUCKSALES_C72',
  NEWTRUCKSALES_F70: 'NEWTRUCKSALES_F62',
  NEWTRUCKSALES_G70: 'NEWTRUCKSALES_F70+NEWTRUCKSALES_F72',
  NEWTRUCKSALES_C71: 'NEWTRUCKSALES_C70+NEWTRUCKSALES_C69',
  NEWTRUCKSALES_F71: 'NEWTRUCKSALES_F70+NEWTRUCKSALES_F69',
  NEWTRUCKSALES_C72: 'NEWTRUCKSALES_C64',
  NEWTRUCKSALES_F72: 'NEWTRUCKSALES_F64',
  NEWTRUCKSALES_C73: 'NEWTRUCKSALES_C65-NEWTRUCKSALES_C59',
  NEWTRUCKSALES_F73: 'NEWTRUCKSALES_F65-NEWTRUCKSALES_F59',
  NEWTRUCKSALES_D82: 'NEWTRUCKSALES_E10',
  NEWTRUCKSALES_E82: 'NEWTRUCKSALES_C82*(NEWTRUCKSALES_D82+1)^(2030-2021)',
  NEWTRUCKSALES_G82: 'NEWTRUCKSALES_F82*NEWTRUCKSALES_E82',
  NEWTRUCKSALES_H82: 'NEWTRUCKSALES_E82*(1-NEWTRUCKSALES_F82)',
  NEWTRUCKSALES_I82: 'RRI(2030-2021,NEWTRUCKSALES_C82,NEWTRUCKSALES_H82)',
  NEWTRUCKSALES_D83: 'NEWTRUCKSALES_E11',
  NEWTRUCKSALES_E83: 'NEWTRUCKSALES_C83*(NEWTRUCKSALES_D83+1)^(2030-2021)',
  NEWTRUCKSALES_G83: 'NEWTRUCKSALES_F83*NEWTRUCKSALES_E83',
  NEWTRUCKSALES_H83: 'NEWTRUCKSALES_E83*(1-NEWTRUCKSALES_F83)',
  NEWTRUCKSALES_I83: 'RRI(2030-2021,NEWTRUCKSALES_C83,NEWTRUCKSALES_H83)',
  NEWTRUCKSALES_D84: 'NEWTRUCKSALES_E11',
  NEWTRUCKSALES_E84: 'NEWTRUCKSALES_C84*(NEWTRUCKSALES_D84+1)^(2030-2021)',
  NEWTRUCKSALES_G84: 'NEWTRUCKSALES_F84*NEWTRUCKSALES_E84',
  NEWTRUCKSALES_H84: 'NEWTRUCKSALES_E84*(1-NEWTRUCKSALES_F84)',
  NEWTRUCKSALES_I84: 'RRI(2030-2021,NEWTRUCKSALES_C84,NEWTRUCKSALES_H84)',
  NEWTRUCKSALES_D85: 'NEWTRUCKSALES_E12',
  NEWTRUCKSALES_E85: 'NEWTRUCKSALES_C85*(NEWTRUCKSALES_D85+1)^(2030-2021)',
  NEWTRUCKSALES_F85: 'NEWTRUCKSALES_G85/(NEWTRUCKSALES_G85+NEWTRUCKSALES_H85)',
  NEWTRUCKSALES_G85: 'NEWTRUCKSALES_G86+NEWTRUCKSALES_G87',
  NEWTRUCKSALES_H85: 'NEWTRUCKSALES_H86+NEWTRUCKSALES_H87',
  NEWTRUCKSALES_I85: 'RRI(2030-2021,NEWTRUCKSALES_C85,NEWTRUCKSALES_H85)',
  NEWTRUCKSALES_C86: 'NEWTRUCKSALES_C85*NEWTRUCKSALES_H8',
  NEWTRUCKSALES_D86: 'NEWTRUCKSALES_E12',
  NEWTRUCKSALES_E86: 'NEWTRUCKSALES_C86*(NEWTRUCKSALES_D86+1)^(2030-2021)',
  NEWTRUCKSALES_G86: 'NEWTRUCKSALES_F86*NEWTRUCKSALES_E86',
  NEWTRUCKSALES_H86: 'NEWTRUCKSALES_E86*(1-NEWTRUCKSALES_F86)',
  NEWTRUCKSALES_I86: 'RRI(2030-2021,NEWTRUCKSALES_C86,NEWTRUCKSALES_H86)',
  NEWTRUCKSALES_C87: 'NEWTRUCKSALES_C85*NEWTRUCKSALES_H9',
  NEWTRUCKSALES_D87: 'NEWTRUCKSALES_E12',
  NEWTRUCKSALES_E87: 'NEWTRUCKSALES_C87*(NEWTRUCKSALES_D87+1)^(2030-2021)',
  NEWTRUCKSALES_G87: 'NEWTRUCKSALES_F87*NEWTRUCKSALES_E87',
  NEWTRUCKSALES_H87: 'NEWTRUCKSALES_E87*(1-NEWTRUCKSALES_F87)',
  NEWTRUCKSALES_I87: 'RRI(2030-2021,NEWTRUCKSALES_C87,NEWTRUCKSALES_H87)',
  NEWTRUCKSALES_C88:
    '(NEWTRUCKSALES_C82 + NEWTRUCKSALES_C83 + NEWTRUCKSALES_C84 + NEWTRUCKSALES_C85)',
  NEWTRUCKSALES_D88:
    '((NEWTRUCKSALES_D82*NEWTRUCKSALES_C82)+(NEWTRUCKSALES_D83*NEWTRUCKSALES_C83)+(NEWTRUCKSALES_D84*NEWTRUCKSALES_C84)+(NEWTRUCKSALES_D85*NEWTRUCKSALES_C85))/NEWTRUCKSALES_C88',
  NEWTRUCKSALES_E88:
    '(NEWTRUCKSALES_E82 + NEWTRUCKSALES_E83 + NEWTRUCKSALES_E84 + NEWTRUCKSALES_E85)',
  NEWTRUCKSALES_G88:
    '(NEWTRUCKSALES_G82 + NEWTRUCKSALES_G83 + NEWTRUCKSALES_G84 + NEWTRUCKSALES_G85)',
  NEWTRUCKSALES_H88:
    '(NEWTRUCKSALES_H82 + NEWTRUCKSALES_H83 + NEWTRUCKSALES_H84 + NEWTRUCKSALES_H85)',
  NEWTRUCKSALES_I88: 'RRI(2030-2021,NEWTRUCKSALES_C88,NEWTRUCKSALES_H88)',
  NEWTRUCKSALES_F89: 'NEWTRUCKSALES_G88/(NEWTRUCKSALES_G88+NEWTRUCKSALES_H88)',
  NEWTRUCKSALES_C90: 'NEWTRUCKSALES_C88/NUM.UNIQUEDEALERGRPS_D1',
  NEWTRUCKSALES_E90: 'NEWTRUCKSALES_E88/NUM.UNIQUEDEALERGRPS_D1',
  NEWTRUCKSALES_G90: 'NEWTRUCKSALES_G88/NUM.UNIQUEDEALERGRPS_D1',
  NEWTRUCKSALES_H90: 'NEWTRUCKSALES_H88/NUM.UNIQUEDEALERGRPS_D1',
  NEWTRUCKSALES_C95: 'TRUCKREVENUE_O9',
  NEWTRUCKSALES_C96: 'TRUCKREVENUE_O14',
  NEWTRUCKSALES_C97: 'TRUCKREVENUE_O18',
  NEWTRUCKSALES_C98: 'TRUCKREVENUE_O4',
  NEWTRUCKSALES_G98:
    '((NEWTRUCKSALES_G99*NEWTRUCKSALES_G86)+(NEWTRUCKSALES_G100*NEWTRUCKSALES_G87))/NEWTRUCKSALES_G85',
  NEWTRUCKSALES_D102:
    "IF(NEWTRUCKSALES_C102 == 'NO' ) => 0% ELSE NEWTRUCKSALES_G102",
  NEWTRUCKSALES_C108: 'TRUCKREVENUE_O9',
  NEWTRUCKSALES_D108: 'NEWTRUCKSALES_C108*NEWTRUCKSALES_C82',
  NEWTRUCKSALES_E108: 'NEWTRUCKSALES_D108/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C109: 'TRUCKREVENUE_O14',
  NEWTRUCKSALES_D109: 'NEWTRUCKSALES_C109*NEWTRUCKSALES_C83',
  NEWTRUCKSALES_E109: 'NEWTRUCKSALES_D109/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C110: 'TRUCKREVENUE_O18',
  NEWTRUCKSALES_D110: 'NEWTRUCKSALES_C110*NEWTRUCKSALES_C84',
  NEWTRUCKSALES_E110: 'NEWTRUCKSALES_D110/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C111: 'TRUCKREVENUE_O4',
  NEWTRUCKSALES_D111: 'NEWTRUCKSALES_C111*NEWTRUCKSALES_C85',
  NEWTRUCKSALES_E111: 'NEWTRUCKSALES_D111/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_H111: 'NEWTRUCKSALES_F120/NEWTRUCKSALES_G120',
  NEWTRUCKSALES_D112:
    '(NEWTRUCKSALES_D108 + NEWTRUCKSALES_D109 + NEWTRUCKSALES_D110 + NEWTRUCKSALES_D111)',
  NEWTRUCKSALES_E112: 'NEWTRUCKSALES_D112/NEWTRUCKSALES_D112',
  NEWTRUCKSALES_H112: 'NEWTRUCKSALES_E120/NEWTRUCKSALES_G120',
  NEWTRUCKSALES_C116: 'NEWTRUCKSALES_C95+NEWTRUCKSALES_D95',
  NEWTRUCKSALES_D116: 'NEWTRUCKSALES_C116*NEWTRUCKSALES_G95',
  NEWTRUCKSALES_E116: 'NEWTRUCKSALES_C116*NEWTRUCKSALES_H82',
  NEWTRUCKSALES_F116: 'NEWTRUCKSALES_D116*NEWTRUCKSALES_G82',
  NEWTRUCKSALES_G116:
    '(NEWTRUCKSALES_D116*NEWTRUCKSALES_G82)+(NEWTRUCKSALES_C116*NEWTRUCKSALES_H82)',
  NEWTRUCKSALES_H116: 'NEWTRUCKSALES_G116/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C117: 'NEWTRUCKSALES_C96+NEWTRUCKSALES_D96',
  NEWTRUCKSALES_D117: 'NEWTRUCKSALES_C117*NEWTRUCKSALES_G96',
  NEWTRUCKSALES_E117: 'NEWTRUCKSALES_C117*NEWTRUCKSALES_H83',
  NEWTRUCKSALES_F117: 'NEWTRUCKSALES_D117*NEWTRUCKSALES_G83',
  NEWTRUCKSALES_G117:
    '(NEWTRUCKSALES_D117*NEWTRUCKSALES_G83)+(NEWTRUCKSALES_C117*NEWTRUCKSALES_H83)',
  NEWTRUCKSALES_H117: 'NEWTRUCKSALES_G117/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C118: 'NEWTRUCKSALES_C97+NEWTRUCKSALES_D97',
  NEWTRUCKSALES_D118: 'NEWTRUCKSALES_C118*NEWTRUCKSALES_G97',
  NEWTRUCKSALES_E118: 'NEWTRUCKSALES_C118*NEWTRUCKSALES_H84',
  NEWTRUCKSALES_F118: 'NEWTRUCKSALES_D118*NEWTRUCKSALES_G84',
  NEWTRUCKSALES_G118:
    '(NEWTRUCKSALES_D118*NEWTRUCKSALES_G84)+(NEWTRUCKSALES_C118*NEWTRUCKSALES_H84)',
  NEWTRUCKSALES_H118: 'NEWTRUCKSALES_G118/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C119: 'NEWTRUCKSALES_C98+NEWTRUCKSALES_D98',
  NEWTRUCKSALES_D119: 'NEWTRUCKSALES_C119*NEWTRUCKSALES_G98',
  NEWTRUCKSALES_E119: 'NEWTRUCKSALES_C119*NEWTRUCKSALES_H85',
  NEWTRUCKSALES_F119: 'NEWTRUCKSALES_D119*NEWTRUCKSALES_G85',
  NEWTRUCKSALES_G119:
    '(NEWTRUCKSALES_D119*NEWTRUCKSALES_G85)+(NEWTRUCKSALES_C119*NEWTRUCKSALES_H85)',
  NEWTRUCKSALES_H119: 'NEWTRUCKSALES_G119/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_E120:
    '(NEWTRUCKSALES_E116 + NEWTRUCKSALES_E117 + NEWTRUCKSALES_E118 + NEWTRUCKSALES_E119)',
  NEWTRUCKSALES_F120:
    '(NEWTRUCKSALES_F116 + NEWTRUCKSALES_F117 + NEWTRUCKSALES_F118 + NEWTRUCKSALES_F119)',
  NEWTRUCKSALES_G120:
    '(NEWTRUCKSALES_G116 + NEWTRUCKSALES_G117 + NEWTRUCKSALES_G118 + NEWTRUCKSALES_G119)',
  NEWTRUCKSALES_H120: 'NEWTRUCKSALES_G120/NEWTRUCKSALES_D112',
  NEWTRUCKSALES_E121: 'NEWTRUCKSALES_E120/NUM.UNIQUEDEALERGRPS_D1',
  NEWTRUCKSALES_F121: 'NEWTRUCKSALES_F120/18',
  NEWTRUCKSALES_C124: 'MEDIUMCURRENTP&L_D4',
  NEWTRUCKSALES_F124: 'MEDIUMCURRENTP&L_D22',
  NEWTRUCKSALES_G124: 'NEWTRUCKSALES_F124/NEWTRUCKSALES_C124',
  NEWTRUCKSALES_C125: 'NEWTRUCKSALES_C124*NEWTRUCKSALES_H120',
  NEWTRUCKSALES_F125: 'NEWTRUCKSALES_G125*NEWTRUCKSALES_C125',
  NEWTRUCKSALES_C126: 'NEWTRUCKSALES_C125*NEWTRUCKSALES_H112',
  NEWTRUCKSALES_F126: 'NEWTRUCKSALES_C126*NEWTRUCKSALES_G126',
  NEWTRUCKSALES_C127: 'NEWTRUCKSALES_C125*NEWTRUCKSALES_H111',
  NEWTRUCKSALES_F127: 'NEWTRUCKSALES_C127*NEWTRUCKSALES_G127',
  NEWTRUCKSALES_C128: 'EXTWARRANTYSALESNEWTRUCK_F180',
  NEWTRUCKSALES_F128: 'EXTWARRANTYSALESNEWTRUCK_F180',
  NEWTRUCKSALES_C129: 'CHARGERSALES+FINANCING_E204',
  NEWTRUCKSALES_F129: 'CHARGERSALES+FINANCING_E237',
  NEWTRUCKSALES_G129: 'NEWTRUCKSALES_F129/NEWTRUCKSALES_C129',
  NEWTRUCKSALES_H129:
    'NEWTRUCKSALES_F128+NEWTRUCKSALES_F129+NEWTRUCKSALES_F127',
  NEWTRUCKSALES_C130: 'NEWTRUCKSALES_C125+NEWTRUCKSALES_C129',
  NEWTRUCKSALES_F130:
    'NEWTRUCKSALES_F125+NEWTRUCKSALES_F129+NEWTRUCKSALES_F128',
  NEWTRUCKSALES_G130: 'NEWTRUCKSALES_F130/NEWTRUCKSALES_C130',
  NEWTRUCKSALES_C134: 'NEWTRUCKSALES_C126-NEWTRUCKSALES_C124',
  NEWTRUCKSALES_F134: 'NEWTRUCKSALES_F126-NEWTRUCKSALES_F124',
  NEWTRUCKSALES_C135: 'NEWTRUCKSALES_C127',
  NEWTRUCKSALES_F135: 'NEWTRUCKSALES_F127',
  NEWTRUCKSALES_C136: 'NEWTRUCKSALES_C135+NEWTRUCKSALES_C134',
  NEWTRUCKSALES_D136: 'NEWTRUCKSALES_C135+NEWTRUCKSALES_C137',
  NEWTRUCKSALES_F136: 'NEWTRUCKSALES_F135+NEWTRUCKSALES_F134',
  NEWTRUCKSALES_G136: 'NEWTRUCKSALES_F135+NEWTRUCKSALES_F137',
  NEWTRUCKSALES_C137: 'NEWTRUCKSALES_C129',
  NEWTRUCKSALES_F137: 'NEWTRUCKSALES_F129',
  NEWTRUCKSALES_C138: 'NEWTRUCKSALES_C130-NEWTRUCKSALES_C124',
  NEWTRUCKSALES_F138: 'NEWTRUCKSALES_F130-NEWTRUCKSALES_F124',
  NEWTRUCKSALES_C148: 'NEWTRUCKSALES_C9',
  NEWTRUCKSALES_C149: 'NEWTRUCKSALES_C10',
  NEWTRUCKSALES_E149:
    'RRI(NEWTRUCKSALES_D148-NEWTRUCKSALES_C148,NEWTRUCKSALES_C149,NEWTRUCKSALES_D149)',
  NEWTRUCKSALES_C150: 'NEWTRUCKSALES_C11',
  NEWTRUCKSALES_E150:
    'RRI(NEWTRUCKSALES_D148-NEWTRUCKSALES_C148,NEWTRUCKSALES_C150,NEWTRUCKSALES_D150)',
  NEWTRUCKSALES_C151: 'NEWTRUCKSALES_C12',
  NEWTRUCKSALES_E151:
    'RRI(NEWTRUCKSALES_D148-NEWTRUCKSALES_C148,NEWTRUCKSALES_C151,NEWTRUCKSALES_D151)',
  NEWTRUCKSALES_C156: 'NEWTRUCKSALES_C17',
  NEWTRUCKSALES_D156: 'NEWTRUCKSALES_E149',
  NEWTRUCKSALES_E156:
    'NEWTRUCKSALES_C156*(NEWTRUCKSALES_D156+1)^(NEWTRUCKSALES_B140-2021)',
  NEWTRUCKSALES_F156:
    "IF(INTERACTIVETAB_NEWTRUCKSALES_G22 == '') => INTERACTIVETAB_NEWTRUCKSALES_H22 ELSE INTERACTIVETAB_NEWTRUCKSALES_G22",
  NEWTRUCKSALES_G156: 'NEWTRUCKSALES_F156*NEWTRUCKSALES_E156',
  NEWTRUCKSALES_H156: 'NEWTRUCKSALES_E156*(1-NEWTRUCKSALES_F156)',
  NEWTRUCKSALES_I156:
    'RRI(NEWTRUCKSALES_B140-2021,NEWTRUCKSALES_C156,NEWTRUCKSALES_H156)',
  NEWTRUCKSALES_C157: 'NEWTRUCKSALES_C18',
  NEWTRUCKSALES_D157: 'NEWTRUCKSALES_E150',
  NEWTRUCKSALES_E157:
    'NEWTRUCKSALES_C157*(NEWTRUCKSALES_D157+1)^(NEWTRUCKSALES_B140-2021)',
  NEWTRUCKSALES_F157:
    "IF(INTERACTIVETAB_NEWTRUCKSALES_G23 == '') => INTERACTIVETAB_NEWTRUCKSALES_H23 ELSE INTERACTIVETAB_NEWTRUCKSALES_G23",
  NEWTRUCKSALES_G157: 'NEWTRUCKSALES_F157*NEWTRUCKSALES_E157',
  NEWTRUCKSALES_H157: 'NEWTRUCKSALES_E157*(1-NEWTRUCKSALES_F157)',
  NEWTRUCKSALES_I157:
    'RRI(NEWTRUCKSALES_B140-2021,NEWTRUCKSALES_C157,NEWTRUCKSALES_H157)',
  NEWTRUCKSALES_C158: 'NEWTRUCKSALES_C19',
  NEWTRUCKSALES_D158: 'NEWTRUCKSALES_E150',
  NEWTRUCKSALES_E158:
    'NEWTRUCKSALES_C158*(NEWTRUCKSALES_D158+1)^(NEWTRUCKSALES_B140-2021)',
  NEWTRUCKSALES_F158:
    "IF(INTERACTIVETAB_NEWTRUCKSALES_G24 == '') => INTERACTIVETAB_NEWTRUCKSALES_H24 ELSE INTERACTIVETAB_NEWTRUCKSALES_G24",
  NEWTRUCKSALES_G158: 'NEWTRUCKSALES_F158*NEWTRUCKSALES_E158',
  NEWTRUCKSALES_H158: 'NEWTRUCKSALES_E158*(1-NEWTRUCKSALES_F158)',
  NEWTRUCKSALES_I158:
    'RRI(NEWTRUCKSALES_B140-2021,NEWTRUCKSALES_C158,NEWTRUCKSALES_H158)',
  NEWTRUCKSALES_C159: 'NEWTRUCKSALES_C20',
  NEWTRUCKSALES_D159: 'NEWTRUCKSALES_E151',
  NEWTRUCKSALES_E159:
    'NEWTRUCKSALES_C159*(NEWTRUCKSALES_D159+1)^(NEWTRUCKSALES_B140-2021)',
  NEWTRUCKSALES_F159:
    'NEWTRUCKSALES_G159/(NEWTRUCKSALES_G159+NEWTRUCKSALES_H159)',
  NEWTRUCKSALES_G159: 'NEWTRUCKSALES_G160+NEWTRUCKSALES_G161',
  NEWTRUCKSALES_H159: 'NEWTRUCKSALES_H160+NEWTRUCKSALES_H161',
  NEWTRUCKSALES_I159:
    'RRI(NEWTRUCKSALES_B140-2021,NEWTRUCKSALES_C159,NEWTRUCKSALES_H159)',
  NEWTRUCKSALES_C160: 'NEWTRUCKSALES_C21',
  NEWTRUCKSALES_D160: 'NEWTRUCKSALES_E151',
  NEWTRUCKSALES_E160:
    'NEWTRUCKSALES_C160*(NEWTRUCKSALES_D160+1)^(NEWTRUCKSALES_B140-2021)',
  NEWTRUCKSALES_F160:
    "IF(INTERACTIVETAB_NEWTRUCKSALES_G26 == '') => INTERACTIVETAB_NEWTRUCKSALES_H26 ELSE INTERACTIVETAB_NEWTRUCKSALES_G26",
  NEWTRUCKSALES_G160: 'NEWTRUCKSALES_F160*NEWTRUCKSALES_E160',
  NEWTRUCKSALES_H160: 'NEWTRUCKSALES_E160*(1-NEWTRUCKSALES_F160)',
  NEWTRUCKSALES_I160:
    'RRI(NEWTRUCKSALES_B140-2021,NEWTRUCKSALES_C160,NEWTRUCKSALES_H160)',
  NEWTRUCKSALES_C161: 'NEWTRUCKSALES_C22',
  NEWTRUCKSALES_D161: 'NEWTRUCKSALES_E151',
  NEWTRUCKSALES_E161:
    'NEWTRUCKSALES_C161*(NEWTRUCKSALES_D161+1)^(NEWTRUCKSALES_B140-2021)',
  NEWTRUCKSALES_F161:
    "IF(INTERACTIVETAB_NEWTRUCKSALES_G27 == '') => INTERACTIVETAB_NEWTRUCKSALES_H27 ELSE INTERACTIVETAB_NEWTRUCKSALES_G27",
  NEWTRUCKSALES_G161: 'NEWTRUCKSALES_F161*NEWTRUCKSALES_E161',
  NEWTRUCKSALES_H161: 'NEWTRUCKSALES_E161*(1-NEWTRUCKSALES_F161)',
  NEWTRUCKSALES_I161:
    'RRI(NEWTRUCKSALES_B140-2021,NEWTRUCKSALES_C161,NEWTRUCKSALES_H161)',
  NEWTRUCKSALES_C162: 'NEWTRUCKSALES_C23',
  NEWTRUCKSALES_D162:
    '((NEWTRUCKSALES_D156*NEWTRUCKSALES_C156)+(NEWTRUCKSALES_D157*NEWTRUCKSALES_C157)+(NEWTRUCKSALES_D158*NEWTRUCKSALES_C158)+(NEWTRUCKSALES_D159*NEWTRUCKSALES_C159))/NEWTRUCKSALES_C162',
  NEWTRUCKSALES_E162:
    '(NEWTRUCKSALES_E156 + NEWTRUCKSALES_E157 + NEWTRUCKSALES_E158 + NEWTRUCKSALES_E159)',
  NEWTRUCKSALES_G162:
    '(NEWTRUCKSALES_G156 + NEWTRUCKSALES_G157 + NEWTRUCKSALES_G158 + NEWTRUCKSALES_G159)',
  NEWTRUCKSALES_H162:
    '(NEWTRUCKSALES_H156 + NEWTRUCKSALES_H157 + NEWTRUCKSALES_H158 + NEWTRUCKSALES_H159)',
  NEWTRUCKSALES_I162:
    'RRI(NEWTRUCKSALES_B140-2021,NEWTRUCKSALES_C162,NEWTRUCKSALES_H162)',
  NEWTRUCKSALES_F163:
    'NEWTRUCKSALES_G162/(NEWTRUCKSALES_G162+NEWTRUCKSALES_H162)',
  NEWTRUCKSALES_C164: 'NEWTRUCKSALES_C25',
  NEWTRUCKSALES_E164: 'NEWTRUCKSALES_E162',
  NEWTRUCKSALES_G164: 'NEWTRUCKSALES_G162',
  NEWTRUCKSALES_H164: 'NEWTRUCKSALES_H162',
  NEWTRUCKSALES_G172:
    '((NEWTRUCKSALES_G173*NEWTRUCKSALES_G160)+(NEWTRUCKSALES_G174*NEWTRUCKSALES_G161))/NEWTRUCKSALES_G159',
  NEWTRUCKSALES_D176:
    "IF(NEWTRUCKSALES_C176 == 'NO' ) => 0% ELSE NEWTRUCKSALES_G176",
  NEWTRUCKSALES_C182: 'TRUCKREVENUE_O9',
  NEWTRUCKSALES_D182: 'NEWTRUCKSALES_C182*NEWTRUCKSALES_C156',
  NEWTRUCKSALES_E182: 'NEWTRUCKSALES_D182/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C183: 'TRUCKREVENUE_O14',
  NEWTRUCKSALES_D183: 'NEWTRUCKSALES_C183*NEWTRUCKSALES_C157',
  NEWTRUCKSALES_E183: 'NEWTRUCKSALES_D183/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C184: 'TRUCKREVENUE_O18',
  NEWTRUCKSALES_D184: 'NEWTRUCKSALES_C184*NEWTRUCKSALES_C158',
  NEWTRUCKSALES_E184: 'NEWTRUCKSALES_D184/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C185: 'TRUCKREVENUE_O4',
  NEWTRUCKSALES_D185: 'NEWTRUCKSALES_C185*NEWTRUCKSALES_C159',
  NEWTRUCKSALES_E185: 'NEWTRUCKSALES_D185/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_H185: 'NEWTRUCKSALES_F194/NEWTRUCKSALES_G194',
  NEWTRUCKSALES_D186:
    '(NEWTRUCKSALES_D182 + NEWTRUCKSALES_D183 + NEWTRUCKSALES_D184 + NEWTRUCKSALES_D185)',
  NEWTRUCKSALES_E186: 'NEWTRUCKSALES_D186/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_H186: 'NEWTRUCKSALES_E194/NEWTRUCKSALES_G194',
  NEWTRUCKSALES_E187: 'NEWTRUCKSALES_D187/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C190: 'NEWTRUCKSALES_C169+NEWTRUCKSALES_D169',
  NEWTRUCKSALES_D190: 'NEWTRUCKSALES_C190*NEWTRUCKSALES_G169',
  NEWTRUCKSALES_E190: 'NEWTRUCKSALES_C190*NEWTRUCKSALES_H156',
  NEWTRUCKSALES_F190: 'NEWTRUCKSALES_D190*NEWTRUCKSALES_G156',
  NEWTRUCKSALES_G190:
    '(NEWTRUCKSALES_D190*NEWTRUCKSALES_G156)+(NEWTRUCKSALES_C190*NEWTRUCKSALES_H156)',
  NEWTRUCKSALES_H190: 'NEWTRUCKSALES_G190/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C191: 'NEWTRUCKSALES_C170+NEWTRUCKSALES_D170',
  NEWTRUCKSALES_D191: 'NEWTRUCKSALES_C191*NEWTRUCKSALES_G170',
  NEWTRUCKSALES_E191: 'NEWTRUCKSALES_C191*NEWTRUCKSALES_H157',
  NEWTRUCKSALES_F191: 'NEWTRUCKSALES_D191*NEWTRUCKSALES_G157',
  NEWTRUCKSALES_G191:
    '(NEWTRUCKSALES_D191*NEWTRUCKSALES_G157)+(NEWTRUCKSALES_C191*NEWTRUCKSALES_H157)',
  NEWTRUCKSALES_H191: 'NEWTRUCKSALES_G191/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C192: 'NEWTRUCKSALES_C171+NEWTRUCKSALES_D171',
  NEWTRUCKSALES_D192: 'NEWTRUCKSALES_C192*NEWTRUCKSALES_G171',
  NEWTRUCKSALES_E192: 'NEWTRUCKSALES_C192*NEWTRUCKSALES_H158',
  NEWTRUCKSALES_F192: 'NEWTRUCKSALES_D192*NEWTRUCKSALES_G158',
  NEWTRUCKSALES_G192:
    '(NEWTRUCKSALES_D192*NEWTRUCKSALES_G158)+(NEWTRUCKSALES_C192*NEWTRUCKSALES_H158)',
  NEWTRUCKSALES_H192: 'NEWTRUCKSALES_G192/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C193: 'NEWTRUCKSALES_C172+NEWTRUCKSALES_D172',
  NEWTRUCKSALES_D193: 'NEWTRUCKSALES_C193*NEWTRUCKSALES_G172',
  NEWTRUCKSALES_E193: 'NEWTRUCKSALES_C193*NEWTRUCKSALES_H159',
  NEWTRUCKSALES_F193: 'NEWTRUCKSALES_D193*NEWTRUCKSALES_G159',
  NEWTRUCKSALES_G193:
    '(NEWTRUCKSALES_D193*NEWTRUCKSALES_G159)+(NEWTRUCKSALES_C193*NEWTRUCKSALES_H159)',
  NEWTRUCKSALES_H193: 'NEWTRUCKSALES_G193/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_E194:
    '(NEWTRUCKSALES_E190 + NEWTRUCKSALES_E191 + NEWTRUCKSALES_E192 + NEWTRUCKSALES_E193)',
  NEWTRUCKSALES_F194:
    '(NEWTRUCKSALES_F190 + NEWTRUCKSALES_F191 + NEWTRUCKSALES_F192 + NEWTRUCKSALES_F193)',
  NEWTRUCKSALES_G194:
    '(NEWTRUCKSALES_G190 + NEWTRUCKSALES_G191 + NEWTRUCKSALES_G192 + NEWTRUCKSALES_G193)',
  NEWTRUCKSALES_H194: 'NEWTRUCKSALES_G194/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_E195: 'NEWTRUCKSALES_E194/18',
  NEWTRUCKSALES_F195: 'NEWTRUCKSALES_F194/18',
  NEWTRUCKSALES_C198: 'LARGEFUTUREP&L_H4',
  NEWTRUCKSALES_F198: 'LARGEFUTUREP&L_H43',
  NEWTRUCKSALES_G198: 'NEWTRUCKSALES_F198/NEWTRUCKSALES_C198',
  NEWTRUCKSALES_C199: 'NEWTRUCKSALES_C198*NEWTRUCKSALES_H194',
  NEWTRUCKSALES_F199: 'NEWTRUCKSALES_G199*NEWTRUCKSALES_C199',
  NEWTRUCKSALES_C200: 'NEWTRUCKSALES_C199*NEWTRUCKSALES_H186',
  NEWTRUCKSALES_F200: 'NEWTRUCKSALES_C200*NEWTRUCKSALES_G200',
  NEWTRUCKSALES_C201: 'NEWTRUCKSALES_C199*NEWTRUCKSALES_H185',
  NEWTRUCKSALES_F201: 'NEWTRUCKSALES_C201*NEWTRUCKSALES_G201',
  NEWTRUCKSALES_C202: 'EXTWARRANTYSALESNEWTRUCK_G86',
  NEWTRUCKSALES_F202: 'EXTWARRANTYSALESNEWTRUCK_G86',
  NEWTRUCKSALES_C203: 'CHARGERSALES+FINANCING_D86',
  NEWTRUCKSALES_D203:
    'NEWTRUCKSALES_C201+NEWTRUCKSALES_C202+NEWTRUCKSALES_C203',
  NEWTRUCKSALES_F203: 'CHARGERSALES+FINANCING_D119',
  NEWTRUCKSALES_G203: 'NEWTRUCKSALES_F203/NEWTRUCKSALES_C203',
  NEWTRUCKSALES_H203:
    'NEWTRUCKSALES_F201+NEWTRUCKSALES_F202+NEWTRUCKSALES_F203',
  NEWTRUCKSALES_C204:
    'NEWTRUCKSALES_C199+NEWTRUCKSALES_C203+NEWTRUCKSALES_C202',
  NEWTRUCKSALES_F204:
    'NEWTRUCKSALES_F199+NEWTRUCKSALES_F203+NEWTRUCKSALES_F202',
  NEWTRUCKSALES_G204: 'NEWTRUCKSALES_F204/NEWTRUCKSALES_C204',
  NEWTRUCKSALES_C208: 'NEWTRUCKSALES_C200-NEWTRUCKSALES_C198',
  NEWTRUCKSALES_F208: 'NEWTRUCKSALES_F200-NEWTRUCKSALES_F198',
  NEWTRUCKSALES_C209: 'NEWTRUCKSALES_C201',
  NEWTRUCKSALES_D209: 'NEWTRUCKSALES_C209+NEWTRUCKSALES_C211',
  NEWTRUCKSALES_F209: 'NEWTRUCKSALES_F201',
  NEWTRUCKSALES_G209: 'NEWTRUCKSALES_F209+NEWTRUCKSALES_F211',
  NEWTRUCKSALES_C210: 'NEWTRUCKSALES_C209+NEWTRUCKSALES_C208',
  NEWTRUCKSALES_F210: 'NEWTRUCKSALES_F209+NEWTRUCKSALES_F208',
  NEWTRUCKSALES_C211: 'NEWTRUCKSALES_C203',
  NEWTRUCKSALES_F211: 'NEWTRUCKSALES_F203',
  NEWTRUCKSALES_C212: 'NEWTRUCKSALES_C204-NEWTRUCKSALES_C198',
  NEWTRUCKSALES_F212: 'NEWTRUCKSALES_F204-NEWTRUCKSALES_F198',
  NEWTRUCKSALES_D221: 'NEWTRUCKSALES_D156',
  NEWTRUCKSALES_E221:
    'NEWTRUCKSALES_C221*(NEWTRUCKSALES_D221+1)^(NEWTRUCKSALES_B140-2021)',
  NEWTRUCKSALES_F221: 'NEWTRUCKSALES_F156',
  NEWTRUCKSALES_G221: 'NEWTRUCKSALES_F221*NEWTRUCKSALES_E221',
  NEWTRUCKSALES_H221: 'NEWTRUCKSALES_E221*(1-NEWTRUCKSALES_F221)',
  NEWTRUCKSALES_I221:
    'RRI(NEWTRUCKSALES_B140-2021,NEWTRUCKSALES_C221,NEWTRUCKSALES_H221)',
  NEWTRUCKSALES_D222: 'NEWTRUCKSALES_D157',
  NEWTRUCKSALES_E222:
    'NEWTRUCKSALES_C222*(NEWTRUCKSALES_D222+1)^(NEWTRUCKSALES_B140-2021)',
  NEWTRUCKSALES_F222: 'NEWTRUCKSALES_F157',
  NEWTRUCKSALES_G222: 'NEWTRUCKSALES_F222*NEWTRUCKSALES_E222',
  NEWTRUCKSALES_H222: 'NEWTRUCKSALES_E222*(1-NEWTRUCKSALES_F222)',
  NEWTRUCKSALES_I222:
    'RRI(NEWTRUCKSALES_B140-2021,NEWTRUCKSALES_C222,NEWTRUCKSALES_H222)',
  NEWTRUCKSALES_D223: 'NEWTRUCKSALES_D158',
  NEWTRUCKSALES_E223:
    'NEWTRUCKSALES_C223*(NEWTRUCKSALES_D223+1)^(NEWTRUCKSALES_B140-2021)',
  NEWTRUCKSALES_F223: 'NEWTRUCKSALES_F158',
  NEWTRUCKSALES_G223: 'NEWTRUCKSALES_F223*NEWTRUCKSALES_E223',
  NEWTRUCKSALES_H223: 'NEWTRUCKSALES_E223*(1-NEWTRUCKSALES_F223)',
  NEWTRUCKSALES_I223:
    'RRI(NEWTRUCKSALES_B140-2021,NEWTRUCKSALES_C223,NEWTRUCKSALES_H223)',
  NEWTRUCKSALES_D224: 'NEWTRUCKSALES_D159',
  NEWTRUCKSALES_E224:
    'NEWTRUCKSALES_C224*(NEWTRUCKSALES_D224+1)^(NEWTRUCKSALES_B140-2021)',
  NEWTRUCKSALES_F224:
    'NEWTRUCKSALES_G224/(NEWTRUCKSALES_G224+NEWTRUCKSALES_H224)',
  NEWTRUCKSALES_G224: 'NEWTRUCKSALES_G225+NEWTRUCKSALES_G226',
  NEWTRUCKSALES_H224: 'NEWTRUCKSALES_H225+NEWTRUCKSALES_H226',
  NEWTRUCKSALES_I224:
    'RRI(NEWTRUCKSALES_B140-2021,NEWTRUCKSALES_C224,NEWTRUCKSALES_H224)',
  NEWTRUCKSALES_C225: 'NEWTRUCKSALES_C224*NEWTRUCKSALES_H8',
  NEWTRUCKSALES_D225: 'NEWTRUCKSALES_D160',
  NEWTRUCKSALES_E225:
    'NEWTRUCKSALES_C225*(NEWTRUCKSALES_D225+1)^(NEWTRUCKSALES_B140-2021)',
  NEWTRUCKSALES_F225: 'NEWTRUCKSALES_F160',
  NEWTRUCKSALES_G225: 'NEWTRUCKSALES_F225*NEWTRUCKSALES_E225',
  NEWTRUCKSALES_H225: 'NEWTRUCKSALES_E225*(1-NEWTRUCKSALES_F225)',
  NEWTRUCKSALES_I225:
    'RRI(NEWTRUCKSALES_B140-2021,NEWTRUCKSALES_C225,NEWTRUCKSALES_H225)',
  NEWTRUCKSALES_C226: 'NEWTRUCKSALES_C224*NEWTRUCKSALES_H9',
  NEWTRUCKSALES_D226: 'NEWTRUCKSALES_D161',
  NEWTRUCKSALES_E226:
    'NEWTRUCKSALES_C226*(NEWTRUCKSALES_D226+1)^(NEWTRUCKSALES_B140-2021)',
  NEWTRUCKSALES_F226: 'NEWTRUCKSALES_F161',
  NEWTRUCKSALES_G226: 'NEWTRUCKSALES_F226*NEWTRUCKSALES_E226',
  NEWTRUCKSALES_H226: 'NEWTRUCKSALES_E226*(1-NEWTRUCKSALES_F226)',
  NEWTRUCKSALES_I226:
    'RRI(NEWTRUCKSALES_B140-2021,NEWTRUCKSALES_C226,NEWTRUCKSALES_H226)',
  NEWTRUCKSALES_C227:
    '(NEWTRUCKSALES_C221 + NEWTRUCKSALES_C222 + NEWTRUCKSALES_C223 + NEWTRUCKSALES_C224)',
  NEWTRUCKSALES_D227:
    '((NEWTRUCKSALES_D221*NEWTRUCKSALES_C221)+(NEWTRUCKSALES_D222*NEWTRUCKSALES_C222)+(NEWTRUCKSALES_D223*NEWTRUCKSALES_C223)+(NEWTRUCKSALES_D224*NEWTRUCKSALES_C224))/NEWTRUCKSALES_C227',
  NEWTRUCKSALES_E227:
    '(NEWTRUCKSALES_E221 + NEWTRUCKSALES_E222 + NEWTRUCKSALES_E223 + NEWTRUCKSALES_E224)',
  NEWTRUCKSALES_G227:
    '(NEWTRUCKSALES_G221 + NEWTRUCKSALES_G222 + NEWTRUCKSALES_G223 + NEWTRUCKSALES_G224)',
  NEWTRUCKSALES_H227:
    '(NEWTRUCKSALES_H221 + NEWTRUCKSALES_H222 + NEWTRUCKSALES_H223 + NEWTRUCKSALES_H224)',
  NEWTRUCKSALES_I227:
    'RRI(NEWTRUCKSALES_B140-2021,NEWTRUCKSALES_C227,NEWTRUCKSALES_H227)',
  NEWTRUCKSALES_F228:
    'NEWTRUCKSALES_G227/(NEWTRUCKSALES_G227+NEWTRUCKSALES_H227)',
  NEWTRUCKSALES_C229: 'NEWTRUCKSALES_C227/NUM.UNIQUEDEALERGRPS_D1',
  NEWTRUCKSALES_E229: 'NEWTRUCKSALES_E227/NUM.UNIQUEDEALERGRPS_D1',
  NEWTRUCKSALES_G229: 'NEWTRUCKSALES_G227/NUM.UNIQUEDEALERGRPS_D1',
  NEWTRUCKSALES_H229: 'NEWTRUCKSALES_H227/NUM.UNIQUEDEALERGRPS_D1',
  NEWTRUCKSALES_C234: 'NEWTRUCKSALES_C169',
  NEWTRUCKSALES_D234: 'NEWTRUCKSALES_D169',
  NEWTRUCKSALES_G234: 'NEWTRUCKSALES_G169',
  NEWTRUCKSALES_C235: 'NEWTRUCKSALES_C170',
  NEWTRUCKSALES_D235: 'NEWTRUCKSALES_D170',
  NEWTRUCKSALES_G235: 'NEWTRUCKSALES_G170',
  NEWTRUCKSALES_C236: 'NEWTRUCKSALES_C171',
  NEWTRUCKSALES_D236: 'NEWTRUCKSALES_D171',
  NEWTRUCKSALES_G236: 'NEWTRUCKSALES_G171',
  NEWTRUCKSALES_C237: 'NEWTRUCKSALES_C172',
  NEWTRUCKSALES_D237: 'NEWTRUCKSALES_D172',
  NEWTRUCKSALES_G237:
    '((NEWTRUCKSALES_G238*NEWTRUCKSALES_G225)+(NEWTRUCKSALES_G239*NEWTRUCKSALES_G226))/NEWTRUCKSALES_G224',
  NEWTRUCKSALES_G238: 'NEWTRUCKSALES_G173',
  NEWTRUCKSALES_G239: 'NEWTRUCKSALES_G174',
  NEWTRUCKSALES_D241:
    "IF(NEWTRUCKSALES_C241 == 'NO' ) => 0% ELSE NEWTRUCKSALES_G241",
  NEWTRUCKSALES_C247: 'TRUCKREVENUE_O9',
  NEWTRUCKSALES_D247: 'NEWTRUCKSALES_C247*NEWTRUCKSALES_C221',
  NEWTRUCKSALES_E247: 'NEWTRUCKSALES_D247/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C248: 'TRUCKREVENUE_O14',
  NEWTRUCKSALES_D248: 'NEWTRUCKSALES_C248*NEWTRUCKSALES_C222',
  NEWTRUCKSALES_E248: 'NEWTRUCKSALES_D248/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C249: 'TRUCKREVENUE_O18',
  NEWTRUCKSALES_D249: 'NEWTRUCKSALES_C249*NEWTRUCKSALES_C223',
  NEWTRUCKSALES_E249: 'NEWTRUCKSALES_D249/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C250: 'TRUCKREVENUE_O4',
  NEWTRUCKSALES_D250: 'NEWTRUCKSALES_C250*NEWTRUCKSALES_C224',
  NEWTRUCKSALES_E250: 'NEWTRUCKSALES_D250/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_H250: 'NEWTRUCKSALES_F259/NEWTRUCKSALES_G259',
  NEWTRUCKSALES_D251:
    '(NEWTRUCKSALES_D247 + NEWTRUCKSALES_D248 + NEWTRUCKSALES_D249 + NEWTRUCKSALES_D250)',
  NEWTRUCKSALES_E251: 'NEWTRUCKSALES_D251/NEWTRUCKSALES_D251',
  NEWTRUCKSALES_H251: 'NEWTRUCKSALES_E259/NEWTRUCKSALES_G259',
  NEWTRUCKSALES_C255: 'NEWTRUCKSALES_C234+NEWTRUCKSALES_D234',
  NEWTRUCKSALES_D255: 'NEWTRUCKSALES_C255*NEWTRUCKSALES_G234',
  NEWTRUCKSALES_E255: 'NEWTRUCKSALES_C255*NEWTRUCKSALES_H221',
  NEWTRUCKSALES_F255: 'NEWTRUCKSALES_D255*NEWTRUCKSALES_G221',
  NEWTRUCKSALES_G255:
    '(NEWTRUCKSALES_D255*NEWTRUCKSALES_G221)+(NEWTRUCKSALES_C255*NEWTRUCKSALES_H221)',
  NEWTRUCKSALES_H255: 'NEWTRUCKSALES_G255/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C256: 'NEWTRUCKSALES_C235+NEWTRUCKSALES_D235',
  NEWTRUCKSALES_D256: 'NEWTRUCKSALES_C256*NEWTRUCKSALES_G235',
  NEWTRUCKSALES_E256: 'NEWTRUCKSALES_C256*NEWTRUCKSALES_H222',
  NEWTRUCKSALES_F256: 'NEWTRUCKSALES_D256*NEWTRUCKSALES_G222',
  NEWTRUCKSALES_G256:
    '(NEWTRUCKSALES_D256*NEWTRUCKSALES_G222)+(NEWTRUCKSALES_C256*NEWTRUCKSALES_H222)',
  NEWTRUCKSALES_H256: 'NEWTRUCKSALES_G256/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C257: 'NEWTRUCKSALES_C236+NEWTRUCKSALES_D236',
  NEWTRUCKSALES_D257: 'NEWTRUCKSALES_C257*NEWTRUCKSALES_G236',
  NEWTRUCKSALES_E257: 'NEWTRUCKSALES_C257*NEWTRUCKSALES_H223',
  NEWTRUCKSALES_F257: 'NEWTRUCKSALES_D257*NEWTRUCKSALES_G223',
  NEWTRUCKSALES_G257:
    '(NEWTRUCKSALES_D257*NEWTRUCKSALES_G223)+(NEWTRUCKSALES_C257*NEWTRUCKSALES_H223)',
  NEWTRUCKSALES_H257: 'NEWTRUCKSALES_G257/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_C258: 'NEWTRUCKSALES_C237+NEWTRUCKSALES_D237',
  NEWTRUCKSALES_D258: 'NEWTRUCKSALES_C258*NEWTRUCKSALES_G237',
  NEWTRUCKSALES_E258: 'NEWTRUCKSALES_C258*NEWTRUCKSALES_H224',
  NEWTRUCKSALES_F258: 'NEWTRUCKSALES_D258*NEWTRUCKSALES_G224',
  NEWTRUCKSALES_G258:
    '(NEWTRUCKSALES_D258*NEWTRUCKSALES_G224)+(NEWTRUCKSALES_C258*NEWTRUCKSALES_H224)',
  NEWTRUCKSALES_H258: 'NEWTRUCKSALES_G258/NEWTRUCKSALES_D47',
  NEWTRUCKSALES_E259:
    '(NEWTRUCKSALES_E255 + NEWTRUCKSALES_E256 + NEWTRUCKSALES_E257 + NEWTRUCKSALES_E258)',
  NEWTRUCKSALES_F259:
    '(NEWTRUCKSALES_F255 + NEWTRUCKSALES_F256 + NEWTRUCKSALES_F257 + NEWTRUCKSALES_F258)',
  NEWTRUCKSALES_G259:
    '(NEWTRUCKSALES_G255 + NEWTRUCKSALES_G256 + NEWTRUCKSALES_G257 + NEWTRUCKSALES_G258)',
  NEWTRUCKSALES_H259: 'NEWTRUCKSALES_G259/NEWTRUCKSALES_D251',
  NEWTRUCKSALES_E260: 'NEWTRUCKSALES_E259/NUM.UNIQUEDEALERGRPS_D1',
  NEWTRUCKSALES_F260: 'NEWTRUCKSALES_F259/18',
  NEWTRUCKSALES_C263: 'MEDIUMFUTUREP&L_G4',
  NEWTRUCKSALES_F263: 'MEDIUMFUTUREP&L_G43',
  NEWTRUCKSALES_G263: 'NEWTRUCKSALES_F263/NEWTRUCKSALES_C263',
  NEWTRUCKSALES_C264: 'NEWTRUCKSALES_C263*NEWTRUCKSALES_H259',
  NEWTRUCKSALES_F264: 'NEWTRUCKSALES_G264*NEWTRUCKSALES_C264',
  NEWTRUCKSALES_C265: 'NEWTRUCKSALES_C264*NEWTRUCKSALES_H251',
  NEWTRUCKSALES_F265: 'NEWTRUCKSALES_C265*NEWTRUCKSALES_G265',
  NEWTRUCKSALES_C266: 'NEWTRUCKSALES_C264*NEWTRUCKSALES_H250',
  NEWTRUCKSALES_F266: 'NEWTRUCKSALES_C266*NEWTRUCKSALES_G266',
  NEWTRUCKSALES_C267: 'EXTWARRANTYSALESNEWTRUCK_G180',
  NEWTRUCKSALES_F267: 'EXTWARRANTYSALESNEWTRUCK_G180',
  NEWTRUCKSALES_C268: 'CHARGERSALES+FINANCING_D204',
  NEWTRUCKSALES_F268: 'CHARGERSALES+FINANCING_D237',
  NEWTRUCKSALES_G268: 'NEWTRUCKSALES_F268/NEWTRUCKSALES_C268',
  NEWTRUCKSALES_H268:
    'NEWTRUCKSALES_F267+NEWTRUCKSALES_F268+NEWTRUCKSALES_F266',
  NEWTRUCKSALES_C269: 'NEWTRUCKSALES_C264+NEWTRUCKSALES_C268',
  NEWTRUCKSALES_F269:
    'NEWTRUCKSALES_F264+NEWTRUCKSALES_F268+NEWTRUCKSALES_F267',
  NEWTRUCKSALES_G269: 'NEWTRUCKSALES_F269/NEWTRUCKSALES_C269',
  NEWTRUCKSALES_C273: 'NEWTRUCKSALES_C265-NEWTRUCKSALES_C263',
  NEWTRUCKSALES_F273: 'NEWTRUCKSALES_F265-NEWTRUCKSALES_F263',
  NEWTRUCKSALES_C274: 'NEWTRUCKSALES_C266',
  NEWTRUCKSALES_F274: 'NEWTRUCKSALES_F266',
  NEWTRUCKSALES_C275: 'NEWTRUCKSALES_C274+NEWTRUCKSALES_C273',
  NEWTRUCKSALES_D275: 'NEWTRUCKSALES_C274+NEWTRUCKSALES_C276',
  NEWTRUCKSALES_F275: 'NEWTRUCKSALES_F274+NEWTRUCKSALES_F273',
  NEWTRUCKSALES_G275: 'NEWTRUCKSALES_F274+NEWTRUCKSALES_F276',
  NEWTRUCKSALES_C276: 'NEWTRUCKSALES_C268',
  NEWTRUCKSALES_F276: 'NEWTRUCKSALES_F268',
  NEWTRUCKSALES_C277: 'NEWTRUCKSALES_C269-NEWTRUCKSALES_C263',
  NEWTRUCKSALES_F277: 'NEWTRUCKSALES_F269-NEWTRUCKSALES_F263',
  USEDTRUCKSALES_C6: "'BEV adoption scenarios'!USEDTRUCKSALES_K7",
  USEDTRUCKSALES_C7: "'BEV adoption scenarios'!USEDTRUCKSALES_K7",
  USEDTRUCKSALES_C8: "'BEV adoption scenarios'!USEDTRUCKSALES_K7",
  USEDTRUCKSALES_C10: "'BEV adoption scenarios'!USEDTRUCKSALES_K5",
  USEDTRUCKSALES_C11: "'BEV adoption scenarios'!USEDTRUCKSALES_K6",
  USEDTRUCKSALES_C12: "'BEV adoption scenarios'!USEDTRUCKSALES_K6",
  USEDTRUCKSALES_C13:
    "IF(INTERACTIVETAB_G30 == '') => INTERACTIVETAB_H30 ELSE INTERACTIVETAB_G30",
  USEDTRUCKSALES_C16:
    "IF(INTERACTIVETAB_G61 == '') => NEWTRUCKSALES_D162 ELSE INTERACTIVETAB_G61",
  USEDTRUCKSALES_C22: 'USEDTRUCKSALES_D22',
  USEDTRUCKSALES_C23: 'USEDTRUCKSALES_D23',
  USEDTRUCKSALES_D23:
    "USEDTRUCKSALES_D22/'Large Current P&L'!USEDTRUCKSALES_D4",
  USEDTRUCKSALES_C24: 'USEDTRUCKSALES_D24',
  USEDTRUCKSALES_C25: 'USEDTRUCKSALES_D25',
  USEDTRUCKSALES_C27: 'USEDTRUCKSALES_D27',
  USEDTRUCKSALES_D27: "'Large Current P&L'!USEDTRUCKSALES_D23",
  USEDTRUCKSALES_C28: 'USEDTRUCKSALES_D28',
  USEDTRUCKSALES_D28: "'Large Current P&L'!USEDTRUCKSALES_E23",
  USEDTRUCKSALES_C32: 'USEDTRUCKSALES_D32',
  USEDTRUCKSALES_C33: 'USEDTRUCKSALES_D33',
  USEDTRUCKSALES_C34: 'USEDTRUCKSALES_D34',
  USEDTRUCKSALES_C35: 'USEDTRUCKSALES_D35',
  USEDTRUCKSALES_C36: 'USEDTRUCKSALES_D36',
  USEDTRUCKSALES_C38: 'USEDTRUCKSALES_C39+USEDTRUCKSALES_C40',
  USEDTRUCKSALES_D38: 'USEDTRUCKSALES_D39+USEDTRUCKSALES_D40',
  USEDTRUCKSALES_C39:
    '(USEDTRUCKSALES_C25*(1+USEDTRUCKSALES_C16)^(USEDTRUCKSALES_C2-2021))*(1-USEDTRUCKSALES_C13)*(1+USEDTRUCKSALES_C33)',
  USEDTRUCKSALES_C40:
    '(USEDTRUCKSALES_C25*(1+USEDTRUCKSALES_C16)^(USEDTRUCKSALES_C2-2021))*(USEDTRUCKSALES_C13)*USEDTRUCKSALES_C32',
  USEDTRUCKSALES_C42:
    '((USEDTRUCKSALES_C43*USEDTRUCKSALES_C39)+(USEDTRUCKSALES_C44*USEDTRUCKSALES_C40))/USEDTRUCKSALES_C38',
  USEDTRUCKSALES_D42:
    '((USEDTRUCKSALES_D43*USEDTRUCKSALES_D39)+(USEDTRUCKSALES_D44*USEDTRUCKSALES_D40))/USEDTRUCKSALES_D38',
  USEDTRUCKSALES_C43: 'USEDTRUCKSALES_C24*(1+USEDTRUCKSALES_C35)',
  USEDTRUCKSALES_C44:
    '(USEDTRUCKSALES_C24*(1+USEDTRUCKSALES_C35))*USEDTRUCKSALES_C34',
  USEDTRUCKSALES_C46: 'USEDTRUCKSALES_C47+USEDTRUCKSALES_C48',
  USEDTRUCKSALES_D46: 'USEDTRUCKSALES_D47+USEDTRUCKSALES_D48',
  USEDTRUCKSALES_C47: 'USEDTRUCKSALES_C43*USEDTRUCKSALES_C39',
  USEDTRUCKSALES_C48: 'USEDTRUCKSALES_C44*USEDTRUCKSALES_C40',
  USEDTRUCKSALES_C50: 'USEDTRUCKSALES_C51+USEDTRUCKSALES_C52',
  USEDTRUCKSALES_D50: 'USEDTRUCKSALES_D51+USEDTRUCKSALES_D52',
  USEDTRUCKSALES_C51: 'USEDTRUCKSALES_C47*USEDTRUCKSALES_C36',
  USEDTRUCKSALES_C52: 'USEDTRUCKSALES_C48*USEDTRUCKSALES_C36',
  USEDTRUCKSALES_C56: 'USEDTRUCKSALES_C47-USEDTRUCKSALES_C22',
  USEDTRUCKSALES_D56: 'USEDTRUCKSALES_D47-USEDTRUCKSALES_D22',
  USEDTRUCKSALES_C57: 'USEDTRUCKSALES_C48',
  USEDTRUCKSALES_D57: 'USEDTRUCKSALES_D48',
  USEDTRUCKSALES_C58: 'USEDTRUCKSALES_C56+USEDTRUCKSALES_C57',
  USEDTRUCKSALES_D58: 'USEDTRUCKSALES_D56+USEDTRUCKSALES_D57',
  USEDTRUCKSALES_C59: 'USEDTRUCKSALES_C51-USEDTRUCKSALES_C27',
  USEDTRUCKSALES_D59: 'USEDTRUCKSALES_D51-USEDTRUCKSALES_D27',
  USEDTRUCKSALES_C60: 'USEDTRUCKSALES_C52',
  USEDTRUCKSALES_D60: 'USEDTRUCKSALES_D52',
  USEDTRUCKSALES_C61: 'USEDTRUCKSALES_C59+USEDTRUCKSALES_C60',
  USEDTRUCKSALES_D61: 'USEDTRUCKSALES_D59+USEDTRUCKSALES_D60',
  USEDTRUCKSALES_C68: 'USEDTRUCKSALES_D68',
  USEDTRUCKSALES_D68: 'MEDIUMCURRENTP&L_D5',
  USEDTRUCKSALES_C69: 'USEDTRUCKSALES_D69',
  USEDTRUCKSALES_D69: 'USEDTRUCKSALES_D68/MEDIUMCURRENTP&L_D4',
  USEDTRUCKSALES_C70: 'USEDTRUCKSALES_D70',
  USEDTRUCKSALES_D70:
    '(USEDTRUCKSALES_C198 + USEDTRUCKSALES_D198 + USEDTRUCKSALES_E198 + USEDTRUCKSALES_F198)',
  USEDTRUCKSALES_C71: 'USEDTRUCKSALES_D71',
  USEDTRUCKSALES_D71: 'USEDTRUCKSALES_D68/USEDTRUCKSALES_D70',
  USEDTRUCKSALES_C72: 'USEDTRUCKSALES_D72',
  USEDTRUCKSALES_C73: 'USEDTRUCKSALES_D73',
  USEDTRUCKSALES_D73: 'MEDIUMCURRENTP&L_D23',
  USEDTRUCKSALES_C74: 'USEDTRUCKSALES_D74',
  USEDTRUCKSALES_D74: 'MEDIUMCURRENTP&L_E23',
  USEDTRUCKSALES_C84: 'USEDTRUCKSALES_C85+USEDTRUCKSALES_C86',
  USEDTRUCKSALES_D84: 'USEDTRUCKSALES_D85+USEDTRUCKSALES_D86',
  USEDTRUCKSALES_C85:
    '(USEDTRUCKSALES_C71*(1+USEDTRUCKSALES_C16)^(USEDTRUCKSALES_C2-2021))*(1-USEDTRUCKSALES_C13)*(1+USEDTRUCKSALES_C79)',
  USEDTRUCKSALES_D85:
    '(USEDTRUCKSALES_D71*(1+USEDTRUCKSALES_D16)^(2030-2021))*(1-USEDTRUCKSALES_D13)*(1+USEDTRUCKSALES_D79)',
  USEDTRUCKSALES_C86:
    '(USEDTRUCKSALES_C71*(1+USEDTRUCKSALES_C16)^(USEDTRUCKSALES_C2-2021))*(USEDTRUCKSALES_C13)*USEDTRUCKSALES_C78',
  USEDTRUCKSALES_D86:
    '(USEDTRUCKSALES_D71*(1+USEDTRUCKSALES_D16)^(2030-2021))*(USEDTRUCKSALES_D13)*USEDTRUCKSALES_D78',
  USEDTRUCKSALES_C88:
    '((USEDTRUCKSALES_C89*USEDTRUCKSALES_C85)+(USEDTRUCKSALES_C90*USEDTRUCKSALES_C86))/USEDTRUCKSALES_C84',
  USEDTRUCKSALES_D88:
    '((USEDTRUCKSALES_D89*USEDTRUCKSALES_D85)+(USEDTRUCKSALES_D90*USEDTRUCKSALES_D86))/USEDTRUCKSALES_D84',
  USEDTRUCKSALES_C89: 'USEDTRUCKSALES_C70*(1+USEDTRUCKSALES_C81)',
  USEDTRUCKSALES_D89: 'USEDTRUCKSALES_D70*(1+USEDTRUCKSALES_D81)',
  USEDTRUCKSALES_C90:
    '(USEDTRUCKSALES_C70*(1+USEDTRUCKSALES_C81))*USEDTRUCKSALES_C80',
  USEDTRUCKSALES_D90:
    '(USEDTRUCKSALES_D70*(1+USEDTRUCKSALES_D81))*USEDTRUCKSALES_D80',
  USEDTRUCKSALES_C92: 'USEDTRUCKSALES_C93+USEDTRUCKSALES_C94',
  USEDTRUCKSALES_D92: 'USEDTRUCKSALES_D93+USEDTRUCKSALES_D94',
  USEDTRUCKSALES_C93: 'USEDTRUCKSALES_C89*USEDTRUCKSALES_C85',
  USEDTRUCKSALES_D93: 'USEDTRUCKSALES_D89*USEDTRUCKSALES_D85',
  USEDTRUCKSALES_C94: 'USEDTRUCKSALES_C90*USEDTRUCKSALES_C86',
  USEDTRUCKSALES_D94: 'USEDTRUCKSALES_D90*USEDTRUCKSALES_D86',
  USEDTRUCKSALES_C96: 'USEDTRUCKSALES_C97+USEDTRUCKSALES_C98',
  USEDTRUCKSALES_D96: 'USEDTRUCKSALES_D97+USEDTRUCKSALES_D98',
  USEDTRUCKSALES_C97: 'USEDTRUCKSALES_C93*USEDTRUCKSALES_C82',
  USEDTRUCKSALES_D97: 'USEDTRUCKSALES_D93*USEDTRUCKSALES_D82',
  USEDTRUCKSALES_C98: 'USEDTRUCKSALES_C94*USEDTRUCKSALES_C82',
  USEDTRUCKSALES_D98: 'USEDTRUCKSALES_D94*USEDTRUCKSALES_D82',
  USEDTRUCKSALES_C102: 'USEDTRUCKSALES_C93-USEDTRUCKSALES_C68',
  USEDTRUCKSALES_D102: 'USEDTRUCKSALES_D93-USEDTRUCKSALES_D68',
  USEDTRUCKSALES_C103: 'USEDTRUCKSALES_C94',
  USEDTRUCKSALES_D103: 'USEDTRUCKSALES_D94',
  USEDTRUCKSALES_C104: 'USEDTRUCKSALES_C102+USEDTRUCKSALES_C103',
  USEDTRUCKSALES_D104: 'USEDTRUCKSALES_D102+USEDTRUCKSALES_D103',
  USEDTRUCKSALES_C105: 'USEDTRUCKSALES_C97-USEDTRUCKSALES_C73',
  USEDTRUCKSALES_D105: 'USEDTRUCKSALES_D97-USEDTRUCKSALES_D73',
  USEDTRUCKSALES_C106: 'USEDTRUCKSALES_C98',
  USEDTRUCKSALES_D106: 'USEDTRUCKSALES_D98',
  USEDTRUCKSALES_C107: 'USEDTRUCKSALES_C105+USEDTRUCKSALES_C106',
  USEDTRUCKSALES_D107: 'USEDTRUCKSALES_D105+USEDTRUCKSALES_D106',
  'PARTS&SERVICE_C13':
    "IF(INTERACTIVETAB_G30 == '') => INTERACTIVETAB_H30 ELSE INTERACTIVETAB_G30",
  'PARTS&SERVICE_C16':
    "IF(INTERACTIVETAB_G61 == '') => NEW_TRUCK_SALES!PARTS&SERVICE_D162 ELSE INTERACTIVETAB_G61",
  'PARTS&SERVICE_C25': 'PARTS&SERVICE_D25',
  'PARTS&SERVICE_C27': 'PARTS&SERVICE_D27',
  'PARTS&SERVICE_C28': 'PARTS&SERVICE_D28',
  'PARTS&SERVICE_C29': 'PARTS&SERVICE_D29',
  'PARTS&SERVICE_C30': 'PARTS&SERVICE_D30',
  'PARTS&SERVICE_C31': 'PARTS&SERVICE_D31',
  'PARTS&SERVICE_C32': 'PARTS&SERVICE_D32',
  'PARTS&SERVICE_C34': 'PARTS&SERVICE_D34',
  'PARTS&SERVICE_C35': 'PARTS&SERVICE_D35',
  'PARTS&SERVICE_C37': 'PARTS&SERVICE_D37',
  'PARTS&SERVICE_C39': 'PARTS&SERVICE_D39',
  'PARTS&SERVICE_C43':
    'PARTS&SERVICE_C39*(PARTS&SERVICE_C16+1)^(PARTS&SERVICE_C41-2021)',
  'PARTS&SERVICE_C46': '1-PARTS&SERVICE_C13',
  'PARTS&SERVICE_C49':
    "IF(INTERACTIVETAB_G47 == '') => (PARTS&SERVICE_C51+(PARTS&SERVICE_C52*PARTS&SERVICE_C53))/PARTS&SERVICE_C50 ELSE INTERACTIVETAB_G47",
  'PARTS&SERVICE_C54': '1-PARTS&SERVICE_C49',
  'PARTS&SERVICE_C57':
    "IF(INTERACTIVETAB_G48 == '') => INTERACTIVETAB_H48 ELSE INTERACTIVETAB_G48",
  'PARTS&SERVICE_C58':
    "IF(INTERACTIVETAB_G49 == '') => INTERACTIVETAB_H49 ELSE INTERACTIVETAB_G49",
  'PARTS&SERVICE_C60':
    '(PARTS&SERVICE_C49*PARTS&SERVICE_C57)+PARTS&SERVICE_C54*PARTS&SERVICE_C58',
  'PARTS&SERVICE_C62': 'PARTS&SERVICE_C151',
  'PARTS&SERVICE_D62': 'PARTS&SERVICE_D151',
  'PARTS&SERVICE_C65': 'PARTS&SERVICE_C13',
  'PARTS&SERVICE_C68':
    "IF(INTERACTIVETAB_G57 == '') => INTERACTIVETAB_H57 ELSE INTERACTIVETAB_G57",
  'PARTS&SERVICE_C69':
    "IF(INTERACTIVETAB_G58 == '') => INTERACTIVETAB_H58 ELSE INTERACTIVETAB_G58",
  'PARTS&SERVICE_C72':
    "IF(INTERACTIVETAB_G52 == '') => (PARTS&SERVICE_C74+(PARTS&SERVICE_C75*PARTS&SERVICE_C76))/PARTS&SERVICE_C73 ELSE INTERACTIVETAB_G52",
  'PARTS&SERVICE_C77': '1-PARTS&SERVICE_C72',
  'PARTS&SERVICE_C80':
    "IF(INTERACTIVETAB_G53 == '') => INTERACTIVETAB_H53 ELSE INTERACTIVETAB_G53",
  'PARTS&SERVICE_C81':
    "IF(INTERACTIVETAB_G54 == '') => INTERACTIVETAB_H54 ELSE INTERACTIVETAB_G54",
  'PARTS&SERVICE_C83':
    '(PARTS&SERVICE_C72*PARTS&SERVICE_C80)+PARTS&SERVICE_C77*PARTS&SERVICE_C81',
  'PARTS&SERVICE_C85': 'PARTS&SERVICE_C62',
  'PARTS&SERVICE_D85': 'PARTS&SERVICE_D62',
  'PARTS&SERVICE_C88': 'PARTS&SERVICE_C43*PARTS&SERVICE_C46*PARTS&SERVICE_C60',
  'PARTS&SERVICE_C89': 'PARTS&SERVICE_C88*PARTS&SERVICE_C62',
  'PARTS&SERVICE_D89': 'PARTS&SERVICE_D88*PARTS&SERVICE_D62',
  'PARTS&SERVICE_C90':
    'PARTS&SERVICE_C43*PARTS&SERVICE_C65*PARTS&SERVICE_C83*(1+PARTS&SERVICE_C68)*(1+PARTS&SERVICE_C69)',
  'PARTS&SERVICE_C91': 'PARTS&SERVICE_C90*PARTS&SERVICE_C85',
  'PARTS&SERVICE_D91': 'PARTS&SERVICE_D90*PARTS&SERVICE_D85',
  'PARTS&SERVICE_C92': 'PARTS&SERVICE_C88+PARTS&SERVICE_C90',
  'PARTS&SERVICE_D92': 'PARTS&SERVICE_D88+PARTS&SERVICE_D90',
  'PARTS&SERVICE_F92': 'PARTS&SERVICE_D90/PARTS&SERVICE_D92',
  'PARTS&SERVICE_C96': 'PARTS&SERVICE_D96',
  'PARTS&SERVICE_C98': 'PARTS&SERVICE_C88*PARTS&SERVICE_C96',
  'PARTS&SERVICE_C99': "PARTS&SERVICE_C98-'LARGEFUTUREP&L_H45",
  'PARTS&SERVICE_D99': "PARTS&SERVICE_D98-'LARGEFUTUREP&L_H45",
  'PARTS&SERVICE_C101': '(33%-10%)/33%',
  'PARTS&SERVICE_C103':
    '(-PARTS&SERVICE_C101*PARTS&SERVICE_C96)+PARTS&SERVICE_C96',
  'PARTS&SERVICE_C104':
    '(PARTS&SERVICE_C102*PARTS&SERVICE_C103)+(1-PARTS&SERVICE_C102)*PARTS&SERVICE_C96',
  'PARTS&SERVICE_C105': 'PARTS&SERVICE_C104*PARTS&SERVICE_C90',
  'PARTS&SERVICE_C106': 'PARTS&SERVICE_C105+PARTS&SERVICE_C98',
  'PARTS&SERVICE_D106': 'PARTS&SERVICE_D105+PARTS&SERVICE_D98',
  'PARTS&SERVICE_C107': 'PARTS&SERVICE_C106+PARTS&SERVICE_C196',
  'PARTS&SERVICE_D107': 'PARTS&SERVICE_D106+PARTS&SERVICE_D196',
  'PARTS&SERVICE_C114': 'PARTS&SERVICE_D114',
  'PARTS&SERVICE_C116': 'PARTS&SERVICE_C27',
  'PARTS&SERVICE_C117': 'PARTS&SERVICE_C28',
  'PARTS&SERVICE_D117': 'PARTS&SERVICE_D28',
  'PARTS&SERVICE_C118': 'PARTS&SERVICE_C29',
  'PARTS&SERVICE_D118': 'PARTS&SERVICE_D29',
  'PARTS&SERVICE_C119': 'PARTS&SERVICE_C30',
  'PARTS&SERVICE_D119': 'PARTS&SERVICE_D30',
  'PARTS&SERVICE_C120': 'PARTS&SERVICE_C31',
  'PARTS&SERVICE_D120': 'PARTS&SERVICE_D31',
  'PARTS&SERVICE_C121': 'PARTS&SERVICE_C32',
  'PARTS&SERVICE_C123': 'PARTS&SERVICE_D123',
  'PARTS&SERVICE_C124': 'PARTS&SERVICE_D124',
  'PARTS&SERVICE_C126':
    '(PARTS&SERVICE_C116*PARTS&SERVICE_C123)+(PARTS&SERVICE_C121*PARTS&SERVICE_C124)',
  'PARTS&SERVICE_C128': 'PARTS&SERVICE_C114/PARTS&SERVICE_C126',
  'PARTS&SERVICE_C132':
    'PARTS&SERVICE_C128*(PARTS&SERVICE_C16+1)^(PARTS&SERVICE_C130-2021)',
  'PARTS&SERVICE_C135': '1-PARTS&SERVICE_C13',
  'PARTS&SERVICE_C138': 'PARTS&SERVICE_C49',
  'PARTS&SERVICE_C139': 'PARTS&SERVICE_C50',
  'PARTS&SERVICE_D139': 'PARTS&SERVICE_D50',
  'PARTS&SERVICE_C140': 'PARTS&SERVICE_C51',
  'PARTS&SERVICE_D140': 'PARTS&SERVICE_D51',
  'PARTS&SERVICE_C141': 'PARTS&SERVICE_C52',
  'PARTS&SERVICE_D141': 'PARTS&SERVICE_D52',
  'PARTS&SERVICE_C142': 'PARTS&SERVICE_C53',
  'PARTS&SERVICE_D142': 'PARTS&SERVICE_D53',
  'PARTS&SERVICE_C143': 'PARTS&SERVICE_C54',
  'PARTS&SERVICE_C146':
    "IF(INTERACTIVETAB_G66 == '') => INTERACTIVETAB_H66 ELSE INTERACTIVETAB_G66",
  'PARTS&SERVICE_C147':
    "IF(INTERACTIVETAB_G67 == '') => INTERACTIVETAB_H67 ELSE INTERACTIVETAB_G67",
  'PARTS&SERVICE_C149':
    '(PARTS&SERVICE_C138*PARTS&SERVICE_C146)+PARTS&SERVICE_C143*PARTS&SERVICE_C147',
  'PARTS&SERVICE_C151':
    "IF(INTERACTIVETAB_G78 == '') => INTERACTIVETAB_H78 ELSE INTERACTIVETAB_G78",
  'PARTS&SERVICE_C154': 'PARTS&SERVICE_C13',
  'PARTS&SERVICE_C157':
    "IF(INTERACTIVETAB_G74 == '') => INTERACTIVETAB_H74 ELSE INTERACTIVETAB_G74",
  'PARTS&SERVICE_C158':
    "IF(INTERACTIVETAB_G75 == '') => INTERACTIVETAB_H75 ELSE INTERACTIVETAB_G75",
  'PARTS&SERVICE_C161': 'PARTS&SERVICE_C72',
  'PARTS&SERVICE_C162': 'PARTS&SERVICE_C73',
  'PARTS&SERVICE_D162': 'PARTS&SERVICE_D73',
  'PARTS&SERVICE_C163': 'PARTS&SERVICE_C74',
  'PARTS&SERVICE_D163': 'PARTS&SERVICE_D74',
  'PARTS&SERVICE_C164': 'PARTS&SERVICE_C75',
  'PARTS&SERVICE_D164': 'PARTS&SERVICE_D75',
  'PARTS&SERVICE_C165': 'PARTS&SERVICE_C76',
  'PARTS&SERVICE_D165': 'PARTS&SERVICE_D76',
  'PARTS&SERVICE_C166': 'PARTS&SERVICE_C77',
  'PARTS&SERVICE_C169':
    "IF(INTERACTIVETAB_G70 == '') => INTERACTIVETAB_H70 ELSE INTERACTIVETAB_G70",
  'PARTS&SERVICE_C170':
    "IF(INTERACTIVETAB_G71 == '') => INTERACTIVETAB_H71 ELSE INTERACTIVETAB_G71",
  'PARTS&SERVICE_C172':
    '(PARTS&SERVICE_C161*PARTS&SERVICE_C169)+PARTS&SERVICE_C166*PARTS&SERVICE_C170',
  'PARTS&SERVICE_C174': 'PARTS&SERVICE_C151',
  'PARTS&SERVICE_C178': 'PARTS&SERVICE_C179+PARTS&SERVICE_C180',
  'PARTS&SERVICE_I178':
    '(PARTS&SERVICE_D178-PARTS&SERVICE_D114)/PARTS&SERVICE_D114',
  'PARTS&SERVICE_C179':
    'PARTS&SERVICE_C132*PARTS&SERVICE_C135*PARTS&SERVICE_C149*(1-PARTS&SERVICE_C151)',
  'PARTS&SERVICE_F179':
    '(PARTS&SERVICE_D179+PARTS&SERVICE_D182+PARTS&SERVICE_D88-PARTS&SERVICE_D89+PARTS&SERVICE_D90-PARTS&SERVICE_D91-PARTS&SERVICE_D114-PARTS&SERVICE_D25)/(PARTS&SERVICE_D114+PARTS&SERVICE_D25)',
  'PARTS&SERVICE_C180':
    'PARTS&SERVICE_C132*PARTS&SERVICE_C135*PARTS&SERVICE_C149*PARTS&SERVICE_C151*(1+PARTS&SERVICE_C175)',
  'PARTS&SERVICE_C181': 'PARTS&SERVICE_C182+PARTS&SERVICE_C183',
  'PARTS&SERVICE_C182':
    'PARTS&SERVICE_C132*PARTS&SERVICE_C154*PARTS&SERVICE_C172*(1-PARTS&SERVICE_C174)*(1+PARTS&SERVICE_C157)*(1+PARTS&SERVICE_C158)',
  'PARTS&SERVICE_C183':
    'PARTS&SERVICE_C132*PARTS&SERVICE_C154*PARTS&SERVICE_C172*PARTS&SERVICE_C174*(1+PARTS&SERVICE_C175)*(1+PARTS&SERVICE_C157)*(1+PARTS&SERVICE_C158)',
  'PARTS&SERVICE_C184': 'PARTS&SERVICE_C178+PARTS&SERVICE_C181',
  'PARTS&SERVICE_D184': 'PARTS&SERVICE_D178+PARTS&SERVICE_D181',
  'PARTS&SERVICE_F184': 'PARTS&SERVICE_D181/PARTS&SERVICE_D184',
  'PARTS&SERVICE_C188': 'PARTS&SERVICE_D188',
  'PARTS&SERVICE_C189': "PARTS&SERVICE_C190-'LARGEFUTUREP&L_H46",
  'PARTS&SERVICE_D189': "PARTS&SERVICE_D190-'LARGEFUTUREP&L_H46",
  'PARTS&SERVICE_C190': 'PARTS&SERVICE_C178*PARTS&SERVICE_C188',
  'PARTS&SERVICE_I190':
    "(PARTS&SERVICE_D190-'LARGEFUTUREP&L_H46)/'LARGEFUTUREP&L_H46",
  'PARTS&SERVICE_C194': 'PARTS&SERVICE_C188*PARTS&SERVICE_C193',
  'PARTS&SERVICE_C195': 'PARTS&SERVICE_C194*PARTS&SERVICE_C181',
  'PARTS&SERVICE_C196': 'PARTS&SERVICE_C195+PARTS&SERVICE_C190',
  'PARTS&SERVICE_D196': 'PARTS&SERVICE_D195+PARTS&SERVICE_D190',
  'PARTS&SERVICE_F196': 'PARTS&SERVICE_D195/PARTS&SERVICE_D196',
  'PARTS&SERVICE_C200': 'PARTS&SERVICE_C88-PARTS&SERVICE_C25',
  'PARTS&SERVICE_D200': 'PARTS&SERVICE_D88-PARTS&SERVICE_D25',
  'PARTS&SERVICE_G200': 'PARTS&SERVICE_C178-PARTS&SERVICE_C114',
  'PARTS&SERVICE_H200': 'PARTS&SERVICE_D178-PARTS&SERVICE_D114',
  'PARTS&SERVICE_C201': 'PARTS&SERVICE_C200*PARTS&SERVICE_C96',
  'PARTS&SERVICE_D201': 'PARTS&SERVICE_D200*PARTS&SERVICE_D96',
  'PARTS&SERVICE_G201': 'PARTS&SERVICE_G200*PARTS&SERVICE_C188',
  'PARTS&SERVICE_H201': 'PARTS&SERVICE_H200*PARTS&SERVICE_D188',
  'PARTS&SERVICE_C202':
    'PARTS&SERVICE_C90-PARTS&SERVICE_C91-PARTS&SERVICE_C208',
  'PARTS&SERVICE_D202':
    'PARTS&SERVICE_D90-PARTS&SERVICE_D91-PARTS&SERVICE_D208',
  'PARTS&SERVICE_G202': 'PARTS&SERVICE_C182-PARTS&SERVICE_G208',
  'PARTS&SERVICE_H202': 'PARTS&SERVICE_D182-PARTS&SERVICE_H208',
  'PARTS&SERVICE_C203': '(PARTS&SERVICE_C202*PARTS&SERVICE_C104)',
  'PARTS&SERVICE_D203': '(PARTS&SERVICE_D202*PARTS&SERVICE_D104)',
  'PARTS&SERVICE_G203':
    'PARTS&SERVICE_G202*PARTS&SERVICE_C194-((PARTS&SERVICE_G202*PARTS&SERVICE_C194)-(PARTS&SERVICE_G202*PARTS&SERVICE_C188))',
  'PARTS&SERVICE_H203':
    'PARTS&SERVICE_H202*PARTS&SERVICE_D194-((PARTS&SERVICE_H202*PARTS&SERVICE_D194)-(PARTS&SERVICE_H202*PARTS&SERVICE_D188))',
  'PARTS&SERVICE_C204': 'PARTS&SERVICE_C89',
  'PARTS&SERVICE_D204': 'PARTS&SERVICE_D89',
  'PARTS&SERVICE_G204': 'PARTS&SERVICE_C180',
  'PARTS&SERVICE_H204': 'PARTS&SERVICE_D180',
  'PARTS&SERVICE_C205': 'PARTS&SERVICE_C89*PARTS&SERVICE_C96',
  'PARTS&SERVICE_D205': 'PARTS&SERVICE_D89*PARTS&SERVICE_D96',
  'PARTS&SERVICE_G205': 'PARTS&SERVICE_C180*PARTS&SERVICE_C188',
  'PARTS&SERVICE_H205': 'PARTS&SERVICE_D180*PARTS&SERVICE_D188',
  'PARTS&SERVICE_C206': 'PARTS&SERVICE_C91',
  'PARTS&SERVICE_D206': 'PARTS&SERVICE_D91',
  'PARTS&SERVICE_G206': 'PARTS&SERVICE_C183',
  'PARTS&SERVICE_H206': 'PARTS&SERVICE_D183',
  'PARTS&SERVICE_C207': 'PARTS&SERVICE_C91*PARTS&SERVICE_C104',
  'PARTS&SERVICE_D207': 'PARTS&SERVICE_D91*PARTS&SERVICE_D104',
  'PARTS&SERVICE_G207':
    'PARTS&SERVICE_C183*PARTS&SERVICE_C194-((PARTS&SERVICE_C183*PARTS&SERVICE_C194)-(PARTS&SERVICE_C183*PARTS&SERVICE_C188))',
  'PARTS&SERVICE_H207':
    'PARTS&SERVICE_D183*PARTS&SERVICE_D194-((PARTS&SERVICE_D183*PARTS&SERVICE_D194)-(PARTS&SERVICE_D183*PARTS&SERVICE_D188))',
  'PARTS&SERVICE_C208':
    '(PARTS&SERVICE_C43*PARTS&SERVICE_C65*PARTS&SERVICE_C83*(1-PARTS&SERVICE_C85))-(PARTS&SERVICE_C43*PARTS&SERVICE_C65*PARTS&SERVICE_C60*(1-PARTS&SERVICE_C85))',
  'PARTS&SERVICE_D208':
    '(PARTS&SERVICE_D43*PARTS&SERVICE_D65*PARTS&SERVICE_D83*(1-PARTS&SERVICE_D85))-(PARTS&SERVICE_D43*PARTS&SERVICE_D65*PARTS&SERVICE_D60*(1-PARTS&SERVICE_D85))',
  'PARTS&SERVICE_G208':
    '(PARTS&SERVICE_C132*PARTS&SERVICE_C154*PARTS&SERVICE_C172*(1+PARTS&SERVICE_C157)*(1+PARTS&SERVICE_C158)*((1-PARTS&SERVICE_C174)*(1+PARTS&SERVICE_C175))-(PARTS&SERVICE_C132*PARTS&SERVICE_C154*PARTS&SERVICE_C149*(1+PARTS&SERVICE_C157)*(1+PARTS&SERVICE_C158)*((1-PARTS&SERVICE_C174)*(1+PARTS&SERVICE_C175))))',
  'PARTS&SERVICE_H208':
    '(PARTS&SERVICE_D132*PARTS&SERVICE_D154*PARTS&SERVICE_D172*(1+PARTS&SERVICE_D157)*(1+PARTS&SERVICE_D158)*((1-PARTS&SERVICE_D174)*(1+PARTS&SERVICE_D175))-(PARTS&SERVICE_D132*PARTS&SERVICE_D154*PARTS&SERVICE_D149*(1+PARTS&SERVICE_D157)*(1+PARTS&SERVICE_D158)*((1-PARTS&SERVICE_D174)*(1+PARTS&SERVICE_D175))))',
  'PARTS&SERVICE_C209': 'PARTS&SERVICE_C208*PARTS&SERVICE_C104',
  'PARTS&SERVICE_D209': 'PARTS&SERVICE_D208*PARTS&SERVICE_D104',
  'PARTS&SERVICE_G209':
    'PARTS&SERVICE_G208*PARTS&SERVICE_C194-((PARTS&SERVICE_G208*PARTS&SERVICE_C194)-(PARTS&SERVICE_G208*PARTS&SERVICE_C188))',
  'PARTS&SERVICE_H209':
    'PARTS&SERVICE_H208*PARTS&SERVICE_D194-((PARTS&SERVICE_H208*PARTS&SERVICE_D194)-(PARTS&SERVICE_H208*PARTS&SERVICE_D188))',
  'PARTS&SERVICE_G210':
    '(PARTS&SERVICE_C181*PARTS&SERVICE_C194)-(PARTS&SERVICE_C181*PARTS&SERVICE_C188)',
  'PARTS&SERVICE_H210':
    '(PARTS&SERVICE_D181*PARTS&SERVICE_D194)-(PARTS&SERVICE_D181*PARTS&SERVICE_D188)',
  'PARTS&SERVICE_C218': 'PARTS&SERVICE_D218',
  'PARTS&SERVICE_D218': "'Medium Current P&L'!PARTS&SERVICE_D6",
  'PARTS&SERVICE_C220': 'PARTS&SERVICE_D220',
  'PARTS&SERVICE_D220':
    '(PARTS&SERVICE_D222+(PARTS&SERVICE_D223*PARTS&SERVICE_D224))/PARTS&SERVICE_D221',
  'PARTS&SERVICE_J220':
    "IF(PARTS&SERVICE_D220=PARTS&SERVICE_D27 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C221': 'PARTS&SERVICE_D221',
  'PARTS&SERVICE_J221':
    "IF(PARTS&SERVICE_D221=PARTS&SERVICE_D28 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C222': 'PARTS&SERVICE_D222',
  'PARTS&SERVICE_J222':
    "IF(PARTS&SERVICE_D222=PARTS&SERVICE_D29 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C223': 'PARTS&SERVICE_D223',
  'PARTS&SERVICE_J223':
    "IF(PARTS&SERVICE_D223=PARTS&SERVICE_D30 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C224': 'PARTS&SERVICE_D224',
  'PARTS&SERVICE_J224':
    "IF(PARTS&SERVICE_D224=PARTS&SERVICE_D31 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C225': 'PARTS&SERVICE_D225',
  'PARTS&SERVICE_D225': '1-PARTS&SERVICE_D220',
  'PARTS&SERVICE_J225':
    "IF(PARTS&SERVICE_D225=PARTS&SERVICE_D32 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C227': 'PARTS&SERVICE_D227',
  'PARTS&SERVICE_J227':
    "IF(PARTS&SERVICE_D227=PARTS&SERVICE_D34 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C228': 'PARTS&SERVICE_D228',
  'PARTS&SERVICE_J228':
    "IF(PARTS&SERVICE_D228=PARTS&SERVICE_D35 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C230': 'PARTS&SERVICE_D230',
  'PARTS&SERVICE_D230':
    '(PARTS&SERVICE_D220*PARTS&SERVICE_D227)+(PARTS&SERVICE_D225*PARTS&SERVICE_D228)',
  'PARTS&SERVICE_J230':
    "IF(PARTS&SERVICE_D230=PARTS&SERVICE_D37 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C232': 'PARTS&SERVICE_D232',
  'PARTS&SERVICE_D232': 'PARTS&SERVICE_D218/PARTS&SERVICE_D230',
  'PARTS&SERVICE_C236':
    'PARTS&SERVICE_C232*(PARTS&SERVICE_C16+1)^(PARTS&SERVICE_C234-2021)',
  'PARTS&SERVICE_D236':
    'PARTS&SERVICE_D232*(PARTS&SERVICE_D16+1)^(PARTS&SERVICE_D234-2021)',
  'PARTS&SERVICE_C239': '1-PARTS&SERVICE_C13',
  'PARTS&SERVICE_D239': '1-PARTS&SERVICE_D13',
  'PARTS&SERVICE_J239':
    "IF(PARTS&SERVICE_D239=PARTS&SERVICE_D46 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C242':
    '(PARTS&SERVICE_C244+(PARTS&SERVICE_C245*PARTS&SERVICE_C246))/PARTS&SERVICE_C243',
  'PARTS&SERVICE_D242':
    '(PARTS&SERVICE_D244+(PARTS&SERVICE_D245*PARTS&SERVICE_D246))/PARTS&SERVICE_D243',
  'PARTS&SERVICE_J242':
    "IF(PARTS&SERVICE_D242=PARTS&SERVICE_D49 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J243':
    "IF(PARTS&SERVICE_D243=PARTS&SERVICE_D50 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J244':
    "IF(PARTS&SERVICE_D244=PARTS&SERVICE_D51 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J245':
    "IF(PARTS&SERVICE_D245=PARTS&SERVICE_D52 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J246':
    "IF(PARTS&SERVICE_D246=PARTS&SERVICE_D53 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C247': '1-PARTS&SERVICE_C242',
  'PARTS&SERVICE_D247': '1-PARTS&SERVICE_D242',
  'PARTS&SERVICE_J247':
    "IF(PARTS&SERVICE_D247=PARTS&SERVICE_D54 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J250':
    "IF(PARTS&SERVICE_D250=PARTS&SERVICE_D57 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C251': '(30%+32.5%)/2',
  'PARTS&SERVICE_J251':
    "IF(PARTS&SERVICE_D251=PARTS&SERVICE_D58 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C253':
    '(PARTS&SERVICE_C242*PARTS&SERVICE_C250)+PARTS&SERVICE_C247*PARTS&SERVICE_C251',
  'PARTS&SERVICE_D253':
    '(PARTS&SERVICE_D242*PARTS&SERVICE_D250)+PARTS&SERVICE_D247*PARTS&SERVICE_D251',
  'PARTS&SERVICE_J253':
    "IF(PARTS&SERVICE_D253=PARTS&SERVICE_D60 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J255':
    "IF(PARTS&SERVICE_D255=PARTS&SERVICE_D62 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C258': 'PARTS&SERVICE_C13',
  'PARTS&SERVICE_D258': 'PARTS&SERVICE_D13',
  'PARTS&SERVICE_J258':
    "IF(PARTS&SERVICE_D258=PARTS&SERVICE_D65 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J261':
    "IF(PARTS&SERVICE_D261=PARTS&SERVICE_D68 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J262':
    "IF(PARTS&SERVICE_D262=PARTS&SERVICE_D69 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C265':
    '(PARTS&SERVICE_C267+(PARTS&SERVICE_C268*PARTS&SERVICE_C269))/PARTS&SERVICE_C266',
  'PARTS&SERVICE_D265':
    '(PARTS&SERVICE_D267+(PARTS&SERVICE_D268*PARTS&SERVICE_D269))/PARTS&SERVICE_D266',
  'PARTS&SERVICE_J265':
    "IF(PARTS&SERVICE_D265=PARTS&SERVICE_D72 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J266':
    "IF(PARTS&SERVICE_D266=PARTS&SERVICE_D73 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J267':
    "IF(PARTS&SERVICE_D267=PARTS&SERVICE_D74 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J268':
    "IF(PARTS&SERVICE_D268=PARTS&SERVICE_D75 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J269':
    "IF(PARTS&SERVICE_D269=PARTS&SERVICE_D76 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C270': '1-PARTS&SERVICE_C265',
  'PARTS&SERVICE_D270': '1-PARTS&SERVICE_D265',
  'PARTS&SERVICE_J270':
    "IF(PARTS&SERVICE_D270=PARTS&SERVICE_D77 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J273':
    "IF(PARTS&SERVICE_D273=PARTS&SERVICE_D80 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J274':
    "IF(PARTS&SERVICE_D274=PARTS&SERVICE_D81 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C276':
    '(PARTS&SERVICE_C265*PARTS&SERVICE_C273)+PARTS&SERVICE_C270*PARTS&SERVICE_C274',
  'PARTS&SERVICE_D276':
    '(PARTS&SERVICE_D265*PARTS&SERVICE_D273)+PARTS&SERVICE_D270*PARTS&SERVICE_D274',
  'PARTS&SERVICE_J276':
    "IF(PARTS&SERVICE_D276=PARTS&SERVICE_D83 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J278':
    "IF(PARTS&SERVICE_D278=PARTS&SERVICE_D85 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C281':
    'PARTS&SERVICE_C236*PARTS&SERVICE_C239*PARTS&SERVICE_C253',
  'PARTS&SERVICE_D281':
    'PARTS&SERVICE_D236*PARTS&SERVICE_D239*PARTS&SERVICE_D253',
  'PARTS&SERVICE_C282': 'PARTS&SERVICE_C281*PARTS&SERVICE_C255',
  'PARTS&SERVICE_D282': 'PARTS&SERVICE_D281*PARTS&SERVICE_D255',
  'PARTS&SERVICE_C283':
    'PARTS&SERVICE_C236*PARTS&SERVICE_C258*PARTS&SERVICE_C276*(1+PARTS&SERVICE_C261)*(1+PARTS&SERVICE_C262)',
  'PARTS&SERVICE_D283':
    'PARTS&SERVICE_D236*PARTS&SERVICE_D258*PARTS&SERVICE_D276*(1+PARTS&SERVICE_D261)*(1+PARTS&SERVICE_D262)',
  'PARTS&SERVICE_C284': 'PARTS&SERVICE_C283*PARTS&SERVICE_C278',
  'PARTS&SERVICE_D284': 'PARTS&SERVICE_D283*PARTS&SERVICE_D278',
  'PARTS&SERVICE_C285': 'PARTS&SERVICE_C281+PARTS&SERVICE_C283',
  'PARTS&SERVICE_D285': 'PARTS&SERVICE_D281+PARTS&SERVICE_D283',
  'PARTS&SERVICE_C289': 'PARTS&SERVICE_D289',
  'PARTS&SERVICE_D289': "'Medium Current P&L'!PARTS&SERVICE_E24",
  'PARTS&SERVICE_C291': 'PARTS&SERVICE_C281*PARTS&SERVICE_C289',
  'PARTS&SERVICE_D291': 'PARTS&SERVICE_D281*PARTS&SERVICE_D289',
  'PARTS&SERVICE_C294': '(33%-10%)/33%',
  'PARTS&SERVICE_D294': '(33%-10%)/33%',
  'PARTS&SERVICE_J294':
    "IF(PARTS&SERVICE_D294=PARTS&SERVICE_D101 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J295':
    "IF(PARTS&SERVICE_D295=PARTS&SERVICE_D102 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C296':
    '(-PARTS&SERVICE_C294*PARTS&SERVICE_C289)+PARTS&SERVICE_C289',
  'PARTS&SERVICE_D296':
    '(-PARTS&SERVICE_D294*PARTS&SERVICE_D289)+PARTS&SERVICE_D289',
  'PARTS&SERVICE_C297':
    '(PARTS&SERVICE_C295*PARTS&SERVICE_C296)+(1-PARTS&SERVICE_C295)*PARTS&SERVICE_C289',
  'PARTS&SERVICE_D297':
    '(PARTS&SERVICE_D295*PARTS&SERVICE_D296)+(1-PARTS&SERVICE_D295)*PARTS&SERVICE_D289',
  'PARTS&SERVICE_C298': 'PARTS&SERVICE_C297*PARTS&SERVICE_C283',
  'PARTS&SERVICE_D298': 'PARTS&SERVICE_D297*PARTS&SERVICE_D283',
  'PARTS&SERVICE_C299': 'PARTS&SERVICE_C298+PARTS&SERVICE_C291',
  'PARTS&SERVICE_D299': 'PARTS&SERVICE_D298+PARTS&SERVICE_D291',
  'PARTS&SERVICE_C307': 'PARTS&SERVICE_D307',
  'PARTS&SERVICE_D307': "'Medium Current P&L'!PARTS&SERVICE_D7",
  'PARTS&SERVICE_C309': 'PARTS&SERVICE_D309',
  'PARTS&SERVICE_D309':
    '(PARTS&SERVICE_D311+(PARTS&SERVICE_D312*PARTS&SERVICE_D313))/PARTS&SERVICE_D310',
  'PARTS&SERVICE_J309':
    "IF(PARTS&SERVICE_D309=PARTS&SERVICE_D116 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C310': 'PARTS&SERVICE_D310',
  'PARTS&SERVICE_D310': 'PARTS&SERVICE_D221',
  'PARTS&SERVICE_J310':
    "IF(PARTS&SERVICE_D310=PARTS&SERVICE_D117 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C311': 'PARTS&SERVICE_D311',
  'PARTS&SERVICE_D311': 'PARTS&SERVICE_D222',
  'PARTS&SERVICE_J311':
    "IF(PARTS&SERVICE_D311=PARTS&SERVICE_D118 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C312': 'PARTS&SERVICE_D312',
  'PARTS&SERVICE_D312': 'PARTS&SERVICE_D223',
  'PARTS&SERVICE_J312':
    "IF(PARTS&SERVICE_D312=PARTS&SERVICE_D119 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C313': 'PARTS&SERVICE_D313',
  'PARTS&SERVICE_D313': 'PARTS&SERVICE_D224',
  'PARTS&SERVICE_J313':
    "IF(PARTS&SERVICE_D313=PARTS&SERVICE_D120 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C314': 'PARTS&SERVICE_D314',
  'PARTS&SERVICE_D314': 'PARTS&SERVICE_D225',
  'PARTS&SERVICE_J314':
    "IF(PARTS&SERVICE_D314=PARTS&SERVICE_D121 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C316': 'PARTS&SERVICE_D316',
  'PARTS&SERVICE_J316':
    "IF(PARTS&SERVICE_D316=PARTS&SERVICE_D123 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C317': 'PARTS&SERVICE_D317',
  'PARTS&SERVICE_J317':
    "IF(PARTS&SERVICE_D317=PARTS&SERVICE_D124 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C319': 'PARTS&SERVICE_D319',
  'PARTS&SERVICE_D319':
    '(PARTS&SERVICE_D309*PARTS&SERVICE_D316)+(PARTS&SERVICE_D314*PARTS&SERVICE_D317)',
  'PARTS&SERVICE_J319':
    "IF(PARTS&SERVICE_D319=PARTS&SERVICE_D126 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C321': 'PARTS&SERVICE_D321',
  'PARTS&SERVICE_D321': 'PARTS&SERVICE_D307/PARTS&SERVICE_D319',
  'PARTS&SERVICE_C325':
    'PARTS&SERVICE_C321*(PARTS&SERVICE_C16+1)^(PARTS&SERVICE_C323-2021)',
  'PARTS&SERVICE_D325':
    'PARTS&SERVICE_D321*(PARTS&SERVICE_D16+1)^(PARTS&SERVICE_D323-2021)',
  'PARTS&SERVICE_C328': '1-PARTS&SERVICE_C13',
  'PARTS&SERVICE_D328': '1-PARTS&SERVICE_D13',
  'PARTS&SERVICE_J328':
    "IF(PARTS&SERVICE_D328=PARTS&SERVICE_D135 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C331': 'PARTS&SERVICE_C242',
  'PARTS&SERVICE_D331': 'PARTS&SERVICE_D242',
  'PARTS&SERVICE_J331':
    "IF(PARTS&SERVICE_D331=PARTS&SERVICE_D138 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C332': 'PARTS&SERVICE_C243',
  'PARTS&SERVICE_D332': 'PARTS&SERVICE_D243',
  'PARTS&SERVICE_J332':
    "IF(PARTS&SERVICE_D332=PARTS&SERVICE_D139 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C333': 'PARTS&SERVICE_C244',
  'PARTS&SERVICE_D333': 'PARTS&SERVICE_D244',
  'PARTS&SERVICE_J333':
    "IF(PARTS&SERVICE_D333=PARTS&SERVICE_D140 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C334': 'PARTS&SERVICE_C245',
  'PARTS&SERVICE_D334': 'PARTS&SERVICE_D245',
  'PARTS&SERVICE_J334':
    "IF(PARTS&SERVICE_D334=PARTS&SERVICE_D141 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C335': 'PARTS&SERVICE_C246',
  'PARTS&SERVICE_D335': 'PARTS&SERVICE_D246',
  'PARTS&SERVICE_J335':
    "IF(PARTS&SERVICE_D335=PARTS&SERVICE_D142 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C336': 'PARTS&SERVICE_C247',
  'PARTS&SERVICE_D336': 'PARTS&SERVICE_D247',
  'PARTS&SERVICE_J336':
    "IF(PARTS&SERVICE_D336=PARTS&SERVICE_D143 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J339':
    "IF(PARTS&SERVICE_D339=PARTS&SERVICE_D146 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C340': '(20%+22.5%)/2',
  'PARTS&SERVICE_J340':
    "IF(PARTS&SERVICE_D340=PARTS&SERVICE_D147 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C342':
    '(PARTS&SERVICE_C331*PARTS&SERVICE_C339)+PARTS&SERVICE_C336*PARTS&SERVICE_C340',
  'PARTS&SERVICE_D342':
    '(PARTS&SERVICE_D331*PARTS&SERVICE_D339)+PARTS&SERVICE_D336*PARTS&SERVICE_D340',
  'PARTS&SERVICE_J342':
    "IF(PARTS&SERVICE_D342=PARTS&SERVICE_D149 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J344':
    "IF(PARTS&SERVICE_D344=PARTS&SERVICE_D151 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C347': 'PARTS&SERVICE_C13',
  'PARTS&SERVICE_D347': 'PARTS&SERVICE_D13',
  'PARTS&SERVICE_J347':
    "IF(PARTS&SERVICE_D347=PARTS&SERVICE_D154 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J350':
    "IF(PARTS&SERVICE_D350=PARTS&SERVICE_D157 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J351':
    "IF(PARTS&SERVICE_D351=PARTS&SERVICE_D158 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C354': 'PARTS&SERVICE_C265',
  'PARTS&SERVICE_D354': 'PARTS&SERVICE_D265',
  'PARTS&SERVICE_J354':
    "IF(PARTS&SERVICE_D354=PARTS&SERVICE_D161 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C355': 'PARTS&SERVICE_C266',
  'PARTS&SERVICE_D355': 'PARTS&SERVICE_D266',
  'PARTS&SERVICE_J355':
    "IF(PARTS&SERVICE_D355=PARTS&SERVICE_D162 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C356': 'PARTS&SERVICE_C267',
  'PARTS&SERVICE_D356': 'PARTS&SERVICE_D267',
  'PARTS&SERVICE_J356':
    "IF(PARTS&SERVICE_D356=PARTS&SERVICE_D163 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C357': 'PARTS&SERVICE_C268',
  'PARTS&SERVICE_D357': 'PARTS&SERVICE_D268',
  'PARTS&SERVICE_J357':
    "IF(PARTS&SERVICE_D357=PARTS&SERVICE_D164 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C358': 'PARTS&SERVICE_C269',
  'PARTS&SERVICE_D358': 'PARTS&SERVICE_D269',
  'PARTS&SERVICE_J358':
    "IF(PARTS&SERVICE_D358=PARTS&SERVICE_D165 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C359': 'PARTS&SERVICE_C270',
  'PARTS&SERVICE_D359': 'PARTS&SERVICE_D270',
  'PARTS&SERVICE_J359':
    "IF(PARTS&SERVICE_D359=PARTS&SERVICE_D166 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J362':
    "IF(PARTS&SERVICE_D362=PARTS&SERVICE_D169 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J363':
    "IF(PARTS&SERVICE_D363=PARTS&SERVICE_D170 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C365':
    '(PARTS&SERVICE_C354*PARTS&SERVICE_C362)+PARTS&SERVICE_C359*PARTS&SERVICE_C363',
  'PARTS&SERVICE_D365':
    '(PARTS&SERVICE_D354*PARTS&SERVICE_D362)+PARTS&SERVICE_D359*PARTS&SERVICE_D363',
  'PARTS&SERVICE_J365':
    "IF(PARTS&SERVICE_D365=PARTS&SERVICE_D172 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J367':
    "IF(PARTS&SERVICE_D367=PARTS&SERVICE_D174 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J368':
    "IF(PARTS&SERVICE_D368=PARTS&SERVICE_D175 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C371': 'PARTS&SERVICE_C372+PARTS&SERVICE_C373',
  'PARTS&SERVICE_D371': 'PARTS&SERVICE_D372+PARTS&SERVICE_D373',
  'PARTS&SERVICE_C372':
    'PARTS&SERVICE_C325*PARTS&SERVICE_C328*PARTS&SERVICE_C342*(1-PARTS&SERVICE_C344)',
  'PARTS&SERVICE_D372':
    'PARTS&SERVICE_D325*PARTS&SERVICE_D328*PARTS&SERVICE_D342*(1-PARTS&SERVICE_D344)',
  'PARTS&SERVICE_C373':
    'PARTS&SERVICE_C325*PARTS&SERVICE_C328*PARTS&SERVICE_C342*PARTS&SERVICE_C367*(1+PARTS&SERVICE_C368)',
  'PARTS&SERVICE_D373':
    'PARTS&SERVICE_D325*PARTS&SERVICE_D328*PARTS&SERVICE_D342*PARTS&SERVICE_D367*(1+PARTS&SERVICE_D368)',
  'PARTS&SERVICE_C374': 'PARTS&SERVICE_C375+PARTS&SERVICE_C376',
  'PARTS&SERVICE_D374': 'PARTS&SERVICE_D375+PARTS&SERVICE_D376',
  'PARTS&SERVICE_C375':
    'PARTS&SERVICE_C325*PARTS&SERVICE_C347*PARTS&SERVICE_C365*(1-PARTS&SERVICE_C367)*(1+PARTS&SERVICE_C350)*(1+PARTS&SERVICE_C351)',
  'PARTS&SERVICE_D375':
    'PARTS&SERVICE_D325*PARTS&SERVICE_D347*PARTS&SERVICE_D365*(1-PARTS&SERVICE_D367)*(1+PARTS&SERVICE_D350)*(1+PARTS&SERVICE_D351)',
  'PARTS&SERVICE_C376':
    'PARTS&SERVICE_C325*PARTS&SERVICE_C347*PARTS&SERVICE_C365*PARTS&SERVICE_C367*(1+PARTS&SERVICE_C368)*(1+PARTS&SERVICE_C350)*(1+PARTS&SERVICE_C351)',
  'PARTS&SERVICE_D376':
    'PARTS&SERVICE_D325*PARTS&SERVICE_D347*PARTS&SERVICE_D365*PARTS&SERVICE_D367*(1+PARTS&SERVICE_D368)*(1+PARTS&SERVICE_D350)*(1+PARTS&SERVICE_D351)',
  'PARTS&SERVICE_C377': 'PARTS&SERVICE_C371+PARTS&SERVICE_C374',
  'PARTS&SERVICE_D377': 'PARTS&SERVICE_D371+PARTS&SERVICE_D374',
  'PARTS&SERVICE_C381': 'PARTS&SERVICE_D381',
  'PARTS&SERVICE_D381': "'Medium Current P&L'!PARTS&SERVICE_E25",
  'PARTS&SERVICE_C383': 'PARTS&SERVICE_C371*PARTS&SERVICE_C381',
  'PARTS&SERVICE_D383': 'PARTS&SERVICE_D371*PARTS&SERVICE_D381',
  'PARTS&SERVICE_J384':
    "IF(PARTS&SERVICE_D384=PARTS&SERVICE_D191 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_J386':
    "IF(PARTS&SERVICE_D386=PARTS&SERVICE_D193 == '') => 'Y' ELSE 'N'",
  'PARTS&SERVICE_C387': 'PARTS&SERVICE_C381*PARTS&SERVICE_C386',
  'PARTS&SERVICE_D387': 'PARTS&SERVICE_D381*PARTS&SERVICE_D386',
  'PARTS&SERVICE_C388': 'PARTS&SERVICE_C387*PARTS&SERVICE_C374',
  'PARTS&SERVICE_D388': 'PARTS&SERVICE_D387*PARTS&SERVICE_D374',
  'PARTS&SERVICE_C389': 'PARTS&SERVICE_C388+PARTS&SERVICE_C383',
  'PARTS&SERVICE_D389': 'PARTS&SERVICE_D388+PARTS&SERVICE_D383',
  'PARTS&SERVICE_C393': 'PARTS&SERVICE_C281-PARTS&SERVICE_C218',
  'PARTS&SERVICE_D393': 'PARTS&SERVICE_D281-PARTS&SERVICE_D218',
  'PARTS&SERVICE_G393': 'PARTS&SERVICE_C371-PARTS&SERVICE_C307',
  'PARTS&SERVICE_H393': 'PARTS&SERVICE_D371-PARTS&SERVICE_D307',
  'PARTS&SERVICE_J393': 'PARTS&SERVICE_H393/PARTS&SERVICE_D307',
  'PARTS&SERVICE_C394': 'PARTS&SERVICE_C393*PARTS&SERVICE_C289',
  'PARTS&SERVICE_D394': 'PARTS&SERVICE_D393*PARTS&SERVICE_D289',
  'PARTS&SERVICE_G394': 'PARTS&SERVICE_G393*PARTS&SERVICE_C381',
  'PARTS&SERVICE_H394': 'PARTS&SERVICE_H393*PARTS&SERVICE_D381',
  'PARTS&SERVICE_C395':
    'PARTS&SERVICE_C283-PARTS&SERVICE_C284-PARTS&SERVICE_C401',
  'PARTS&SERVICE_D395':
    'PARTS&SERVICE_D283-PARTS&SERVICE_D284-PARTS&SERVICE_D401',
  'PARTS&SERVICE_G395': 'PARTS&SERVICE_C375-PARTS&SERVICE_G401',
  'PARTS&SERVICE_H395': 'PARTS&SERVICE_D375-PARTS&SERVICE_H401',
  'PARTS&SERVICE_C396': '(PARTS&SERVICE_C395*PARTS&SERVICE_C297)',
  'PARTS&SERVICE_D396': '(PARTS&SERVICE_D395*PARTS&SERVICE_D297)',
  'PARTS&SERVICE_G396': 'PARTS&SERVICE_G395*PARTS&SERVICE_C387',
  'PARTS&SERVICE_H396': 'PARTS&SERVICE_H395*PARTS&SERVICE_D387',
  'PARTS&SERVICE_C397': 'PARTS&SERVICE_C282',
  'PARTS&SERVICE_D397': 'PARTS&SERVICE_D282',
  'PARTS&SERVICE_G397': 'PARTS&SERVICE_C373',
  'PARTS&SERVICE_H397': 'PARTS&SERVICE_D373',
  'PARTS&SERVICE_C398': 'PARTS&SERVICE_C282*PARTS&SERVICE_C289',
  'PARTS&SERVICE_D398': 'PARTS&SERVICE_D282*PARTS&SERVICE_D289',
  'PARTS&SERVICE_G398': 'PARTS&SERVICE_C373*PARTS&SERVICE_C381',
  'PARTS&SERVICE_H398': 'PARTS&SERVICE_D373*PARTS&SERVICE_D381',
  'PARTS&SERVICE_C399': 'PARTS&SERVICE_C284',
  'PARTS&SERVICE_D399': 'PARTS&SERVICE_D284',
  'PARTS&SERVICE_G399': 'PARTS&SERVICE_C376',
  'PARTS&SERVICE_H399': 'PARTS&SERVICE_D376',
  'PARTS&SERVICE_C400': 'PARTS&SERVICE_C284*PARTS&SERVICE_C297',
  'PARTS&SERVICE_D400': 'PARTS&SERVICE_D284*PARTS&SERVICE_D297',
  'PARTS&SERVICE_G400': 'PARTS&SERVICE_C376*PARTS&SERVICE_C387',
  'PARTS&SERVICE_H400': 'PARTS&SERVICE_D376*PARTS&SERVICE_D387',
  'PARTS&SERVICE_C401':
    '(PARTS&SERVICE_C236*PARTS&SERVICE_C258*PARTS&SERVICE_C276*(1-PARTS&SERVICE_C278))-(PARTS&SERVICE_C236*PARTS&SERVICE_C258*PARTS&SERVICE_C253*(1-PARTS&SERVICE_C278))',
  'PARTS&SERVICE_D401':
    '(PARTS&SERVICE_D236*PARTS&SERVICE_D258*PARTS&SERVICE_D276*(1-PARTS&SERVICE_D278))-(PARTS&SERVICE_D236*PARTS&SERVICE_D258*PARTS&SERVICE_D253*(1-PARTS&SERVICE_D278))',
  'PARTS&SERVICE_G401':
    '(PARTS&SERVICE_C325*PARTS&SERVICE_C347*PARTS&SERVICE_C365*((1-PARTS&SERVICE_C367)*(1+PARTS&SERVICE_C368))-(PARTS&SERVICE_C325*PARTS&SERVICE_C347*PARTS&SERVICE_C342*((1-PARTS&SERVICE_C278)*(1+PARTS&SERVICE_C368))))',
  'PARTS&SERVICE_H401':
    '(PARTS&SERVICE_D325*PARTS&SERVICE_D347*PARTS&SERVICE_D365*((1-PARTS&SERVICE_D367)*(1+PARTS&SERVICE_D368))-(PARTS&SERVICE_D325*PARTS&SERVICE_D347*PARTS&SERVICE_D342*((1-PARTS&SERVICE_D278)*(1+PARTS&SERVICE_D368))))',
  'PARTS&SERVICE_C402': 'PARTS&SERVICE_C401*PARTS&SERVICE_C297',
  'PARTS&SERVICE_D402': 'PARTS&SERVICE_D401*PARTS&SERVICE_D297',
  'PARTS&SERVICE_G402': 'PARTS&SERVICE_G401*PARTS&SERVICE_C387',
  'PARTS&SERVICE_H402': 'PARTS&SERVICE_H401*PARTS&SERVICE_D387',
};
