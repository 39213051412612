<div class="mt-5">
  <p class="font-weight-500 text-center">
    If You Have Any Questions On The Financial Simulation Tool Please Contact
    DTNA's Regional Business Development Manager:
  </p>
  <div class="d-flex flex-column mx-5">
    <div class="center-container">
      <img src="../assets/images/DND_Logo.png" />
    </div>
    <table class="point-of-contact-details">
      <thead>
        <th>Development Manager</th>
        <th>Email</th>
        <th>Cell number</th>
      </thead>
      <tbody>
        <tr>
          <td>Michelle Derbyshire</td>
          <td>Michelle.Derbyshire@DaimlerTruck.com</td>
          <td>360.607.3868</td>
        </tr>
        <tr>
          <td>John Georgoulis</td>
          <td>John.Georgoulis@DaimlerTruck.com</td>
          <td>913.461.1108</td>
        </tr>
        <tr>
          <td>Antoinette Hayward</td>
          <td>Antoinette. Hayward@DaimlerTruck.com</td>
          <td>647.242.9951</td>
        </tr>
        <tr>
          <td>Bill Hoelscher</td>
          <td>William.Hoelscher@Daimler Truck.com</td>
          <td>440.725.1341</td>
        </tr>
        <tr>
          <td>Doug Rogers</td>
          <td>Douglas.Rogers@Daimler Truck.com</td>
          <td>803.280.1208</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="dealership-documents-section d-flex flex-column align-items-center">
    <p class="font-weight-500 text-center">If You Have Any Questions Relating To eConsulting, Please Contact:</p>
    <img src="../assets/images/detroit-econsulting.png" />

    <table class="point-of-contact-details w-100 mb-5">
      <thead>
        <th>Detroit eConsulting and Training</th>
        <th>Email</th>
        <th>Cell number</th>
      </thead>
      <tbody>
        <tr>
          <td>Andrew J.W. Gerbetz - Manager ZEV Consulting</td>
          <td>Andrew.Gerbetz@Daimler Truck.com</td>
          <td>971.349.3109</td>
        </tr>
      </tbody>
    </table>

    <p class="mt-3 font-weight-500">Dealership Of Future And eMobility Documents</p>
    <div>
      <table>
        <thead>
          <th>Document</th>
          <th>Locations</th>
        </thead>
        <tbody>
          <tr>
            <td>
              <span class="font-weight-500">Dealership of the Future</span>
              <ul class="mt-3">
                <li class="my-3">Introduction Letter to Dealer Principals</li>
                <li class="my-3">Webinar Recording</li>
                <li>Dealer Playbooks (one for each of the nine e-Opportunities)</li>
              </ul>
            </td>
            <td>
              <span class="font-weight-500">DTNA Portal</span>
              <span>
                / Service / Distribution Network / Dealership of the
                Future:
                </span>
              <a target="_blank" class="ml-3"
                href="https:/dtnacontent-dtna.prd.freightliner.com//content/dtna-portal/en/ServiceLP/DistributionNetwork/Dealership-of-the-Future.html">
                https://dtnacontent-dtna.prd.freightliner.com//content/dtna-portal/en/ServiceLP/Distribution
                Network/Dealership-of-the-Future.html
              </a>
            </td>
          </tr>
          <tr>
            <td class="font-weight-500">Dealership eMobility Service Readiness Self-Assessment</td>
            <td>
              <span>
                DTNA's Service Support Index
                <span class="font-weight-500">(SSI)</span> / Assessment:
              </span>
              <a target="_blank" class="ml-3" href="https:/extranet-ddc.freightliner.com/servexc/ssi_searchDealers.aspx">
                https://extranet-ddc.freightliner.com/servexc/ssi_searchDealers.aspx
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="mt-5">
        <thead>
          <th>Additional eMobility Information</th>
          <th>Locations</th>
        </thead>
        <tbody>
          <tr>
            <td class="font-weight-500">eMobility Resource Hub</td>
            <td>
              <span class="font-weight-500">DTNA Portal</span>
              <span>
                / Sales / Freightliner / On-Highway Sales Resources /
                eMobility:
              </span>
              <a target="_blank" class="ml-3" href="https://dtnacontent-dtna.prd.freightliner.com/content/dtna-portal/en/sales/eMobilityResourceHub.html">
                eMobility Resource Hub (freightliner.com)
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="mt-5 w-100">
        <thead>
          <th colspan="3">General Information or Comments</th>
        </thead>
        <tbody>
          <tr>
            <td>Christopher Giesemann</td>
            <td>christopher.giesemann@daimlertruck.com</td>
            <td>Cell: 503.807.7711</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="mt-5 mb-4">
    <img class="w-100" src="../assets/images/eCascadia-day-cab-studio.jpg" />
  </div>
  <div class="d-flex justify-content-end">
    <a class="btn link-btn" routerLink="/instructions">Back</a>
  </div>
</div>
