<div class="mt-5">
    <form [formGroup]="partsServiceForm" class="parts-service-form" (keydown)="enterSubmitted($event)">
        <div [class.remove-border]="removeBorder" class="annual-incremental-costs-section custom-fieldset mt-5" #annualPreparednessContent>
          <p *ngIf="addHeading" class="font-weight-500 dealer-title"></p>
            <p class="font-weight-500 text-capitalize custom-fieldset-title-section">
                <span class="custom-fieldset-title">Annual Preparedness per Rooftop</span>
            </p>
            <table>
                <thead class="bev-action-term">
                    <th class="bev-readiness-schedule"></th>
                    <th class="bev-readiness-target"></th>
                    <td style="background-color: white;" class="bev-readiness-year"></td>
                    <td style="background-color: white;"class="bev-readiness-year"></td>
                    <th class="bev-readiness-year year-term immediate-term" colspan="2">Immediate action</th>
                    <th class="bev-readiness-year year-term medium-term" colspan="3">Medium Term</th>
                    <th class="bev-readiness-year year-term long-term" colspan="3">Long Term</th>
                </thead>
                <tbody>
                    <tr class="table-heading">
                        <td class="bev-readiness-schedule">Dealer Group BEV-Readiness Schedule</td>
                        <td class="bev-readiness-target">Readiness targets</td>
                        <td class="bev-readiness-year">2021</td>
                        <td class="bev-readiness-year">2022</td>
                        <td class="bev-readiness-year">2023</td>
                        <td class="bev-readiness-year">2024</td>
                        <td class="bev-readiness-year">2025</td>
                        <td class="bev-readiness-year">2026</td>
                        <td class="bev-readiness-year">2027</td>
                        <td class="bev-readiness-year">2028</td>
                        <td class="bev-readiness-year">2029</td>
                        <td class="bev-readiness-year">2030</td>
                        <td class="bev-readiness-year no-background"></td>
                    </tr>
                    <tr>
                        <div style="display: flex; align-items: center;" class="bev-readiness-schedule">
                         <td class="font-weight-500">Total full service rooftops </td>
                         <span class="text-danger">(*)</span>
                        </div>
                        <td class="bev-readiness-target"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year p-0">
                            <div class="input-box" [class.input-box-error]="partsServiceForm.get('PARTSSERVICEINVESTMENTS_H8')?.hasError('required')">
                                <input type="text"  appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_H8" value="" formControlName="PARTSSERVICEINVESTMENTS_H8" />
                            </div>
                        </td>
                        <td class="bev-readiness-year p-0">
                            <div class="input-box" [class.input-box-error]="partsServiceForm.get('PARTSSERVICEINVESTMENTS_I8')?.hasError('required')">
                                <input type="text"  appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_I8" value="" formControlName="PARTSSERVICEINVESTMENTS_I8" />
                            </div>
                        </td>
                        <td class="bev-readiness-year p-0">
                            <div class="input-box" [class.input-box-error]="partsServiceForm.get('PARTSSERVICEINVESTMENTS_J8')?.hasError('required')">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_J8" value="" formControlName="PARTSSERVICEINVESTMENTS_J8" />
                            </div>
                        </td>
                        <td class="bev-readiness-year p-0">
                            <div class="input-box" [class.input-box-error]="partsServiceForm.get('PARTSSERVICEINVESTMENTS_K8')?.hasError('required')">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_K8" value="" formControlName="PARTSSERVICEINVESTMENTS_K8" />
                            </div>
                        </td>
                        <td  class="bev-readiness-year p-0">
                            <div class="input-box" [class.input-box-error]="partsServiceForm.get('PARTSSERVICEINVESTMENTS_L8')?.hasError('required')">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_L8" value="" formControlName="PARTSSERVICEINVESTMENTS_L8" />
                            </div>
                        </td>
                        <td  class="bev-readiness-year p-0">
                            <div class="input-box" [class.input-box-error]="partsServiceForm.get('PARTSSERVICEINVESTMENTS_M8')?.hasError('required')">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_M8" value="" formControlName="PARTSSERVICEINVESTMENTS_M8" />
                            </div>
                        </td>
                        <td  class="bev-readiness-year p-0">
                            <div class="input-box" [class.input-box-error]="partsServiceForm.get('PARTSSERVICEINVESTMENTS_N8')?.hasError('required')">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_N8" value="" formControlName="PARTSSERVICEINVESTMENTS_N8" />
                            </div>
                        </td>
                        <td  class="bev-readiness-year p-0">
                            <div class="input-box" [class.input-box-error]="partsServiceForm.get('PARTSSERVICEINVESTMENTS_O8')?.hasError('required')">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_O8" value="" formControlName="PARTSSERVICEINVESTMENTS_O8" />
                            </div>
                        </td>
                        <td class="bev-readiness-year">
                            <span class="bi-info-circle w-auto" placement="top" ngbTooltip="Enter number of rooftops offering parts, service and sales in each year"></span>
                        </td>
                    </tr>
                    <tr>
                        <td class="bev-readiness-schedule pl-3">Cumulative number of BEV-ready full service rooftops</td>
                        <td class="bev-readiness-target">
                            <div class="input-box bev-target" [class.input-box-error]="partsServiceForm.get('PARTSSERVICEINVESTMENTS_C9')?.hasError('required')">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_C9" value="100" formControlName="PARTSSERVICEINVESTMENTS_C9" />
                                <span>% of rooftops BEV-ready in 2024 <span class="text-danger">(*)</span></span>
                            </div>
                            <div class="d-flex input-error" *ngIf="partsServiceForm.controls.PARTSSERVICEINVESTMENTS_C9.touched">
                                <div *ngIf="partsServiceForm.controls.PARTSSERVICEINVESTMENTS_C9.errors?.min || partsServiceForm.controls.PARTSSERVICEINVESTMENTS_C9.errors?.max">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Please enter a valid percentage between 0 and 100</span>
                                </div>
                            </div>
                        </td>
                        <td  class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td  class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td  class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [currency]="false" [formulaList]="formulaList"  appNonNegative id ="PARTSSERVICEINVESTMENTS_H9" value="" formControlName="PARTSSERVICEINVESTMENTS_H9" />

                        </td>
                        <td  class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_I9" value="" formControlName="PARTSSERVICEINVESTMENTS_I9" />

                        </td>
                        <td  class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_J9" value="" formControlName="PARTSSERVICEINVESTMENTS_J9" />

                        </td>
                        <td  class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_K9" value="" formControlName="PARTSSERVICEINVESTMENTS_K9" />

                        </td>
                        <td  class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_L9" value="" formControlName="PARTSSERVICEINVESTMENTS_L9" />

                        </td>
                        <td  class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_M9" value="" formControlName="PARTSSERVICEINVESTMENTS_M9" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_N9" value="" formControlName="PARTSSERVICEINVESTMENTS_N9" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_O9" value="" formControlName="PARTSSERVICEINVESTMENTS_O9" />

                        </td>
                        <td class="bev-readiness-year"></td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule pl-3">Incremental number of BEV-ready full service rooftops</td>
                        <td class="bev-readiness-target"></td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_F10" value="" formControlName="PARTSSERVICEINVESTMENTS_F10" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_G10" value="" formControlName="PARTSSERVICEINVESTMENTS_G10" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative  id ="PARTSSERVICEINVESTMENTS_H10" value="" formControlName="PARTSSERVICEINVESTMENTS_H10" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_I10" value="" formControlName="PARTSSERVICEINVESTMENTS_I10" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_J10" value="" formControlName="PARTSSERVICEINVESTMENTS_J10" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_K10" value="" formControlName="PARTSSERVICEINVESTMENTS_K10" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_L10" value="" formControlName="PARTSSERVICEINVESTMENTS_L10" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_M10" value="" formControlName="PARTSSERVICEINVESTMENTS_M10" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_N10" value="" formControlName="PARTSSERVICEINVESTMENTS_N10" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_O10" value="" formControlName="PARTSSERVICEINVESTMENTS_O10" />

                        </td>
                        <td class="bev-readiness-year"></td>
                    </tr>

                    <tr>
                        <div style="display: flex; align-items: center;" class="bev-readiness-schedule">
                            <td class="font-weight-500">Total active bays (across all rooftops)</td>
                            <span class="text-danger">(*)</span>
                         </div>
                        <td class="bev-readiness-target">
                            <div class="input-box bev-target" [class.input-box-error]="partsServiceForm.get('PARTSSERVICEINVESTMENTS_C12')?.hasError('required')">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_C12" value="" formControlName="PARTSSERVICEINVESTMENTS_C12" />
                                <span>bays per rooftop BEV-ready in 2024 <span class="text-danger">(*)</span></span>
                            </div>
                        </td>
                        <td class="bev-readiness-year p-0">
                            <div class="input-box">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_F12" value="" formControlName="PARTSSERVICEINVESTMENTS_F12" />
                            </div>
                        </td>
                        <td class="bev-readiness-year p-0">
                            <div class="input-box">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_G12" value="" formControlName="PARTSSERVICEINVESTMENTS_G12" />
                            </div>
                        </td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year p-0">
                            <div class="input-box">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_O12" value="" formControlName="PARTSSERVICEINVESTMENTS_O12" />
                            </div>
                        </td>
                        <td class="bev-readiness-year">
                            <span class="bi-info-circle w-auto" placement="top"
                                ngbTooltip="Enter number of active service bays across all rooftops in 2021, 2022 and expected in 2030"></span>
                        </td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule pl-3">Cumulative number of BEV-ready bays</td>
                        <td class="bev-readiness-target">
                            <div class="input-box bev-target" [class.input-box-error]="partsServiceForm.get('PARTSSERVICEINVESTMENTS_C13')?.hasError('required')">
                                <input type="text" appAllowOnlyNumbers [percentage]="true" id ="PARTSSERVICEINVESTMENTS_C13" value="" formControlName="PARTSSERVICEINVESTMENTS_C13" />
                                <span>% Bays BEV-ready in 2030 <span class="text-danger">(*)</span></span>
                            </div>
                            <div class="d-flex input-error" *ngIf="partsServiceForm.controls.PARTSSERVICEINVESTMENTS_C13.touched">
                                <div *ngIf="partsServiceForm.controls.PARTSSERVICEINVESTMENTS_C13.errors?.min || partsServiceForm.controls.PARTSSERVICEINVESTMENTS_C13.errors?.max">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Please enter a valid percentage between 0 and 100</span>
                                </div>
                            </div>
                        </td>
                        <td class="bev-readiness-year default-cell"><span>0</span></td>
                        <td class="bev-readiness-year default-cell"><span>0</span></td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_H13" value="" formControlName="PARTSSERVICEINVESTMENTS_H13" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_I13" value="" formControlName="PARTSSERVICEINVESTMENTS_I13" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_J13" value="" formControlName="PARTSSERVICEINVESTMENTS_J13" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_K13" value="" formControlName="PARTSSERVICEINVESTMENTS_K13" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_L13" value="" formControlName="PARTSSERVICEINVESTMENTS_L13" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_M13" value="" formControlName="PARTSSERVICEINVESTMENTS_M13" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_N13" value="" formControlName="PARTSSERVICEINVESTMENTS_N13" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_O13" value="" formControlName="PARTSSERVICEINVESTMENTS_O13" />

                        </td>
                        <td class="bev-readiness-year"></td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule pl-3">Incremental number of BEV-ready bays</td>
                        <td class="bev-readiness-target"></td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_F14" value="" formControlName="PARTSSERVICEINVESTMENTS_F14" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_G14" value="" formControlName="PARTSSERVICEINVESTMENTS_G14" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_H14" value="" formControlName="PARTSSERVICEINVESTMENTS_H14" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_I14" value="" formControlName="PARTSSERVICEINVESTMENTS_I14" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_J14" value="" formControlName="PARTSSERVICEINVESTMENTS_J14" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_K14" value="" formControlName="PARTSSERVICEINVESTMENTS_K14" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_L14" value="" formControlName="PARTSSERVICEINVESTMENTS_L14" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_M14" value="" formControlName="PARTSSERVICEINVESTMENTS_M14" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_N14" value="" formControlName="PARTSSERVICEINVESTMENTS_N14" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_O14" value="" formControlName="PARTSSERVICEINVESTMENTS_O14" />

                        </td>
                        <td class="bev-readiness-year"></td>
                    </tr>

                    <tr>
                        <div style="display: flex; align-items: center;" class="bev-readiness-schedule">
                            <td class="font-weight-500">Total service technicians (across all rooftops)</td>
                            <span class="text-danger">(*)</span>
                           </div>
                        <td class="bev-readiness-target">
                            <div class="input-box bev-target" [class.input-box-error]="partsServiceForm.get('PARTSSERVICEINVESTMENTS_C16')?.hasError('required')">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_C16" value="" formControlName="PARTSSERVICEINVESTMENTS_C16" />
                                <span>techs BEV-ready per rooftop in 2024 <span class="text-danger">(*)</span></span>
                            </div>
                        </td>
                        <td class="bev-readiness-year p-0">
                            <div class="input-box">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_F16" value="" formControlName="PARTSSERVICEINVESTMENTS_F16" />
                            </div>
                        </td>
                        <td class="bev-readiness-year p-0">
                            <div class="input-box">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_G16" value="" formControlName="PARTSSERVICEINVESTMENTS_G16" />
                            </div>
                        </td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year p-0">
                            <div class="input-box">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_O16" value="" formControlName="PARTSSERVICEINVESTMENTS_O16" />
                            </div>
                        </td>
                        <td class="bev-readiness-year">
                            <span class="bi-info-circle w-auto" placement="top"
                                ngbTooltip="Enter number of techs across all rooftops for 2021, 2022 and expected in 2030"></span>
                        </td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule pl-3">Cumulative number of BEV-ready (SH+Battery Specialist) technicians</td>
                        <td class="bev-readiness-target">
                            <div class="input-box bev-target" [class.input-box-error]="partsServiceForm.get('PARTSSERVICEINVESTMENTS_C17')?.hasError('required')">
                                <input type="text" appAllowOnlyNumbers [percentage]="true" id ="PARTSSERVICEINVESTMENTS_C17" value="" formControlName="PARTSSERVICEINVESTMENTS_C17" />
                                <span>% Techs BEV-ready in 2030 <span class="text-danger">(*)</span></span>
                            </div>
                            <div class="d-flex input-error" *ngIf="partsServiceForm.controls.PARTSSERVICEINVESTMENTS_C17.touched">
                                <div *ngIf="partsServiceForm.controls.PARTSSERVICEINVESTMENTS_C17.errors?.min || partsServiceForm.controls.PARTSSERVICEINVESTMENTS_C17.errors?.max">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Please enter a valid percentage between 0 and 100</span>
                                </div>
                            </div>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_H17" value="" formControlName="PARTSSERVICEINVESTMENTS_H17" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_I17" value="" formControlName="PARTSSERVICEINVESTMENTS_I17" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_J17" value="" formControlName="PARTSSERVICEINVESTMENTS_J17" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_K17" value="" formControlName="PARTSSERVICEINVESTMENTS_K17" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_L17" value="" formControlName="PARTSSERVICEINVESTMENTS_L17" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_M17" value="" formControlName="PARTSSERVICEINVESTMENTS_M17" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_N17" value="" formControlName="PARTSSERVICEINVESTMENTS_N17" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_O17" value="" formControlName="PARTSSERVICEINVESTMENTS_O17" />

                        </td>
                        <td class="bev-readiness-year"></td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule pl-3">Incremental number of BEV-ready (SH+Battery Specialist) technicians</td>
                        <td class="bev-readiness-target"></td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_F18" value="" formControlName="PARTSSERVICEINVESTMENTS_F18" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_G18" value="" formControlName="PARTSSERVICEINVESTMENTS_G18" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_H18" value="" formControlName="PARTSSERVICEINVESTMENTS_H18" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_I18" value="" formControlName="PARTSSERVICEINVESTMENTS_I18" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_J18" value="" formControlName="PARTSSERVICEINVESTMENTS_J18" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_K18" value="" formControlName="PARTSSERVICEINVESTMENTS_K18" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_L18" value="" formControlName="PARTSSERVICEINVESTMENTS_L18" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_M18" value="" formControlName="PARTSSERVICEINVESTMENTS_M18" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false" [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_N18" value="" formControlName="PARTSSERVICEINVESTMENTS_N18" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_O18" value="" formControlName="PARTSSERVICEINVESTMENTS_O18" />

                        </td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">
                            <span>New Truck Sales variables</span>
                            <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="No input required in this section.  Results are based on number of rooftops"></span>
                        </td>
                        <td class="bev-readiness-target"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule pl-3">Sales people to receive eConsulting baseline training</td>
                        <td class="bev-readiness-target"></td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_H21" value="" formControlName="PARTSSERVICEINVESTMENTS_H21" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_J21" value="" formControlName="PARTSSERVICEINVESTMENTS_J21" />

                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_M21" value="" formControlName="PARTSSERVICEINVESTMENTS_M21" />

                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year"></td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule pl-3">Promotional Detroit eFill chargers</td>
                        <td class="bev-readiness-target"></td>
                        <td class="bev-readiness-year default-cell"><span>0</span></td>
                        <td class="bev-readiness-year default-cell"><span>0</span></td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_J22" value="" formControlName="PARTSSERVICEINVESTMENTS_J22" />

                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_M22" value="" formControlName="PARTSSERVICEINVESTMENTS_M22" />

                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year"></td>
                    </tr>

                    <tr>
                        <div style="display: flex; align-items: center;" class="bev-readiness-schedule">
                            <td class="font-weight-500">Total mobile service vehicles (across all rooftops)</td>
                            <span class="text-danger">(*)</span>
                           </div>
                        <td class="bev-readiness-target"></td>
                        <td class="bev-readiness-year p-0">
                            <div class="input-box">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_F24" value="" formControlName="PARTSSERVICEINVESTMENTS_F24" />
                            </div>
                        </td>
                        <td class="bev-readiness-year p-0">
                            <div class="input-box">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_G24" value="" formControlName="PARTSSERVICEINVESTMENTS_G24" />
                            </div>
                        </td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year p-0">
                            <div class="input-box">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_O24" value="" formControlName="PARTSSERVICEINVESTMENTS_O24" />
                            </div>
                        </td>
                        <td class="bev-readiness-year">
                            <span class="bi-info-circle w-auto" placement="top"
                                ngbTooltip="Enter number of mobile service vehicles across all rooftops in 2021, 2022 and expected in 2030"></span>
                        </td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule pl-3">Cumulative number of BEV-ready mobile service vehicles</td>
                        <td class="bev-readiness-target">
                            <div class="input-box bev-target" [class.input-box-error]="partsServiceForm.get('PARTSSERVICEINVESTMENTS_C25')?.hasError('required')">
                                <input type="text" appAllowOnlyNumbers [percentage]="true" id ="PARTSSERVICEINVESTMENTS_C25" value="" formControlName="PARTSSERVICEINVESTMENTS_C25" />
                                <span>% mobile service vehicles BEV-ready in 2030 <span class="text-danger">(*)</span></span>
                            </div>
                            <div class="d-flex input-error" *ngIf="partsServiceForm.controls.PARTSSERVICEINVESTMENTS_C25.touched">
                                <div *ngIf="partsServiceForm.controls.PARTSSERVICEINVESTMENTS_C25.errors?.min || partsServiceForm.controls.PARTSSERVICEINVESTMENTS_C25.errors?.max">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Please enter a valid percentage between 0 and 100</span>
                                </div>
                            </div>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_H25" value="" formControlName="PARTSSERVICEINVESTMENTS_H25" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_I25" value="" formControlName="PARTSSERVICEINVESTMENTS_I25" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_J25" value="" formControlName="PARTSSERVICEINVESTMENTS_J25" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_K25" value="" formControlName="PARTSSERVICEINVESTMENTS_K25" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_L25" value="" formControlName="PARTSSERVICEINVESTMENTS_L25" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_M25" value="" formControlName="PARTSSERVICEINVESTMENTS_M25" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_N25" value="" formControlName="PARTSSERVICEINVESTMENTS_N25" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_O25" value="" formControlName="PARTSSERVICEINVESTMENTS_O25" />

                        </td>
                        <td class="bev-readiness-year"></td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule pl-3">Incremental number of BEV ready mobile service vehicles</td>
                        <td class="bev-readiness-target"></td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_F26" value="" formControlName="PARTSSERVICEINVESTMENTS_F26" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_G26" value="" formControlName="PARTSSERVICEINVESTMENTS_G26" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_H26" value="" formControlName="PARTSSERVICEINVESTMENTS_H26" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_I26" value="" formControlName="PARTSSERVICEINVESTMENTS_I26" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_J26" value="" formControlName="PARTSSERVICEINVESTMENTS_J26" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_K26" value="" formControlName="PARTSSERVICEINVESTMENTS_K26" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_L26" value="" formControlName="PARTSSERVICEINVESTMENTS_L26" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_M26" value="" formControlName="PARTSSERVICEINVESTMENTS_M26" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_N26" value="" formControlName="PARTSSERVICEINVESTMENTS_N26" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_O26" value="" formControlName="PARTSSERVICEINVESTMENTS_O26" />

                        </td>
                        <td class="bev-readiness-year"></td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Total parts warehouses</td>
                        <td class="bev-readiness-target"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule pl-3">Cumulative number of parts warehouses modified to store 10+ battery packs</td>
                        <td class="bev-readiness-target"></td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year default-cell">
                            <span>0</span>
                        </td>
                        <td class="bev-readiness-year p-0">
                            <div class="input-box">
                                <input type="text" appAllowOnlyNumbers id ="PARTSSERVICEINVESTMENTS_O29" value="" formControlName="PARTSSERVICEINVESTMENTS_O29" />
                            </div>
                        </td>
                        <td class="bev-readiness-year">
                            <span class="bi-info-circle w-auto" placement="top"
                                ngbTooltip="Enter number of parts warehouses modified to store 10+ battery packs in 2030.  Note: only a small number of dealership outlets will likely require long-term storage of battery packs"></span>
                        </td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule pl-3">Incremental number of parts warehouses modified to store 10+ battery packs</td>
                        <td class="bev-readiness-target"></td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_F30" value="" formControlName="PARTSSERVICEINVESTMENTS_F30" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_G30" value="" formControlName="PARTSSERVICEINVESTMENTS_G30" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_H30" value="" formControlName="PARTSSERVICEINVESTMENTS_H30" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_I30" value="" formControlName="PARTSSERVICEINVESTMENTS_I30" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_J30" value="" formControlName="PARTSSERVICEINVESTMENTS_J30" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_K30" value="" formControlName="PARTSSERVICEINVESTMENTS_K30" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_L30" value="" formControlName="PARTSSERVICEINVESTMENTS_L30" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_M30" value="" formControlName="PARTSSERVICEINVESTMENTS_M30" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_N30" value="" formControlName="PARTSSERVICEINVESTMENTS_N30" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula [currency]="false"  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_O30" value="" formControlName="PARTSSERVICEINVESTMENTS_O30" />

                        </td>
                        <td class="bev-readiness-year"></td>
                    </tr>

                    <tr>
                        <div style="display: flex; align-items: center;" class="bev-readiness-schedule">
                            <td class="font-weight-500">Amortization period of investment length (in years) </td>
                            <span class="text-danger">(*)</span>
                           </div>
                        <td class="bev-readiness-target">
                            <div class="input-box bev-target">
                                <input type="text" appAllowOnlyNumbers appMinMaxValue [minValue]="0" [maxValue]="10" appNonNegative id ="PARTSSERVICEINVESTMENTS_C32" value="" formControlName="PARTSSERVICEINVESTMENTS_C32" />
                                <span class="text-danger">(*)</span>
                                <span class="bi-info-circle ml-2 w-auto" placement="top"
                                    ngbTooltip="Default is 5"></span>
                            </div>
                            <div class="d-flex input-error show-min-max-error d-none">
                                <span class="bi-exclamation-circle mr-2"></span>
                                <span>Maximum value of Amortization period of investment is 10</span>
                            </div>
                        </td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                        <td class="bev-readiness-year"></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div [class.remove-border]="removeBorder" class="annual-incremental-costs-section  custom-fieldset mt-5" #annualBevCostContent>
            <p *ngIf="addHeading" class="font-weight-500 dealer-title"></p>
            <p class="font-weight-500 text-capitalize custom-fieldset-title-section">
                <span class="custom-fieldset-title">Annual Incremental BEV-Readiness Costs - Summary</span>
            </p>

            <table>
                <thead class="bev-action-term">
                    <th class="bev-readiness-schedule"></th>
                    <th class="bev-readiness-target"></th>
                    <td style="background-color: white;"class="bev-readiness-year"></td>
                    <td style="background-color: white;"class="bev-readiness-year"></td>
                    <th class="bev-readiness-year year-term immediate-term" colspan="2">
                        <div style="display: block;">
                            <div class="text-line">Immediate action</div>
                            <input type="text" class="bev-total-text" style="background: #f45147;text-align: end;border: none!important;" appFormula [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_H33" value="" formControlName="PARTSSERVICEINVESTMENTS_H33" />
                        </div>

                    </th>
                    <th class="bev-readiness-year year-term medium-term" colspan="3">
                        <div style="display: block;">
                            <div class="text-line"> Medium Term</div>
                            <input type="text" class="bev-total-text" style="background:#ecc039;text-align: end;border: none!important;" appFormula [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_K33" value="" formControlName="PARTSSERVICEINVESTMENTS_K33" />
                        </div>

                    </th>
                    <th class="bev-readiness-year year-term long-term" colspan="3">
                        <div style="display: block;">
                            <div class="text-line"> Long Term</div>
                            <input type="text" class="bev-total-text" style="background:#5ec576;text-align: end;border: none!important;" appFormula [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_O33" value="" formControlName="PARTSSERVICEINVESTMENTS_O33" />
                        </div>
                    </th>
                </thead>
                <tbody>
                    <tr class="table-heading">
                        <td class="bev-readiness-schedule">Parts & Service</td>
                        <td class="bev-readiness-target"></td>
                        <td class="bev-readiness-year">2021</td>
                        <td class="bev-readiness-year">2022</td>
                        <td class="bev-readiness-year">2023</td>
                        <td class="bev-readiness-year">2024</td>
                        <td class="bev-readiness-year">2025</td>
                        <td class="bev-readiness-year">2026</td>
                        <td class="bev-readiness-year">2027</td>
                        <td class="bev-readiness-year">2028</td>
                        <td class="bev-readiness-year">2029</td>
                        <td class="bev-readiness-year">2030</td>
                        <td class="bev-readiness-year">Cumulative years</td>
                    </tr>
                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Grand Total</td>
                        <td class="bev-readiness-target"></td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_F35" value="" formControlName="PARTSSERVICEINVESTMENTS_F35" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_G35" value="" formControlName="PARTSSERVICEINVESTMENTS_G35" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_H35" value="" formControlName="PARTSSERVICEINVESTMENTS_H35" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_I35" value="" formControlName="PARTSSERVICEINVESTMENTS_I35" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_J35" value="" formControlName="PARTSSERVICEINVESTMENTS_J35" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_K35" value="" formControlName="PARTSSERVICEINVESTMENTS_K35" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_L35" value="" formControlName="PARTSSERVICEINVESTMENTS_L35" />

                        </td>
                        <td  class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_M35" value="" formControlName="PARTSSERVICEINVESTMENTS_M35" />

                        </td>
                        <td  class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_N35" value="" formControlName="PARTSSERVICEINVESTMENTS_N35" />

                        </td>
                        <td  class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_O35" value="" formControlName="PARTSSERVICEINVESTMENTS_O35" />

                        </td>
                        <td  class="bev-readiness-year calculated-cell">
                            <input type="text"  id ="PARTSSERVICEINVESTMENTS_P35" [value]="getFinalCount()" />
                        </td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead class="bev-action-term">
                    <th class="bev-readiness-schedule"></th>
                    <th class="bev-readiness-target"></th>
                    <td style="background-color: white;"class="bev-readiness-year"></td>
                    <td style="background-color: white;"class="bev-readiness-year"></td>
                    <th class="bev-readiness-year year-term immediate-term" colspan="2">
                        <div style="display: block;">
                            <div class="text-line">Immediate action</div>
                            <input type="text" class="bev-total-text" style="background: #f45147;text-align: end;border: none!important;" appFormula [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_H37" value="" formControlName="PARTSSERVICEINVESTMENTS_H37" />
                        </div>
                    </th>
                    <th class="bev-readiness-year year-term medium-term" colspan="3">
                        <div style="display: block;">
                            <div class="text-line">Medium Term</div>
                            <input type="text" class="bev-total-text" style="background: #ecc039;text-align: end;border: none!important;" appFormula [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_K37" value="" formControlName="PARTSSERVICEINVESTMENTS_K37" />
                        </div>

                    </th>
                    <th class="bev-readiness-year year-term long-term" colspan="3">
                        <div style="display: block;">
                            <div class="text-line">Long Term</div>
                            <input type="text" class="bev-total-text" style="background: #5ec576;text-align: end;border: none!important;" appFormula [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_O37" value="" formControlName="PARTSSERVICEINVESTMENTS_O37" />
                        </div>
                    </th>
                </thead>
                <tbody>
                    <tr class="table-heading">
                        <td class="bev-readiness-schedule">New Truck Sales</td>
                        <td class="bev-readiness-target"></td>
                        <td class="bev-readiness-year">2021</td>
                        <td class="bev-readiness-year">2022</td>
                        <td class="bev-readiness-year">2023</td>
                        <td class="bev-readiness-year">2024</td>
                        <td class="bev-readiness-year">2025</td>
                        <td class="bev-readiness-year">2026</td>
                        <td class="bev-readiness-year">2027</td>
                        <td class="bev-readiness-year">2028</td>
                        <td class="bev-readiness-year">2029</td>
                        <td class="bev-readiness-year">2030</td>
                        <td class="bev-readiness-year">Cumulative years</td>
                    </tr>
                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Grand Total</td>
                        <td class="bev-readiness-target"></td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_F39" value="" formControlName="PARTSSERVICEINVESTMENTS_F39" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_G39" value="" formControlName="PARTSSERVICEINVESTMENTS_G39" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_H39" value="" formControlName="PARTSSERVICEINVESTMENTS_H39" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_I39" value="" formControlName="PARTSSERVICEINVESTMENTS_I39" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_J39" value="" formControlName="PARTSSERVICEINVESTMENTS_J39" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_K39" value="" formControlName="PARTSSERVICEINVESTMENTS_K39" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_L39" value="" formControlName="PARTSSERVICEINVESTMENTS_L39" />

                        </td>
                        <td  class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_M39" value="" formControlName="PARTSSERVICEINVESTMENTS_M39" />

                        </td>
                        <td  class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_N39" value="" formControlName="PARTSSERVICEINVESTMENTS_N39" />

                        </td>
                        <td  class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_O39" value="" formControlName="PARTSSERVICEINVESTMENTS_O39" />

                        </td>
                        <td  class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative id ="PARTSSERVICEINVESTMENTS_P39" value="" formControlName="PARTSSERVICEINVESTMENTS_P39" />

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div [class.remove-border]="removeBorder"  class="annual-incremental-costs-section custom-fieldset mt-5" #annualIncrementalCostContent>
            <p *ngIf="addHeading" class="font-weight-500 dealer-title"></p>
            <p class="font-weight-500 text-capitalize custom-fieldset-title-section">
                <span class="custom-fieldset-title">Annual Incremental Cost - Detail</span>
            </p>

            <table>
                <thead>
                    <th class="bev-readiness-schedule">Rooftop-variable costs</th>
                    <th class="bev-readiness-unit">Unit cost</th>
                    <th class="bev-readiness-unit">Units per BEV-ready Rooftop</th>
                    <th class="bev-readiness-unit">Per rooftop cost</th>
                    <th class="bev-readiness-year">2021</th>
                    <th class="bev-readiness-year">2022</th>
                    <th class="bev-readiness-year">2023</th>
                    <th class="bev-readiness-year">2024</th>
                    <th class="bev-readiness-year">2025</th>
                    <th class="bev-readiness-year">2026</th>
                    <th class="bev-readiness-year">2027</th>
                    <th class="bev-readiness-year">2028</th>
                    <th class="bev-readiness-year">2029</th>
                    <th class="bev-readiness-year">2030</th>
                    <th class="bev-readiness-year">Cumulative years</th>
                </thead>
                <tbody>
                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Per Rooftop Sub-Total</td>
                        <td class="bev-readiness-unit"></td>
                        <td class="bev-readiness-unit"></td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appFormula [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E42" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F42" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G42" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H42" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I42" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J42" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K42" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L42" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M42" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N42" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O42" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P42" />

                        </td>
                    </tr>
                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Tooling - Impact Protection Lifting Tool</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C43" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D43" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E43" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F43" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G43" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H43" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I43" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J43" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K43" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L43" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M43" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N43" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O43" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P43" />

                        </td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Tooling - Impact Protection Holding Cart</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C44C" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D44" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E44" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F44" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G44" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H44" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I44" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J44" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K44" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L44" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M44" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N44" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O44" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P44" />

                        </td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Tooling - Battery Lifting Device</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C45C" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D45" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E45" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F45" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G45" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H45" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I45" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J45" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K45" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L45" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M45" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N45" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O45" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P45" />

                        </td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Tooling - eAxle Cart</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C46C" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D46" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E46" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F46" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G46" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H46" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I46" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J46" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K46" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L46" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M46" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N46" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O46" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P46" />

                        </td>
                    </tr>


                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Safety - Defibrillation Device (AED)</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C47C" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D47" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E47" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F47" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G47" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H47" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I47" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J47" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K47" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L47" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M47" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N47" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O47" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P47" />

                        </td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Safety - Battery quarantine area outdoors (50x41.5ft; 2,075 sqft)</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C48" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D48" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E48" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F48" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G48" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H48" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I48" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J48" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K48" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L48" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M48" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N48" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O48" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P48" />

                        </td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Service charging -Mobile shop charger (DC fast charger Level 3)</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C49C" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D49" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E49" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F49" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G49" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H49" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I49" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J49" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K49" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L49" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M49" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N49" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O49" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P49" />

                        </td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">NTS - eConsulting Baseline training ($600 tuition + $1000 travel expense)</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C51C" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D51" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E51" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F51" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G51" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H51" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I51" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J51" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K51" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L51" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M51" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N51" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O51" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P51" />

                        </td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">NTS - Promotional Detroit eFill chargers</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C52C" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D52" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E52" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F52" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G52" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H52" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I52" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J52" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K52" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L52" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M52" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N52" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O52" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P52" />

                        </td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <th class="bev-readiness-schedule">Bay-variable costs</th>
                    <th class="bev-readiness-unit">Unit cost</th>
                    <th class="bev-readiness-unit">Units per BEV-ready Bay</th>
                    <th class="bev-readiness-unit">Per bay cost</th>
                    <th class="bev-readiness-year">2021</th>
                    <th class="bev-readiness-year">2022</th>
                    <th class="bev-readiness-year">2023</th>
                    <th class="bev-readiness-year">2024</th>
                    <th class="bev-readiness-year">2025</th>
                    <th class="bev-readiness-year">2026</th>
                    <th class="bev-readiness-year">2027</th>
                    <th class="bev-readiness-year">2028</th>
                    <th class="bev-readiness-year">2029</th>
                    <th class="bev-readiness-year">2030</th>
                    <th class="bev-readiness-year">Cumulative years</th>
                </thead>
                <tbody>
                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Per Bay Sub-Total</td>
                        <td class="bev-readiness-unit"></td>
                        <td class="bev-readiness-unit"></td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E55" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F55" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G55" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H55" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I55" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J55" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K55" />


                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L55" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M55" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N55" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O55" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P55" />

                        </td>
                    </tr>
                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Tooling - HVIL Testing Tool</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C56" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D56" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E56" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F56" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G56" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H56" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I56" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J56" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K56" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L56" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M56" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N56" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O56" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P56" />

                        </td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Tooling - HVS420 8.75Pin M8x1.25x75mm</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C57" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D57" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E57" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F57" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G57" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H57" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I57" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J57" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K57" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L57" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M57" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N57" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O57" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P57" />

                        </td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Safety - Fiberglass insulated rescue hook. Salisbury mod. 3GY61 or equiv.</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C58" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D58" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E58" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F58" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G58" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H58" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I58" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J58" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K58" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L58" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M58" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N58" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O58" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P58" />

                        </td>
                    </tr>

                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Service charging - Wallbox charger (no transfomer/switchgear work req.)</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C59C" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D59" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E59" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F59" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G59" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H59" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I59" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J59" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K59" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L59" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M59" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N59" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O59" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P59" />

                        </td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <th class="bev-readiness-schedule">Technician-variable costs</th>
                    <th class="bev-readiness-unit">Unit cost</th>
                    <th class="bev-readiness-unit">Units per BEV-ready Technician</th>
                    <th class="bev-readiness-unit">Per technician cost</th>
                    <th class="bev-readiness-year">2021</th>
                    <th class="bev-readiness-year">2022</th>
                    <th class="bev-readiness-year">2023</th>
                    <th class="bev-readiness-year">2024</th>
                    <th class="bev-readiness-year">2025</th>
                    <th class="bev-readiness-year">2026</th>
                    <th class="bev-readiness-year">2027</th>
                    <th class="bev-readiness-year">2028</th>
                    <th class="bev-readiness-year">2029</th>
                    <th class="bev-readiness-year">2030</th>
                    <th class="bev-readiness-year">Cumulative years</th>
                </thead>
                <tbody>
                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Per Technician Sub-Total</td>
                        <td class="bev-readiness-unit"></td>
                        <td class="bev-readiness-unit"></td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E66" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F66" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G66" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H66" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I66" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J66" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K66" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L66" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M66" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N66" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O66" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P66" />

                        </td>
                    </tr>
                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Training - SH/HV3 certification and Battery Specialist certification (one time cost)</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C67C" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D67" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E67" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F67" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G67" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H67" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I67" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J67" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K67" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L67" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M67" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N67" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O67" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P67" />

                        </td>
                    </tr>
                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Consummable - PPE and Standard Testing Kit (annual cost)</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C68C" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D68" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E68" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F68" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G68" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H68" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I68" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J68" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K68" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L68" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M68" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N68" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O68" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P68" />

                        </td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <th class="bev-readiness-schedule">Sales personnel-variable costs</th>
                    <th class="bev-readiness-unit">Unit cost</th>
                    <th class="bev-readiness-unit">Units per BEV-ready sales person</th>
                    <th class="bev-readiness-unit">Per sales personnel cost</th>
                    <th class="bev-readiness-year">2021</th>
                    <th class="bev-readiness-year">2022</th>
                    <th class="bev-readiness-year">2023</th>
                    <th class="bev-readiness-year">2024</th>
                    <th class="bev-readiness-year">2025</th>
                    <th class="bev-readiness-year">2026</th>
                    <th class="bev-readiness-year">2027</th>
                    <th class="bev-readiness-year">2028</th>
                    <th class="bev-readiness-year">2029</th>
                    <th class="bev-readiness-year">2030</th>
                    <th class="bev-readiness-year">Cumulative years</th>
                </thead>
                <tbody>
                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Per Sales Personnel Sub-Total</td>
                        <td class="bev-readiness-unit"></td>
                        <td class="bev-readiness-unit"></td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E77" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F77" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G77" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H77" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I77" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J77" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K77" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L77" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M77" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N77" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O77" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P77" />

                        </td>
                    </tr>
                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Training - eConsulting Baseline training (for new truck sales dept.)</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C78" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D78" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E78" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F78" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G78" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H78" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I78" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J78" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K78" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L78" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M78" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N78" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O78" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P78" />

                        </td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <th class="bev-readiness-schedule">Mobile Service Vehicle-variable costs</th>
                    <th class="bev-readiness-unit">Unit cost</th>
                    <th class="bev-readiness-unit">Units per BEV-ready  mobile service vehicle</th>
                    <th class="bev-readiness-unit">Per mobile service vehicle cost</th>
                    <th class="bev-readiness-year">2021</th>
                    <th class="bev-readiness-year">2022</th>
                    <th class="bev-readiness-year">2023</th>
                    <th class="bev-readiness-year">2024</th>
                    <th class="bev-readiness-year">2025</th>
                    <th class="bev-readiness-year">2026</th>
                    <th class="bev-readiness-year">2027</th>
                    <th class="bev-readiness-year">2028</th>
                    <th class="bev-readiness-year">2029</th>
                    <th class="bev-readiness-year">2030</th>
                    <th class="bev-readiness-year">Cumulative years</th>
                </thead>
                <tbody>
                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Per Mobile Service Vehicle Sub-Total</td>
                        <td class="bev-readiness-unit"></td>
                        <td class="bev-readiness-unit"></td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E88" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F88" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G88" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H88" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I88" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J88" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K88" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L88" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M88" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N88" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O88" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P88" />

                        </td>
                    </tr>
                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Vehicle - Cost of RAM 5500</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C89C" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D89" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E89" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F89" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G89" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H89" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I89" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J89" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K89" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L89" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M89" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N89" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O89" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P89" />

                        </td>
                    </tr>
                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Mobile Service Vehicle - Tools & Equip.</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C90C" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D90C" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E90C" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F90C" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G90C" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H90C" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I90C" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J90C" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K90C" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L90C" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M90C" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N90C" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O90C" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P90C" />

                        </td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <th class="bev-readiness-schedule">Parts Warehouse-Variable Cost</th>
                    <th class="bev-readiness-unit">Unit cost</th>
                    <th class="bev-readiness-unit">Units per BEV-ready parts warehouse</th>
                    <th class="bev-readiness-unit">Per parts warehouse cost</th>
                    <th class="bev-readiness-year">2021</th>
                    <th class="bev-readiness-year">2022</th>
                    <th class="bev-readiness-year">2023</th>
                    <th class="bev-readiness-year">2024</th>
                    <th class="bev-readiness-year">2025</th>
                    <th class="bev-readiness-year">2026</th>
                    <th class="bev-readiness-year">2027</th>
                    <th class="bev-readiness-year">2028</th>
                    <th class="bev-readiness-year">2029</th>
                    <th class="bev-readiness-year">2030</th>
                    <th class="bev-readiness-year">Cumulative years</th>
                </thead>
                <tbody>
                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Sub-Total</td>
                        <td class="bev-readiness-unit"></td>
                        <td class="bev-readiness-unit"></td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E99" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F99" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G99" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H99" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I99" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J99" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K99" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L99" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M99" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N99" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O99" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P99" />

                        </td>
                    </tr>
                    <tr>
                        <td class="bev-readiness-schedule font-weight-500">Safety - Firewall / hazzard perimeter in parts warehouse for battery pack storage (10+ packs)</td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_C100C" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_D100" />
                        </td>
                        <td class="bev-readiness-unit calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_E100" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_F100" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_G100" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_H100" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_I100" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_J100" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_K100" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_L100" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_M100" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_N100" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_O100" />

                        </td>
                        <td class="bev-readiness-year calculated-cell">
                            <input type="text"  appFormula  [formulaList]="formulaList" appNonNegative value=""  formControlName="PARTSSERVICEINVESTMENTS_P100" />

                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </form>
    <div class="d-flex justify-content-end mt-4">
        <button class="btn link-btn" (click)="prevPage()">Back</button>
        <button class="btn link-btn ml-3" (click)="resetForm()">Reset</button>
        <button class="btn link-btn ml-3" [disabled]="partsServiceForm.invalid" (click)="beforeClick()" >Next Step</button>
    </div>
</div>

<!-- <div #draftModalRef class="modal fade" id="draftModal"  aria-labelledby="userProfileModalLabel" aria-hidden="addHeading">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <span type="button" class="close" (click)="draftModal.hide()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="addHeading"><i class="bi-x-circle-fill"></i></span>
                </span>
            </div>
            <div class="modal-body">
                <div>
                    <p class="text-capitalize">Would you like to resume from your Last Draft</p>
                    <div class="d-flex justify-content-end mt-4">
                        <button class="btn link-btn" (click)="yesDraft()">Resume</button>
                        <button class="btn link-btn ml-3" (click)="deleteDraft()">Fresh Input</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
