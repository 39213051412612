<div class="footer-section mt-4">
    <div class="d-flex justify-content-between footer-image-section">
        <img src="../../../assets/images/em2.png" class="em2-image" title="em2Image" />
        <img src="../../../assets/images/ecascadia.png" class="ecascadia-image" title="ecascadiaImage" />
    </div>
    <div class="d-flex justify-content-center">
        <img src="../../../assets/images/daimler-truck-image.png" class="daimler-truck-image" title="daimlerTruckImage" />
    </div>
    
    <p class="mt-4 ml-2 footer-text">
        <span>Copyright © Daimler Truck North America LLC. All rights reserved. Daimler Truck North America LLC is a Daimler company</span>
        <a class="privacy-link ml-2" href="https://northamerica.daimlertruck.com/privacy-policy/">Privacy Statement, Legal Notices and Terms</a>
    </p>    
</div>
