import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit, ChangeDetectorRef, Renderer2 } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Modal } from 'bootstrap';
import { AuthService } from '../services/auth.service';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { isEmpty, merge } from 'lodash';
import { ScreenshotService } from '../services/screenshot.service';
import { FormulaList } from './interactive-tab.formulaList';
import { LoaderService } from '../shared/loader/loader.service';
import { typeOf } from 'mathjs';

@Component({
  selector: 'app-interactive-tab-form',
  templateUrl: './interactive-tab-form.component.html',
  styleUrls: ['./interactive-tab-form.component.scss'],
})
export class InteractiveTabFormComponent implements OnInit, OnDestroy, AfterViewInit {
  interactiveTabForm: FormGroup;
  bevRateChecked: boolean = false;
  partsServiceChecked: boolean = false;
  publicChargingChecked: boolean = false;
  referenceDataList: any;
  draftData: any;
  partialData: any;
  class8Total = 0;
  allClassTotal = 0;
  class8TotalAdaptionRate2026 = 0;
  class8TotalAdaptionRate2030 = 0;
  formulaList: any = FormulaList;
  addHeading= false;

  // Subscriptions
  subscriptionsList: Array<Subscription> = [];


  draftModal: Modal;
  @ViewChild('draftModalRef') draftModalRef: ElementRef;

  @ViewChild('revenueAdpRateContent') revenueAdpRateContentElement: ElementRef;
  @ViewChild('newTruckSalesVolContent') newTruckSalesVolContentElement: ElementRef;
  @ViewChild('partsSalesContent') partsSalesContentElement: ElementRef;
  @ViewChild('serviceSalesContent') serviceSalesContentElement: ElementRef;
  @ViewChild('publicChargingContent') publicChargingContentElement: ElementRef;

  revenueAdpRateCapturedImage: any;
  newTruckSalesVolCapturedImage: any;
  partsSalesCapturedImage: any;
  serviceSalesCapturedImage: any;
  publicChargingCapturedImage: any;
  removeArrow = false

  constructor(
    public apiService: ApiService,
    private fb: FormBuilder,
    private router: Router,
    private toast: ToastrService,
    private authService: AuthService,
    private screenshotService: ScreenshotService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private el: ElementRef,
    private loaderService: LoaderService,

  ) {}

  ngOnInit(): void {
    this.apiService.pageTitle =
      'This Is A Custom Scenario Where You Can Input The Revenue, Truck Sales Volumes, And Other Assumptions To Customize The Financial Simulation To Your Dealership(s)';
    // this.getAllReferenceData();
    this.createInteractiveForm();
      }

  ngAfterViewInit(): void {
        if(Object.keys(this.apiService.formData).length === 0) {
      // this.redirectToLandingPage();
    } else {
      const prevFormData = this.apiService.getPrevFormData();
      if(prevFormData.interactiveTab){
      prevFormData.interactiveTab.INTERACTIVETAB_G22=prevFormData.interactiveTab.INTERACTIVETAB_G22 ;
      prevFormData.interactiveTab.INTERACTIVETAB_G23=prevFormData.interactiveTab.INTERACTIVETAB_G23 ;
      prevFormData.interactiveTab.INTERACTIVETAB_G24=prevFormData.interactiveTab.INTERACTIVETAB_G24 ;
      prevFormData.interactiveTab.INTERACTIVETAB_G26=prevFormData.interactiveTab.INTERACTIVETAB_G26 ;
      prevFormData.interactiveTab.INTERACTIVETAB_G27=prevFormData.interactiveTab.INTERACTIVETAB_G27 ;
      prevFormData.interactiveTab.INTERACTIVETAB_G30=prevFormData.interactiveTab.INTERACTIVETAB_G30 ;
      prevFormData.interactiveTab.INTERACTIVETAB_J22=prevFormData.interactiveTab.INTERACTIVETAB_J22 ;
      prevFormData.interactiveTab.INTERACTIVETAB_J23=prevFormData.interactiveTab.INTERACTIVETAB_J23 ;
      prevFormData.interactiveTab.INTERACTIVETAB_J24=prevFormData.interactiveTab.INTERACTIVETAB_J24 ;
      prevFormData.interactiveTab.INTERACTIVETAB_J26=prevFormData.interactiveTab.INTERACTIVETAB_J26 ;
      prevFormData.interactiveTab.INTERACTIVETAB_J27=prevFormData.interactiveTab.INTERACTIVETAB_J27 ;
      prevFormData.interactiveTab.INTERACTIVETAB_J30=prevFormData.interactiveTab.INTERACTIVETAB_J30 ;
      prevFormData.interactiveTab.INTERACTIVETAB_D47=prevFormData.interactiveTab.INTERACTIVETAB_D47 ;
      prevFormData.interactiveTab.INTERACTIVETAB_D48=prevFormData.interactiveTab.INTERACTIVETAB_D48 ;
      prevFormData.interactiveTab.INTERACTIVETAB_D49=prevFormData.interactiveTab.INTERACTIVETAB_D49 ;
      prevFormData.interactiveTab.INTERACTIVETAB_G47=prevFormData.interactiveTab.INTERACTIVETAB_G47 ;
      prevFormData.interactiveTab.INTERACTIVETAB_G48=prevFormData.interactiveTab.INTERACTIVETAB_G48 ;
      prevFormData.interactiveTab.INTERACTIVETAB_G49=prevFormData.interactiveTab.INTERACTIVETAB_G49 ;
      prevFormData.interactiveTab.INTERACTIVETAB_J47=prevFormData.interactiveTab.INTERACTIVETAB_J47 ;
      prevFormData.interactiveTab.INTERACTIVETAB_J48=prevFormData.interactiveTab.INTERACTIVETAB_J48 ;
      prevFormData.interactiveTab.INTERACTIVETAB_J49=prevFormData.interactiveTab.INTERACTIVETAB_J49 ;

      prevFormData.interactiveTab.INTERACTIVETAB_G52=prevFormData.interactiveTab.INTERACTIVETAB_G52 ;
      prevFormData.interactiveTab.INTERACTIVETAB_G53=prevFormData.interactiveTab.INTERACTIVETAB_G53 ;
      prevFormData.interactiveTab.INTERACTIVETAB_G54=prevFormData.interactiveTab.INTERACTIVETAB_G54 ;

      prevFormData.interactiveTab.INTERACTIVETAB_J52=prevFormData.interactiveTab.INTERACTIVETAB_J52 ;
      prevFormData.interactiveTab.INTERACTIVETAB_J53=prevFormData.interactiveTab.INTERACTIVETAB_J53 ;
      prevFormData.interactiveTab.INTERACTIVETAB_J54=prevFormData.interactiveTab.INTERACTIVETAB_J54 ;

      prevFormData.interactiveTab.INTERACTIVETAB_G57=prevFormData.interactiveTab.INTERACTIVETAB_G57 ;
      prevFormData.interactiveTab.INTERACTIVETAB_G58=prevFormData.interactiveTab.INTERACTIVETAB_G58 ;

      prevFormData.interactiveTab.INTERACTIVETAB_J57=prevFormData.interactiveTab.INTERACTIVETAB_J57 ;
      prevFormData.interactiveTab.INTERACTIVETAB_J58=prevFormData.interactiveTab.INTERACTIVETAB_J58 ;

      prevFormData.interactiveTab.INTERACTIVETAB_J61=prevFormData.interactiveTab.INTERACTIVETAB_J61 ;
      prevFormData.interactiveTab.INTERACTIVETAB_G61=prevFormData.interactiveTab.INTERACTIVETAB_G61 ;

      prevFormData.interactiveTab.INTERACTIVETAB_D66=prevFormData.interactiveTab.INTERACTIVETAB_D66 ;
      prevFormData.interactiveTab.INTERACTIVETAB_D67=prevFormData.interactiveTab.INTERACTIVETAB_D67 ;

      prevFormData.interactiveTab.INTERACTIVETAB_G66=prevFormData.interactiveTab.INTERACTIVETAB_G66 ;
      prevFormData.interactiveTab.INTERACTIVETAB_G67=prevFormData.interactiveTab.INTERACTIVETAB_G67 ;

      prevFormData.interactiveTab.INTERACTIVETAB_J66=prevFormData.interactiveTab.INTERACTIVETAB_J66 ;
      prevFormData.interactiveTab.INTERACTIVETAB_J67=prevFormData.interactiveTab.INTERACTIVETAB_J67 ;

      prevFormData.interactiveTab.INTERACTIVETAB_G70=prevFormData.interactiveTab.INTERACTIVETAB_G70 ;
      prevFormData.interactiveTab.INTERACTIVETAB_G71=prevFormData.interactiveTab.INTERACTIVETAB_G71 ;

      prevFormData.interactiveTab.INTERACTIVETAB_J70=prevFormData.interactiveTab.INTERACTIVETAB_J70 ;
      prevFormData.interactiveTab.INTERACTIVETAB_J71=prevFormData.interactiveTab.INTERACTIVETAB_J71 ;

      prevFormData.interactiveTab.INTERACTIVETAB_G74=prevFormData.interactiveTab.INTERACTIVETAB_G74 ;
      prevFormData.interactiveTab.INTERACTIVETAB_G75=prevFormData.interactiveTab.INTERACTIVETAB_G75 ;

      prevFormData.interactiveTab.INTERACTIVETAB_J74=prevFormData.interactiveTab.INTERACTIVETAB_J74 ;
      prevFormData.interactiveTab.INTERACTIVETAB_J75=prevFormData.interactiveTab.INTERACTIVETAB_J75 ;

      prevFormData.interactiveTab.INTERACTIVETAB_G78=prevFormData.interactiveTab.INTERACTIVETAB_G78 ;
      prevFormData.interactiveTab.INTERACTIVETAB_J78=prevFormData.interactiveTab.INTERACTIVETAB_J78 ;
      }
      if (!isEmpty(prevFormData.interactiveTab)) {
        this.partialData = prevFormData.interactiveTab;
      } else {
        this.partialData = this.apiService.getFormData();
      }
      this.assignDraftData();
    }
if(!this.partialData) {
  this.partialData = {}
}
    this.interactiveTabForm.get("INTERACTIVETAB_K97")?.setValue(this.partialData["INTERACTIVETAB_K97"]?this.partialData["INTERACTIVETAB_K97"]:"YES");
    this.interactiveTabForm.get("INTERACTIVETAB_K101")?.setValue(this.partialData["INTERACTIVETAB_K101"]?this.partialData["INTERACTIVETAB_K101"]:"YES");
    this.interactiveTabForm.get("INTERACTIVETAB_K105")?.setValue(this.partialData["INTERACTIVETAB_K105"]?this.partialData["INTERACTIVETAB_K105"]:"YES");
    this.interactiveTabForm.get("INTERACTIVETAB_K109")?.setValue(this.partialData["INTERACTIVETAB_K109"]?this.partialData["INTERACTIVETAB_K109"]:"YES");
    this.interactiveTabForm.get("INTERACTIVETAB_K111")?.setValue(this.partialData["INTERACTIVETAB_K111"]?this.partialData["INTERACTIVETAB_K111"]:"YES");
    this.interactiveTabForm.get("INTERACTIVETAB_K118")?.setValue(this.partialData["INTERACTIVETAB_K118"]?this.partialData["INTERACTIVETAB_K118"]:"YES");
    this.interactiveTabForm.get("INTERACTIVETAB_K123")?.setValue(this.partialData["INTERACTIVETAB_K123"]?this.partialData["INTERACTIVETAB_K123"]:"YES");
    this.interactiveTabForm.get("INTERACTIVETAB_K129")?.setValue(this.partialData["INTERACTIVETAB_K129"]?this.partialData["INTERACTIVETAB_K129"]:"YES");
    this.interactiveTabForm.get("INTERACTIVETAB_K135")?.setValue(this.partialData["INTERACTIVETAB_K135"]?this.partialData["INTERACTIVETAB_K135"]:"YES");
    this.interactiveTabForm.get("INTERACTIVETAB_K137")?.setValue(this.partialData["INTERACTIVETAB_K137"]?this.partialData["INTERACTIVETAB_K137"]:"YES");
    this.interactiveTabForm.get("INTERACTIVETAB_K141")?.setValue(this.partialData["INTERACTIVETAB_K141"]?this.partialData["INTERACTIVETAB_K141"]:"YES");
    this.interactiveTabForm.get("INTERACTIVETAB_K143")?.setValue(this.partialData["INTERACTIVETAB_K143"]?this.partialData["INTERACTIVETAB_K143"]:"YES");
  }

  ngOnDestroy(): void {
    if (this.subscriptionsList.length > 0) {
      this.subscriptionsList.forEach((item: Subscription) => {
        item.unsubscribe();
      });
    }
  }

  redirectToLandingPage(): void {
    this.router.navigate(['/']);
  }

  yesDraft() {
    this.apiService.loadFromDraft();
    this.apiService.saveFormData(this.draftData);
    this.assignDraftData();
    this.draftModal.hide();
    const bevRateToggle = document.querySelector('#INTERACTIVETAB_C19') as HTMLFormElement;
    bevRateToggle.checked = true;
    this.bevRateChecked = true;

    const partsServiceToggle = document.querySelector('#toggleButton') as HTMLFormElement;
    partsServiceToggle.checked = true;
    this.partsServiceChecked = true;

    const publicChargingToggle = document.querySelector('#publicChargingToggle') as HTMLFormElement;
    publicChargingToggle.checked = true;
    this.publicChargingChecked = true;
  }

  deleteDraft() {
    this.apiService.discardDraft();
    this.interactiveTabForm.reset();
    this.draftModal.hide();
    this.interactiveTabForm.patchValue({
      INTERACTIVETAB_G86: 'YES',
      INTERACTIVETAB_J86: 'YES',
    });
  }

  createInteractiveForm() {
    this.interactiveTabForm = this.fb.group({
      OUTPUTGRAPH_B6: 'CUSTOM OPTIONS',
      INTERACTIVETAB_D11: ['', Validators.required],
      INTERACTIVETAB_D12: ['', Validators.required],
      INTERACTIVETAB_D13: ['', Validators.required],
      INTERACTIVETAB_D14: ['', Validators.required],
      INTERACTIVETAB_D15: ['', Validators.required],
      INTERACTIVETAB_D16: ['', Validators.required],
      INTERACTIVETAB_G22: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G23: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G24: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G26: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G27: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J22: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J23: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J24: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J26: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J27: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G30: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J30: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_D35: ['', Validators.required],
      INTERACTIVETAB_D36: ['', Validators.required],
      INTERACTIVETAB_D37: ['', Validators.required],
      INTERACTIVETAB_D39: ['', Validators.required],
      INTERACTIVETAB_D40: ['', Validators.required],
      INTERACTIVETAB_D47: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G47: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J47: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_D48: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G48: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J48: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_D49: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G49: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J49: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G52: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J52: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G53: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J53: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G54: ['', [Validators.min(0), Validators.max(100)]],
      INTERACTIVETAB_J54: ['', [Validators.min(0), Validators.max(100)]],
      INTERACTIVETAB_G57: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J57: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G58: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J58: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G61: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J61: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_D66: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G66: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J66: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_D67: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G67: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J67: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G70: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J70: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G71: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J71: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G74: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J74: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G75: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J75: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G78: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_J78: ['',  [
        Validators.min(0),
        Validators.max(100),
        this.percentValidator
      ]],
      INTERACTIVETAB_G82: [''],
      INTERACTIVETAB_J82: [''],
      INTERACTIVETAB_G83: [''],
      INTERACTIVETAB_J83: [''],
      INTERACTIVETAB_G84: [''],
      INTERACTIVETAB_J84: [''],
      INTERACTIVETAB_G85: [''],
      INTERACTIVETAB_J85: [''],
      INTERACTIVETAB_G86: ['YES'],
      INTERACTIVETAB_J86: ['YES'],
      TOTAL_HAULS: [''],
      INTERACTIVETAB_D38: [''],
      INTERACTIVETAB_G25: [''],
      INTERACTIVETAB_J25: [''],
      INTERACTIVETAB_K97: ['YES'],
      INTERACTIVETAB_K101: ['YES'],
      INTERACTIVETAB_K105: ['YES'],
      INTERACTIVETAB_K109: ['YES'],
      INTERACTIVETAB_K111: ['YES'],
      INTERACTIVETAB_K118: ['YES'],
      INTERACTIVETAB_K123: ['YES'],
      INTERACTIVETAB_K129: ['YES'],
      INTERACTIVETAB_K135: ['YES'],
      INTERACTIVETAB_K137: ['YES'],
      INTERACTIVETAB_K141: ['YES'],
      INTERACTIVETAB_K143: ['YES'],
    },);



    this.setEnterKeyHandler();

  }
  percentValidator(control:any) {
    const value = control.value;
    if(!value){
      return
    }
    const value2 = value.replace("%","")
    if (value2 !== null && (isNaN(value2) || value2 < 0 || value2 > 100)) {
      return { percentRange: true };
    }
 
    return null;
  }

  setEnterKeyHandler() {
    // Dynamically add the keydown.enter event to input fields
    this.addEnterKeyHandler('INTERACTIVETAB_D11', 'INTERACTIVETAB_D12');
    this.addEnterKeyHandler('INTERACTIVETAB_D12', 'INTERACTIVETAB_D13');
    this.addEnterKeyHandler('INTERACTIVETAB_D13', 'INTERACTIVETAB_D14');
    this.addEnterKeyHandler('INTERACTIVETAB_D14', 'INTERACTIVETAB_D15');
    this.addEnterKeyHandler('INTERACTIVETAB_D15', 'INTERACTIVETAB_D16');

    if (this.bevRateChecked) {
      this.addEnterKeyHandler('INTERACTIVETAB_D16', 'INTERACTIVETAB_G22');
      this.addEnterKeyHandler('INTERACTIVETAB_G22', 'INTERACTIVETAB_J22');
      this.addEnterKeyHandler('INTERACTIVETAB_J22', 'INTERACTIVETAB_G23');
      this.addEnterKeyHandler('INTERACTIVETAB_G23', 'INTERACTIVETAB_J23');
      this.addEnterKeyHandler('INTERACTIVETAB_J23', 'INTERACTIVETAB_G24');
      this.addEnterKeyHandler('INTERACTIVETAB_G24', 'INTERACTIVETAB_J24');
      this.addEnterKeyHandler('INTERACTIVETAB_J24', 'INTERACTIVETAB_G26');
      this.addEnterKeyHandler('INTERACTIVETAB_G26', 'INTERACTIVETAB_J26');
      this.addEnterKeyHandler('INTERACTIVETAB_J26', 'INTERACTIVETAB_G27');
      this.addEnterKeyHandler('INTERACTIVETAB_G27', 'INTERACTIVETAB_J27');
      this.addEnterKeyHandler('INTERACTIVETAB_J27', 'INTERACTIVETAB_G30');
      this.addEnterKeyHandler('INTERACTIVETAB_G30', 'INTERACTIVETAB_J30');
      this.addEnterKeyHandler('INTERACTIVETAB_J30', 'INTERACTIVETAB_D35');
    } else {
      this.addEnterKeyHandler('INTERACTIVETAB_D16', 'INTERACTIVETAB_D35');
      this.addEnterKeyHandler('INTERACTIVETAB_D35', 'INTERACTIVETAB_D36');
      this.addEnterKeyHandler('INTERACTIVETAB_D36', 'INTERACTIVETAB_D37');
      this.addEnterKeyHandler('INTERACTIVETAB_D37', 'INTERACTIVETAB_D39');
      this.addEnterKeyHandler('INTERACTIVETAB_D39', 'INTERACTIVETAB_D40');
    }

    if(this.partsServiceChecked) {
      this.addEnterKeyHandler('INTERACTIVETAB_D40', 'INTERACTIVETAB_D47');
      this.addEnterKeyHandler('INTERACTIVETAB_D47', 'INTERACTIVETAB_G47');
      this.addEnterKeyHandler('INTERACTIVETAB_G47', 'INTERACTIVETAB_J47');

      this.addEnterKeyHandler('INTERACTIVETAB_J47', 'INTERACTIVETAB_D48');
      this.addEnterKeyHandler('INTERACTIVETAB_D48', 'INTERACTIVETAB_G48');
      this.addEnterKeyHandler('INTERACTIVETAB_G48', 'INTERACTIVETAB_J48');

      this.addEnterKeyHandler('INTERACTIVETAB_J48', 'INTERACTIVETAB_D49');
      this.addEnterKeyHandler('INTERACTIVETAB_D49', 'INTERACTIVETAB_G49');
      this.addEnterKeyHandler('INTERACTIVETAB_G49', 'INTERACTIVETAB_J49');

      this.addEnterKeyHandler('INTERACTIVETAB_J49', 'INTERACTIVETAB_G52');
      this.addEnterKeyHandler('INTERACTIVETAB_G52', 'INTERACTIVETAB_J52');

      this.addEnterKeyHandler('INTERACTIVETAB_J52', 'INTERACTIVETAB_G53');
      this.addEnterKeyHandler('INTERACTIVETAB_G53', 'INTERACTIVETAB_J53');

      this.addEnterKeyHandler('INTERACTIVETAB_J53', 'INTERACTIVETAB_G54');
      this.addEnterKeyHandler('INTERACTIVETAB_G54', 'INTERACTIVETAB_J54');

      this.addEnterKeyHandler('INTERACTIVETAB_J54', 'INTERACTIVETAB_G57');
      this.addEnterKeyHandler('INTERACTIVETAB_G57', 'INTERACTIVETAB_J57');

      this.addEnterKeyHandler('INTERACTIVETAB_J57', 'INTERACTIVETAB_G58');
      this.addEnterKeyHandler('INTERACTIVETAB_G58', 'INTERACTIVETAB_J58');

      this.addEnterKeyHandler('INTERACTIVETAB_J58', 'INTERACTIVETAB_G61');
      this.addEnterKeyHandler('INTERACTIVETAB_G61', 'INTERACTIVETAB_J61');

      this.addEnterKeyHandler('INTERACTIVETAB_J61', 'INTERACTIVETAB_D66');
      this.addEnterKeyHandler('INTERACTIVETAB_D66', 'INTERACTIVETAB_G66');
      this.addEnterKeyHandler('INTERACTIVETAB_G66', 'INTERACTIVETAB_J66');

      this.addEnterKeyHandler('INTERACTIVETAB_J66', 'INTERACTIVETAB_D67');
      this.addEnterKeyHandler('INTERACTIVETAB_D67', 'INTERACTIVETAB_G67');
      this.addEnterKeyHandler('INTERACTIVETAB_G67', 'INTERACTIVETAB_J67');

      this.addEnterKeyHandler('INTERACTIVETAB_J67', 'INTERACTIVETAB_G70');
      this.addEnterKeyHandler('INTERACTIVETAB_G70', 'INTERACTIVETAB_J70');

      this.addEnterKeyHandler('INTERACTIVETAB_J70', 'INTERACTIVETAB_G71');
      this.addEnterKeyHandler('INTERACTIVETAB_G71', 'INTERACTIVETAB_J71');

      this.addEnterKeyHandler('INTERACTIVETAB_J71', 'INTERACTIVETAB_G74');
      this.addEnterKeyHandler('INTERACTIVETAB_G74', 'INTERACTIVETAB_J74');

      this.addEnterKeyHandler('INTERACTIVETAB_J74', 'INTERACTIVETAB_G75');
      this.addEnterKeyHandler('INTERACTIVETAB_G75', 'INTERACTIVETAB_J75');

      this.addEnterKeyHandler('INTERACTIVETAB_J75', 'INTERACTIVETAB_G78');
      this.addEnterKeyHandler('INTERACTIVETAB_G78', 'INTERACTIVETAB_J78');
    }

    if (this.publicChargingChecked) {
      if (this.partsServiceChecked) {
        this.addEnterKeyHandler('INTERACTIVETAB_J78', 'INTERACTIVETAB_G82');
      } else {
        this.addEnterKeyHandler('INTERACTIVETAB_D40', 'INTERACTIVETAB_G82');
      }

      this.addEnterKeyHandler('INTERACTIVETAB_G82', 'INTERACTIVETAB_J82');

      this.addEnterKeyHandler('INTERACTIVETAB_J82', 'INTERACTIVETAB_G83');
      this.addEnterKeyHandler('INTERACTIVETAB_G83', 'INTERACTIVETAB_J83');

      this.addEnterKeyHandler('INTERACTIVETAB_J83', 'INTERACTIVETAB_G84');
      this.addEnterKeyHandler('INTERACTIVETAB_G84', 'INTERACTIVETAB_J84');

      this.addEnterKeyHandler('INTERACTIVETAB_J84', 'INTERACTIVETAB_G85');
      this.addEnterKeyHandler('INTERACTIVETAB_G85', 'INTERACTIVETAB_J85');

      this.addEnterKeyHandler('INTERACTIVETAB_J85', 'INTERACTIVETAB_G86');
      this.addEnterKeyHandler('INTERACTIVETAB_G86', 'INTERACTIVETAB_J86');

    }

  }

  createValueChangeSubscriptions() {
    const s1: any = this.interactiveTabForm
      .get('INTERACTIVETAB_D35')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        this.updateAllClassTotal();
      });
      if (s1) {
        this.subscriptionsList.push(s1);
      }

      const s2: any = this.interactiveTabForm
      .get('INTERACTIVETAB_D36')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        this.updateAllClassTotal();
      });
      if (s2) {
        this.subscriptionsList.push(s2);
      }

      const s3: any = this.interactiveTabForm
      .get('INTERACTIVETAB_D37')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        this.updateAllClassTotal();
      });
      if (s3) {
        this.subscriptionsList.push(s3);
      }

      const s4: any = this.interactiveTabForm
      .get('INTERACTIVETAB_D39')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        this.updateClass8Total();
        this.updateAllClassTotal();
      });
      if (s4) {
        this.subscriptionsList.push(s4);
      }

      const s5: any = this.interactiveTabForm
      .get('INTERACTIVETAB_D40')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        this.updateClass8Total();
        this.updateAllClassTotal();
      });
      if (s5) {
        this.subscriptionsList.push(s5);
      }

      // const s6: any = this.interactiveTabForm
      // .get('INTERACTIVETAB_G26')
      // ?.valueChanges.pipe(distinctUntilChanged())
      // .subscribe(() => {
      //   this.updateClass8TotalAdaptionRate2026();
      // });
      // if (s6) {
      //   this.subscriptionsList.push(s6);
      // }

      // const s7: any = this.interactiveTabForm
      // .get('INTERACTIVETAB_G27')
      // ?.valueChanges.pipe(distinctUntilChanged())
      // .subscribe(() => {
      //   this.updateClass8TotalAdaptionRate2026();
      // });
      // if (s7) {
      //   this.subscriptionsList.push(s7);
      // }

      // const s8: any = this.interactiveTabForm
      // .get('INTERACTIVETAB_J26')
      // ?.valueChanges.pipe(distinctUntilChanged())
      // .subscribe(() => {
      //   this.updateClass8TotalAdaptionRate2030();
      // });
      // if (s8) {
      //   this.subscriptionsList.push(s8);
      // }

      // const s9: any = this.interactiveTabForm
      // .get('INTERACTIVETAB_J27')
      // ?.valueChanges.pipe(distinctUntilChanged())
      // .subscribe(() => {
      //   this.updateClass8TotalAdaptionRate2030();
      // });

      // if (s9) {
      //   this.subscriptionsList.push(s9);
      // }
  }

  updateClass8TotalAdaptionRate2026() {
    const class8LongHaulAdaptionRateValue2026 = this.interactiveTabForm.get('INTERACTIVETAB_G26')?.value.replace(/%/g, '');
    const class8ShortHaulAdaptionRateValue2026 = this.interactiveTabForm.get('INTERACTIVETAB_G27')?.value.replace(/%/g, '');
    const class8LongHaulAdaptionRate2026 = class8LongHaulAdaptionRateValue2026 || 0;
    const class8ShortHaulAdaptionRate2026 = class8ShortHaulAdaptionRateValue2026 || 0;
    this.class8TotalAdaptionRate2026 = Number(class8LongHaulAdaptionRate2026) + Number(class8ShortHaulAdaptionRate2026);
    this.interactiveTabForm.patchValue({
      INTERACTIVETAB_G25: this.class8TotalAdaptionRate2026 + '%',
      });
  }

  updateClass8TotalAdaptionRate2030() {
    const class8LongHaulAdaptionRateValue2030 = this.interactiveTabForm.get('INTERACTIVETAB_J26')?.value.replace(/%/g, '');
    const class8ShortHaulAdaptionRateValue2030 = this.interactiveTabForm.get('INTERACTIVETAB_J27')?.value.replace(/%/g, '');
    const class8LongHaulAdaptionRate2030 = class8LongHaulAdaptionRateValue2030 || 0;
    const class8ShortHaulAdaptionRate2030 = class8ShortHaulAdaptionRateValue2030 || 0;
    this.class8TotalAdaptionRate2030 = Number(class8LongHaulAdaptionRate2030) + Number(class8ShortHaulAdaptionRate2030);
    this.interactiveTabForm.patchValue({
      INTERACTIVETAB_J25: this.class8TotalAdaptionRate2030 + '%',
      });
  }

  updateClass8Total() {
    const class8LongHaul =
      this.interactiveTabForm.get('INTERACTIVETAB_D39')?.value || 0;
    const class8ShortHaul =
      this.interactiveTabForm.get('INTERACTIVETAB_D40')?.value || 0;
    this.class8Total = Number(class8LongHaul) + Number(class8ShortHaul);
    this.interactiveTabForm.patchValue({
      INTERACTIVETAB_D38: this.class8Total,
      });

  }

  updateAllClassTotal() {
    const class5Value =
      this.interactiveTabForm.get('INTERACTIVETAB_D35')?.value || 0;
    const class6Value =
      this.interactiveTabForm.get('INTERACTIVETAB_D36')?.value || 0;
    const class7Value =
      this.interactiveTabForm.get('INTERACTIVETAB_D37')?.value || 0;
    const class8LongHaul =
      this.interactiveTabForm.get('INTERACTIVETAB_D39')?.value || 0;
    const class8ShortHaul =
      this.interactiveTabForm.get('INTERACTIVETAB_D40')?.value || 0;
    this.allClassTotal =
      Number(class5Value) +
      Number(class6Value) +
      Number(class7Value) +
      Number(class8LongHaul) +
      Number(class8ShortHaul);
    this.interactiveTabForm.patchValue({
      TOTAL_HAULS: this.allClassTotal,
    });
  }

  getAllReferenceData() {
    const s10: any = this.apiService.getReferenceData().subscribe((response) => {
      this.referenceDataList = response;
    });

    if (s10) {
      this.subscriptionsList.push(s10);
    }
  }

  getDraftData() {
    if (!this.loaderService.isVisible()) {
      this.loaderService.show();
    }
    const userdata = this.authService.getCurrentUser().data;

    const requestData = {
      userId: userdata.userId,
      draft: this.apiService.formData,
      createdBy: userdata.username,
      updatedBy: userdata.username,
    };

    const s11: any = this.apiService.previousPageData(requestData).subscribe(
      (response) => {
        if (response.status === 'success') {
          this.draftData = response?.data?.draft ? JSON.parse(response?.data?.draft?.draft) : {};
          this.partialData = isEmpty(response.data.partial) ? this.draftData : response.data.partial;
          if (!isEmpty(this.draftData)) {
            this.draftModal = new Modal(this.draftModalRef.nativeElement, {});
            this.draftModal.show();
          } else {
            this.interactiveTabForm.reset();
            this.interactiveTabForm.patchValue({
              INTERACTIVETAB_G86: 'YES',
              INTERACTIVETAB_J86: 'YES',
            });
          }
          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
        }
      },
      (err) => {
        if (this.loaderService.isVisible()) {
          this.loaderService.hide();
        }
        //console.log(err);
      }
    );

    if (s11) {
      this.subscriptionsList.push(s11);
    }
  }

  resetForm() {
    Object.keys(this.interactiveTabForm.controls).forEach((item:any) => {
      this.interactiveTabForm?.get(item)?.reset()
  })
    this.interactiveTabForm.patchValue({
      INTERACTIVETAB_G86: 'YES',
      INTERACTIVETAB_J86: 'YES',
    });
  }

  private prependDollarSign(inputString: any) {
    // Check if the string already starts with a dollar sign
    if (!inputString.toString().startsWith('$')) {
      // If not, prepend a dollar sign to the string
      inputString = Number(inputString).toLocaleString("en-US");
      return '$' + inputString;
    }

    // If the string already starts with a dollar sign, return the original string
    return inputString;
  }

  private appendPercentSign(inputString: any) {
    // Check if the string already starts with a dollar sign
    if (!inputString.toString().endsWith('%')) {
      // If not, prepend a dollar sign to the string
     return inputString + '%';
      }
    // If the string already starts with a dollar sign, return the original string
    return inputString;
  }

  private convertExpression(input: any) {
     if (typeof input === 'number') {
      return input + '%';
    } else if (typeof input === 'string') {
      return input.replace(/%/g, '')+"%";
    }
    else{
    return input;
    }
  }


  assignDraftData() {
    this.interactiveTabForm.patchValue({
      INTERACTIVETAB_D11: this.formatCurrency(this.partialData?.INTERACTIVETAB_D11?.toString() ? this.prependDollarSign(this.partialData?.INTERACTIVETAB_D11): ''),
      INTERACTIVETAB_D12: this.formatCurrency(this.partialData?.INTERACTIVETAB_D12?.toString() ? this.prependDollarSign(this.partialData?.INTERACTIVETAB_D12) : ''),
      INTERACTIVETAB_D13: this.formatCurrency(this.partialData?.INTERACTIVETAB_D13?.toString() ? this.prependDollarSign(this.partialData?.INTERACTIVETAB_D13) : ''),
      INTERACTIVETAB_D14: this.formatCurrency(this.partialData?.INTERACTIVETAB_D14?.toString() ? this.prependDollarSign(this.partialData?.INTERACTIVETAB_D14) : ''),
      INTERACTIVETAB_D15: this.formatCurrency(this.partialData?.INTERACTIVETAB_D15?.toString() ? this.prependDollarSign(this.partialData?.INTERACTIVETAB_D15) : ''),
      INTERACTIVETAB_D16: this.formatCurrency(this.partialData?.INTERACTIVETAB_D16?.toString() ? this.prependDollarSign(this.partialData?.INTERACTIVETAB_D16) : ''),

      INTERACTIVETAB_G22: this.partialData?.INTERACTIVETAB_G22 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G22) : '',
      INTERACTIVETAB_G23: this.partialData?.INTERACTIVETAB_G23 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G23) : '',
      INTERACTIVETAB_G24: this.partialData?.INTERACTIVETAB_G24 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G24) : '',
      INTERACTIVETAB_G26: this.partialData?.INTERACTIVETAB_G26 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G26) : '',
      INTERACTIVETAB_G27: this.partialData?.INTERACTIVETAB_G27 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G27) : '',
      INTERACTIVETAB_J22: this.partialData?.INTERACTIVETAB_J22 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J22) : '',
      INTERACTIVETAB_J23: this.partialData?.INTERACTIVETAB_J23 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J23) : '',
      INTERACTIVETAB_J24: this.partialData?.INTERACTIVETAB_J24 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J24) : '',
      INTERACTIVETAB_J26: this.partialData?.INTERACTIVETAB_J26 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J26) : '',
      INTERACTIVETAB_J27: this.partialData?.INTERACTIVETAB_J27 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J27) : '',
      INTERACTIVETAB_G30: this.partialData?.INTERACTIVETAB_G30 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G30) : '',
      INTERACTIVETAB_J30: this.partialData?.INTERACTIVETAB_J30 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J30) : '',
      INTERACTIVETAB_D35: this.partialData?.INTERACTIVETAB_D35,
      INTERACTIVETAB_D36: this.partialData?.INTERACTIVETAB_D36,
      INTERACTIVETAB_D37: this.partialData?.INTERACTIVETAB_D37,
      INTERACTIVETAB_D39: this.partialData?.INTERACTIVETAB_D39,
      INTERACTIVETAB_D40: this.partialData?.INTERACTIVETAB_D40,
      INTERACTIVETAB_D47: this.partialData?.INTERACTIVETAB_D47 ? this.convertExpression(this.partialData?.INTERACTIVETAB_D47) : '',
      INTERACTIVETAB_G47: this.partialData?.INTERACTIVETAB_G47 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G47) : '',
      INTERACTIVETAB_J47: this.partialData?.INTERACTIVETAB_J47 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J47) : '',
      INTERACTIVETAB_D48: this.partialData?.INTERACTIVETAB_D48 ? this.convertExpression(this.partialData?.INTERACTIVETAB_D48) : '',
      INTERACTIVETAB_G48: this.partialData?.INTERACTIVETAB_G48 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G48) : '',
      INTERACTIVETAB_J48: this.partialData?.INTERACTIVETAB_J48 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J48) : '',
      INTERACTIVETAB_D49: this.partialData?.INTERACTIVETAB_D49 ? this.convertExpression(this.partialData?.INTERACTIVETAB_D49) : '',
      INTERACTIVETAB_G49: this.partialData?.INTERACTIVETAB_G49 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G49) : '',
      INTERACTIVETAB_J49: this.partialData?.INTERACTIVETAB_J49 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J49) : '',
      INTERACTIVETAB_G52: this.partialData?.INTERACTIVETAB_G52 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G52) : '',
      INTERACTIVETAB_J52: this.partialData?.INTERACTIVETAB_J52 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J52) : '',
      INTERACTIVETAB_G53: this.partialData?.INTERACTIVETAB_G53 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G53) : '',
      INTERACTIVETAB_J53: this.partialData?.INTERACTIVETAB_J53 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J53) : '',
      INTERACTIVETAB_G54: this.partialData?.INTERACTIVETAB_G54 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G54) : '',
      INTERACTIVETAB_J54: this.partialData?.INTERACTIVETAB_J54 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J54) : '',
      INTERACTIVETAB_G57: this.partialData?.INTERACTIVETAB_G57 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G57) : '',
      INTERACTIVETAB_J57: this.partialData?.INTERACTIVETAB_J57 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J57) : '',
      INTERACTIVETAB_G58: this.partialData?.INTERACTIVETAB_G58 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G58) : '',
      INTERACTIVETAB_J58: this.partialData?.INTERACTIVETAB_J58 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J58) : '',
      INTERACTIVETAB_G61: this.partialData?.INTERACTIVETAB_G61 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G61) : '',
      INTERACTIVETAB_J61: this.partialData?.INTERACTIVETAB_J61 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J61) : '',
      INTERACTIVETAB_D66: this.partialData?.INTERACTIVETAB_D66 ? this.convertExpression(this.partialData?.INTERACTIVETAB_D66) : '',
      INTERACTIVETAB_G66: this.partialData?.INTERACTIVETAB_G66 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G66) : '',
      INTERACTIVETAB_J66: this.partialData?.INTERACTIVETAB_J66 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J66) : '',
      INTERACTIVETAB_D67: this.partialData?.INTERACTIVETAB_D67 ? this.convertExpression(this.partialData?.INTERACTIVETAB_D67) : '',
      INTERACTIVETAB_G67: this.partialData?.INTERACTIVETAB_G67 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G67) : '',
      INTERACTIVETAB_J67: this.partialData?.INTERACTIVETAB_J67 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J67) : '',
      INTERACTIVETAB_G70: this.partialData?.INTERACTIVETAB_G70 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G70) : '',
      INTERACTIVETAB_J70: this.partialData?.INTERACTIVETAB_J70 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J70) : '',
      INTERACTIVETAB_G71: this.partialData?.INTERACTIVETAB_G71 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G71) : '',
      INTERACTIVETAB_J71: this.partialData?.INTERACTIVETAB_J71 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J71) : '',
      INTERACTIVETAB_G74: this.partialData?.INTERACTIVETAB_G74 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G74) : '',
      INTERACTIVETAB_J74: this.partialData?.INTERACTIVETAB_J74 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J74) : '',
      INTERACTIVETAB_G75: this.partialData?.INTERACTIVETAB_G75 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G75) : '',
      INTERACTIVETAB_J75: this.partialData?.INTERACTIVETAB_J75 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J75) : '',
      INTERACTIVETAB_G78: this.partialData?.INTERACTIVETAB_G78 ? this.convertExpression(this.partialData?.INTERACTIVETAB_G78) : '',
      INTERACTIVETAB_J78: this.partialData?.INTERACTIVETAB_J78 ? this.convertExpression(this.partialData?.INTERACTIVETAB_J78) : '',
      INTERACTIVETAB_G82: this.partialData?.INTERACTIVETAB_G82,
      INTERACTIVETAB_J82: this.partialData?.INTERACTIVETAB_J82,
      INTERACTIVETAB_G83: this.partialData?.INTERACTIVETAB_G83,
      INTERACTIVETAB_J83: this.partialData?.INTERACTIVETAB_J83,
      INTERACTIVETAB_G84: this.partialData?.INTERACTIVETAB_G84?.toString() ? this.prependDollarSign(this.partialData.INTERACTIVETAB_G84) : '',
      INTERACTIVETAB_J84: this.partialData?.INTERACTIVETAB_J84?.toString() ? this.prependDollarSign(this.partialData.INTERACTIVETAB_J84) : '',
      INTERACTIVETAB_G85: this.partialData?.INTERACTIVETAB_G85?.toString() ? this.prependDollarSign(this.partialData.INTERACTIVETAB_G85) : '',
      INTERACTIVETAB_J85: this.partialData?.INTERACTIVETAB_J85?.toString() ? this.prependDollarSign(this.partialData.INTERACTIVETAB_J85) : '',
      INTERACTIVETAB_G86: this.partialData?.INTERACTIVETAB_G86,
      INTERACTIVETAB_J86: this.partialData?.INTERACTIVETAB_J86
    });

    this.bevRateChecked = this.partialData?.bevRateChecked || false;
    this.partsServiceChecked = this.partialData?.partsServiceChecked || false;
    this.publicChargingChecked = this.partialData?.publicChargingChecked || false;

    this.cdr.detectChanges();
  }

  onPartsServiceChecked() {
    if (this.partsServiceChecked) {
      this.partsServiceChecked = false;
    } else {
      this.partsServiceChecked = true;
    }
    this.setEnterKeyHandler();
  }

  onBevRateChecked() {
    if (this.bevRateChecked) {
      this.bevRateChecked = false;
    } else {
      this.bevRateChecked = true;
    }
    this.setEnterKeyHandler();
  }

  onPublicChargingChecked() {
    if (this.publicChargingChecked) {
      this.publicChargingChecked = false;
    } else {
      this.publicChargingChecked = true;
    }
    this.setEnterKeyHandler();
  }
  validateCells(updatedFormData:any,e:any):void{
    if(updatedFormData[e]){
    let validData = updatedFormData[e]?.split('.')
    if (validData) {
     updatedFormData[e] = validData[0]+ '.' + validData[1].slice(0,2)
    }}
}
  nextPage() {
    this.removeArrow = true
    this.addHeading = true
    if (this.interactiveTabForm.valid) {
      if (!this.loaderService.isVisible()) {
        this.loaderService.show();
      }
      const updatedFormData = this.slicInputs(this.interactiveTabForm.value)
      // this.apiService.saveFormData(this.interactiveTabForm.value);
      this.apiService.saveFormData(updatedFormData);

      const prevFormData = merge(updatedFormData, {
        bevRateChecked: this.bevRateChecked,
        partsServiceChecked: this.partsServiceChecked,
        publicChargingChecked: this.publicChargingChecked
      });
      //this.apiService.savePrevFormData('interactiveTab', prevFormData);
      // ------ Save previous form data ----
     this.validateCells(updatedFormData,'INTERACTIVETAB_G84')
     this.validateCells(updatedFormData,'INTERACTIVETAB_G85')
     this.validateCells(updatedFormData,'INTERACTIVETAB_J84')
     this.validateCells(updatedFormData,'INTERACTIVETAB_J85')
        this.apiService.savePrevFormData(
          "interactiveTab",
          updatedFormData
        );
        let finalFormValue=this.interactiveTabForm.value;
        finalFormValue.INTERACTIVETAB_D47=this.interactiveTabForm.get("INTERACTIVETAB_D47")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_D47")?.value)) / 100):null

        finalFormValue.INTERACTIVETAB_G47=this.interactiveTabForm.get("INTERACTIVETAB_G47")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G47")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J47=this.interactiveTabForm.get("INTERACTIVETAB_J47")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J47")?.value)) / 100):null

        finalFormValue.INTERACTIVETAB_D48=this.interactiveTabForm.get("INTERACTIVETAB_D48")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_D48")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J48=this.interactiveTabForm.get("INTERACTIVETAB_J48")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J48")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G48=this.interactiveTabForm.get("INTERACTIVETAB_G48")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G48")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_D49=this.interactiveTabForm.get("INTERACTIVETAB_D49")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_D49")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G49=this.interactiveTabForm.get("INTERACTIVETAB_G49")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G49")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J49=this.interactiveTabForm.get("INTERACTIVETAB_J49")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J49")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G52=this.interactiveTabForm.get("INTERACTIVETAB_G52")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G52")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G53=this.interactiveTabForm.get("INTERACTIVETAB_G53")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G53")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G54=this.interactiveTabForm.get("INTERACTIVETAB_G54")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G54")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J52=this.interactiveTabForm.get("INTERACTIVETAB_J52")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J52")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J53=this.interactiveTabForm.get("INTERACTIVETAB_J53")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J53")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J54=this.interactiveTabForm.get("INTERACTIVETAB_J54")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J54")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G57=this.interactiveTabForm.get("INTERACTIVETAB_G57")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G57")?.value)) / 100):null

        finalFormValue.INTERACTIVETAB_J57=this.interactiveTabForm.get("INTERACTIVETAB_J57")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J57")?.value)) / 100):null
        finalFormValue.INTERACTIVETAB_G58=this.interactiveTabForm.get("INTERACTIVETAB_G58")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G58")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J58=this.interactiveTabForm.get("INTERACTIVETAB_J58")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J58")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G61=this.interactiveTabForm.get("INTERACTIVETAB_G61")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G61")?.value)) / 100):null

        finalFormValue.INTERACTIVETAB_J61=this.interactiveTabForm.get("INTERACTIVETAB_J61")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J61")?.value)) / 100):null

        finalFormValue.INTERACTIVETAB_D66=this.interactiveTabForm.get("INTERACTIVETAB_D66")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_D66")?.value)) / 100):null



        finalFormValue.INTERACTIVETAB_D67=this.interactiveTabForm.get("INTERACTIVETAB_D67")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_D67")?.value)) / 100):null



        finalFormValue.INTERACTIVETAB_G66=this.interactiveTabForm.get("INTERACTIVETAB_G66")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G66")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G67=this.interactiveTabForm.get("INTERACTIVETAB_G67")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G67")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J66=this.interactiveTabForm.get("INTERACTIVETAB_J66")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J66")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J67=this.interactiveTabForm.get("INTERACTIVETAB_J67")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J67")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G70=this.interactiveTabForm.get("INTERACTIVETAB_G70")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G70")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G71=this.interactiveTabForm.get("INTERACTIVETAB_G71")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G71")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J70=this.interactiveTabForm.get("INTERACTIVETAB_J70")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J70")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J71=this.interactiveTabForm.get("INTERACTIVETAB_J71")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J71")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G74=this.interactiveTabForm.get("INTERACTIVETAB_G74")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G74")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G75=this.interactiveTabForm.get("INTERACTIVETAB_G75")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G75")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J74=this.interactiveTabForm.get("INTERACTIVETAB_J74")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J74")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J75=this.interactiveTabForm.get("INTERACTIVETAB_J75")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J75")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G78=this.interactiveTabForm.get("INTERACTIVETAB_G78")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G78")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J78=this.interactiveTabForm.get("INTERACTIVETAB_J78")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J78")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G22=this.interactiveTabForm.get("INTERACTIVETAB_G22")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G22")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G23=this.interactiveTabForm.get("INTERACTIVETAB_G23")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G23")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G24=this.interactiveTabForm.get("INTERACTIVETAB_G24")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G24")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G26=this.interactiveTabForm.get("INTERACTIVETAB_G26")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G26")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G27=this.interactiveTabForm.get("INTERACTIVETAB_G27")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G27")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_G30=this.interactiveTabForm.get("INTERACTIVETAB_G30")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_G30")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J22=this.interactiveTabForm.get("INTERACTIVETAB_J22")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J22")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J23=this.interactiveTabForm.get("INTERACTIVETAB_J23")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J23")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J24=this.interactiveTabForm.get("INTERACTIVETAB_J24")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J24")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J26=this.interactiveTabForm.get("INTERACTIVETAB_J26")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J26")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J27=this.interactiveTabForm.get("INTERACTIVETAB_J27")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J27")?.value)) / 100):null


        finalFormValue.INTERACTIVETAB_J30=this.interactiveTabForm.get("INTERACTIVETAB_J30")?.value?(parseFloat(this.convertExpression(this.interactiveTabForm.get("INTERACTIVETAB_J30")?.value)) / 100):null


        if (!this.loaderService.isVisible()) {
          this.loaderService.show();
        }

        this.apiService.saveFormData(finalFormValue);

      const userdata = this.authService.getCurrentUser().data;
      const requestData = {
        userId: userdata.userId,
        draft: this.apiService.formData,
        createdBy: userdata.username,
        updatedBy: userdata.username,
      };
      const bevRateToggle = document.querySelector('#INTERACTIVETAB_C19') as HTMLFormElement;
      bevRateToggle.checked = true;
      this.bevRateChecked = true;

      const partsServiceToggle = document.querySelector('#toggleButton') as HTMLFormElement;
      partsServiceToggle.checked = true;
      this.partsServiceChecked = true;

      const publicChargingToggle = document.querySelector('#publicChargingToggle') as HTMLFormElement;
      publicChargingToggle.checked = true;
      this.publicChargingChecked = true;


      const s12: any = this.apiService.nextPageNavigation(requestData).subscribe(
        (response) => {
          if (response.status === 'success') {
            this.apiService.saveFormData(response.data.partial);
            this.captureScreenshot();
            // this.interactiveTabForm.reset();
            this.toast.success('Values saved successfully in Draft');
            if (this.loaderService.isVisible()) {
              this.loaderService.hide();
            }
          }
        },
        (err) => {
          console.error(err);
          this.toast.error(
            err?.error?.errorDescription ||
              'Something went wrong in saving the values in Draft'
          );
          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
        }
      );

      if (s12) {
        this.subscriptionsList.push(s12);
      }
    }
  }

  prevPage() {
    this.apiService.saveFormData(this.interactiveTabForm.value);
    this.apiService.savePrevFormData('interactiveTab', this.interactiveTabForm.value);
    this.router.navigate(['/instructions']);
  }

  async captureScreenshot() {
    const revenueAdpRateElementToCapture = this.revenueAdpRateContentElement.nativeElement;
    const newTruckSalesVolElementToCapture = this.newTruckSalesVolContentElement.nativeElement;
    const partsSalesElementToCapture = this.partsSalesContentElement.nativeElement;
    const serviceSalesElementToCapture = this.serviceSalesContentElement.nativeElement;
    const publicChargingElementToCapture = this.publicChargingContentElement.nativeElement;
    await this.screenshotService
    .captureScreenshot(revenueAdpRateElementToCapture)
    .then((canvas) => {
      this.revenueAdpRateCapturedImage = canvas.toDataURL('image/png');
    });
    await this.screenshotService
    .captureScreenshot(newTruckSalesVolElementToCapture)
    .then((canvas) => {
      this.newTruckSalesVolCapturedImage = canvas.toDataURL('image/png');
    });
    await this.screenshotService
    .captureScreenshot(partsSalesElementToCapture)
    .then((canvas) => {
      this.partsSalesCapturedImage = canvas.toDataURL('image/png');
    });
    await this.screenshotService
    .captureScreenshot(serviceSalesElementToCapture)
    .then((canvas) => {
      this.serviceSalesCapturedImage = canvas.toDataURL('image/png');
    });
    await this.screenshotService
    .captureScreenshot(publicChargingElementToCapture)
    .then((canvas) => {
      this.publicChargingCapturedImage = canvas.toDataURL('image/png');
    });
    const imgObject = [
      {
        id: 'revenueAdpRateContent',
        imgCaptured: this.revenueAdpRateCapturedImage
      },
      {
        id: 'newTruckSalesVolContent',
        imgCaptured: this.newTruckSalesVolCapturedImage
      },
      {
        id: 'partsSalesContent',
        imgCaptured: this.partsSalesCapturedImage
      },
      {
        id: 'serviceSalesContent',
        imgCaptured: this.serviceSalesCapturedImage
      },
      {
        id: 'publicChargingContent',
        imgCaptured: this.publicChargingCapturedImage
      },
    ]

    const pageObject = {
      page: 'Dealership Assumptions',
      imgCapture: imgObject
    }

    if (this.apiService.pageScreenshot['Dealership Assumptions']) {
      this.apiService.pageScreenshot['Dealership Assumptions']['imgCapture'].push(imgObject);
    } else {
      this.apiService.pageScreenshot['Dealership Assumptions'] = pageObject;
    }
    setTimeout(() => {
      this.router.navigate(['/parts-service-investments']);
      }, 1000);
  }

  updatedValueForm(event: {formControlName: any, value: any}) {
    this.interactiveTabForm.get(event.formControlName)?.setValue(event.value)
  }

  enterSubmitted(event: any) {
    if(event.which === 13) {
      event.preventDefault();
    }
  }

  focusNextField(fieldName: string) {
    const nextField = document.getElementById(fieldName);
    const nextFieldSelectAll = document.getElementById(fieldName) as HTMLInputElement
    nextFieldSelectAll?.select();
    if (nextField) {
      nextField.focus();
    }
  }

  private addEnterKeyHandler(currentField: string, nextField: string) {
    const currentFieldElement = this.el.nativeElement.querySelector(`#${currentField}`);
    this.renderer.listen(currentFieldElement, 'keydown.enter', (event) => {
      event.preventDefault();
      this.focusNextField(nextField);
    });
  }

  private slicInputs(formdata: any){
    formdata.INTERACTIVETAB_D11 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_D11);
    formdata.INTERACTIVETAB_D12 = this.checkCharAtLatPosition(formdata.INTERACTIVETAB_D12);
    formdata.INTERACTIVETAB_D13 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_D13);
    formdata.INTERACTIVETAB_D14 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_D14);
    formdata.INTERACTIVETAB_D15 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_D15);
    formdata.INTERACTIVETAB_D16 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_D16);

    formdata.INTERACTIVETAB_D35 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_D35);
    formdata.INTERACTIVETAB_D36 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_D36);
    formdata.INTERACTIVETAB_D37 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_D37);
    formdata.INTERACTIVETAB_D39 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_D39);
    formdata.INTERACTIVETAB_D40 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_D40);

    formdata.INTERACTIVETAB_G82 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_G82);
    formdata.INTERACTIVETAB_G83 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_G83);
    formdata.INTERACTIVETAB_G84 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_G84);
    formdata.INTERACTIVETAB_G85 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_G85);
    formdata.INTERACTIVETAB_J82 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_J82);
    formdata.INTERACTIVETAB_J83 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_J83);
    formdata.INTERACTIVETAB_J84 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_J84);
    formdata.INTERACTIVETAB_J85 =  this.checkCharAtLatPosition(formdata.INTERACTIVETAB_J85);
    return formdata
  }

  private checkCharAtLatPosition(inputValue: any){
    if(inputValue && typeof inputValue === 'string'){
      const lastChar = inputValue.charAt(inputValue.length - 1)
      const isNotDigit: boolean = /\D/.test(lastChar);
      if(isNotDigit){
        return inputValue.slice(0, -1)
      } else {
        return inputValue;
      }
    } else {
      return inputValue
    }
  }
   formatCurrency(input:any) {
    const value = parseFloat(input.replace(/[$,]/g, ''));

    // Check if the value is a valid number
    if (isNaN(value)) {
        // console.error('Invalid input');
        return null;
    }

    // Format the number with commas
    const formattedValue = value.toLocaleString('en-US');

    return '$' + formattedValue;
}

}
