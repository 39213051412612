export const environment = {
  production: false,
  environmentName: 'development',
  // API_URL: 'http://localhost:8282/dfst-api',
  API_URL:'https://dfst-dev.tsac-staging.daimlertruck.com/dfst-api',
  SECRET_KEY: 'knBdxhSLbKgW2P9UmZF85GvaMy4HeYAT',
  ENCRYPTION_ENABLED: false,
  clientId:"79821369-612a-4165-ad3e-e051ab7601be",
  redirectURI:"https://dfst-dev.tsac-staging.daimlertruck.com/",
  knownAuthorities: ["login-qa.ciam.daimlertruck.com"],
  postLogoutRedirectUri:"https://dfst-dev.tsac-staging.daimlertruck.com/logout",
  authority:"https://login-qa.ciam.daimlertruck.com/88f558f5-a216-470b-b34a-3164f5d6ec0f/b2c_1a_signin_oidc_noam",
  logoutUrl:"https://login-qa.ciam.daimlertruck.com/88f558f5-a216-470b-b34a-3164f5d6ec0f/b2c_1a_signin_oidc_row/oauth2/v2.0/logout"
};
