// breadcrumb.component.ts

import { AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbService } from '../services/breadcumbs.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent implements AfterContentChecked, AfterViewInit, OnDestroy {
  breadcrumbs: any = [];
  sub: Subscription;
  winLocation = location

  constructor(private breadcrumbService: BreadcrumbService, private cdr: ChangeDetectorRef,) {}

  ngAfterViewInit(): void {
    this.sub = this.breadcrumbService.breadcrumbs$.subscribe((breadcrumbs: any) => {
      this.breadcrumbs = breadcrumbs;
    });
  }
ngAfterContentChecked(): void {
  this.cdr.detectChanges();
}
  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
