import {
  AfterContentChecked,
  Directive,
  ElementRef,
  Input,
  Optional,
  Renderer2,
} from '@angular/core';
import {
  filter,
  forEach,
  get,
  includes,
  isBoolean,
  isEmpty,
  isNaN,
  isNull,
  isNumber,
  merge,
} from 'lodash';
import * as math from 'mathjs';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { formatCurrency } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { FormulaList } from './formulaList';
import { CurrencyFormatPipe } from '../pipes/currencyFormat.pipe';

// Key words to exclude when checking a formual for variables
const EXCLUDE_EXPRESSIONS = [
  'IF',
  'ELSE',
  'NULL',
  'CUSTOM',
  'OPTIONS',
  'NO',
  '+',
  'ROUND',
  'ROUNDUP',
  'ROUNDDOWN',
  'FLOOR',
];

@Directive({
  selector: '[appFormattedValue]',
})
export class FormulaEvaluationDirective implements AfterContentChecked {
  formulaList: any = FormulaList;
  @Input() formGroup: FormGroup;
  @Input() dataScope: any = {};
  @Input() precision: number = 0;
  @Input() isNumber = false;
  @Input() isCurrency = false;
  @Input() isPercentage = false;
  @Input() defaultText = '';
  @Input() defaultTextCondition = '';

  formulaProcessed = false;
  formula: string = '';
  formulaContext: any = {};
  scope: any = {};
  subscription: Subscription;
  errs: any = [];

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private cfr: CurrencyFormatPipe
  ) {}

  ngAfterContentChecked(): void {
    if (!this.formulaProcessed) {
      this.formulaList = this.convertObjectValuesToNumbers(
        this.formulaList,
        false
      );
      this.formulaProcessed = true;
    }
    this.subscribeToFormChanges();
  }

  ngOnDestroy(): void {
    this.formulaProcessed = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private subscribeToFormChanges(): void {
    const formGroup = this.formGroup;
    if (formGroup && !this.subscription) {
      this.subscription = formGroup.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((formValues) => {
          const formData = JSON.parse(localStorage.getItem('formData') || '{}');
          this.formulaContext = merge(formData, this.replaceDollarSigns(formValues));
          this.updateResult();
        });
    }
  }

  private replaceDollarSigns(obj: any) {
    // Iterate through each key-value pair in the object
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // If the value is an object, recursively call the function
        obj[key] = this.replaceDollarSigns(obj[key]);
      } else if (typeof obj[key] === 'string') {
        // If the value is a string, replace '$' sign
        obj[key] = obj[key].replace(/%/g, ''); // Replace with your desired replacement string
      }
    }
    return obj;
  }

  private updateResult() {
    let result;
    try {
      this.scope = this.convertObjectValuesToNumbers(
        merge(this.formulaContext, this.dataScope),
        true
      );

      let temp;
      let expression = '';

      const formCtrlId = this.elementRef.nativeElement.id;

      expression = get(this.formulaList, `${formCtrlId}`);
      if (!expression) {
        expression = formCtrlId;
        if (this.scope.hasOwnProperty(expression)) {
          expression = this.scope[expression];
        }
      } else {
        expression = this.lookupVariables(expression, this.scope);
      }

      if (isNumber(expression) || expression == 'YES' || expression == 'NO') {
        temp = expression;
      } else if (isBoolean(expression)) {
        temp = !expression ? 'NO' : 'YES';
      } else if (
        expression.startsWith('ROUNDUP') ||
        expression.startsWith('ROUNDDOWN')
      ) {
        temp = this.evaluateRoundFunctions(expression, this.scope);
      } else if (includes(expression, 'IF') || includes(expression, 'ELSE')) {
        temp = this.evaluateIfElseExpression(expression, this.scope);
      } else {
        temp =
          math.round(math.evaluate(expression, this.scope), this.precision) ||
          0;
      }

      if (this.isCurrency) {
        result = formatCurrency(temp, 'en-US', '$', 'USD', '1.0-0');
      } else if (this.isNumber) {
        result = temp;
      } else if (this.isPercentage) {
        result = parseFloat(temp).toFixed(this.precision).toString() + '%';
      } else {
        result = temp;
      }
      if (
        this.defaultTextCondition &&
        this.defaultText &&
        result == this.defaultTextCondition
      ) {
        result = this.defaultText;
      }
    } catch (error) {
      // console.error(error);
      result = 0;
    }

    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'innerHTML',
      result
    );
  }

  private lookupVariables(expression: any, scope: any) {
    try {
      if (expression !== undefined) {
        // Return if expression is number, YES, NO
        if (isNumber(expression) || expression == 'YES' || expression == 'NO') {
          return expression;
        }

        // Extract variables
        const variables = this.extractVariables(expression);

        // IF there are variables, evaluate and replace
        if (variables) {
          forEach(variables, (variable: string) => {
            // Look into formula list
            let expr = this.formulaList[variable];

            // Look into calculated data (data from API + UI)
            if (!expr) {
              expr = scope[variable];
            }

            if (expr !== undefined) {
              const temp = this.lookupVariables(expr, scope);
              expression = expression.replaceAll(variable, temp);
            }
          });

          return expression;
        } else {
          let result = 0;

          result =
            math.round(math.evaluate(expression, scope), this.precision) || 0;
          return result;
        }
      }
    } catch (error) {
      // console.error(error);
      return 0;
    }
  }

  private extractVariables(expression: string) {
    if (expression) {
      expression = expression
        .replaceAll('+', ' + ')
        .replaceAll('*', ' * ')
        .replaceAll('-', ' - ')
        .replaceAll('/', ' / ');

      // Define a regular expression to match variable names
      // const variableRegex = /[a-zA-Z_][a-zA-Z0-9_+&]*/g;
      const variableRegex = /\b(?![0-9]\.)[a-zA-Z_][a-zA-Z0-9_+&]*\b/g;

      // Find all matches of variable names in the expression
      const matches = expression.match(variableRegex);

      if (matches) {
        // Remove duplicate variable names (if any)
        const temp = Array.from(new Set(matches));

        // Filter out keywords
        const uniqueVariables = filter(temp, function (o: any) {
          return !includes(EXCLUDE_EXPRESSIONS, o);
        });
        return uniqueVariables;
      }
    }
    return [];
  }

  private convertObjectValuesToNumbers(obj: any, modify = true) {
    const result: any = {};
    for (const key in obj) {
      const replacedKey = key.replaceAll('&', '_').replaceAll('$', '');
      if (obj.hasOwnProperty(key)) {
        let value = obj[key];
        if (value) {
          value = obj[key].toString();
          value = value.replaceAll('$', '');

          if (
            value.startsWith('ROUNDUP') ||
            value.startsWith('ROUNDDOWN') ||
            value.startsWith('FLOOR')
          ) {
          } else {
            // Replace , with empty
            value = value.replaceAll(',', '');
          }
        }
        if (modify) {
          let replacedValue;
          if (value == 'YES' || value == 'NO') {
            replacedValue = value;
          } else {
            replacedValue = value ? parseFloat(value) : 0;
          }
          result[replacedKey] = replacedValue;
        } else {
          result[replacedKey] = value;
        }
      }
    }
    return result;
  }

  private evaluateRoundFunctions(formula: string, scope: any): any {
    try {
      let expr = formula;
      if (expr.startsWith('ROUNDUP')) {
        expr = expr.replace('ROUNDUP(', '');
      }
      if (expr.startsWith('ROUNDDOWN')) {
        expr = expr.replace('ROUNDDOWN(', '');
      }
      expr = expr.slice(0, -1);
      const temp = expr.split(',');
      const roundOffValue = parseInt(temp[1]?.trim());
      const evaluationResult =
        math.round(math.evaluate(temp[0]?.trim(), scope), 1) || 0;
      const output = evaluationResult.toFixed(roundOffValue);
      return parseFloat(output);
    } catch (error) {
      // console.error(error);
      return 0;
    }
  }

  private evaluateIfElseExpression(formula: string, scope: any): any {
    try {
      // Replace 'IF' with ternary operator '? :'
      const transformedExpression =
        formula
          .replaceAll(/IF/g, '(')
          .replaceAll(/=>/g, '?')
          .replaceAll(/ELSE/g, ':') + ')';

      const replacedExpr = this.replaceValuesWithJson(
        transformedExpression,
        scope
      );

      let retVal;

      // Use a safer custom parser
      if (replacedExpr && !isNaN(eval(replacedExpr))) {
        retVal = eval(replacedExpr);
        if (retVal) {
          retVal = retVal.toFixed(this.precision);
        } else {
          retVal = 0;
        }
      }
      return retVal;
    } catch (error) {
      // console.error(error);
      return 0;
    }
  }

  private replaceValuesWithJson(expression: string, jsonData: any) {
    const replacedExpr = expression.replace(/(\w+)/g, function (match, p1) {
      if (jsonData.hasOwnProperty(p1)) {
        return jsonData[p1] && !isNaN(jsonData[p1]) ? jsonData[p1] : 0;
      }
      return match;
    });
    return replacedExpr;
  }
}
