import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/services/auth.service';
import { MsalService, MsalBroadcastService, MsalGuardConfiguration, MSAL_GUARD_CONFIG} from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import queryString from 'qs';
import { Subject, filter, takeUntil } from 'rxjs';
import * as msal from "@azure/msal-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'dfst-ui';
  isIframe = false;
  loginDisplay = false;
  users: any;
  private readonly _destroying$ = new Subject<void>();
  constructor(public authService: AuthService, private router: Router,private cookieService: CookieService,@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  private broadcastService: MsalBroadcastService, private msalauthService: MsalService) {

  }
  private goToLogon() {
    const parsed = queryString.parse(location.search);
  }
  
  async ngOnInit(): Promise<void> {

    this.callUserList()
    const currentUrl = this.router.url
     if (this.msalGuardConfig.authRequest){
        this.msalauthService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
        this.msalauthService.loginRedirect();
    }
    this.initAuthorization();
    if(this.authService.getCurrentUser()) { 
      this.authService.loggedIn = true;
      this.router.navigate(['']);

    } else {
      this.router.navigate(['']);
      this.authService.loggedIn = false;
      
    }
    await this.msalauthService.instance.initialize();
    function generateUniqueString() {
      const timestamp = Date.now(); // Current timestamp in milliseconds
      const randomNum = Math.random().toString(36).substring(2); // Random string
      return `${timestamp}-${randomNum}`;
  }
  console.log(generateUniqueString());
    this.msalauthService.instance.addEventCallback((event) => {
      const name:any = localStorage.getItem("name")
      const USERID = localStorage.getItem("userId");
      const email = localStorage.getItem("email");
      const businessRole = localStorage.getItem("primaryBusinessRole");
      if (!name || !email || !USERID) {
        console.error('User information is missing in local storage.');
      }
      const data = {
        userid : USERID,
        name:name,
        email:email,
        primarybusinessrole: businessRole
      }
      if (event.eventType === msal.EventType.LOGIN_SUCCESS) {
        this.authService.saveUserData(data).subscribe(res => {
          this.authService.setSessionId(res)
        })
          console.log("Login successful!");
      } 
  });
    await this.msalauthService.instance.loginRedirect();

    this.isIframe = window !== window.parent && !window.opener;

    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    })

  }
  
  callUserList(){
    this.authService.getUserData().subscribe({
      next: (data) => {
        console.log('User Data:', data);
      },
      error: (error) => {
        console.error('Error fetching user data:', error);
      },
      complete: () => {
        console.log('Request complete');
      }
    });
  }
  setLoginDisplay() {
    this.loginDisplay = this.msalauthService.instance.getAllAccounts().length > 0;
  }

  login() {
    if (this.msalGuardConfig.authRequest){
      this.msalauthService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.msalauthService.loginRedirect();
    }
  }
  initAuthorization() {
    const cookieValue = localStorage.getItem("idToken");
  }
 }

