import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { LoaderService } from '../loader/loader.service';

@Component({
  selector: 'app-user-scenario',
  templateUrl: './user-scenario.component.html',
  styleUrls: ['./user-scenario.component.scss'],
})
export class UserScenarioComponent implements OnInit, OnDestroy {
  scenarioData: any = [];
  subscription: Subscription;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private toast: ToastrService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    if (!this.subscription) {
      this.subscription = this.apiService.scenarioData.subscribe((data: any) => {
        this.scenarioData = data;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  upload(scenarioId: any) {
    const userdata = this.authService.getCurrentUser().data;

    const requestData = {
      id: scenarioId,
      userId: userdata.userId,
    };

    this.apiService.uploadScenario(requestData).subscribe((response) => {
      if (response.status === 'success') {
        this.apiService.backToMultipleOfHundredAndSave(JSON.parse(response?.data.output.draft));
        // this.apiService.saveFormData(JSON.parse(response.data.output.draft));
        this.apiService.loadFromDraft();
        document.getElementById('scenario-close')?.click();
        this.toast.success('Scenario Loaded');  
        localStorage.setItem("myScenario","true") 

          this.router.navigate(['/instructions'] );

      }
    });
  }
}
