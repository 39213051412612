import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Modal } from 'bootstrap';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { LoaderService } from '../loader/loader.service';
import { CookieService } from 'ngx-cookie-service';
import { MsalBroadcastService, MsalService  } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { filter } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  helpModal: Modal;
  showData : boolean = false
  @ViewChild('helpModalRef') helpModalRef: ElementRef;

  userProfileModal: Modal;
  @ViewChild('userProfileModalRef') userProfileModalRef: ElementRef;

  userScenarioModal: Modal;
  @ViewChild('userScenarioModalRef') userScenarioModalRef: ElementRef;

  constructor(
    public apiService: ApiService,
    public authService: AuthService,
    private router: Router,
    private toast: ToastrService,
    private loaderService: LoaderService,
    private msalService: MsalService,
    private cookieService: CookieService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    // this.displayAdmin()
  }

  userData: any;

  async ngOnInit() {
    this.displayAdmin()
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.displayAdmin(); // Re-evaluate roles on navigation
    });
  
    if (this.authService.loggedIn) {
      this.userData = this.authService.getCurrentUser()?.data;
      if (this.userData.username !=="" || null || undefined) {
        this.authService.userName = this.userData.username;
      } else  {
        this.authService.userName = this.userData.username;
      }
      const accounts = this.msalService.instance.getAllAccounts(); // Get all MSAL accounts
      if (accounts.length > 0) {
        const activeAccount = accounts[0]; // Select the first account (or the active one)
        const idTokenClaims = activeAccount?.idTokenClaims;

        if (idTokenClaims && typeof idTokenClaims['idToken'] === 'string') {
          const idToken: string = idTokenClaims['idToken']; // Explicitly cast idToken to a string
          localStorage.setItem('idToken', idToken); // Store idToken in localStorage
        }
      }}
  }

  displayAdmin() {
    const idToken = localStorage.getItem('idToken'); // Retrieve idToken from localStorage
    if (idToken) {
      const decoded: any = jwtDecode(idToken); // Decode the JWT token
      this.showData = decoded?.UserAppRoles?.includes('DFST_Admin'); // Check for 'DFST_Admin' role
    }
  }
  openHelpModal() {
    this.helpModal = new Modal(this.helpModalRef.nativeElement, {});
    this.helpModal.show();
  }

  openUserProfileModal() {
    if (this.authService.loggedIn) {
      this.userData = this.authService.getCurrentUser()?.data;
      if (this.userData.username !=="" || null || undefined) {
        this.authService.userName = this.userData.username;
      } else {
        this.authService.userName = this.userData.username;
      }
    }

    this.userProfileModal = new Modal(
      this.userProfileModalRef.nativeElement,
      {}
    );
    this.userProfileModal.show();
  }

  async openUserScenarioModal() {
    try {
      if (this.authService.loggedIn) {

        const userdata = this.authService.getCurrentUser().data;

        const requestData = {
          userId: userdata.userId,
        };

        const apiCall = this.apiService.fetchScenario(requestData);
        const response = await lastValueFrom(apiCall);
        if (response.status === 'success') {
          this.apiService.scenarioData.next(response.data.output);
          
          this.userScenarioModal = new Modal(
            this.userScenarioModalRef.nativeElement,
            {}
          );

          this.userScenarioModal.show();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async logout() {
    try {
      const userData = this.authService.getCurrentUser().data;

      const requestData = {
        userId: userData.userId,
      };

      const apiCall = this.authService.logoutUser(requestData);
      const response = await lastValueFrom(apiCall);
    } catch (error) {
      console.error(error);
    } finally {
      this.loaderService.show()
      localStorage.removeItem('currentUser');
      localStorage.removeItem('formData');
      localStorage.removeItem('loadFromDraft');
      //localStorage.removeItem('previousFormData');
      const idToken:any = localStorage.getItem("idToken")
      this.msalService.logoutRedirect({postLogoutRedirectUri:environment.redirectURI,idTokenHint:idToken})
      this.router.navigate(['/status-check'])
      this.authService.loggedIn = false
      this.loaderService.hide()
      this.toast.success('Logged Out successfully');
    }
  }
  navigateToAdmin() {
    debugger
    this.router.navigate(['/admin']);
   
  }
}
