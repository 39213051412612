import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InteractiveTabFormComponent } from './interactive-tab/interactive-tab-form.component';
import { HeaderComponent } from './shared/header/header.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { FooterComponent } from './shared/footer/footer.component';
import { InstructionsComponent } from './instructions/instructions.component';
import { PurseOpportunityComponent } from './purse-opportunity/purse-opportunity.component';
import { ChargingInvestmentComponent } from './charging-investment/charging-investment.component';
import { FullServiceLeasingComponent } from './full-service-leasing/full-service-leasing.component';
import { OutputGraphComponent } from './output-graph/output-graph.component';
import { ScoringWorksheetComponent } from './scoring-worksheet/scoring-worksheet.component';
import { PointOfContactComponent } from './point-of-contact/point-of-contact.component';
import { PartServiceInvestmentsComponent } from './part-service-investments/part-service-investments.component';
import { RequestEncryptionInterceptor } from './interceptor/request-encryption-interceptor';
import { ResponseDecryptionInterceptor } from './interceptor/response-decryption-interceptor';
import { UserScenarioComponent } from './shared/user-scenario/user-scenario.component';
import { LoginComponent } from './shared/login/login.component';
import { LoaderService } from './shared/loader/loader.service';
import { LoaderInterceptor } from './interceptor/loader-interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NonNegativeDirective } from './directives/nonNegative.directive';
import { MinMaxValueDirective } from './directives/minMax.directive';
import { FormulaDirective } from './directives/formulaContext.directive';
import { AllowNegativeDirective } from './directives/allowNegative.directive';
// import { DollarCurrencyDirective } from './directives/dollar-currency.directive';
import { CurrencyFormatPipe } from './pipes/currencyFormat.pipe';
import { ScreenshotService } from './services/screenshot.service';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FormulaEvaluationDirective } from './directives/formula-evaluation.directive';
import { ComparionChartComponent } from './comparion-chart/comparion-chart.component';
import { AllowOnlyNumbersDirective } from './directives/allow-only-numbers.directive';
import { AllowOnlyCostDirective } from './directives/allow-only-cost.directive';
// import { ClearDefaultDirective } from './directives/clear-default.directive';
// import { ClearDefaultDirective } from './directives/clear-default.directive';
import { DecimalPipe } from './decimal.pipe';
import { OnlyPercentageDirective } from './directives/only-percentage.directive';
import { AllowDecimalInputsDirective } from './directives/allow-decimal-inputs.directive';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { UserLoggedOutComponent } from './user-logged-out/user-logged-out.component';
import { AdminComponent } from './shared/admin/admin.component';
// import { AllowCommaDirective } from './directives/allow-comma.directive';

@NgModule({
  declarations: [
    AppComponent,
    InteractiveTabFormComponent,
    HeaderComponent,
    WelcomeComponent,
    FooterComponent,
    InstructionsComponent,
    PurseOpportunityComponent,
    ChargingInvestmentComponent,
    FullServiceLeasingComponent,
    OutputGraphComponent,
    ScoringWorksheetComponent,
    PointOfContactComponent,
    PartServiceInvestmentsComponent,
    UserScenarioComponent,
    LoginComponent,
    NonNegativeDirective,
    MinMaxValueDirective,
    AllowNegativeDirective,
    FormulaDirective,
    CurrencyFormatPipe,
    BreadcrumbComponent,
    FormulaEvaluationDirective,
    ComparionChartComponent,
    AllowOnlyNumbersDirective,
    AllowOnlyCostDirective,
    DecimalPipe,
    OnlyPercentageDirective,
    AllowDecimalInputsDirective,
    UserLoggedOutComponent,
    AdminComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    NgxSpinnerModule.forRoot(),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.clientId,
          authority:
        environment.authority,
          redirectUri: environment.redirectURI,
          knownAuthorities: environment.knownAuthorities,
          postLogoutRedirectUri:environment.postLogoutRedirectUri,
          // redirectUri: "http://localhost:4200/",
          // postLogoutRedirectUri:"http://localhost:4200/home"
        },
        cache: {
          cacheLocation: "localStorage",
          //storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ["user.read"],
          extraQueryParameters: {"id_token_hint" : localStorage.getItem("idToken") as any}
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          ["https://graph.microsoft.com/v1.0/me", ["user.read"]],
        ]),
      }
    ),
    ToastrModule.forRoot({
      timeOut: 5000,
      easeTime: 500,
      progressBar: true,
      progressAnimation: 'decreasing'
    })
  ],
  providers: [
    LoaderService,
    ScreenshotService,
    CurrencyFormatPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestEncryptionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseDecryptionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    MsalGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
