import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef,
  Renderer2,
  AfterContentChecked,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { Modal } from 'bootstrap';
import { ScreenshotService } from '../services/screenshot.service';

import { isEmpty, merge } from 'lodash';
import { BreadcrumbService } from '../services/breadcumbs.service';
import { Subscription, distinctUntilChanged, throttleTime } from 'rxjs';
import { LoaderService } from '../shared/loader/loader.service';

@Component({
  selector: 'app-full-service-leasing',
  templateUrl: './full-service-leasing.component.html',
  styleUrls: ['./full-service-leasing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullServiceLeasingComponent implements OnInit, OnDestroy, AfterContentChecked {
  fullServiceLeasingForm: FormGroup;
  draftData: any;
  partialData: any;
  draftModal: Modal;
  formChangeSubscription: Subscription;
  requireRefresh = true;
  removeRecalculateButton=true;
  @ViewChild('draftModalRef') draftModalRef: ElementRef;

  @ViewChild('content') contentElement: ElementRef;

  capturedImage: string | null = null;

  constructor(
    public apiService: ApiService,
    private fb: FormBuilder,
    private router: Router,
    private toast: ToastrService,
    private authService: AuthService,
    private screenshotService: ScreenshotService,
    private breadCrumbsSvc: BreadcrumbService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private el: ElementRef,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.apiService.pageTitle = 'Full Service Leasing';
    this.breadCrumbsSvc.addBreadcrumbs();
    this.createFullServiceLeasingForm();
  }
  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
  ngAfterViewInit(): void {
    if (Object.keys(this.apiService.formData).length === 0) {
      // this.redirectToLandingPage();
    } else {
      const prevFormData = this.apiService.getPrevFormData();
      if (!isEmpty(prevFormData.fullServiceLeasing)) {
        this.partialData = merge(prevFormData.fullServiceLeasing, this.apiService.getFormData());
      } else {
        this.partialData = this.apiService.getFormData();
      }
      this.assignDraftData();
      this.requireRefresh = true;
    }
    this.createFormChangeSubscription();
    const randomVal = Math.random() * 100;
    document.getElementById('REFRESH_PAGE')?.setAttribute('value', randomVal.toString());
    // this.updateForm();
  }

  ngOnDestroy(): void {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
  }

  redirectToLandingPage(): void {
    this.router.navigate(['/']);
  }

  getDraftData() {
    if (!this.loaderService.isVisible()) {
      this.loaderService.show();
    }
    const userdata = this.authService.getCurrentUser().data;

    const requestData = {
      userId: userdata.userId,
      draft: this.apiService.formData,
      createdBy: userdata.username,
      updatedBy: userdata.username,
    };

    this.apiService.previousPageData(requestData).subscribe(
      (response) => {
        if (response.status === 'success') {
          this.draftData = response?.data?.draft
            ? JSON.parse(response.data.draft.draft)
            : {};
          this.partialData = isEmpty(response.data.partial)
            ? this.draftData
            : response.data.partial;
          if (isEmpty(this.draftData)) {
            this.draftModal = new Modal(this.draftModalRef.nativeElement, {});
            this.draftModal.show();
          } else {
            this.fullServiceLeasingForm.reset();
          }
          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
        }
      },
      (err) => {
        if (this.loaderService.isVisible()) {
          this.loaderService.hide();
        }
        //console.log(err);
      }
    );
  }

  yesDraft() {
    this.apiService.loadFromDraft();
    this.apiService.saveFormData(this.draftData);
    this.assignDraftData();
    this.draftModal.hide();
  }

  deleteDraft() {
    this.apiService.discardDraft();
    this.fullServiceLeasingForm.reset();
    this.draftModal.hide();
  }

  createFullServiceLeasingForm() {
    this.fullServiceLeasingForm = this.fb.group({
      FULLSVCLEASINGINV_C11: [''],
      FULLSVCLEASINGINV_D11: [''],

      FULLSVCLEASINGINV_C12: [''],
      FULLSVCLEASINGINV_D12: [''],

      FULLSVCLEASINGINV_C20: [''],
      FULLSVCLEASINGINV_D20: [''],

      FULLSVCLEASINGINV_C21: [''],
      FULLSVCLEASINGINV_D21: [''],

      FULLSVCLEASINGINV_C22: [''],
      FULLSVCLEASINGINV_D22: [''],

      FULLSVCLEASINGINV_C24: [''],
      FULLSVCLEASINGINV_D24: [''],

      FULLSVCLEASINGINV_C25: [''],
      FULLSVCLEASINGINV_D25: [''],

      FULLSVCLEASINGINV_C26: [''],
      FULLSVCLEASINGINV_D26: [''],
    });

    this.setEnterKeyHandler();
  }

  setEnterKeyHandler() {
    this.addEnterKeyHandler('FULLSVCLEASINGINV_C11', 'FULLSVCLEASINGINV_D11');
    this.addEnterKeyHandler('FULLSVCLEASINGINV_D11', 'FULLSVCLEASINGINV_C12');
    this.addEnterKeyHandler('FULLSVCLEASINGINV_C12', 'FULLSVCLEASINGINV_D12');
    this.addEnterKeyHandler('FULLSVCLEASINGINV_C20', 'FULLSVCLEASINGINV_D20');
    this.addEnterKeyHandler('FULLSVCLEASINGINV_D20', 'FULLSVCLEASINGINV_C21');
    this.addEnterKeyHandler('FULLSVCLEASINGINV_C21', 'FULLSVCLEASINGINV_D21');
    this.addEnterKeyHandler('FULLSVCLEASINGINV_D21', 'FULLSVCLEASINGINV_C22');
    this.addEnterKeyHandler('FULLSVCLEASINGINV_C22', 'FULLSVCLEASINGINV_D22');
    this.addEnterKeyHandler('FULLSVCLEASINGINV_D22', 'FULLSVCLEASINGINV_C24');
    this.addEnterKeyHandler('FULLSVCLEASINGINV_C24', 'FULLSVCLEASINGINV_D24');
    this.addEnterKeyHandler('FULLSVCLEASINGINV_D24', 'FULLSVCLEASINGINV_C25');
    this.addEnterKeyHandler('FULLSVCLEASINGINV_C25', 'FULLSVCLEASINGINV_D25');
    this.addEnterKeyHandler('FULLSVCLEASINGINV_D25', 'FULLSVCLEASINGINV_C26');
    this.addEnterKeyHandler('FULLSVCLEASINGINV_C26', 'FULLSVCLEASINGINV_D26');
    this.addEnterKeyHandler('FULLSVCLEASINGINV_D26', 'FULLSVCLEASINGINV_C11');
    this.addEnterKeyHandler('FULLSVCLEASINGINV_D12', 'FULLSVCLEASINGINV_C20');
  }

  resetForm() {
    this.fullServiceLeasingForm.reset();
    this.partialData = {};
    this.updateForm();
  }

  assignDraftData() {
    this.partialData.FULLSVCLEASINGINV_D12 = this.formatCurrency(this.partialData.FULLSVCLEASINGINV_D12)
    this.partialData.FULLSVCLEASINGINV_C12 = this.formatCurrency(this.partialData.FULLSVCLEASINGINV_C12)
    this.partialData.FULLSVCLEASINGINV_C11 = this.formatCurrency(this.partialData.FULLSVCLEASINGINV_C11)
    this.partialData.FULLSVCLEASINGINV_D11 = this.formatCurrency(this.partialData.FULLSVCLEASINGINV_D11)
    this.fullServiceLeasingForm.patchValue(this.partialData, {
      emitEvent: true,
    });
  }

  nextPage() {
    this.removeRecalculateButton = false
    if (this.fullServiceLeasingForm.valid) {
      if (!this.loaderService.isVisible()) {
        this.loaderService.show();
      }

      const updatedFormData = this.slicInputs(this.fullServiceLeasingForm.value);
      this.apiService.saveFormData(updatedFormData);

      // this.apiService.saveFormData(this.fullServiceLeasingForm.value);
      this.apiService.savePrevFormData(
        'fullServiceLeasing',
        updatedFormData
      );

      const userdata = this.authService.getCurrentUser().data;
      const requestData = {
        userId: userdata.userId,
        draft: this.apiService.formData,
        createdBy: userdata.username,
        updatedBy: userdata.username,
      };
      this.apiService.nextPageNavigation(requestData).subscribe(
        (response) => {
          if (response.status === 'success') {
            if (this.loaderService.isVisible()) {
              this.loaderService.hide();
            }
            this.apiService.saveFormData(response.data.partial);
            this.captureScreenshot();
            this.toast.success('Values saved successfully in Draft');
          }
        },
        (err) => {
          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
          this.toast.error(
            err?.error?.errorDescription ||
              'Something went wrong in saving the values in Draft'
          );
        }
      );
    }
  }

  prevPage() {
    this.apiService.savePrevFormData(
      'fullServiceLeasing',
      this.fullServiceLeasingForm.value
    );
    this.router.navigate(['/charging-investment']);
  }

  createFormChangeSubscription() {
    if (this.fullServiceLeasingForm) {
      this.formChangeSubscription = this.fullServiceLeasingForm.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(() => {
          // this.updateForm();
        });
    }
  }

  updateForm() {
    if (!this.loaderService.isVisible()) {
      this.loaderService.show();
    }
    // this.apiService.saveFormData(this.transformData(this.fullServiceLeasingForm.value));
    this.apiService.saveFormData(this.fullServiceLeasingForm.value);
    const userdata = this.authService.getCurrentUser().data;
    const requestData = {
      userId: userdata.userId,
      draft: this.apiService.formData,
      createdBy: userdata.username,
      updatedBy: userdata.username,
    };
    this.apiService.updatePage(requestData).subscribe(
      (response) => {
        if (response.status === 'success') {
          this.apiService.saveFormData(response.data.partial);
          this.partialData = response.data.partial;
          this.requireRefresh = false;
          this.toast.success('Page values updated');
          const randomVal = Math.random() * 100;
          document.getElementById('REFRESH_PAGE')?.setAttribute('value', randomVal.toString());
          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
        }
      },
      (err) => {
        if (this.loaderService.isVisible()) {
          this.loaderService.hide();
        }
       // console.log(err);
        this.toast.error(err?.error?.errorDescription || 'Error updating page');
      }
    );
  }

  private transformData(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null || obj[key] === "") {
          obj[key] = 0;
        }
      }
    }
    return obj;
  }

  async captureScreenshot() {
    const elementToCapture = this.contentElement.nativeElement;

    const canavas = await this.screenshotService
      .captureScreenshot(elementToCapture)
      .then((canvas) => {
        this.capturedImage = canvas.toDataURL('image/png');
      });

    const imgObject = {
      id: 'fullServiceLeasing',
      imgCaptured: this.capturedImage,
    };

    // this.apiService.pageScreenshot.forEach((screenshot: any) => {
    //   if (screenshot.page === 'Dealership Revenues & Investments') {
    //     screenshot.imgCapture.push(imgObject);
    //   }
    // });
    if (this.apiService.pageScreenshot['Dealership Revenues & Investments']) {
      this.apiService.pageScreenshot['Dealership Revenues & Investments']['imgCapture'].push(imgObject);
    }
    setTimeout(() => {
      this.router.navigate(['/pursue-opportunity']);
    }, 1000);
  }

  updatedValueForm(event: { formControlName: any; value: any }) {
    this.fullServiceLeasingForm
      .get(event.formControlName)
      ?.setValue(event.value);
  }

  enterSubmitted(event: any) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  focusNextField(fieldName: string) {
    const nextField = document.getElementById(fieldName);
    const nextFieldSelectAll = document.getElementById(fieldName) as HTMLInputElement
    nextFieldSelectAll?.select();
    if (nextField) {
      nextField.focus();
    }
  }

  private addEnterKeyHandler(currentField: string, nextField: string) {
    const currentFieldElement = this.el.nativeElement.querySelector(`#${currentField}`);
    this.renderer.listen(currentFieldElement, 'keydown.enter', (event) => {
      event.preventDefault();
      this.focusNextField(nextField);
    });

    this.renderer.listen(currentFieldElement, 'keydown.tab', (event) => {
      event.preventDefault();
      this.focusNextField(nextField);
    });
  }

  private slicInputs(formdata: any){
    formdata.FULLSVCLEASINGINV_C20 =  this.checkCharAtLatPosition(formdata.FULLSVCLEASINGINV_C20);
    formdata.FULLSVCLEASINGINV_C21 =  this.checkCharAtLatPosition(formdata.FULLSVCLEASINGINV_C21);
    formdata.FULLSVCLEASINGINV_C22 =  this.checkCharAtLatPosition(formdata.FULLSVCLEASINGINV_C22);
    formdata.FULLSVCLEASINGINV_C24 =  this.checkCharAtLatPosition(formdata.FULLSVCLEASINGINV_C24);
    formdata.FULLSVCLEASINGINV_C25 =  this.checkCharAtLatPosition(formdata.FULLSVCLEASINGINV_C25);
    formdata.FULLSVCLEASINGINV_C26 =  this.checkCharAtLatPosition(formdata.FULLSVCLEASINGINV_C26);
    formdata.FULLSVCLEASINGINV_C11 =  this.checkCharAtLatPosition(formdata.FULLSVCLEASINGINV_C11);
    formdata.FULLSVCLEASINGINV_C12 =  this.checkCharAtLatPosition(formdata.FULLSVCLEASINGINV_C12);

    formdata.FULLSVCLEASINGINV_D20 =  this.checkCharAtLatPosition(formdata.FULLSVCLEASINGINV_D20);
    formdata.FULLSVCLEASINGINV_D21 =  this.checkCharAtLatPosition(formdata.FULLSVCLEASINGINV_D21);
    formdata.FULLSVCLEASINGINV_D22 =  this.checkCharAtLatPosition(formdata.FULLSVCLEASINGINV_D22);
    formdata.FULLSVCLEASINGINV_D24 =  this.checkCharAtLatPosition(formdata.FULLSVCLEASINGINV_D24);
    formdata.FULLSVCLEASINGINV_D25 =  this.checkCharAtLatPosition(formdata.FULLSVCLEASINGINV_D25);
    formdata.FULLSVCLEASINGINV_D26 =  this.checkCharAtLatPosition(formdata.FULLSVCLEASINGINV_D26);
    formdata.FULLSVCLEASINGINV_D11 =  this.checkCharAtLatPosition(formdata.FULLSVCLEASINGINV_D11);
    formdata.FULLSVCLEASINGINV_D12 =  this.checkCharAtLatPosition(formdata.FULLSVCLEASINGINV_D12);



    return formdata
  }
  private checkCharAtLatPosition(inputValue: any){
    if(inputValue && typeof inputValue === 'string'){
      const lastChar = inputValue.charAt(inputValue.length - 1)
      const isNotDigit: boolean = /\D/.test(lastChar);
      if(isNotDigit){
        return inputValue.slice(0, -1)
      } else {
        return inputValue;
      }
    } else {
      return inputValue
    }
  }
  formatCurrency(input:any = '') {
    input = input + '';
    const value = parseFloat(input.replace(/[$,]/g, ''));

    // Check if the value is a valid number
    if (isNaN(value)) {
        console.error('Invalid input');
        return null;
    }

    // Format the number with commas
    const formattedValue = value.toLocaleString('en-US');

    return '$' + formattedValue;
}
}
