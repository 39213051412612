import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbService } from '../services/breadcumbs.service';
import { ApiService } from '../services/api.service';
import { Modal } from 'bootstrap';
import { isEmpty } from 'lodash';
import { AuthService } from '../services/auth.service';
import { Subject, filter, lastValueFrom, takeUntil } from 'rxjs';
import { LoaderService } from '../shared/loader/loader.service';
import { CookieService } from 'ngx-cookie-service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
// import { jwtDecode } from "jwt-decode";
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  draftData: any;
  partialData: any;
  draftModal: Modal;
  draftModallogin:Modal;
  idtoken:string;
  decodedvalue:any;
  draftaccepted:boolean = true;
  loginDisplay = false;
  @ViewChild('draftModalRef') draftModalRef: ElementRef;
  @ViewChild('draftModalReflogin') draftModalReflogin: ElementRef;
  isIframe: boolean;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private breadcrumbsService: BreadcrumbService,
    private loaderService: LoaderService,
    private router: Router,
    private cookieService: CookieService,
    private msalauthService: MsalService, 
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnInit() {
    const data:any = localStorage.getItem("primaryBusinessRole")
    const userInfo = localStorage.getItem("addlfunctionalrole");
    const uid = localStorage.getItem("uid");
    const userData = localStorage.getItem("applicationRole");
    // setTimeout(() => {
    //   if ( data == 'Dealer Principal') {
    //     this.getDraftData();
    //     this.breadcrumbsService.addBreadcrumbs();
    //   } else if(uid == "MDERBY" || uid == "AHAYWAR" || uid == "DOUROGE" || uid == "JGEORGO" || uid == "WHOELSC"|| uid == "CGIESEM" || uid == "BARAVIC" || uid == "GTEKKAL" || uid == "MUKUNDK" || uid == "SDONTHI" || uid == "MANOHPR"){
    //     this.getDraftData();
    //     this.breadcrumbsService.addBreadcrumbs();
    //   }else{
    //     if (userInfo?.includes('Dealer Principal')) {
    //       this.getDraftData();
    //       this.breadcrumbsService.addBreadcrumbs();
    //     }else{
    //       this.openmodalpopup()
    //     }
    //   }
    // }, 1000);
    setTimeout(() => { 
      if ( data == 'Dealer Principal') {
        this.getDraftData();
        this.breadcrumbsService.addBreadcrumbs();
      }else{
        if (userData?.includes('DFST_User')) {
              this.getDraftData();
              this.breadcrumbsService.addBreadcrumbs();
            } else{
                this.openmodalpopup()
            }
      }
    }, 1000);
  }

  getDraftData() {
    const userdata = this.authService.getCurrentUser()?.data;
    const requestData = {
      userId: userdata?.userId,
      draft: isEmpty(this.apiService.formData) ? {} : this.apiService.formData,
      createdBy: userdata?.username,
      updatedBy: userdata?.username,
    };

    this.apiService.previousPageData(requestData).subscribe(
      (response) => {
        if (response.status === 'success') {
          this.draftData = response?.data?.draft ? JSON.parse(response?.data?.draft?.draft) : {};
          this.partialData = isEmpty(response.data.partial) ? this.draftData : response.data.partial;
          if (!isEmpty(this.draftData)) {
            this.draftModal = new Modal(this.draftModalRef.nativeElement, {});
            this.draftModal.show();
          }else{
            this.draftaccepted = true
          } 
        }
      },
      (err) => {
       // console.log(err);
      }
    );
  }

  openmodalpopup(){
    const modaldiv = document.getElementById('myModal')
    if (modaldiv != null) {
      modaldiv.style.display = 'block' 
      this.draftaccepted = false
    }
  }
  closemodalpopup(){
    const modaldiv = document.getElementById('myModal')
    if (modaldiv != null) {
      modaldiv.style.display = 'none' 
    }
  }


  yesDraft() {
    this.apiService.loadFromDraft();
    this.apiService.saveFormData(this.draftData);
    this.draftaccepted = true
    this.draftModal.hide();
  }
  closePage(){
    //this.draftModalReflogin.hide()
  }

  deleteDraft() {
    localStorage.removeItem('formData');
    localStorage.removeItem('previousFormData')
    this.apiService.discardDraft();
    this.draftaccepted = true
     this.draftModal.hide();
  }
}
