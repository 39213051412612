<div class="mt-5">
  <div class="opportunities-chart mb-5" #outputGraphPdf >
    <p class="font-weight-500 text-center font-size-small opportunities-matrix-title">Opportunity Evaluation Matrix</p>
    <div style="display: flex; justify-content: flex-end;">
      <div style="width: 35px; height: 35px; background-color: skyblue; border-radius: 50%; margin-right: 5px;">
      </div>
      <div>
        <p style="display: flex;align-items: center; height: 100%;"  class="font-size-small mr-3 text-right font-weight-500">Bubble Size = 2030 Net Cash (2030 gross profit - capitalized opportunity investment)</p>
    </div>
    </div>
    <p style="display: flex;align-items: center; height: 100%;justify-content: flex-end;"  class="font-size-small mr-3 text-right font-weight-500">Hover over the opportunity bubbles on the graph for details</p>
    <!-- <a class="font-size-small mr-3 d-flex justify-content-end font-weight-500 comparison-link" routerLink="/comparisonwithMarket">Comparison with Market</a> -->
    <div class="mt-5 relative d-flex flex-row ">
      <canvas class="relative h-0 top-0" id="outputGraphCanvas" #outputGraphCanvas width="50" height="30"></canvas>
      <canvas class="relative h-0 top-0" id="quadrantCanvas" #quadrantCanvas width="50" height="30"></canvas>

    </div>

    <p class="font-size-small font-weight-500 opportunities-notes mt-4">*Opportunities with negative net cash will not appear on the matrix</p>

    <!-- <div style="display: none!important;"  class="d-flex mt-3 mb-3">
      <div class="w-50" style="border: 1px solid grey">
        <p class="text-center font-weight-500 mb-3">Comparison of Net Cash</p>
        <canvas id="netCashPolarChart" #netCashPolarChart></canvas>
      </div>
      <div class="w-50" style="border: 1px solid grey">
        <p class="text-center font-weight-500 mb-3">Comparison of ROS</p>
        <canvas id="rosPolarChart" #rosPolarChart></canvas>
      </div>
    </div> -->


  </div>

  <div class="opportunities-data-section" #outputGraphTable>
    <table class="opportunities-output-table w-100">
      <thead>
        <th>Opportunity</th>
        <th>Implementation Score</th>
        <th>ROS %</th>
        <th>Net Cash</th>
      </thead>
      <tbody>
          <ng-container *ngFor="let data of outputDataTable">
            <tr *ngIf="data[0] === 'partsAndServices'">
              <td>Parts & Service</td>
              <td>{{data[1].implementationScore}}</td>
              <td>{{data[1].ROS | decimal}}%</td>
              <td>${{data[1].netCash | number}}</td>
            </tr>
            <tr *ngIf="data[0] === 'publicCharging'">
              <td>Public Charging</td>
              <td>{{data[1].implementationScore}}</td>
              <td>{{data[1].ROS | decimal}}%</td>
              <td>${{data[1].netCash | number}}</td>
            </tr>
            <tr *ngIf="data[0] === 'newTruckSales'">
              <td>New Truck Sales</td>
              <td>{{data[1].implementationScore}}</td>
              <td>{{data[1].ROS | decimal}}%</td>
              <td>${{data[1].netCash | number}}</td>
            </tr>
            <tr *ngIf="data[0] === 'fullServiceLeasing'">
              <td>Full Service Leasing</td>
              <td>{{data[1].implementationScore}}</td>
              <td>{{data[1].ROS | decimal}}%</td>
              <td>${{data[1].netCash | number}}</td>
            </tr>
            <tr *ngIf="data[0] === 'retailFinancingAndLeasing'">
              <td>Retail Financing & Leasing</td>
              <td>{{data[1].implementationScore}}</td>
              <td>{{data[1].ROS | decimal}}%</td>
              <td>${{data[1].netCash | number}}</td>
            </tr>
            <tr *ngIf="data[0] === 'usedTruckSales'">
              <td>Used Truck Sales</td>
              <td>{{data[1].implementationScore}}</td>
              <td>{{data[1].ROS | decimal}}%</td>
              <td>${{data[1].netCash | number}}</td>
            </tr>
            <tr *ngIf="data[0] === 'constructionProjectAndFinancing'">
              <td>Construction Project Financing</td>
              <td>{{data[1].implementationScore}}</td>
              <td>{{data[1].ROS | decimal}}%</td>
              <td>${{data[1].netCash | number}}</td>
            </tr>
            <tr *ngIf="data[0] === 'chargerSales'">
              <td>Charger Sales</td>
              <td>{{data[1].implementationScore}}</td>
              <td>{{data[1].ROS | decimal}}%</td>
              <td>${{data[1].netCash | number}}</td>
            </tr>
            <tr *ngIf="data[0] === 'chargerServiceAndMaintainance'">
              <td>Charger Service & Maintenance</td>
              <td>{{data[1].implementationScore}}</td>
              <td>{{data[1].ROS | decimal}}%</td>
              <td>${{data[1].netCash | number}}</td>
            </tr>
          </ng-container>
      </tbody>
    </table>
  </div>

  <div id="printButtonNone" class="print-button-section justify-content-end mt-4">
    <a class="btn link-btn" routerLink="/scoring-worksheet">Back</a>
    <a class="btn link-btn ml-3" (click)="openSaveScenarioModal()">Save</a>
    <a class="btn link-btn ml-3" (click)="printPdf()">Print</a>
  </div>
</div>
 <div #saveOutputModal class="modal fade" id="saveOutputModal" tabindex="-1" aria-labelledby="saveOutputModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Save Scenario</h5>
              <span type="button" class="close" (click)="saveModal.hide()" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i class="bi-x-circle-fill"></i></span>
              </span>
          </div>
          <div class="modal-body">
            <div>
              <div class="input-box align-items-start">
                <input type="text" [(ngModel)]="outputName" />
                <div class="d-flex input-error" *ngIf="!outputName">
                  <div>
                      <span class="bi-exclamation-circle mr-2"></span>
                      <span>Please enter scenario name</span>
                  </div>
              </div>
              </div>
                <div class="d-flex justify-content-end mt-4">
                      <button class="btn link-btn" (click)="saveScenario()">Save</button>
                      <button class="btn link-btn ml-3" (click)="saveModal.hide()">Cancel</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div> 
