<header>
  <section class="d-flex justify-content-between mt-3">
    <div>
      <a href="/" class="logo">
        <img src="../assets/images/DTNA_logo.png" alt="daimler" />
      </a>
    </div>
    <div class="d-flex align-items-center mr-2">
      <span class="welcome-text mr-4" *ngIf="authService.loggedIn && authService.userName">Welcome, {{authService.userName}}</span>
      <a class="mr-4 help-link" (click)="openHelpModal()">Help</a>
      <div class="dropdown" *ngIf="authService.loggedIn">
        <button class="bi-person-fill" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" (click)="openUserProfileModal()">My Profile</a>
          <a class="dropdown-item" (click)="openUserScenarioModal()">My Scenarios</a>
          <a class="dropdown-item" *ngIf="showData" (click)="navigateToAdmin()">Admin</a>
          <a class="dropdown-item" (click)="logout()">Logout</a>
        </div>
      </div>
    </div>
  </section>
</header>

<section [hidden]="!authService.loggedIn">
  <div class="mt-4">
    <h1 class="text-center text-uppercase mb-4"> Dealer Financial Simulation Tool </h1>
    <app-breadcrumb *ngIf="authService.loggedIn"></app-breadcrumb>
    <div class="d-flex">
      <div class="position-relative">
        <img src="../assets/images/truck.png" />
        <div *ngIf="apiService.pageTitle">
          <p class="text-center font-weight-bold tab-description">
            {{ apiService.pageTitle }}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<div #helpModalRef class="modal fade" id="helpModal" tabindex="-1" aria-labelledby="helpModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered help-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Point Of Contact</h5>
        <span type="button" class="close" (click)="helpModal.hide()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i class="bi-x-circle-fill"></i></span>
        </span>
      </div>
      <div class="modal-body">
        <p class="font-weight-500 text-center">
          If You Have Any Questions On The Financial Simulation Tool Please Contact
          DTNA's Regional Business Development Manager:
        </p>
        <div class="d-flex flex-column mx-2">
          <div class="center-container">
            <img src="../assets/images/DND_Logo.png" />
          </div>
          <table class="point-of-contact-details">
            <thead>
              <th>Development Manager</th>
              <th>Email</th>
              <th>Cell number</th>
            </thead>
            <tbody>
              <tr>
                <td>Michelle Derbyshire</td>
                <td>Michelle.Derbyshire@DaimlerTruck.com</td>
                <td>360.607.3868</td>
              </tr>
              <tr>
                <td>John Georgoulis</td>
                <td>John.Georgoulis@DaimlerTruck.com</td>
                <td>913.461.1108</td>
              </tr>
              <tr>
                <td>Antoinette Hayward</td>
                <td>Antoinette. Hayward@DaimlerTruck.com</td>
                <td>647.242.9951</td>
              </tr>
              <tr>
                <td>Bill Hoelscher</td>
                <td>William.Hoelscher@DaimlerTruck.com</td>
                <td>440.725.1341</td>
              </tr>
              <tr>
                <td>Doug Rogers</td>
                <td>Douglas.Rogers@DaimlerTruck.com</td>
                <td>803.280.1208</td>
              </tr>
            </tbody>
          </table>
        </div>
      
        <div class="dealership-documents-section d-flex flex-column align-items-center">
          <p class="font-weight-500 text-center">If You Have Any Questions Relating To eConsulting, Please Contact:</p>
          <img style="margin-bottom: 2rem;" src="../assets/images/detroit-econsulting.png" />
          <table class="point-of-contact-details w-100 mb-2 mt-1">
            <thead>
              <th>Detroit eConsulting and Training</th>
              <th>Email</th>
              <th>Cell number</th>
            </thead>
            <tbody>
              <tr>
                <td>Andrew J.W. Gerbetz - Manager ZEV Consulting</td>
                <td>Andrew.Gerbetz@DaimlerTruck.com</td>
                <td>971.349.3109</td>
              </tr>
            </tbody>
          </table>
          <div class="side-by-side">
            <span class="font-weight-500 text-center mb-0">
              <a style="text-decoration: none;" href="../assets/images/User_Manual_DFST.docx" target="_self" download="User_manual_DFST.docx">User Manual DFST</a>
          </span>,&nbsp;
          <span class="font-weight-500 text-center mb-0">
              <a style="text-decoration: none;" href="../../../assets/images/FAQs-Dealer_Financial_Simulation_Application.pdf" target="_blank">FAQs DFST</a>
          </span> </div>
          <p class="mt-3 font-weight-500">Dealership Of Future And eMobility Documents</p>
          <div>
            <table>
              <thead>
                <th>Document</th>
                <th>Locations</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span class="font-weight-500">Dealership of the Future</span>
                    <ul class="mt-3">
                      <li class="my-3">Introduction Letter to Dealer Principals</li>
                      <li class="my-3">Webinar Recording</li>
                      <li>Dealer Playbooks (one for each of the nine e-Opportunities)</li>
                    </ul>
                  </td>
                  <td>
                    <span class="font-weight-500">DTNA Portal</span>
                    <span>
                      / Service / Distribution Network / Dealership of the
                      Future:
                      </span>
                    <a target="_blank" class="ml-3"
                      href="https://dtnacontent-dtna.prd.freightliner.com//content/dtna-portal/en/ServiceLP/DistributionNetwork/Dealership-of-the-Future.html">
                      https://dtnacontent-dtna.prd.freightliner.com//content/dtna-portal/en/ServiceLP/Distribution
                      Network/Dealership-of-the-Future.html
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-500">Dealership eMobility Service Readiness Self-Assessment</td>
                  <td>
                    <span>
                      DTNA's Service Support Index
                      <span class="font-weight-500">(SSI)</span> / Assessment:
                    </span>
                    <a target="_blank" class="ml-3" href="https://extranet-ddc.freightliner.com/servexc/ssi_searchDealers.aspx">
                      https://extranet-ddc.freightliner.com/servexc/ssi_searchDealers.aspx
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
      
            <table class="mt-5">
              <thead>
                <th>Additional eMobility Information</th>
                <th>Locations</th>
              </thead>
              <tbody>
                <tr>
                  <td class="font-weight-500">eMobility Resource Hub</td>
                  <td>
                    <span class="font-weight-500">DTNA Portal</span>
                    <span>
                      / Sales / Freightliner / On-Highway Sales Resources /
                      eMobility:
                    </span>
                    <a target="_blank" href="https://dtnacontent-dtna.prd.freightliner.com/content/dtna-portal/en/sales/eMobilityResourceHub.html">
                      eMobility Resource Hub (freightliner.com)
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-500">Freightliner - Highspot / eCascadia & eM2</td>
                  <td>
                    <a target="_blank" href="https://daimler.highspot.com/spots/61d373f4998150d577d97949?list=61d374df754060bdd268d6ac&overview=true"> Freightliner | Highspot</a>
                  </td>
                </tr>
              </tbody>
            </table>
      
            <table class="mt-5 w-100">
              <thead>
                <th colspan="3">General Information or Comments</th>
              </thead>
              <tbody>
                <tr>
                  <td>Christopher Giesemann</td>
                  <td>christopher.giesemann@daimlertruck.com</td>
                  <td>Cell: 503.807.7711</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #userProfileModalRef class="modal fade" id="userProfileModal" tabindex="-1" aria-labelledby="userProfileModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title bi-person-circle d-flex align-items-center">
          <span class="user-profile ml-2">My Profile</span>
        </h5>
        <span type="button" class="close" (click)="userProfileModal.hide()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i class="bi-x-circle-fill"></i></span>
        </span>
      </div>
      <div class="modal-body">
        <div class="mt-2">
          User Name:<span class="user-name ml-2">{{userData?.username}}</span>
        </div>
        <div class="mt-1">
          User Id:<span class="user-id ml-2 text-capitalize">{{userData?.userId}}</span>
        </div>
        <div class="mt-1">
          Dealership Name:<span class="dealership-name ml-2">{{userData?.dealershipName}}</span>
        </div>
        <!-- <div class="mt-1">Simulation Scenario:<span class="simulation-scenario ml-2 text-capitalize"></span></div> -->
      </div>
    </div>
  </div>
</div>

<div #userScenarioModalRef class="modal fade" id="userScenarioModal" tabindex="-1" aria-labelledby="userScenarioModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered user-scenario-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title bi-person-circle d-flex align-items-center">
          <span class="user-profile ml-2">My Scenarios</span>
        </h5>
        <span id="scenario-close" type="button" class="close" (click)="userScenarioModal.hide()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i class="bi-x-circle-fill"></i></span>
        </span>
      </div>
      <div class="modal-body">
        <app-user-scenario></app-user-scenario>
      </div>
    </div>
  </div>
</div>


