import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[appNonNegative]',
})
export class NonNegativeDirective {
  @Input() decimal = false;
  @Input() percentage = false;
  @Input() currency = false;
  @Input() formatNumber = false;
  @Input() precision = 0;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('keyup', ['$event']) onKeyDown(event: any): void {
    if (event.key === 'Backspace' || event.key === ',') {
      // Allow backspace without formatting
      return;
    }
    const rawValue = this.el.nativeElement.value;

    if (this.isAllowedInput(event)) {
      const actualValue = rawValue
        .replaceAll('%', '')
        .replaceAll(',', '')
        .replaceAll('$', '')
        .replaceAll('.', '');

      let formattedValue = '';

      if (!isNaN(parseFloat(actualValue))) {
        formattedValue = this.formatValue(parseFloat(actualValue));
      }
      this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
    } else {
      event.preventDefault();
    }
  }

  private isAllowedInput(event: any): boolean {
    // Allow numeric input, backspace, delete, and arrow keys
    return /[0-9,.]|Backspace|Delete|ArrowLeft|ArrowRight|Home|End|Tab|Enter/.test(
      event.key
    );

    // return /[0-9]|Delete|ArrowLeft|ArrowRight|Home|End|Tab|Enter/.test(event.key);
  }

  private formatValue(value: number): string {
    if (this.currency) {

      let Svalue = value.toString();
      let integerPart = Svalue.slice(0, Svalue.length % 3 || 3);
      const parts = [integerPart];
      for (let i = integerPart.length; i < Svalue.length; i += 3) {
        parts.push(Svalue.slice(i, i + 3));
      }
      Svalue = parts.join(',');
      return '$' + Svalue;
    } else if (this.percentage) {
      return `${value.toFixed(this.precision)}%`;
    } else if (this.decimal) {
      return value.toFixed(this.precision);
    } else {
      return value.toString();
    }
  }
}
