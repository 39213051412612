// import { Directive } from '@angular/core';

// @Directive({
//   selector: '[appAllowOnlyNumbers]'
// })
// export class AllowOnlyNumbersDirective {

//   constructor() { }

// }
import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appAllowOnlyNumbers]'
})
export class AllowOnlyNumbersDirective {

  @Input() decimal: Boolean;
  @Input() percentage: Boolean;
  @Input() currency: Boolean;
  @Output() valueChanged = new EventEmitter();
  @Input() formatNumber: Boolean;
  formattedCurrency: any;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: any): void {
    let initialValue = this.el.nativeElement.value;

    this.el.nativeElement.value = initialValue.replace(/[^0-9]/g, '');
    
    this.valueChanged.emit({formControlName: this.el.nativeElement.id, value: this.el.nativeElement.value});

    if (this.el.nativeElement.value !== initialValue) {
      event.stopPropagation();
    }
  } 

}

