<div class="login-section d-flex justify-content-center flex-column align-items-center mt-3">
    <div class="d-flex justify-content-center">
        <img class="user-profile-icon" src="assets/images/daimler-logo.png" alt="daimler-logo">
    </div>
    <div class="d-flex justify-content-center mt-3">
        <img class="user-profile-icon" src="assets/images/user-profile.png" alt="user-profile">
    </div>
    <div class="d-flex justify-content-center mt-3">
        <h4>DEALERSHIP OF THE FUTURE</h4>
    </div>
    <div class="d-flex justify-content-center mt-3">
        <h3>DAIMLER TRUCKS NORTH AMERICA</h3>
    </div>
    <div class="user-profile-title">
        <h2 class="mb-3">Logging you off</h2>
    </div>
    <div class="d-flex justify-content-center">
        <span style="font-size: 14px; margin-bottom: 15px;">Please Wait....</span>
    </div>
</div>

