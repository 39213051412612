<div class="mt-5" style="max-width:100%;">

    <form [formGroup]="interactiveTabForm" style="max-width:1350px; padding:0px 15px" class="interactive-tab-form" (keydown)="enterSubmitted($event)">
        <div>
            <div #revenueAdpRateContent>
                <div>
                    <p class="font-weight-500">Input 2023 Dealer Group Revenue <span class="text-danger font-size-small">(* - Mandatory)</span>
                    <div class="row ml-2">
                        <div class="col-6">
                            <div class="input-box">
                                <div>
                                    <label class="text-uppercase">new truck sales revenue <span class="text-danger">(*)</span></label>
                                    <input placeholder="" type="text" appAllowOnlyCost appMinMaxValue [minValue]="0" [maxValue]="2500000000" appNonNegative [currency]="true" id ="INTERACTIVETAB_D11" value="" formControlName="INTERACTIVETAB_D11" />
                                    <input type="hidden" id="INTERACTIVETAB_K97" formControlName="INTERACTIVETAB_K97" value="" />
                                    <input type="hidden" id="INTERACTIVETAB_K101" formControlName="INTERACTIVETAB_K101" value="" />
                                    <input type="hidden" id="INTERACTIVETAB_K105" formControlName="INTERACTIVETAB_K105" value="" />
                                    <input type="hidden" id="INTERACTIVETAB_K109" formControlName="INTERACTIVETAB_K109" value="" />
                                    <input type="hidden" id="INTERACTIVETAB_K111" formControlName="INTERACTIVETAB_K111" value="" />
                                    <input type="hidden" id="INTERACTIVETAB_K118" formControlName="INTERACTIVETAB_K118" value="" />
                                    <input type="hidden" id="INTERACTIVETAB_K123" formControlName="INTERACTIVETAB_K123" value="" />
                                    <input type="hidden" id="INTERACTIVETAB_K129" formControlName="INTERACTIVETAB_K129" value="" />
                                    <input type="hidden" id="INTERACTIVETAB_K135" formControlName="INTERACTIVETAB_K135" value="" />
                                    <input type="hidden" id="INTERACTIVETAB_K137" formControlName="INTERACTIVETAB_K137" value="" />
                                    <input type="hidden" id="INTERACTIVETAB_K141" formControlName="INTERACTIVETAB_K141" value="" />
                                    <input type="hidden" id="INTERACTIVETAB_K143" formControlName="INTERACTIVETAB_K143" value="" />
                                    <span class="bi-info-circle ml-2 w-auto" ngbTooltip="Enter revenue figures in whole dollars. Enter 0 for no sales"></span>
                                </div>
                                <div class="d-flex input-error show-min-max-error d-none">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Maximum value of New Truck Sales Revenue is 2500000000</span>
                                </div>
                            </div>
                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D11.invalid && (interactiveTabForm.controls.INTERACTIVETAB_D11.dirty || interactiveTabForm.controls.INTERACTIVETAB_D11.touched)">
                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D11.errors?.required">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Please enter new truck sales revenue</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="input-box">
                                <div>
                                    <label class="text-uppercase">used truck sales revenue <span class="text-danger">(*)</span></label>
                                    <input placeholder="" type="text" appAllowOnlyCost appMinMaxValue [minValue]="0" [maxValue]="100000000" appNonNegative [currency]="true" id ="INTERACTIVETAB_D12" value="" formControlName="INTERACTIVETAB_D12" />
                                    <span class="bi-info-circle ml-2 w-auto" ngbTooltip="Enter revenue figures in whole dollars. Enter 0 for no sales"></span>
                                </div>
                                <div class="d-flex input-error show-min-max-error d-none">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Maximum value of Used Truck Sales Revenue is 100000000</span>
                                </div>
                            </div>
                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D12.invalid && (interactiveTabForm.controls.INTERACTIVETAB_D12.dirty || interactiveTabForm.controls.INTERACTIVETAB_D12.touched)">
                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D12.errors?.required">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Please enter used truck sales revenue</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ml-2">
                        <div class="col-6">
                            <div class="input-box">
                                <div>
                                    <label class="text-uppercase">Parts Revenue <span class="text-danger">(*)</span></label>
                                    <input placeholder="" type="text" appAllowOnlyCost appMinMaxValue [minValue]="0" [maxValue]="650000000" appNonNegative [currency]="true" id ="INTERACTIVETAB_D13" value="" formControlName="INTERACTIVETAB_D13" />
                                    <span class="bi-info-circle ml-2 w-auto" ngbTooltip="Enter revenue figures in whole dollars. Enter 0 for no sales"></span>
                                </div>
                                <div class="d-flex input-error show-min-max-error d-none">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Maximum value of Parts Revenue is 650000000</span>
                                </div>
                            </div>
                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D13.invalid && (interactiveTabForm.controls.INTERACTIVETAB_D13.dirty || interactiveTabForm.controls.INTERACTIVETAB_D13.touched)">
                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D13.errors?.required">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Please enter parts revenue</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-box">
                                <div>
                                    <label class="text-uppercase">SERVICE REVENUE <span class="text-danger">(*)</span></label>
                                    <input placeholder="" type="text" appAllowOnlyCost appMinMaxValue [minValue]="0" [maxValue]="250000000" appNonNegative [currency]="true" id ="INTERACTIVETAB_D14" value="" formControlName="INTERACTIVETAB_D14" />
                                    <span class="bi-info-circle ml-2 w-auto" ngbTooltip="Enter revenue figures in whole dollars. Enter 0 for no sales"></span>
                                </div>
                                <div class="d-flex input-error show-min-max-error d-none">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Maximum value of Service Revenue is 250000000</span>
                                </div>
                            </div>
                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D14.invalid && (interactiveTabForm.controls.INTERACTIVETAB_D14.dirty || interactiveTabForm.controls.INTERACTIVETAB_D14.touched)">
                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D14.errors?.required">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Please enter service revenue</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ml-2">
                        <div class="col-6">
                            <div class="input-box">
                                <div>
                                    <label class="text-uppercase">Bodyshop Revenue <span class="text-danger">(*)</span></label>
                                    <input placeholder="" type="text" appAllowOnlyCost appMinMaxValue [minValue]="0" [maxValue]="50000000" appNonNegative [currency]="true" id ="INTERACTIVETAB_D15" value="" formControlName="INTERACTIVETAB_D15" />
                                    <span class="bi-info-circle ml-2 w-auto" ngbTooltip="Enter revenue figures in whole dollars. Enter 0 for no sales"></span>
                                </div>
                                <div class="d-flex input-error show-min-max-error d-none">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Maximum value of Bodyshop Revenue is 50000000</span>
                                </div>
                            </div>
                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D15.invalid && (interactiveTabForm.controls.INTERACTIVETAB_D15.dirty || interactiveTabForm.controls.INTERACTIVETAB_D15.touched)">
                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D15.errors?.required">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Please enter bodyshop revenue</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="input-box">
                                <div>
                                    <label class="text-uppercase">F&I Revenue <span class="text-danger">(*)</span></label>
                                    <input placeholder="" type="text" appAllowOnlyCost appMinMaxValue [minValue]="0" [maxValue]="25000000" appNonNegative [currency]="true" id="INTERACTIVETAB_D16" value="" formControlName="INTERACTIVETAB_D16" />
                                    <span class="bi-info-circle ml-2 w-auto" ngbTooltip="Enter revenue figures in whole dollars. Enter 0 for no sales"></span>
                                </div>
                                <div class="d-flex input-error show-min-max-error d-none">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Maximum value of F&I Revenue is 25000000</span>
                                </div>
                            </div>
                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D16.invalid && (interactiveTabForm.controls.INTERACTIVETAB_D16.dirty || interactiveTabForm.controls.INTERACTIVETAB_D16.touched)">
                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D16.errors?.required">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Please enter F&I revenue</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <p class="font-weight-500 mt-4">
                        <span>BEV Adoption Rates - New Truck Sales</span>
                        <span class="text-info font-size-small">&nbsp;(Optional)</span>
                        <span class="bi-info-circle ml-2 w-auto font-size-small" ngbTooltip="Default values were derived after extensive research and analysis.  If you believe the adoption rates will be significantly different in your AOR, click on the 'Make edits' button"></span>
                    </p>

                    <div class="d-flex">
                        <p class="font-weight-500 font-size-small text-capitalize">Make edits to BEV adoption rate used by the model?</p>


                        <div class="ml-5">
                            <label class="switch">
                                <input type="checkbox" id="INTERACTIVETAB_C19" (click)="onBevRateChecked()" [checked]="bevRateChecked">
                                <div class="slider round"></div>
                            </label>
                        </div>
                    </div>

                    <div class="bev-adoption-rate-section p-4 pl-5 pr-5 text-center mb-5" [hidden]="!bevRateChecked">
                        <p class="mt-2">New Truck BEV Adoption Rate (% Of New Trucks Sold That Are BEVs)</p>

                        <div class="d-flex align-items-start">
                            <div class="w-50 font-weight-bold">
                                <p class="text-left">Year 2026</p>
                                <div>
                                    <div class="input-box align-items-start">
                                        <label class="text-uppercase">CLASS 5</label>
                                        <input placeholder="Default Value is 23%" type="text" appOnlyPercentage appNonNegative [percentage]="true" min="0" max="100" id ="INTERACTIVETAB_G22" value="" formControlName="INTERACTIVETAB_G22" />
                                    </div>
                                    <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G22.touched">
                                        <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G22.hasError('percentRange')">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter a valid percentage between 0 and 100</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="input-box align-items-start">
                                        <label class="text-uppercase">CLASS 6</label>
                                        <input placeholder="Default Value is 23%" type="text" min="0" max="100" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_G23" value="" formControlName="INTERACTIVETAB_G23" />
                                    </div>
                                    <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G23.touched">
                                        <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G23.hasError('percentRange')">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter a valid percentage between 0 and 100</span>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="input-box align-items-start">
                                        <label class="text-uppercase">CLASS 7</label>
                                        <input placeholder="Default Value is 23%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_G24" value="" formControlName="INTERACTIVETAB_G24" />
                                    </div>
                                    <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G24.touched">
                                        <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G24.hasError('percentRange')">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter a valid percentage between 0 and 100</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="input-box align-items-start calculated-cell">
                                    <label class="text-uppercase">CLASS 8</label>
                                    <span class="calculated-cell" appFormattedValue [precision]="2" [defaultText]="'Default Value is 9%'" [defaultTextCondition]="'0.00%'" [isPercentage]="true" [formGroup]="interactiveTabForm" id="INTERACTIVETAB_G25"></span>
                                </div>
                                <div class="ml-5">
                                    <div>
                                        <div class="input-box align-items-start">
                                            <label class="text-uppercase">CLASS 8 LONG HAUL</label>
                                            <input placeholder="Default Value is 4%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_G26" value="" formControlName="INTERACTIVETAB_G26" />
                                        </div>
                                        <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G26.touched">
                                            <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G26.hasError('percentRange')">
                                                <span class="bi-exclamation-circle mr-2"></span>
                                                <span>Please enter a valid percentage between 0 and 100</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div class="input-box align-items-start">
                                            <label class="text-uppercase">CLASS 8 SHORT HAUL/VOCATIONAL</label>
                                            <input placeholder="Default Value is 17%" type="text" min="0" max="100"  appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_G27" value="" formControlName="INTERACTIVETAB_G27" />
                                        </div>
                                        <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G27.touched">
                                            <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G27.hasError('percentRange')">
                                                <span class="bi-exclamation-circle mr-2"></span>
                                                <span>Please enter a valid percentage between 0 and 100</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-50 font-weight-bold">
                                <p class="text-left">Year 2030</p>
                                <div>
                                    <div class="input-box align-items-start">
                                        <label class="text-uppercase">CLASS 5</label>
                                        <input placeholder="Default Value is 39%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_J22" value="" formControlName="INTERACTIVETAB_J22" />
                                    </div>
                                    <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J22.touched || interactiveTabForm.controls.INTERACTIVETAB_J22.dirty">
                                        <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J22.hasError('percentRange')">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter a valid percentage between 0 and 100</span>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="input-box align-items-start">
                                        <label class="text-uppercase">CLASS 6</label>
                                        <input placeholder="Default Value is 39%" type="text" appOnlyPercentage appNonNegative [percentage]="true" min="0" max="100" id ="INTERACTIVETAB_J23" value="" formControlName="INTERACTIVETAB_J23" />
                                    </div>
                                    <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J23.touched">
                                        <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J23.hasError('percentRange')">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter a valid percentage between 0 and 100</span>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="input-box align-items-start">
                                        <label class="text-uppercase">CLASS 7</label>
                                        <input placeholder="Default Value is 39%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_J24" value="" formControlName="INTERACTIVETAB_J24" />
                                    </div>
                                    <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J24.touched">
                                        <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J24.hasError('percentRange')">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter a valid percentage between 0 and 100</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-box align-items-start calculated-cell">
                                    <label class="text-uppercase">CLASS 8</label>
                                    <span class="calculated-cell" appFormattedValue [precision]="2" [defaultTextCondition]="'0.00%'" [defaultText]="'Default Value is 16%'" [isPercentage]="true" [formGroup]="interactiveTabForm" id="INTERACTIVETAB_J25"></span>
                                </div>
                                <div class="ml-5">
                                    <div>
                                        <div class="input-box align-items-start">
                                            <label class="text-uppercase">CLASS 8 LONG HAUL</label>
                                            <input placeholder="Default Value is 7%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_J26" value="" formControlName="INTERACTIVETAB_J26" />
                                        </div>
                                        <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J26.touched">
                                            <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J26.hasError('percentRange')">
                                                <span class="bi-exclamation-circle mr-2"></span>
                                                <span>Please enter a valid percentage between 0 and 100</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="input-box align-items-start">
                                            <label class="text-uppercase">CLASS 8 SHORT HAUL/VOCATIONAL</label>
                                            <input placeholder="Default Value is 29%" type="text" appOnlyPercentage appNonNegative [percentage]="true" min="0" max="100" id ="INTERACTIVETAB_J27" value="" formControlName="INTERACTIVETAB_J27" />
                                        </div>
                                        <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J27.touched">
                                            <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J27.hasError('percentRange')">
                                                <span class="bi-exclamation-circle mr-2"></span>
                                                <span>Please enter a valid percentage between 0 and 100</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <p class="mt-5 mb-5 font-weight-500">Percentage Of Vehicles In Operation (VIO) That Are BEVs</p>
                        <div class="row">
                            <div class="col-6">
                                <div class="input-box align-items-start">
                                    <label class="text-uppercase">BLENDED AVERAGE (ACROSS CLASSES)</label>
                                    <input placeholder="Default Value is 8.03%" type="text" appAllowDecimalInputs [percentage]="true" [decimal]="true" id ="INTERACTIVETAB_G30" value="" formControlName="INTERACTIVETAB_G30" />
                                </div>
                                <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G30.touched">
                                    <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G30.hasError('percentRange')">
                                        <span class="bi-exclamation-circle mr-2"></span>
                                        <span>Please enter a valid percentage between 0 and 100</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="input-box align-items-start">
                                    <label class="text-uppercase">BLENDED AVERAGE (ACROSS CLASSES)</label>
                                    <input placeholder="Default Value is 13.1%" type="text" appAllowDecimalInputs [percentage]="true" [decimal]="true" id ="INTERACTIVETAB_J30" value="" formControlName="INTERACTIVETAB_J30" />
                                </div>
                                <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J30.touched">
                                    <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J27.hasError('percentRange')">
                                        <span class="bi-exclamation-circle mr-2"></span>
                                        <span>Please enter a valid percentage between 0 and 100</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <p class="text-left notes-text mt-3 mb-2">*Please Exercise Care When Changing The BEV Adoption Rate. Default BEV Adoption Rate Used By The Model Is Recommended</p>
                    </div>
                </div>
            </div>
            <div #newTruckSalesVolContent>
                <div class="mt-3">
                    <p class="font-weight-500">
                        <span>Input 2023 New Truck Sales Volume (Units) By Class </span>
                        <span class="text-danger font-size-small">(* - Mandatory)</span>
                        <span class="bi-info-circle ml-2 w-auto font-size-small" ngbTooltip="Enter unit sales in calendar year 2023"></span>
                    </p>
                    <div class="truck-sales-volume-section ml-4">
                        <p class="text-left font-weight-bold">Year 2023</p>
                        <div class="input-box align-items-start">
                            <div class="w-100">
                                <label class="text-uppercase">CLASS 5 <span class="text-danger">(*)</span></label>
                                <input placeholder="" type="text" appAllowOnlyNumbers appMinMaxValue [minValue]="0" [maxValue]="10000" appNonNegative [formatNumber]="true" id ="INTERACTIVETAB_D35" value="" formControlName="INTERACTIVETAB_D35" />
                                <span class="bi-info-circle ml-2 w-auto" ngbTooltip="Enter 0 for no sales"></span>
                            </div>
                            <div class="d-flex input-error show-min-max-error d-none">
                                <span class="bi-exclamation-circle mr-2"></span>
                                <span>Maximum value of Class 5 Sales Volume is 10000</span>
                            </div>
                        </div>
                        <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D35.invalid && (interactiveTabForm.controls.INTERACTIVETAB_D35.dirty || interactiveTabForm.controls.INTERACTIVETAB_D35.touched)">
                            <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D35.errors?.required">
                                <span class="bi-exclamation-circle mr-2"></span>
                                <span>Please enter Class 5 Sales Volume</span>
                            </div>
                        </div>
                        <div class="input-box align-items-start">
                            <div class="w-100">
                                <label class="text-uppercase">CLASS 6 <span class="text-danger">(*)</span></label>
                                <input placeholder="" type="text" appAllowOnlyNumbers appMinMaxValue [minValue]="0" [maxValue]="10000" appNonNegative [formatNumber]="true" id ="INTERACTIVETAB_D36" value="" formControlName="INTERACTIVETAB_D36" />
                                <span class="bi-info-circle ml-2 w-auto" ngbTooltip="Enter 0 for no sales"></span>
                            </div>
                            <div class="d-flex input-error show-min-max-error d-none">
                                <span class="bi-exclamation-circle mr-2"></span>
                                <span>Maximum value of Class 6 Sales Volume is 10000</span>
                            </div>
                        </div>

                        <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D36.invalid && (interactiveTabForm.controls.INTERACTIVETAB_D36.dirty || interactiveTabForm.controls.INTERACTIVETAB_D36.touched)">
                            <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D36.errors?.required">
                                <span class="bi-exclamation-circle mr-2"></span>
                                <span>Please enter Class 6 Sales Volume</span>
                            </div>
                        </div>
                        <div class="input-box align-items-start">
                            <div class="w-100">
                                <label class="text-uppercase">CLASS 7 <span class="text-danger">(*)</span></label>
                                <input placeholder="" type="text" appAllowOnlyNumbers appMinMaxValue [minValue]="0" [maxValue]="10000" appNonNegative [formatNumber]="true" id ="INTERACTIVETAB_D37" value="" formControlName="INTERACTIVETAB_D37" />
                                <span class="bi-info-circle ml-2 w-auto" ngbTooltip="Enter 0 for no sales"></span>
                            </div>
                            <div class="d-flex input-error show-min-max-error d-none">
                                <span class="bi-exclamation-circle mr-2"></span>
                                <span>Maximum value of Class 7 Sales Volume is 10000</span>
                            </div>
                        </div>

                        <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D37.invalid && (interactiveTabForm.controls.INTERACTIVETAB_D37.dirty || interactiveTabForm.controls.INTERACTIVETAB_D37.touched)">
                            <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D37.errors?.required">
                                <span class="bi-exclamation-circle mr-2"></span>
                                <span>Please enter Class 7 Sales Volume</span>
                            </div>
                        </div>
                        <div class="input-box align-items-start calculated-cell">
                            <label class="text-uppercase">CLASS 8</label>
                            <span class="calculated-cell" appFormattedValue [isNumber]="true" [formGroup]="interactiveTabForm" id="INTERACTIVETAB_D38"></span>
                        </div>
                        <div class="ml-5">
                            <div class="input-box align-items-start">
                                <div class="w-100">
                                    <label class="text-uppercase">CLASS 8 LONG HAUL <span class="text-danger">(*)</span></label>
                                    <input placeholder="" type="text" appAllowOnlyNumbers appMinMaxValue [minValue]="0" [maxValue]="10000" appNonNegative [formatNumber]="true" id ="INTERACTIVETAB_D39" value="" formControlName="INTERACTIVETAB_D39" />
                                    <span class="bi-info-circle ml-2 w-auto" ngbTooltip="Enter 0 for no sales"></span>
                                </div>
                                <div class="d-flex input-error show-min-max-error d-none">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Maximum value of Class 8 Long Haul Sales Volume is 10000</span>
                                </div>
                            </div>
                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D39.invalid && (interactiveTabForm.controls.INTERACTIVETAB_D39.dirty || interactiveTabForm.controls.INTERACTIVETAB_D39.touched)">
                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D39.errors?.required">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Please enter Class 8 Long Haul Sales Volume</span>
                                </div>
                            </div>
                            <div class="input-box align-items-start">
                                <div class="w-100">
                                    <label class="text-uppercase">CLASS 8 SHORT HAUL/VOCATIONAL <span class="text-danger">(*)</span></label>
                                    <input placeholder="" type="text" appAllowOnlyNumbers appMinMaxValue [minValue]="0" [maxValue]="10000" appNonNegative [formatNumber]="true" id ="INTERACTIVETAB_D40" value="" formControlName="INTERACTIVETAB_D40" />
                                    <span class="bi-info-circle ml-2 w-auto" ngbTooltip="Enter 0 for no sales"></span>
                                </div>
                                <div class="d-flex input-error show-min-max-error d-none">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Maximum value of Class 8 Short Haul Sales Volume is 10000</span>
                                </div>
                            </div>
                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D40.invalid && (interactiveTabForm.controls.INTERACTIVETAB_D40.dirty || interactiveTabForm.controls.INTERACTIVETAB_D40.touched)">
                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D40.errors?.required">
                                    <span class="bi-exclamation-circle mr-2"></span>
                                    <span>Please enter Class 8 Short Haul Sales Volume</span>
                                </div>
                            </div>
                        </div>
                        <div class="input-box align-items-start calculated-cell">
                            <label class="text-uppercase">TOTAL (All Classes)</label>
                            <span class="calculated-cell" appFormattedValue [isNumber]="true" [formGroup]="interactiveTabForm" id="INTERACTIVETAB_D41"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="mt-5">
                    <div class="d-flex">
                        <p class="font-weight-bold">Make Edits To BEV Parts and Service Adoption Rates Used By The Tool</p>
                        <div class="ml-5">
                            <label class="switch">
                                <input type="checkbox" id="toggleButton" (click)="onPartsServiceChecked()" [checked]="partsServiceChecked">
                                <div class="slider round"></div>
                            </label>
                        </div>
                    </div>

                    <div [hidden]="!partsServiceChecked">
                        <div class="dealer-ice-market-share-section p-4" #partsSalesContent>
                            <p class="font-weight-500 dealer-title">Parts Sales Statistics</p>

                            <div class="custom-fieldset">
                                <p class="font-weight-500 custom-fieldset-title-section">
                                    <span class="custom-fieldset-title">Dealer ICE Parts Market Share <span class="text-info font-size-small">(Optional)</span></span>
                                </p>
                                <div class="ml-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Percent Of ICE Trucks Under Warranty (Effects Service As Well)</p>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2023</label>
                                                <input placeholder="Default Value is 28%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_D47" value="" formControlName="INTERACTIVETAB_D47" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D47.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D47.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2026</label>
                                                <input placeholder="Default Value is 29%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_G47" value="" formControlName="INTERACTIVETAB_G47" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G47.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G47.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col-4">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2030</label>
                                                <input placeholder="Default Value is 30%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_J47" value="" formControlName="INTERACTIVETAB_J47" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J47.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J47.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="ml-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Dealer Capture Of ICE Parts Market For Trucks Under Warranty</p>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2023</label>
                                                <input placeholder="Default Value is 80%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_D48" value="" formControlName="INTERACTIVETAB_D48" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D48.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D48.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2026</label>
                                                <input placeholder="Default Value is 80%" type="text"  min="0" max="100"  appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_G48" value="" formControlName="INTERACTIVETAB_G48" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G48.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G48.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2030</label>
                                                <input placeholder="Default Value is 80%" type="text" appOnlyPercentage appNonNegative [percentage]="true"  id ="INTERACTIVETAB_J48" value="" formControlName="INTERACTIVETAB_J48" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J48.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J48.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="ml-2 mb-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Dealer Capture Of ICE Parts Market For Trucks Not Under Warranty</p>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2023</label>
                                                <input placeholder="Default Value is 30%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_D49" value="" formControlName="INTERACTIVETAB_D49" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D49.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D49.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2026</label>
                                                <input placeholder="Default Value is 31%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_G49" value="" formControlName="INTERACTIVETAB_G49" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G49.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G49.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2030</label>
                                                <input placeholder="Default Value is 33%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_J49" value="" formControlName="INTERACTIVETAB_J49" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J49.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J49.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="mt-5 dealer-bev-parts custom-fieldset">
                                <p class="font-weight-500 custom-fieldset-title-section">
                                    <span class="custom-fieldset-title">Dealer BEV Parts Market Share <span class="text-info font-size-small">(Optional)</span></span>
                                </p>
                                <div class="ml-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Percent of BEV trucks under warranty (effects service as well)</p>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2026</label>
                                                <input placeholder="Default Value is 68%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_G52" value="" formControlName="INTERACTIVETAB_G52" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G52.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G52.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2030</label>
                                                <input placeholder="Default Value is 68%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_J52" value="" formControlName="INTERACTIVETAB_J52" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J52.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J52.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="ml-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Dealer Capture Of BEV Parts Market For Trucks Under Warranty</p>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2026</label>
                                                <input placeholder="Default Value is 90%" type="text"  min="0" max="100" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_G53" value="" formControlName="INTERACTIVETAB_G53" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G53.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G53.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2030</label>
                                                <input placeholder="Default Value is 90%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_J53" value="" formControlName="INTERACTIVETAB_J53" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J53.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J53.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="ml-2 mb-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Dealer Capture Of BEV Parts Market For Trucks Not Under Warranty</p>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2026</label>
                                                <input placeholder="Default Value is 60%" type="text" min="0" max="100" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_G54" value="" formControlName="INTERACTIVETAB_G54" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G54.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G54.errors?.min ||  interactiveTabForm.controls.INTERACTIVETAB_G54.errors?.max">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2030</label>
                                                <input placeholder="Default Value is 60%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_J54" value="" formControlName="INTERACTIVETAB_J54" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J54.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J54.errors?.min ||  interactiveTabForm.controls.INTERACTIVETAB_J54.errors?.max">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="mt-5 dealer-bev-parts custom-fieldset">
                                <p class="font-weight-500 custom-fieldset-title-section">
                                    <span class="custom-fieldset-title">BEV Parts Change <span class="text-info font-size-small">(Optional)</span></span>
                                </p>
                                <div class="ml-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Decline In Volume Of Parts Required For BEV Truck Compared To ICE</p>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2026</label>
                                                <input placeholder="Default Value is -45%" type="text" appAllowNegative [percentage]="true" id ="INTERACTIVETAB_G57" value="" formControlName="INTERACTIVETAB_G57" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G57.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G57.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between -100 and 0</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2030</label>
                                                <input placeholder="Default Value is -45%" type="text"  min="-100" max="100" appAllowNegative [percentage]="true" id ="INTERACTIVETAB_J57" value="" formControlName="INTERACTIVETAB_J57" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J57.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J57.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between -100 and 0</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="ml-2 mb-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Change In Average Price Of Parts For BEV Trucks Compared To ICE</p>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2026</label>
                                                <input placeholder="Default Value is 40%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_G58" value="" formControlName="INTERACTIVETAB_G58" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G58.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G58.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between -100 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2030</label>
                                                <input placeholder="Default Value is 40%" type="text"  min="-100" max="100" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_J58" value="" formControlName="INTERACTIVETAB_J58" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J58.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J58.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between -100 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="mt-5 dealer-bev-parts custom-fieldset">
                                <p class="font-weight-500 custom-fieldset-title-section">
                                    <span class="custom-fieldset-title">Annual CAGR Growth Rate Of Vehicles In Operation (VIO) <span class="text-info font-size-small">(Optional)</span></span>
                                </p>
                                <div class="ml-2 mb-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">*Also Impacts Service Sales Growth, Used Truck Sales Growth, And Used Truck Ext. Warranty Growth</p>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2023 - 2026 CAGR</label>
                                                <input placeholder="Default Value is 0.39%" type="text" appAllowDecimalInputs [percentage]="true" [decimal]="true" id ="INTERACTIVETAB_G61" value="" formControlName="INTERACTIVETAB_G61" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G61.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G61.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between -100 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2023 - 2030 CAGR</label>
                                                <input placeholder="Default Value is 1.29%" type="text" appAllowDecimalInputs [percentage]="true" [decimal]="true" id ="INTERACTIVETAB_J61" value="" formControlName="INTERACTIVETAB_J61" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J61.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J61.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between -100 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dealer-ice-market-share-section p-4 mt-5 mb-5" #serviceSalesContent>
                            <p class="font-weight-500 dealer-title">Service Sales Statistics</p>

                            <div class="mt-5 custom-fieldset">
                                <p class="font-weight-500 custom-fieldset-title-section">
                                    <span class="custom-fieldset-title">Dealer ICE service Market Share <span class="text-info font-size-small">(Optional)</span></span>
                                </p>
                                <div class="ml-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Dealer Capture Of ICE Service Market For Trucks Under Warranty</p>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2023</label>
                                                <input placeholder="Default Value is 80%" type="text"  min="0" max="100" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_D66" value="" formControlName="INTERACTIVETAB_D66" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D66.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D66.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2026</label>
                                                <input placeholder="Default Value is 80%" type="text" appOnlyPercentage  min="0" max="100" appNonNegative [percentage]="true" id ="INTERACTIVETAB_G66" value="" formControlName="INTERACTIVETAB_G66" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G66.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G66.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2030</label>
                                                <input placeholder="Default Value is 80%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_J66" value="" formControlName="INTERACTIVETAB_J66" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J66.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J66.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="ml-2 mb-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Dealer Capture Of ICE Service Market For Trucks Not Under Warranty</p>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2023</label>
                                                <input placeholder="Default Value is 20%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_D67" value="" formControlName="INTERACTIVETAB_D67" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D67.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_D67.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2026</label>
                                                <input placeholder="Default Value is 21%" type="text"  min="0" max="100" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_G67" value="" formControlName="INTERACTIVETAB_G67" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G67.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G67.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2030</label>
                                                <input placeholder="Default Value is 23%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_J67" value="" formControlName="INTERACTIVETAB_J67" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J67.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J67.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div class="mt-5 dealer-bev-parts custom-fieldset">
                                <p class="font-weight-500 custom-fieldset-title-section">
                                    <span class="custom-fieldset-title">Dealer BEV service Market Share <span class="text-info font-size-small">(Optional)</span></span>
                                </p>
                                <div class="ml-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Dealer Capture Of BEV Service Market For Trucks Under Warranty</p>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2026</label>
                                                <input placeholder="Default Value is 80%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_G70" value="" formControlName="INTERACTIVETAB_G70" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G70.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G70.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2030</label>
                                                <input placeholder="Default Value is 80%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_J70" value="" formControlName="INTERACTIVETAB_J70" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J70.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J70.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="ml-2 mb-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Dealer Capture Of BEV Service Market For Trucks Not Under Warranty</p>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2026</label>
                                                <input placeholder="Default Value is 60%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_G71" value="" formControlName="INTERACTIVETAB_G71" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G71.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G71.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2030</label>
                                                <input placeholder="Default Value is 60%" type="text"  min="0" max="100" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_J71" value="" formControlName="INTERACTIVETAB_J71" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J71.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J71.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-5 dealer-bev-parts custom-fieldset">
                                <p class="font-weight-500 custom-fieldset-title-section">
                                    <span class="custom-fieldset-title">BEV Service Changes <span class="text-info font-size-small">(Optional)</span></span>
                                </p>
                                <div class="ml-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Decline In Volume Of Service Required For BEV Truck Compared To ICE</p>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2026</label>
                                                <input placeholder="Default Value is -45%" type="text" appAllowNegative [percentage]="true" id ="INTERACTIVETAB_G74" value="" formControlName="INTERACTIVETAB_G74" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G74.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G74.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between -100 and 0</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2030</label>
                                                <input placeholder="Default Value is -45%" type="text" appAllowNegative [percentage]="true" id ="INTERACTIVETAB_J74" value="" formControlName="INTERACTIVETAB_J74" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J74.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J74.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between -100 and 0</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="ml-2 mb-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Change In Average Price Of Service For BEV Trucks Compared To ICE</p>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2026</label>
                                                <input placeholder="Default Value is 10%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_G75" value="" formControlName="INTERACTIVETAB_G75" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G75.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G75.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between -100 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2030</label>
                                                <input placeholder="Default Value is 10%" type="text" appOnlyPercentage appNonNegative [percentage]="true" id ="INTERACTIVETAB_J75" value="" formControlName="INTERACTIVETAB_J75" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J75.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J75.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between -100 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="mt-5 dealer-bev-parts custom-fieldset">
                                <p class="font-weight-500 custom-fieldset-title-section">
                                    <span class="custom-fieldset-title">Percent Of Service Demand Coming From Mobile Service <span class="text-info font-size-small">(Optional)</span></span>
                                </p>
                                <div class="ml-2 mb-2">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2026</label>
                                                <input placeholder="Default Value is 7.5%" type="text" appAllowDecimalInputs [decimal]="true" [percentage]="true" id ="INTERACTIVETAB_G78" value="" formControlName="INTERACTIVETAB_G78" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G78.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_G78.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="input-box align-items-start">
                                                <label class="text-uppercase">2030</label>
                                                <input placeholder="Default Value is 10%" type="text" appAllowDecimalInputs [decimal]="true"  [percentage]="true" id ="INTERACTIVETAB_J78" value="" formControlName="INTERACTIVETAB_J78" />
                                            </div>
                                            <div class="d-flex input-error" *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J78.touched">
                                                <div *ngIf="interactiveTabForm.controls.INTERACTIVETAB_J78.hasError('percentRange')">
                                                    <span class="bi-exclamation-circle mr-2"></span>
                                                    <span>Please enter a valid percentage between 0 and 100</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="d-flex mt-2">
                            <p class="font-weight-bold">Make Edits To Public Charging Assumptions Used By The Tool</p>
                            <div class="ml-5">
                                <label class="switch">
                                    <input type="checkbox" id="publicChargingToggle" (click)="onPublicChargingChecked()" [checked]="publicChargingChecked">
                                    <div class="slider round"></div>
                                </label>
                            </div>
                        </div>

                        <div class="dealer-ice-market-share-section p-4" [hidden]="!publicChargingChecked"  #publicChargingContent>
                          <p *ngIf="addHeading" class="font-weight-500 dealer-title">Public Charging Assumptions</p>
                            <div class="mt-2 dealer-bev-parts custom-fieldset">
                                <p class="font-weight-500 custom-fieldset-title-section">
                                    <span class="custom-fieldset-title">
                                        Public Charging Assumptions <span class="text-info font-size-small">(Optional)</span>
                                    </span>
                                </p>
                                <div class="ml-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Number Of Current-Gen DC Chargers Across Dealer Group</p>
                                    <div class="row">
                                        <div class="input-box col-6 align-items-start">
                                            <label class="text-uppercase">2026</label>
                                            <input placeholder="Default Value is 20" type="text"  appAllowOnlyNumbers id ="INTERACTIVETAB_G82" value="" formControlName="INTERACTIVETAB_G82" />
                                        </div>
                                        <div class="input-box col-6 align-items-start">
                                            <label class="text-uppercase">2030</label>
                                            <input placeholder="Default Value is 20" type="text" appAllowOnlyNumbers id ="INTERACTIVETAB_J82" value="" formControlName="INTERACTIVETAB_J82" />
                                        </div>
                                    </div>
                                </div>

                                <div class="ml-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                                        <span>Number Of Next-Gen Megawatt Fast Chargers Across Dealer Group</span>
                                        <span class="bi-info-circle ml-2 w-auto" ngbTooltip="Megawatt chargers not expected to rollout until after 2026"></span>
                                    </p>
                                    <div class="row">
                                        <div class="input-box col-6 align-items-start">
                                            <label class="text-uppercase">2026</label>
                                            <input placeholder="Default Value is 0" type="text" appAllowOnlyNumbers id ="INTERACTIVETAB_G83" value="" formControlName="INTERACTIVETAB_G83" />
                                        </div>
                                        <div class="input-box col-6 align-items-start">
                                            <label class="text-uppercase">2030</label>
                                            <input placeholder="Default Value is 10" type="text" appAllowOnlyNumbers id ="INTERACTIVETAB_J83" value="" formControlName="INTERACTIVETAB_J83" />
                                        </div>
                                    </div>
                                </div>

                                <div class="ml-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title text-transform-none">
                                        <span>Spread On Current-Gen DC Fast Chargers Per kWh</span>
                                        <span class="bi-info-circle ml-2 w-auto" ngbTooltip="Price per kWh to customer minus dealer price paid to utility"></span>
                                    </p>
                                    <div class="row">
                                        <div class="input-box col-6 align-items-start">
                                            <label class="text-uppercase">2026</label>
                                            <input placeholder="Default Value is $0.20" type="text"  [allowedDecimalValidation]="true" appAllowDecimalInputs [decimal]="true" [currency]="true" id ="INTERACTIVETAB_G84" value="" formControlName="INTERACTIVETAB_G84" />
                                        </div>
                                        <div class="input-box col-6 align-items-start">
                                            <label class="text-uppercase">2030</label>
                                            <input placeholder="Default Value is $0.20" type="text" [allowedDecimalValidation]="true"  appAllowDecimalInputs [decimal]="true" [currency]="true" id ="INTERACTIVETAB_J84" value="" formControlName="INTERACTIVETAB_J84" />
                                        </div>
                                    </div>
                                </div>

                                <div class="ml-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title text-transform-none">
                                        <span>Spread On Next-Gen Megawatt Fast Chargers Per kWh</span>
                                        <span class="bi-info-circle ml-2 w-auto" ngbTooltip="Price per kWh to customer minus dealer price paid to utility"></span>
                                    </p>
                                    <div class="row">
                                        <div class="input-box col-6 align-items-start">
                                            <label class="text-uppercase">2026</label>
                                            <input placeholder="Default Value is $0.35" type="text" [allowedDecimalValidation]="true" appAllowDecimalInputs [decimal]="true" [currency]="true" id ="INTERACTIVETAB_G85" value="" formControlName="INTERACTIVETAB_G85" />
                                        </div>
                                        <div class="input-box col-6 align-items-start">
                                            <label class="text-uppercase">2030</label>
                                            <input placeholder="Default Value is $0.35" type="text" [allowedDecimalValidation]="true" appAllowDecimalInputs [decimal]="true" [currency]="true" id ="INTERACTIVETAB_J85" value="" formControlName="INTERACTIVETAB_J85" />
                                        </div>
                                    </div>
                                </div>

                                <div class="ml-2 mb-2">
                                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Participate In Amenity Sales? (Yes/No)</p>
                                    <div class="row">
                                        <div class="input-box col-6 align-items-start">
                                            <label class="text-uppercase">2026</label>
                                            <select [class.remove-icon]="removeArrow" class="custom-select" id="INTERACTIVETAB_G86" formControlName="INTERACTIVETAB_G86">
                                                <option value="YES">YES</option>
                                                <option value="NO">NO</option>
                                            </select>
                                        </div>
                                        <div class="input-box col-6 align-items-start">
                                            <label class="text-uppercase">2030</label>
                                            <select [class.remove-icon]="removeArrow" class="custom-select" id="INTERACTIVETAB_J86" formControlName="INTERACTIVETAB_J86">
                                                <option value="YES">YES</option>
                                                <option value="NO">NO</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end mt-4">
                <button class="btn link-btn" (click)="prevPage()">Back</button>
                <button class="btn link-btn ml-3" (click)="resetForm()">Reset</button>
                <button class="btn link-btn ml-3" (click)="nextPage()" [disabled]="interactiveTabForm.invalid">Next Step</button>
            </div>
        </div>
    </form>
</div>

<!-- <div #draftModalRef class="modal fade" id="draftModal" tabindex="-1" aria-labelledby="userProfileModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <span type="button" class="close" (click)="draftModal.hide()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="bi-x-circle-fill"></i></span>
                </span>
            </div>
            <div class="modal-body">
                <div>
                    <p class="text-capitalize">Would you like to resume from your Last Draft</p>
                    <div class="d-flex justify-content-end mt-4">
                        <button class="btn link-btn" (click)="yesDraft()">Resume</button>
                        <button class="btn link-btn ml-3" (click)="deleteDraft()">Fresh Input</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
