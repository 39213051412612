import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appOnlyPercentage]'
})
export class OnlyPercentageDirective {
  @Input() percentage: Boolean;
  @Output() valueChanged = new EventEmitter();
  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInput(event: any): void {
    let initialValue = this.el.nativeElement.value;

    this.el.nativeElement.value = initialValue.replace(/[^0-9.%]/g, '');

    this.valueChanged.emit({formControlName: this.el.nativeElement.id, value: this.el.nativeElement.value});

    if (this.el.nativeElement.value !== initialValue ) {
      event.stopPropagation();
    }
  } 

}
