// loader-interceptor.ts

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoaderService } from '../shared/loader/loader.service';
import { includes } from 'lodash';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // // Exclude update path
    // if (!includes(request.url, '/api/update')) {
    //   // Show loader
    //   this.loaderService.show();
    // }

    // this.loaderService.show();

    return next.handle(request).pipe(map((event: any) => {
      if (event instanceof HttpResponse) {
        // Hide if visible
        // if (this.loaderService.isVisible()) {
        //   this.loaderService.hide();
        // }
      }         
      return event;
  }));
  }
}
