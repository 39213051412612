import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  AfterViewChecked,
  ChangeDetectorRef,
  Renderer2,
  AfterContentChecked,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Modal } from 'bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormulaList } from './parts-service-investment.formulaList';
import { ScreenshotService } from '../services/screenshot.service';
import { isEmpty } from 'lodash';
import { BreadcrumbService } from '../services/breadcumbs.service';
import { LoaderService } from '../shared/loader/loader.service';
@Component({
  selector: 'app-part-service-investments',
  templateUrl: './part-service-investments.component.html',
  styleUrls: ['./part-service-investments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartServiceInvestmentsComponent
  implements OnInit, AfterViewInit, AfterViewChecked, AfterContentChecked
{
  calculatedData: any;
  partsServiceForm: FormGroup;
  draftData: any;
  draftModal: Modal;
  partialData: any;
  formulaList = FormulaList;
  addHeading=false;
  removeBorder=false
  C44 = 3472
  C45 = 5100
  C46 = 5880
  C47 = 1800
  C49 = 51000
  C51 = 1600
  C52 = 51000
  C59 = 20000
  C67 = 5000
  C68 = 1558
  C89 = 100000
  C100 = 50000
  C891 = 100739

  PARTSSERVICEINVESTMENTS_C891C = this.C891.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  PARTSSERVICEINVESTMENTS_C100C = this.C100.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  PARTSSERVICEINVESTMENTS_C89C = this.C89.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    PARTSSERVICEINVESTMENTS_C68C = this.C68.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  PARTSSERVICEINVESTMENTS_C67C = this.C67.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  PARTSSERVICEINVESTMENTS_C59C = this.C59.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  PARTSSERVICEINVESTMENTS_C52C= this.C52.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  PARTSSERVICEINVESTMENTS_C51C= this.C51.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  PARTSSERVICEINVESTMENTS_C49C= this.C49.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  PARTSSERVICEINVESTMENTS_C47C= this.C47.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  PARTSSERVICEINVESTMENTS_C46C= this.C46.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  PARTSSERVICEINVESTMENTS_C45C= this.C45.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  PARTSSERVICEINVESTMENTS_C44C= this.C44.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  @ViewChild('draftModalRef') draftModalRef: ElementRef;

  @ViewChild('annualPreparednessContent')
  annualPreparednessContentElement: ElementRef;
  @ViewChild('annualBevCostContent') annualBevCostContentElement: ElementRef;
  @ViewChild('annualIncrementalCostContent')
  annualIncrementalCostContentElement: ElementRef;

  annualPreparednessCapturedImage: any;
  annualBevCostCapturedImage: any;
  annualIncrementalCostCapturedImage: any;

  constructor(
    public apiService: ApiService,
    private fb: FormBuilder,
    private toast: ToastrService,
    private router: Router,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private screenshotService: ScreenshotService,
    private breadCrumbsSvc: BreadcrumbService,
    private renderer: Renderer2,
    private el: ElementRef,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.apiService.pageTitle = 'Parts & Service Investments';
    this.breadCrumbsSvc.addBreadcrumbs();
    this.createPartsServiceForm();
    this.resetForm();
  }
  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
  ngAfterViewInit(): void {
    this.patchDefaultValue();
    if (Object.keys(this.apiService.formData).length === 0) {
      // this.redirectToLandingPage();
    } else {
      const prevFormData = this.apiService.getPrevFormData();
      if (!isEmpty(prevFormData.partsServiceInvesment)) {
        this.partialData = prevFormData.partsServiceInvesment;
      } else {
        this.partialData = this.apiService.getFormData();

      }
      this.assignDraftData(false);
    }
    this.cdr.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  redirectToLandingPage(): void {
    this.router.navigate(['/']);
  }



  patchDefaultValue() {
    this.partsServiceForm.patchValue({
      PARTSSERVICEINVESTMENTS_H8: 1,
      PARTSSERVICEINVESTMENTS_I8: 1,
      PARTSSERVICEINVESTMENTS_J8: 1,
      PARTSSERVICEINVESTMENTS_K8: 1,
      PARTSSERVICEINVESTMENTS_L8: 1,
      PARTSSERVICEINVESTMENTS_M8: 1,
      PARTSSERVICEINVESTMENTS_N8: 1,
      PARTSSERVICEINVESTMENTS_O8: 1,
      PARTSSERVICEINVESTMENTS_C9: 100,
      PARTSSERVICEINVESTMENTS_C12: 2,
      PARTSSERVICEINVESTMENTS_F12: 25,
      PARTSSERVICEINVESTMENTS_G12: 25,
      PARTSSERVICEINVESTMENTS_O12: 25,
      PARTSSERVICEINVESTMENTS_C13: 12,
      PARTSSERVICEINVESTMENTS_C16: 2,
      PARTSSERVICEINVESTMENTS_F16: 36,
      PARTSSERVICEINVESTMENTS_G16: 36,
      PARTSSERVICEINVESTMENTS_O16: 40,
      PARTSSERVICEINVESTMENTS_C17: 15,
      PARTSSERVICEINVESTMENTS_F24: 0,
      PARTSSERVICEINVESTMENTS_G24: 0,
      PARTSSERVICEINVESTMENTS_O24: 0,
      PARTSSERVICEINVESTMENTS_C25: 0,
      PARTSSERVICEINVESTMENTS_O29: 0,
      PARTSSERVICEINVESTMENTS_C32: 5,
      PARTSSERVICEINVESTMENTS_C43: '$' + 384,
      PARTSSERVICEINVESTMENTS_D43: 1,
      PARTSSERVICEINVESTMENTS_C44: '$' + 3472,
      PARTSSERVICEINVESTMENTS_D44: 1,
      PARTSSERVICEINVESTMENTS_C45: '$' + 5100,
      PARTSSERVICEINVESTMENTS_D45: 1,
      PARTSSERVICEINVESTMENTS_C46: '$' + 5880,
      PARTSSERVICEINVESTMENTS_D46: 1,
      PARTSSERVICEINVESTMENTS_C47: '$' + 1800,
      PARTSSERVICEINVESTMENTS_D47: 2,
      PARTSSERVICEINVESTMENTS_C48: '$' + 0,
      PARTSSERVICEINVESTMENTS_D48: 1,
      PARTSSERVICEINVESTMENTS_C49: '$' + 51000,
      PARTSSERVICEINVESTMENTS_D49: 1,
      PARTSSERVICEINVESTMENTS_C51: '$' + 1600,
      PARTSSERVICEINVESTMENTS_D51: 1,
      PARTSSERVICEINVESTMENTS_C52: '$' + 51000,
      PARTSSERVICEINVESTMENTS_D52: 1,
      PARTSSERVICEINVESTMENTS_C56: '$' + 300,
      PARTSSERVICEINVESTMENTS_D56: 1,
      PARTSSERVICEINVESTMENTS_C57: '$' + 40,
      PARTSSERVICEINVESTMENTS_D57: 1,
      PARTSSERVICEINVESTMENTS_C58: '$' + 399,
      PARTSSERVICEINVESTMENTS_D58: 1,
      PARTSSERVICEINVESTMENTS_C59: '$' + 20000,
      PARTSSERVICEINVESTMENTS_D59: 0.5,
      PARTSSERVICEINVESTMENTS_C67: '$' + 5000,
      PARTSSERVICEINVESTMENTS_D67: 1,
      PARTSSERVICEINVESTMENTS_C68: '$' + 1558,
      PARTSSERVICEINVESTMENTS_D68: 1,
      PARTSSERVICEINVESTMENTS_C78: '$' + 600,
      PARTSSERVICEINVESTMENTS_D78: 1,
      PARTSSERVICEINVESTMENTS_C89: '$' + 100000,
      PARTSSERVICEINVESTMENTS_D89: 1,
      PARTSSERVICEINVESTMENTS_C100: '$' + 50000,
      PARTSSERVICEINVESTMENTS_D100: 1,
      PARTSSERVICEINVESTMENTS_D90C:1,
      PARTSSERVICEINVESTMENTS_E90C:739,

      PARTSSERVICEINVESTMENTS_G9: 0,
       PARTSSERVICEINVESTMENTS_C90C:739,
       PARTSSERVICEINVESTMENTS_C891: '$' + 100739,
       PARTSSERVICEINVESTMENTS_C44C: '$' +this.PARTSSERVICEINVESTMENTS_C44C,
       PARTSSERVICEINVESTMENTS_C45C : '$' + this.PARTSSERVICEINVESTMENTS_C45C,
       PARTSSERVICEINVESTMENTS_C46C : '$' + this.PARTSSERVICEINVESTMENTS_C46C,
       PARTSSERVICEINVESTMENTS_C47C : '$' + this.PARTSSERVICEINVESTMENTS_C47C,
       PARTSSERVICEINVESTMENTS_C49C : '$' + this.PARTSSERVICEINVESTMENTS_C49C,
       PARTSSERVICEINVESTMENTS_C51C : '$' + this.PARTSSERVICEINVESTMENTS_C51C,
       PARTSSERVICEINVESTMENTS_C52C : '$' + this.PARTSSERVICEINVESTMENTS_C52C,
       PARTSSERVICEINVESTMENTS_C891C : '$' + this.PARTSSERVICEINVESTMENTS_C891C,
       PARTSSERVICEINVESTMENTS_C100C : '$' + this.PARTSSERVICEINVESTMENTS_C100C,
       PARTSSERVICEINVESTMENTS_C89C :  '$' +this.PARTSSERVICEINVESTMENTS_C89C,
              PARTSSERVICEINVESTMENTS_C68C : '$' + this.PARTSSERVICEINVESTMENTS_C68C,
       PARTSSERVICEINVESTMENTS_C67C : '$' +this.PARTSSERVICEINVESTMENTS_C67C,
       PARTSSERVICEINVESTMENTS_C59C :'$' + this.PARTSSERVICEINVESTMENTS_C59C

       //PARTSSERVICEINVESTMENTS_E88: '$' + 100739
    });
  }

  getDraftData() {
    if (!this.loaderService.isVisible()) {
      this.loaderService.show();
    }
    const userdata = this.authService.getCurrentUser().data;

    const requestData = {
      userId: userdata.userId,
      draft: this.apiService.formData,
      createdBy: userdata.username,
      updatedBy: userdata.username,
    };

    this.apiService.previousPageData(requestData).subscribe(
      (response) => {
        if (response.status === 'success') {
          this.draftData =
            response.data.draft && response.data.draft.draft
              ? JSON.parse(response.data.draft.draft)
              : {};
          this.partialData = isEmpty(response.data.partial)
            ? this.draftData
            : response.data.partial;
          this.calculatedData = this.partialData;
          if (!isEmpty(this.draftData)) {
            this.draftModal = new Modal(this.draftModalRef.nativeElement, {});
            this.draftModal.show();
          } else {
            this.partsServiceForm.reset();
          }
          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
        }
      },
      (err) => {
        if (this.loaderService.isVisible()) {
          this.loaderService.hide();
        }
        //console.log(err);
      }
    );
  }

  yesDraft() {
    this.apiService.loadFromDraft();
    this.apiService.saveFormData(this.draftData);
    this.assignDraftData(true);
    this.draftModal.hide();
  }

  deleteDraft() {
    this.apiService.discardDraft();
    this.partsServiceForm.reset();
    this.patchDefaultValue();
    this.draftModal.hide();
  }

  assignDraftData(fromDraft: any) {
    if (fromDraft) {
      const formArray = Object.entries(this.partsServiceForm.value);
      formArray.forEach(function (obj: any) {
        obj[0] = obj[0].replace(
          'PARTS&SERVICEINVESTMENTS_',
          'PARTSSERVICEINVESTMENTS_'
        );
      });

      const formObject = Object.fromEntries(formArray);
      this.partialData = formObject;
      this.partsServiceForm.patchValue(this.partialData, { emitEvent: true });
    } else {
      this.partialData=this.replaceKeys(this.partialData)


      const tempData = {...this.partialData, PARTSSERVICEINVESTMENTS_C43: '$' + 384,
      PARTSSERVICEINVESTMENTS_D43: 1,
      PARTSSERVICEINVESTMENTS_C44: '$' + 3472,
      PARTSSERVICEINVESTMENTS_D44: 1,
      PARTSSERVICEINVESTMENTS_C45: '$' + 5100,
      PARTSSERVICEINVESTMENTS_D45: 1,
      PARTSSERVICEINVESTMENTS_C46: '$' + 5880,
      PARTSSERVICEINVESTMENTS_D46: 1,
      PARTSSERVICEINVESTMENTS_C47: '$' + 1800,
      PARTSSERVICEINVESTMENTS_D47: 2,
      PARTSSERVICEINVESTMENTS_C48: '$' + 0,
      PARTSSERVICEINVESTMENTS_D48: 1,
      PARTSSERVICEINVESTMENTS_C49: '$' + 51000,
      PARTSSERVICEINVESTMENTS_D49: 1,
      PARTSSERVICEINVESTMENTS_C51: '$' + 1600,
      PARTSSERVICEINVESTMENTS_D51: 1,
      PARTSSERVICEINVESTMENTS_C52: '$' + 51000,
      PARTSSERVICEINVESTMENTS_D52: 1,
      PARTSSERVICEINVESTMENTS_C56: '$' + 300,
      PARTSSERVICEINVESTMENTS_D56: 1,
      PARTSSERVICEINVESTMENTS_C57: '$' + 40,
      PARTSSERVICEINVESTMENTS_D57: 1,
      PARTSSERVICEINVESTMENTS_C58: '$' + 399,
      PARTSSERVICEINVESTMENTS_D58: 1,
      PARTSSERVICEINVESTMENTS_C59: '$' + 20000,
      PARTSSERVICEINVESTMENTS_D59: 0.5,
      PARTSSERVICEINVESTMENTS_C67: '$' + 5000,
      PARTSSERVICEINVESTMENTS_D67: 1,
      PARTSSERVICEINVESTMENTS_C68: '$' + 1558,
      PARTSSERVICEINVESTMENTS_D68: 1,
      PARTSSERVICEINVESTMENTS_C78: '$' + 600,
      PARTSSERVICEINVESTMENTS_D78: 1,
      PARTSSERVICEINVESTMENTS_C89: '$' + 100000,
      PARTSSERVICEINVESTMENTS_D89: 1,
      PARTSSERVICEINVESTMENTS_C100: '$' + 50000,
      PARTSSERVICEINVESTMENTS_D100: 1,
      PARTSSERVICEINVESTMENTS_D90C:1,
      PARTSSERVICEINVESTMENTS_E90C:739,

      PARTSSERVICEINVESTMENTS_G9: 0,
       PARTSSERVICEINVESTMENTS_C90C:'$' +739,
       PARTSSERVICEINVESTMENTS_C891: '$' + 100739,
       PARTSSERVICEINVESTMENTS_C44C: '$' +this.PARTSSERVICEINVESTMENTS_C44C,
       PARTSSERVICEINVESTMENTS_C45C : '$' + this.PARTSSERVICEINVESTMENTS_C45C,
       PARTSSERVICEINVESTMENTS_C46C : '$' + this.PARTSSERVICEINVESTMENTS_C46C,
       PARTSSERVICEINVESTMENTS_C47C : '$' + this.PARTSSERVICEINVESTMENTS_C47C,
       PARTSSERVICEINVESTMENTS_C49C : '$' + this.PARTSSERVICEINVESTMENTS_C49C,
       PARTSSERVICEINVESTMENTS_C51C : '$' + this.PARTSSERVICEINVESTMENTS_C51C,
       PARTSSERVICEINVESTMENTS_C52C : '$' + this.PARTSSERVICEINVESTMENTS_C52C,
       PARTSSERVICEINVESTMENTS_C891C : '$' + this.PARTSSERVICEINVESTMENTS_C891C,
       PARTSSERVICEINVESTMENTS_C100C : '$' + this.PARTSSERVICEINVESTMENTS_C100C,
       PARTSSERVICEINVESTMENTS_C89C :  '$' +this.PARTSSERVICEINVESTMENTS_C89C,
      PARTSSERVICEINVESTMENTS_C68C : '$' + this.PARTSSERVICEINVESTMENTS_C68C,
       PARTSSERVICEINVESTMENTS_C67C : '$' +this.PARTSSERVICEINVESTMENTS_C67C,
       PARTSSERVICEINVESTMENTS_C59C :'$' + this.PARTSSERVICEINVESTMENTS_C59C
      }
      this.partsServiceForm.patchValue(tempData, { emitEvent: true });
      this.partsServiceForm
        .get('PARTSSERVICEINVESTMENTS_C9')
        ?.setValue(
          Math.round((this.partialData.PARTSSERVICEINVESTMENTS_C9||1) * 100) 
        );
      this.partsServiceForm
        .get('PARTSSERVICEINVESTMENTS_C13')
        ?.setValue(
          Math.round((this.partialData.PARTSSERVICEINVESTMENTS_C13||1) * 100) 
        );
      this.partsServiceForm
        .get('PARTSSERVICEINVESTMENTS_C17')
        ?.setValue(
          Math.round((this.partialData.PARTSSERVICEINVESTMENTS_C17||1) * 100) 
        );
      this.partsServiceForm
        .get('PARTSSERVICEINVESTMENTS_C25')
        ?.setValue(
          Math.round((this.partialData.PARTSSERVICEINVESTMENTS_C25||0) * 100) 
        );
       
    }
    this.partsServiceForm.updateValueAndValidity({ emitEvent: true });
    this.cdr.detectChanges();
  }
   replaceKeys(obj: any): any {
    const newObj: any = {};

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newKey = key.replace( 'PARTS&SERVICEINVESTMENTS_',
        'PARTSSERVICEINVESTMENTS_');
        newObj[newKey] = obj[key];
      }
    }

    return newObj;
  }
  createPartsServiceForm() {
    this.partsServiceForm = this.fb.group({
      PARTSSERVICEINVESTMENTS_H8: ['', Validators.required],
      PARTSSERVICEINVESTMENTS_I8: ['', Validators.required],
      PARTSSERVICEINVESTMENTS_J8: ['', Validators.required],
      PARTSSERVICEINVESTMENTS_K8: ['', Validators.required],
      PARTSSERVICEINVESTMENTS_L8: ['', Validators.required],
      PARTSSERVICEINVESTMENTS_M8: ['', Validators.required],
      PARTSSERVICEINVESTMENTS_N8: ['', Validators.required],
      PARTSSERVICEINVESTMENTS_O8: ['', Validators.required],

      PARTSSERVICEINVESTMENTS_C9: [
        '',
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      PARTSSERVICEINVESTMENTS_H9: [''],
      PARTSSERVICEINVESTMENTS_I9: [''],
      PARTSSERVICEINVESTMENTS_J9: [''],
            PARTSSERVICEINVESTMENTS_K9: [''],
      PARTSSERVICEINVESTMENTS_L9: [''],
      PARTSSERVICEINVESTMENTS_M9: [''],
      PARTSSERVICEINVESTMENTS_N9: [''],
      PARTSSERVICEINVESTMENTS_O9: [''],
      PARTSSERVICEINVESTMENTS_F9: [''],
      PARTSSERVICEINVESTMENTS_G9: [''],

      PARTSSERVICEINVESTMENTS_C10: [''],
      PARTSSERVICEINVESTMENTS_F10: [''],
      PARTSSERVICEINVESTMENTS_G10: [''],
      PARTSSERVICEINVESTMENTS_H10: [''],
      PARTSSERVICEINVESTMENTS_I10: [''],
      PARTSSERVICEINVESTMENTS_J10: [''],
      PARTSSERVICEINVESTMENTS_K10: [''],
      PARTSSERVICEINVESTMENTS_L10: [''],
      PARTSSERVICEINVESTMENTS_M10: [''],
      PARTSSERVICEINVESTMENTS_N10: [''],
      PARTSSERVICEINVESTMENTS_O10: [''],

      PARTSSERVICEINVESTMENTS_C12: ['', Validators.required],
      PARTSSERVICEINVESTMENTS_F12: [''],
      PARTSSERVICEINVESTMENTS_G12: [''],
      PARTSSERVICEINVESTMENTS_O12: [''],

      PARTSSERVICEINVESTMENTS_C13: [
        '',
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      PARTSSERVICEINVESTMENTS_H13: [''],
      PARTSSERVICEINVESTMENTS_G13: [''],
      PARTSSERVICEINVESTMENTS_F13: [''],
      PARTSSERVICEINVESTMENTS_I13: [''],
      PARTSSERVICEINVESTMENTS_J13: [''],
      PARTSSERVICEINVESTMENTS_K13: [''],
      PARTSSERVICEINVESTMENTS_L13: [''],
      PARTSSERVICEINVESTMENTS_M13: [''],
      PARTSSERVICEINVESTMENTS_N13: [''],
      PARTSSERVICEINVESTMENTS_O13: [''],

      PARTSSERVICEINVESTMENTS_F14: [''],
      PARTSSERVICEINVESTMENTS_G14: [''],
      PARTSSERVICEINVESTMENTS_H14: [''],
      PARTSSERVICEINVESTMENTS_I14: [''],
      PARTSSERVICEINVESTMENTS_J14: [''],
      PARTSSERVICEINVESTMENTS_K14: [''],
      PARTSSERVICEINVESTMENTS_L14: [''],
      PARTSSERVICEINVESTMENTS_M14: [''],
      PARTSSERVICEINVESTMENTS_N14: [''],
      PARTSSERVICEINVESTMENTS_O14: [''],

      PARTSSERVICEINVESTMENTS_C16: ['', Validators.required],
      PARTSSERVICEINVESTMENTS_F16: [''],
      PARTSSERVICEINVESTMENTS_G16: [''],
      PARTSSERVICEINVESTMENTS_O16: [''],

      PARTSSERVICEINVESTMENTS_C17: [
        '',
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      PARTSSERVICEINVESTMENTS_H17: [''],
      PARTSSERVICEINVESTMENTS_I17: [''],
      PARTSSERVICEINVESTMENTS_J17: [''],
      PARTSSERVICEINVESTMENTS_K17: [''],
      PARTSSERVICEINVESTMENTS_L17: [''],
      PARTSSERVICEINVESTMENTS_M17: [''],
      PARTSSERVICEINVESTMENTS_N17: [''],
      PARTSSERVICEINVESTMENTS_O17: [''],
      PARTSSERVICEINVESTMENTS_F17: [''],
      PARTSSERVICEINVESTMENTS_G17: [''],

      PARTSSERVICEINVESTMENTS_F18: [''],
      PARTSSERVICEINVESTMENTS_G18: [''],
      PARTSSERVICEINVESTMENTS_H18: [''],
      PARTSSERVICEINVESTMENTS_I18: [''],
      PARTSSERVICEINVESTMENTS_J18: [''],
      PARTSSERVICEINVESTMENTS_K18: [''],
      PARTSSERVICEINVESTMENTS_L18: [''],
      PARTSSERVICEINVESTMENTS_M18: [''],
      PARTSSERVICEINVESTMENTS_N18: [''],
      PARTSSERVICEINVESTMENTS_O18: [''],
      PARTSSERVICEINVESTMENTS_C891:[''],

      PARTSSERVICEINVESTMENTS_H21: [''],
      PARTSSERVICEINVESTMENTS_I21: [''],
      PARTSSERVICEINVESTMENTS_J21: [''],
      PARTSSERVICEINVESTMENTS_K21: [''],
      PARTSSERVICEINVESTMENTS_L21: [''],
      PARTSSERVICEINVESTMENTS_M21: [''],
      PARTSSERVICEINVESTMENTS_N21: [''],
      PARTSSERVICEINVESTMENTS_O21: [''],

      PARTSSERVICEINVESTMENTS_F22: [''],
      PARTSSERVICEINVESTMENTS_G22: [''],
      PARTSSERVICEINVESTMENTS_H22: [''],
      PARTSSERVICEINVESTMENTS_I22: [''],
      PARTSSERVICEINVESTMENTS_J22: [''],
      PARTSSERVICEINVESTMENTS_K22: [''],
      PARTSSERVICEINVESTMENTS_L22: [''],
      PARTSSERVICEINVESTMENTS_M22: [''],
      PARTSSERVICEINVESTMENTS_N22: [''],
      PARTSSERVICEINVESTMENTS_O22: [''],
      PARTSSERVICEINVESTMENTS_C90C:[''],
      PARTSSERVICEINVESTMENTS_E90C:[''],
      PARTSSERVICEINVESTMENTS_D90C:[''],
      PARTSSERVICEINVESTMENTS_F90C: [''],
      PARTSSERVICEINVESTMENTS_G90C: [''],
      PARTSSERVICEINVESTMENTS_H90C: [''],
      PARTSSERVICEINVESTMENTS_I90C: [''],
      PARTSSERVICEINVESTMENTS_J90C: [''],
      PARTSSERVICEINVESTMENTS_K90C: [''],
      PARTSSERVICEINVESTMENTS_L90C: [''],
      PARTSSERVICEINVESTMENTS_M90C: [''],
      PARTSSERVICEINVESTMENTS_N90C: [''],
      PARTSSERVICEINVESTMENTS_O90C: [''],
      PARTSSERVICEINVESTMENTS_P90C: [''],

      PARTSSERVICEINVESTMENTS_F24: [''],
      PARTSSERVICEINVESTMENTS_G24: [''],
      PARTSSERVICEINVESTMENTS_O24: [''],

      PARTSSERVICEINVESTMENTS_C25: [
        '',
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      PARTSSERVICEINVESTMENTS_F25: [''],
      PARTSSERVICEINVESTMENTS_G25: [''],
      PARTSSERVICEINVESTMENTS_H25: [''],
      PARTSSERVICEINVESTMENTS_I25: [''],
      PARTSSERVICEINVESTMENTS_J25: [''],
      PARTSSERVICEINVESTMENTS_K25: [''],
      PARTSSERVICEINVESTMENTS_L25: [''],
      PARTSSERVICEINVESTMENTS_M25: [''],
      PARTSSERVICEINVESTMENTS_N25: [''],
      PARTSSERVICEINVESTMENTS_O25: [''],

      PARTSSERVICEINVESTMENTS_F26: [''],
      PARTSSERVICEINVESTMENTS_G26: [''],
      PARTSSERVICEINVESTMENTS_H26: [''],
      PARTSSERVICEINVESTMENTS_I26: [''],
      PARTSSERVICEINVESTMENTS_J26: [''],
      PARTSSERVICEINVESTMENTS_K26: [''],
      PARTSSERVICEINVESTMENTS_L26: [''],
      PARTSSERVICEINVESTMENTS_M26: [''],
      PARTSSERVICEINVESTMENTS_N26: [''],
      PARTSSERVICEINVESTMENTS_O26: [''],

      PARTSSERVICEINVESTMENTS_F29: [''],
      PARTSSERVICEINVESTMENTS_G29: [''],
      PARTSSERVICEINVESTMENTS_H29: [''],
      PARTSSERVICEINVESTMENTS_I29: [''],
      PARTSSERVICEINVESTMENTS_J29: [''],
      PARTSSERVICEINVESTMENTS_K29: [''],
      PARTSSERVICEINVESTMENTS_L29: [''],
      PARTSSERVICEINVESTMENTS_M29: [''],
      PARTSSERVICEINVESTMENTS_N29: [''],
      PARTSSERVICEINVESTMENTS_O29: [''],

      PARTSSERVICEINVESTMENTS_F30: [''],
      PARTSSERVICEINVESTMENTS_G30: [''],
      PARTSSERVICEINVESTMENTS_H30: [''],
      PARTSSERVICEINVESTMENTS_I30: [''],
      PARTSSERVICEINVESTMENTS_J30: [''],
      PARTSSERVICEINVESTMENTS_K30: [''],
      PARTSSERVICEINVESTMENTS_L30: [''],
      PARTSSERVICEINVESTMENTS_M30: [''],
      PARTSSERVICEINVESTMENTS_N30: [''],
      PARTSSERVICEINVESTMENTS_O30: [''],

      PARTSSERVICEINVESTMENTS_C32: ['', Validators.required],

      PARTSSERVICEINVESTMENTS_F35: [''],
      PARTSSERVICEINVESTMENTS_G35: [''],
      PARTSSERVICEINVESTMENTS_H35: [''],
      PARTSSERVICEINVESTMENTS_I35: [''],
      PARTSSERVICEINVESTMENTS_J35: [''],
      PARTSSERVICEINVESTMENTS_K35: [''],
      PARTSSERVICEINVESTMENTS_L35: [''],
      PARTSSERVICEINVESTMENTS_M35: [''],
      PARTSSERVICEINVESTMENTS_N35: [''],
      PARTSSERVICEINVESTMENTS_O35: [''],
      PARTSSERVICEINVESTMENTS_P35: [''],

      PARTSSERVICEINVESTMENTS_H33: [''],
      PARTSSERVICEINVESTMENTS_K33: [''],
      PARTSSERVICEINVESTMENTS_O33: [''],

      PARTSSERVICEINVESTMENTS_H37: [''],
      PARTSSERVICEINVESTMENTS_K37: [''],
      PARTSSERVICEINVESTMENTS_O37: [''],

      PARTSSERVICEINVESTMENTS_F39: [''],
      PARTSSERVICEINVESTMENTS_G39: [''],
      PARTSSERVICEINVESTMENTS_H39: [''],
      PARTSSERVICEINVESTMENTS_I39: [''],
      PARTSSERVICEINVESTMENTS_J39: [''],
      PARTSSERVICEINVESTMENTS_K39: [''],
      PARTSSERVICEINVESTMENTS_L39: [''],
      PARTSSERVICEINVESTMENTS_M39: [''],
      PARTSSERVICEINVESTMENTS_N39: [''],
      PARTSSERVICEINVESTMENTS_O39: [''],
      PARTSSERVICEINVESTMENTS_P39: [''],

      PARTSSERVICEINVESTMENTS_E42: [''],
      PARTSSERVICEINVESTMENTS_F42: [''],
      PARTSSERVICEINVESTMENTS_G42: [''],
      PARTSSERVICEINVESTMENTS_H42: [''],
      PARTSSERVICEINVESTMENTS_I42: [''],
      PARTSSERVICEINVESTMENTS_J42: [''],
      PARTSSERVICEINVESTMENTS_K42: [''],
      PARTSSERVICEINVESTMENTS_L42: [''],
      PARTSSERVICEINVESTMENTS_M42: [''],
      PARTSSERVICEINVESTMENTS_N42: [''],
      PARTSSERVICEINVESTMENTS_O42: [''],
      PARTSSERVICEINVESTMENTS_P42: [''],

      PARTSSERVICEINVESTMENTS_C43: [''],
      PARTSSERVICEINVESTMENTS_D43: [''],
      PARTSSERVICEINVESTMENTS_E43: [''],
      PARTSSERVICEINVESTMENTS_F43: [''],
      PARTSSERVICEINVESTMENTS_G43: [''],
      PARTSSERVICEINVESTMENTS_H43: [''],
      PARTSSERVICEINVESTMENTS_I43: [''],
      PARTSSERVICEINVESTMENTS_J43: [''],
      PARTSSERVICEINVESTMENTS_K43: [''],
      PARTSSERVICEINVESTMENTS_L43: [''],
      PARTSSERVICEINVESTMENTS_M43: [''],
      PARTSSERVICEINVESTMENTS_N43: [''],
      PARTSSERVICEINVESTMENTS_O43: [''],
      PARTSSERVICEINVESTMENTS_P43: [''],
      PARTSSERVICEINVESTMENTS_C44: [''],
      PARTSSERVICEINVESTMENTS_D44: [''],
      PARTSSERVICEINVESTMENTS_E44: [''],
      PARTSSERVICEINVESTMENTS_F44: [''],
      PARTSSERVICEINVESTMENTS_G44: [''],
      PARTSSERVICEINVESTMENTS_H44: [''],
      PARTSSERVICEINVESTMENTS_I44: [''],
      PARTSSERVICEINVESTMENTS_J44: [''],
      PARTSSERVICEINVESTMENTS_K44: [''],
      PARTSSERVICEINVESTMENTS_L44: [''],
      PARTSSERVICEINVESTMENTS_M44: [''],
      PARTSSERVICEINVESTMENTS_N44: [''],
      PARTSSERVICEINVESTMENTS_O44: [''],
      PARTSSERVICEINVESTMENTS_P44: [''],

      PARTSSERVICEINVESTMENTS_C45: [''],
      PARTSSERVICEINVESTMENTS_D45: [''],
      PARTSSERVICEINVESTMENTS_E45: [''],
      PARTSSERVICEINVESTMENTS_F45: [''],
      PARTSSERVICEINVESTMENTS_G45: [''],
      PARTSSERVICEINVESTMENTS_H45: [''],
      PARTSSERVICEINVESTMENTS_I45: [''],
      PARTSSERVICEINVESTMENTS_J45: [''],
      PARTSSERVICEINVESTMENTS_K45: [''],
      PARTSSERVICEINVESTMENTS_L45: [''],
      PARTSSERVICEINVESTMENTS_M45: [''],
      PARTSSERVICEINVESTMENTS_N45: [''],
      PARTSSERVICEINVESTMENTS_O45: [''],
      PARTSSERVICEINVESTMENTS_P45: [''],

      PARTSSERVICEINVESTMENTS_C46: [''],
      PARTSSERVICEINVESTMENTS_D46: [''],
      PARTSSERVICEINVESTMENTS_E46: [''],
      PARTSSERVICEINVESTMENTS_F46: [''],
      PARTSSERVICEINVESTMENTS_G46: [''],
      PARTSSERVICEINVESTMENTS_H46: [''],
      PARTSSERVICEINVESTMENTS_I46: [''],
      PARTSSERVICEINVESTMENTS_J46: [''],
      PARTSSERVICEINVESTMENTS_K46: [''],
      PARTSSERVICEINVESTMENTS_L46: [''],
      PARTSSERVICEINVESTMENTS_M46: [''],
      PARTSSERVICEINVESTMENTS_N46: [''],
      PARTSSERVICEINVESTMENTS_O46: [''],
      PARTSSERVICEINVESTMENTS_P46: [''],

      PARTSSERVICEINVESTMENTS_C47: [''],
      PARTSSERVICEINVESTMENTS_D47: [''],
      PARTSSERVICEINVESTMENTS_E47: [''],
      PARTSSERVICEINVESTMENTS_F47: [''],
      PARTSSERVICEINVESTMENTS_G47: [''],
      PARTSSERVICEINVESTMENTS_H47: [''],
      PARTSSERVICEINVESTMENTS_I47: [''],
      PARTSSERVICEINVESTMENTS_J47: [''],
      PARTSSERVICEINVESTMENTS_K47: [''],
      PARTSSERVICEINVESTMENTS_L47: [''],
      PARTSSERVICEINVESTMENTS_M47: [''],
      PARTSSERVICEINVESTMENTS_N47: [''],
      PARTSSERVICEINVESTMENTS_O47: [''],
      PARTSSERVICEINVESTMENTS_P47: [''],

      PARTSSERVICEINVESTMENTS_C48: [''],
      PARTSSERVICEINVESTMENTS_D48: [''],
      PARTSSERVICEINVESTMENTS_E48: [''],
      PARTSSERVICEINVESTMENTS_F48: [''],
      PARTSSERVICEINVESTMENTS_G48: [''],
      PARTSSERVICEINVESTMENTS_H48: [''],
      PARTSSERVICEINVESTMENTS_I48: [''],
      PARTSSERVICEINVESTMENTS_J48: [''],
      PARTSSERVICEINVESTMENTS_K48: [''],
      PARTSSERVICEINVESTMENTS_L48: [''],
      PARTSSERVICEINVESTMENTS_M48: [''],
      PARTSSERVICEINVESTMENTS_N48: [''],
      PARTSSERVICEINVESTMENTS_O48: [''],
      PARTSSERVICEINVESTMENTS_P48: [''],

      PARTSSERVICEINVESTMENTS_C49: [''],
      PARTSSERVICEINVESTMENTS_D49: [''],
      PARTSSERVICEINVESTMENTS_E49: [''],
      PARTSSERVICEINVESTMENTS_F49: [''],
      PARTSSERVICEINVESTMENTS_G49: [''],
      PARTSSERVICEINVESTMENTS_H49: [''],
      PARTSSERVICEINVESTMENTS_I49: [''],
      PARTSSERVICEINVESTMENTS_J49: [''],
      PARTSSERVICEINVESTMENTS_K49: [''],
      PARTSSERVICEINVESTMENTS_L49: [''],
      PARTSSERVICEINVESTMENTS_M49: [''],
      PARTSSERVICEINVESTMENTS_N49: [''],
      PARTSSERVICEINVESTMENTS_O49: [''],
      PARTSSERVICEINVESTMENTS_P49: [''],

      PARTSSERVICEINVESTMENTS_C51: [''],
      PARTSSERVICEINVESTMENTS_D51: [''],
      PARTSSERVICEINVESTMENTS_E51: [''],
      PARTSSERVICEINVESTMENTS_F51: [''],
      PARTSSERVICEINVESTMENTS_G51: [''],
      PARTSSERVICEINVESTMENTS_H51: [''],
      PARTSSERVICEINVESTMENTS_I51: [''],
      PARTSSERVICEINVESTMENTS_J51: [''],
      PARTSSERVICEINVESTMENTS_K51: [''],
      PARTSSERVICEINVESTMENTS_L51: [''],
      PARTSSERVICEINVESTMENTS_M51: [''],
      PARTSSERVICEINVESTMENTS_N51: [''],
      PARTSSERVICEINVESTMENTS_O51: [''],
      PARTSSERVICEINVESTMENTS_P51: [''],

      PARTSSERVICEINVESTMENTS_C52: [''],
      PARTSSERVICEINVESTMENTS_D52: [''],
      PARTSSERVICEINVESTMENTS_E52: [''],
      PARTSSERVICEINVESTMENTS_F52: [''],
      PARTSSERVICEINVESTMENTS_G52: [''],
      PARTSSERVICEINVESTMENTS_H52: [''],
      PARTSSERVICEINVESTMENTS_I52: [''],
      PARTSSERVICEINVESTMENTS_J52: [''],
      PARTSSERVICEINVESTMENTS_K52: [''],
      PARTSSERVICEINVESTMENTS_L52: [''],
      PARTSSERVICEINVESTMENTS_M52: [''],
      PARTSSERVICEINVESTMENTS_N52: [''],
      PARTSSERVICEINVESTMENTS_O52: [''],
      PARTSSERVICEINVESTMENTS_P52: [''],

      PARTSSERVICEINVESTMENTS_E55: [''],
      PARTSSERVICEINVESTMENTS_F55: [''],
      PARTSSERVICEINVESTMENTS_G55: [''],
      PARTSSERVICEINVESTMENTS_H55: [''],
      PARTSSERVICEINVESTMENTS_I55: [''],
      PARTSSERVICEINVESTMENTS_J55: [''],
      PARTSSERVICEINVESTMENTS_K55: [''],
      PARTSSERVICEINVESTMENTS_L55: [''],
      PARTSSERVICEINVESTMENTS_M55: [''],
      PARTSSERVICEINVESTMENTS_N55: [''],
      PARTSSERVICEINVESTMENTS_O55: [''],
      PARTSSERVICEINVESTMENTS_P55: [''],

      PARTSSERVICEINVESTMENTS_C56: [''],
      PARTSSERVICEINVESTMENTS_D56: [''],
      PARTSSERVICEINVESTMENTS_E56: [''],
      PARTSSERVICEINVESTMENTS_F56: [''],
      PARTSSERVICEINVESTMENTS_G56: [''],
      PARTSSERVICEINVESTMENTS_H56: [''],
      PARTSSERVICEINVESTMENTS_I56: [''],
      PARTSSERVICEINVESTMENTS_J56: [''],
      PARTSSERVICEINVESTMENTS_K56: [''],
      PARTSSERVICEINVESTMENTS_L56: [''],
      PARTSSERVICEINVESTMENTS_M56: [''],
      PARTSSERVICEINVESTMENTS_N56: [''],
      PARTSSERVICEINVESTMENTS_O56: [''],
      PARTSSERVICEINVESTMENTS_P56: [''],

      PARTSSERVICEINVESTMENTS_C57: [''],
      PARTSSERVICEINVESTMENTS_D57: [''],
      PARTSSERVICEINVESTMENTS_E57: [''],
      PARTSSERVICEINVESTMENTS_F57: [''],
      PARTSSERVICEINVESTMENTS_G57: [''],
      PARTSSERVICEINVESTMENTS_H57: [''],
      PARTSSERVICEINVESTMENTS_I57: [''],
      PARTSSERVICEINVESTMENTS_J57: [''],
      PARTSSERVICEINVESTMENTS_K57: [''],
      PARTSSERVICEINVESTMENTS_L57: [''],
      PARTSSERVICEINVESTMENTS_M57: [''],
      PARTSSERVICEINVESTMENTS_N57: [''],
      PARTSSERVICEINVESTMENTS_O57: [''],
      PARTSSERVICEINVESTMENTS_P57: [''],

      PARTSSERVICEINVESTMENTS_C58: [''],
      PARTSSERVICEINVESTMENTS_D58: [''],
      PARTSSERVICEINVESTMENTS_E58: [''],
      PARTSSERVICEINVESTMENTS_F58: [''],
      PARTSSERVICEINVESTMENTS_G58: [''],
      PARTSSERVICEINVESTMENTS_H58: [''],
      PARTSSERVICEINVESTMENTS_I58: [''],
      PARTSSERVICEINVESTMENTS_J58: [''],
      PARTSSERVICEINVESTMENTS_K58: [''],
      PARTSSERVICEINVESTMENTS_L58: [''],
      PARTSSERVICEINVESTMENTS_M58: [''],
      PARTSSERVICEINVESTMENTS_N58: [''],
      PARTSSERVICEINVESTMENTS_O58: [''],
      PARTSSERVICEINVESTMENTS_P58: [''],

      PARTSSERVICEINVESTMENTS_C59: [''],
      PARTSSERVICEINVESTMENTS_D59: [''],
      PARTSSERVICEINVESTMENTS_E59: [''],
      PARTSSERVICEINVESTMENTS_F59: [''],
      PARTSSERVICEINVESTMENTS_G59: [''],
      PARTSSERVICEINVESTMENTS_H59: [''],
      PARTSSERVICEINVESTMENTS_I59: [''],
      PARTSSERVICEINVESTMENTS_J59: [''],
      PARTSSERVICEINVESTMENTS_K59: [''],
      PARTSSERVICEINVESTMENTS_L59: [''],
      PARTSSERVICEINVESTMENTS_M59: [''],
      PARTSSERVICEINVESTMENTS_N59: [''],
      PARTSSERVICEINVESTMENTS_O59: [''],
      PARTSSERVICEINVESTMENTS_P59: [''],

      PARTSSERVICEINVESTMENTS_E60: [''],

      PARTSSERVICEINVESTMENTS_E61: [''],

      PARTSSERVICEINVESTMENTS_E66: [''],
      PARTSSERVICEINVESTMENTS_F66: [''],
      PARTSSERVICEINVESTMENTS_G66: [''],
      PARTSSERVICEINVESTMENTS_H66: [''],
      PARTSSERVICEINVESTMENTS_I66: [''],
      PARTSSERVICEINVESTMENTS_J66: [''],
      PARTSSERVICEINVESTMENTS_K66: [''],
      PARTSSERVICEINVESTMENTS_L66: [''],
      PARTSSERVICEINVESTMENTS_M66: [''],
      PARTSSERVICEINVESTMENTS_N66: [''],
      PARTSSERVICEINVESTMENTS_O66: [''],
      PARTSSERVICEINVESTMENTS_P66: [''],

      PARTSSERVICEINVESTMENTS_C67: [''],
      PARTSSERVICEINVESTMENTS_D67: [''],
      PARTSSERVICEINVESTMENTS_E67: [''],
      PARTSSERVICEINVESTMENTS_F67: [''],
      PARTSSERVICEINVESTMENTS_G67: [''],
      PARTSSERVICEINVESTMENTS_H67: [''],
      PARTSSERVICEINVESTMENTS_I67: [''],
      PARTSSERVICEINVESTMENTS_J67: [''],
      PARTSSERVICEINVESTMENTS_K67: [''],
      PARTSSERVICEINVESTMENTS_L67: [''],
      PARTSSERVICEINVESTMENTS_M67: [''],
      PARTSSERVICEINVESTMENTS_N67: [''],
      PARTSSERVICEINVESTMENTS_O67: [''],
      PARTSSERVICEINVESTMENTS_P67: [''],

      PARTSSERVICEINVESTMENTS_C891C : [''],
      PARTSSERVICEINVESTMENTS_C100C : [''],
      PARTSSERVICEINVESTMENTS_C89C: [''],
            PARTSSERVICEINVESTMENTS_C68C : [''],
      PARTSSERVICEINVESTMENTS_C67C : [''],
      PARTSSERVICEINVESTMENTS_C59C : [''],
      PARTSSERVICEINVESTMENTS_C52C : [''],
      PARTSSERVICEINVESTMENTS_C51C : [''],
      PARTSSERVICEINVESTMENTS_C49C : [''],
      PARTSSERVICEINVESTMENTS_C47C : [''],
      PARTSSERVICEINVESTMENTS_C46C : [''],
      PARTSSERVICEINVESTMENTS_C45C : [''],
      PARTSSERVICEINVESTMENTS_C44C : [''],

      PARTSSERVICEINVESTMENTS_C68: [''],
      PARTSSERVICEINVESTMENTS_D68: [''],
      PARTSSERVICEINVESTMENTS_E68: [''],
      PARTSSERVICEINVESTMENTS_F68: [''],
      PARTSSERVICEINVESTMENTS_G68: [''],
      PARTSSERVICEINVESTMENTS_H68: [''],
      PARTSSERVICEINVESTMENTS_I68: [''],
      PARTSSERVICEINVESTMENTS_J68: [''],
      PARTSSERVICEINVESTMENTS_K68: [''],
      PARTSSERVICEINVESTMENTS_L68: [''],
      PARTSSERVICEINVESTMENTS_M68: [''],
      PARTSSERVICEINVESTMENTS_N68: [''],
      PARTSSERVICEINVESTMENTS_O68: [''],
      PARTSSERVICEINVESTMENTS_P68: [''],

      PARTSSERVICEINVESTMENTS_E69: [''],
      PARTSSERVICEINVESTMENTS_F69: [''],

      PARTSSERVICEINVESTMENTS_E70: [''],
      PARTSSERVICEINVESTMENTS_F70: [''],

      PARTSSERVICEINVESTMENTS_E77: [''],
      PARTSSERVICEINVESTMENTS_F77: [''],
      PARTSSERVICEINVESTMENTS_G77: [''],
      PARTSSERVICEINVESTMENTS_H77: [''],
      PARTSSERVICEINVESTMENTS_I77: [''],
      PARTSSERVICEINVESTMENTS_J77: [''],
      PARTSSERVICEINVESTMENTS_K77: [''],
      PARTSSERVICEINVESTMENTS_L77: [''],
      PARTSSERVICEINVESTMENTS_M77: [''],
      PARTSSERVICEINVESTMENTS_N77: [''],
      PARTSSERVICEINVESTMENTS_O77: [''],
      PARTSSERVICEINVESTMENTS_P77: [''],

      PARTSSERVICEINVESTMENTS_C78: [''],
      PARTSSERVICEINVESTMENTS_D78: [''],
      PARTSSERVICEINVESTMENTS_E78: [''],
      PARTSSERVICEINVESTMENTS_F78: [''],
      PARTSSERVICEINVESTMENTS_G78: [''],
      PARTSSERVICEINVESTMENTS_H78: [''],
      PARTSSERVICEINVESTMENTS_I78: [''],
      PARTSSERVICEINVESTMENTS_J78: [''],
      PARTSSERVICEINVESTMENTS_K78: [''],
      PARTSSERVICEINVESTMENTS_L78: [''],
      PARTSSERVICEINVESTMENTS_M78: [''],
      PARTSSERVICEINVESTMENTS_N78: [''],
      PARTSSERVICEINVESTMENTS_O78: [''],
      PARTSSERVICEINVESTMENTS_P78: [''],

      PARTSSERVICEINVESTMENTS_E79: [''],

      PARTSSERVICEINVESTMENTS_E80: [''],

      PARTSSERVICEINVESTMENTS_E88: [''],
      PARTSSERVICEINVESTMENTS_F88: [''],
      PARTSSERVICEINVESTMENTS_G88: [''],
      PARTSSERVICEINVESTMENTS_H88: [''],
      PARTSSERVICEINVESTMENTS_I88: [''],
      PARTSSERVICEINVESTMENTS_J88: [''],
      PARTSSERVICEINVESTMENTS_K88: [''],
      PARTSSERVICEINVESTMENTS_L88: [''],
      PARTSSERVICEINVESTMENTS_M88: [''],
      PARTSSERVICEINVESTMENTS_N88: [''],
      PARTSSERVICEINVESTMENTS_O88: [''],
      PARTSSERVICEINVESTMENTS_P88: [''],

      PARTSSERVICEINVESTMENTS_C89: [''],
      PARTSSERVICEINVESTMENTS_D89: [''],
      PARTSSERVICEINVESTMENTS_E89: [''],
      PARTSSERVICEINVESTMENTS_F89: [''],
      PARTSSERVICEINVESTMENTS_G89: [''],
      PARTSSERVICEINVESTMENTS_H89: [''],
      PARTSSERVICEINVESTMENTS_I89: [''],
      PARTSSERVICEINVESTMENTS_J89: [''],
      PARTSSERVICEINVESTMENTS_K89: [''],
      PARTSSERVICEINVESTMENTS_L89: [''],
      PARTSSERVICEINVESTMENTS_M89: [''],
      PARTSSERVICEINVESTMENTS_N89: [''],
      PARTSSERVICEINVESTMENTS_O89: [''],
      PARTSSERVICEINVESTMENTS_P89: [''],

      PARTSSERVICEINVESTMENTS_C90: [''],
PARTSSERVICEINVESTMENTS_D90: [''],
      PARTSSERVICEINVESTMENTS_E90: [''],
      PARTSSERVICEINVESTMENTS_F90: [''],
      PARTSSERVICEINVESTMENTS_G90: [''],
      PARTSSERVICEINVESTMENTS_H90: [''],
      PARTSSERVICEINVESTMENTS_I90: [''],
      PARTSSERVICEINVESTMENTS_J90: [''],
      PARTSSERVICEINVESTMENTS_K90: [''],
      PARTSSERVICEINVESTMENTS_L90: [''],
      PARTSSERVICEINVESTMENTS_M90: [''],
      PARTSSERVICEINVESTMENTS_N90: [''],
      PARTSSERVICEINVESTMENTS_O90: [''],
      PARTSSERVICEINVESTMENTS_P90: [''],

      PARTSSERVICEINVESTMENTS_C91: [''],
      PARTSSERVICEINVESTMENTS_D91: [''],
      PARTSSERVICEINVESTMENTS_E91: [''],
      PARTSSERVICEINVESTMENTS_F91: [''],
      PARTSSERVICEINVESTMENTS_G91: [''],
      PARTSSERVICEINVESTMENTS_H91: [''],
      PARTSSERVICEINVESTMENTS_I91: [''],
      PARTSSERVICEINVESTMENTS_J91: [''],
      PARTSSERVICEINVESTMENTS_K91: [''],
      PARTSSERVICEINVESTMENTS_L91: [''],
      PARTSSERVICEINVESTMENTS_M91: [''],
      PARTSSERVICEINVESTMENTS_N91: [''],
      PARTSSERVICEINVESTMENTS_O91: [''],
      PARTSSERVICEINVESTMENTS_P91: [''],

      PARTSSERVICEINVESTMENTS_C92: [''],
      PARTSSERVICEINVESTMENTS_D92: [''],
      PARTSSERVICEINVESTMENTS_E92: [''],
      PARTSSERVICEINVESTMENTS_F92: [''],
      PARTSSERVICEINVESTMENTS_G92: [''],
      PARTSSERVICEINVESTMENTS_H92: [''],
      PARTSSERVICEINVESTMENTS_I92: [''],
      PARTSSERVICEINVESTMENTS_J92: [''],
      PARTSSERVICEINVESTMENTS_K92: [''],
      PARTSSERVICEINVESTMENTS_L92: [''],
      PARTSSERVICEINVESTMENTS_M92: [''],
      PARTSSERVICEINVESTMENTS_N92: [''],
      PARTSSERVICEINVESTMENTS_O92: [''],
      PARTSSERVICEINVESTMENTS_P92: [''],

      PARTSSERVICEINVESTMENTS_E93: [''],

      PARTSSERVICEINVESTMENTS_E94: [''],

      PARTSSERVICEINVESTMENTS_E99: [''],
      PARTSSERVICEINVESTMENTS_F99: [''],
      PARTSSERVICEINVESTMENTS_G99: [''],
      PARTSSERVICEINVESTMENTS_H99: [''],
      PARTSSERVICEINVESTMENTS_I99: [''],
      PARTSSERVICEINVESTMENTS_J99: [''],
      PARTSSERVICEINVESTMENTS_K99: [''],
      PARTSSERVICEINVESTMENTS_L99: [''],
      PARTSSERVICEINVESTMENTS_M99: [''],
      PARTSSERVICEINVESTMENTS_N99: [''],
      PARTSSERVICEINVESTMENTS_O99: [''],
      PARTSSERVICEINVESTMENTS_P99: [''],

      PARTSSERVICEINVESTMENTS_C100: [''],
      PARTSSERVICEINVESTMENTS_D100: [''],
      PARTSSERVICEINVESTMENTS_E100: [''],
      PARTSSERVICEINVESTMENTS_F100: [''],
      PARTSSERVICEINVESTMENTS_G100: [''],
      PARTSSERVICEINVESTMENTS_H100: [''],
      PARTSSERVICEINVESTMENTS_I100: [''],
      PARTSSERVICEINVESTMENTS_J100: [''],
      PARTSSERVICEINVESTMENTS_K100: [''],
      PARTSSERVICEINVESTMENTS_L100: [''],
      PARTSSERVICEINVESTMENTS_M100: [''],
      PARTSSERVICEINVESTMENTS_N100: [''],
      PARTSSERVICEINVESTMENTS_O100: [''],
      PARTSSERVICEINVESTMENTS_P100: [''],

      PARTSSERVICEINVESTMENTS_E101: [''],

      PARTSSERVICEINVESTMENTS_E102: [''],

      PARTSSERVICEINVESTMENTS_E103: [''],

      PARTSSERVICEINVESTMENTS_C118: [''],
      PARTSSERVICEINVESTMENTS_D118: [''],
      PARTSSERVICEINVESTMENTS_E118: [''],
      PARTSSERVICEINVESTMENTS_F118: [''],
      PARTSSERVICEINVESTMENTS_G118: [''],
      PARTSSERVICEINVESTMENTS_H118: [''],
      PARTSSERVICEINVESTMENTS_I118: [''],
      PARTSSERVICEINVESTMENTS_J118: [''],
      PARTSSERVICEINVESTMENTS_K118: [''],
      PARTSSERVICEINVESTMENTS_L118: [''],
      PARTSSERVICEINVESTMENTS_M118: [''],
      PARTSSERVICEINVESTMENTS_N118: [''],
      PARTSSERVICEINVESTMENTS_O118: [''],
      PARTSSERVICEINVESTMENTS_P118: [''],

      PARTSSERVICEINVESTMENTS_C119: [''],
      PARTSSERVICEINVESTMENTS_D119: [''],
      PARTSSERVICEINVESTMENTS_E119: [''],
      PARTSSERVICEINVESTMENTS_F119: [''],
      PARTSSERVICEINVESTMENTS_G119: [''],
      PARTSSERVICEINVESTMENTS_H119: [''],
      PARTSSERVICEINVESTMENTS_I119: [''],
      PARTSSERVICEINVESTMENTS_J119: [''],
      PARTSSERVICEINVESTMENTS_K119: [''],
      PARTSSERVICEINVESTMENTS_L119: [''],
      PARTSSERVICEINVESTMENTS_M119: [''],
      PARTSSERVICEINVESTMENTS_N119: [''],
      PARTSSERVICEINVESTMENTS_O119: [''],
      PARTSSERVICEINVESTMENTS_P119: [''],

      PARTSSERVICEINVESTMENTS_C120: [''],
      PARTSSERVICEINVESTMENTS_D120: [''],
      PARTSSERVICEINVESTMENTS_E120: [''],
      PARTSSERVICEINVESTMENTS_F120: [''],
      PARTSSERVICEINVESTMENTS_G120: [''],
      PARTSSERVICEINVESTMENTS_H120: [''],
      PARTSSERVICEINVESTMENTS_I120: [''],
      PARTSSERVICEINVESTMENTS_J120: [''],
      PARTSSERVICEINVESTMENTS_K120: [''],
      PARTSSERVICEINVESTMENTS_L120: [''],
      PARTSSERVICEINVESTMENTS_M120: [''],
      PARTSSERVICEINVESTMENTS_N120: [''],
      PARTSSERVICEINVESTMENTS_O120: [''],
      PARTSSERVICEINVESTMENTS_P120: [''],

      PARTSSERVICEINVESTMENTS_C121: [''],
      PARTSSERVICEINVESTMENTS_D121: [''],
      PARTSSERVICEINVESTMENTS_E121: [''],
      PARTSSERVICEINVESTMENTS_F121: [''],
      PARTSSERVICEINVESTMENTS_G121: [''],
      PARTSSERVICEINVESTMENTS_H121: [''],
      PARTSSERVICEINVESTMENTS_I121: [''],
      PARTSSERVICEINVESTMENTS_J121: [''],
      PARTSSERVICEINVESTMENTS_K121: [''],
      PARTSSERVICEINVESTMENTS_L121: [''],
      PARTSSERVICEINVESTMENTS_M121: [''],
      PARTSSERVICEINVESTMENTS_N121: [''],
      PARTSSERVICEINVESTMENTS_O121: [''],
      PARTSSERVICEINVESTMENTS_P121: [''],

      PARTSSERVICEINVESTMENTS_C122: [''],
      PARTSSERVICEINVESTMENTS_D122: [''],
      PARTSSERVICEINVESTMENTS_E122: [''],
      PARTSSERVICEINVESTMENTS_F122: [''],
      PARTSSERVICEINVESTMENTS_G122: [''],
      PARTSSERVICEINVESTMENTS_H122: [''],
      PARTSSERVICEINVESTMENTS_I122: [''],
      PARTSSERVICEINVESTMENTS_J122: [''],
      PARTSSERVICEINVESTMENTS_K122: [''],
      PARTSSERVICEINVESTMENTS_L122: [''],
      PARTSSERVICEINVESTMENTS_M122: [''],
      PARTSSERVICEINVESTMENTS_N122: [''],
      PARTSSERVICEINVESTMENTS_O122: [''],
      PARTSSERVICEINVESTMENTS_P122: [''],

      PARTSSERVICEINVESTMENTS_C123: [''],
      PARTSSERVICEINVESTMENTS_D123: [''],
      PARTSSERVICEINVESTMENTS_E123: [''],
      PARTSSERVICEINVESTMENTS_F123: [''],
      PARTSSERVICEINVESTMENTS_G123: [''],
      PARTSSERVICEINVESTMENTS_H123: [''],
      PARTSSERVICEINVESTMENTS_I123: [''],
      PARTSSERVICEINVESTMENTS_J123: [''],
      PARTSSERVICEINVESTMENTS_K123: [''],
      PARTSSERVICEINVESTMENTS_L123: [''],
      PARTSSERVICEINVESTMENTS_M123: [''],
      PARTSSERVICEINVESTMENTS_N123: [''],
      PARTSSERVICEINVESTMENTS_O123: [''],
      PARTSSERVICEINVESTMENTS_P123: [''],

      PARTSSERVICEINVESTMENTS_C124: [''],
      PARTSSERVICEINVESTMENTS_D124: [''],
      PARTSSERVICEINVESTMENTS_E124: [''],
      PARTSSERVICEINVESTMENTS_F124: [''],
      PARTSSERVICEINVESTMENTS_G124: [''],
      PARTSSERVICEINVESTMENTS_H124: [''],
      PARTSSERVICEINVESTMENTS_I124: [''],
      PARTSSERVICEINVESTMENTS_J124: [''],
      PARTSSERVICEINVESTMENTS_K124: [''],
      PARTSSERVICEINVESTMENTS_L124: [''],
      PARTSSERVICEINVESTMENTS_M124: [''],
      PARTSSERVICEINVESTMENTS_N124: [''],
      PARTSSERVICEINVESTMENTS_O124: [''],
      PARTSSERVICEINVESTMENTS_P124: [''],

      PARTSSERVICEINVESTMENTS_C129: [''],
      PARTSSERVICEINVESTMENTS_D129: [''],
      PARTSSERVICEINVESTMENTS_E129: [''],
      PARTSSERVICEINVESTMENTS_F129: [''],
      PARTSSERVICEINVESTMENTS_G129: [''],
      PARTSSERVICEINVESTMENTS_H129: [''],
      PARTSSERVICEINVESTMENTS_I129: [''],
      PARTSSERVICEINVESTMENTS_J129: [''],
      PARTSSERVICEINVESTMENTS_K129: [''],
      PARTSSERVICEINVESTMENTS_L129: [''],
      PARTSSERVICEINVESTMENTS_M129: [''],
      PARTSSERVICEINVESTMENTS_N129: [''],
      PARTSSERVICEINVESTMENTS_O129: [''],
      PARTSSERVICEINVESTMENTS_P129: [''],

      PARTSSERVICEINVESTMENTS_C130: [''],
      PARTSSERVICEINVESTMENTS_D130: [''],
      PARTSSERVICEINVESTMENTS_E130: [''],
      PARTSSERVICEINVESTMENTS_F130: [''],
      PARTSSERVICEINVESTMENTS_G130: [''],
      PARTSSERVICEINVESTMENTS_H130: [''],
      PARTSSERVICEINVESTMENTS_I130: [''],
      PARTSSERVICEINVESTMENTS_J130: [''],
      PARTSSERVICEINVESTMENTS_K130: [''],
      PARTSSERVICEINVESTMENTS_L130: [''],
      PARTSSERVICEINVESTMENTS_M130: [''],
      PARTSSERVICEINVESTMENTS_N130: [''],
      PARTSSERVICEINVESTMENTS_O130: [''],
      PARTSSERVICEINVESTMENTS_P130: [''],

      PARTSSERVICEINVESTMENTS_C131: [''],
      PARTSSERVICEINVESTMENTS_D131: [''],
      PARTSSERVICEINVESTMENTS_E131: [''],
      PARTSSERVICEINVESTMENTS_F131: [''],
      PARTSSERVICEINVESTMENTS_G131: [''],
      PARTSSERVICEINVESTMENTS_H131: [''],
      PARTSSERVICEINVESTMENTS_I131: [''],
      PARTSSERVICEINVESTMENTS_J131: [''],
      PARTSSERVICEINVESTMENTS_K131: [''],
      PARTSSERVICEINVESTMENTS_L131: [''],
      PARTSSERVICEINVESTMENTS_M131: [''],
      PARTSSERVICEINVESTMENTS_N131: [''],
      PARTSSERVICEINVESTMENTS_O131: [''],
      PARTSSERVICEINVESTMENTS_P131: [''],

      PARTSSERVICEINVESTMENTS_C132: [''],
      PARTSSERVICEINVESTMENTS_D132: [''],
      PARTSSERVICEINVESTMENTS_E132: [''],
      PARTSSERVICEINVESTMENTS_F132: [''],
      PARTSSERVICEINVESTMENTS_G132: [''],
      PARTSSERVICEINVESTMENTS_H132: [''],
      PARTSSERVICEINVESTMENTS_I132: [''],
      PARTSSERVICEINVESTMENTS_J132: [''],
      PARTSSERVICEINVESTMENTS_K132: [''],
      PARTSSERVICEINVESTMENTS_L132: [''],
      PARTSSERVICEINVESTMENTS_M132: [''],
      PARTSSERVICEINVESTMENTS_N132: [''],
      PARTSSERVICEINVESTMENTS_O132: [''],
      PARTSSERVICEINVESTMENTS_P132: [''],

      PARTSSERVICEINVESTMENTS_C140: [''],
      PARTSSERVICEINVESTMENTS_D140: [''],
      PARTSSERVICEINVESTMENTS_E140: [''],
      PARTSSERVICEINVESTMENTS_F140: [''],
      PARTSSERVICEINVESTMENTS_G140: [''],
      PARTSSERVICEINVESTMENTS_H140: [''],
      PARTSSERVICEINVESTMENTS_I140: [''],
      PARTSSERVICEINVESTMENTS_J140: [''],
      PARTSSERVICEINVESTMENTS_K140: [''],
      PARTSSERVICEINVESTMENTS_L140: [''],
      PARTSSERVICEINVESTMENTS_M140: [''],
      PARTSSERVICEINVESTMENTS_N140: [''],
      PARTSSERVICEINVESTMENTS_O140: [''],
      PARTSSERVICEINVESTMENTS_P140: [''],

      PARTSSERVICEINVESTMENTS_C141: [''],
      PARTSSERVICEINVESTMENTS_D141: [''],
      PARTSSERVICEINVESTMENTS_E141: [''],
      PARTSSERVICEINVESTMENTS_F141: [''],
      PARTSSERVICEINVESTMENTS_G141: [''],
      PARTSSERVICEINVESTMENTS_H141: [''],
      PARTSSERVICEINVESTMENTS_I141: [''],
      PARTSSERVICEINVESTMENTS_J141: [''],
      PARTSSERVICEINVESTMENTS_K141: [''],
      PARTSSERVICEINVESTMENTS_L141: [''],
      PARTSSERVICEINVESTMENTS_M141: [''],
      PARTSSERVICEINVESTMENTS_N141: [''],
      PARTSSERVICEINVESTMENTS_O141: [''],
      PARTSSERVICEINVESTMENTS_P141: [''],

      PARTSSERVICEINVESTMENTS_C164: [''],
      PARTSSERVICEINVESTMENTS_D164: [''],
      PARTSSERVICEINVESTMENTS_E164: [''],
      PARTSSERVICEINVESTMENTS_F164: [''],
      PARTSSERVICEINVESTMENTS_G164: [''],
      PARTSSERVICEINVESTMENTS_H164: [''],
      PARTSSERVICEINVESTMENTS_I164: [''],
      PARTSSERVICEINVESTMENTS_J164: [''],
      PARTSSERVICEINVESTMENTS_K164: [''],
      PARTSSERVICEINVESTMENTS_L164: [''],
      PARTSSERVICEINVESTMENTS_M164: [''],
      PARTSSERVICEINVESTMENTS_N164: [''],
      PARTSSERVICEINVESTMENTS_O164: [''],
      PARTSSERVICEINVESTMENTS_P164: [''],

      PARTSSERVICEINVESTMENTS_C165: [''],
      PARTSSERVICEINVESTMENTS_D165: [''],
      PARTSSERVICEINVESTMENTS_E165: [''],
      PARTSSERVICEINVESTMENTS_F165: [''],
      PARTSSERVICEINVESTMENTS_G165: [''],
      PARTSSERVICEINVESTMENTS_H165: [''],
      PARTSSERVICEINVESTMENTS_I165: [''],
      PARTSSERVICEINVESTMENTS_J165: [''],
      PARTSSERVICEINVESTMENTS_K165: [''],
      PARTSSERVICEINVESTMENTS_L165: [''],
      PARTSSERVICEINVESTMENTS_M165: [''],
      PARTSSERVICEINVESTMENTS_N165: [''],
      PARTSSERVICEINVESTMENTS_O165: [''],
      PARTSSERVICEINVESTMENTS_P165: [''],

      PARTSSERVICEINVESTMENTS_C166: [''],
      PARTSSERVICEINVESTMENTS_D166: [''],
      PARTSSERVICEINVESTMENTS_E166: [''],
      PARTSSERVICEINVESTMENTS_F166: [''],
      PARTSSERVICEINVESTMENTS_G166: [''],
      PARTSSERVICEINVESTMENTS_H166: [''],
      PARTSSERVICEINVESTMENTS_I166: [''],
      PARTSSERVICEINVESTMENTS_J166: [''],
      PARTSSERVICEINVESTMENTS_K166: [''],
      PARTSSERVICEINVESTMENTS_L166: [''],
      PARTSSERVICEINVESTMENTS_M166: [''],
      PARTSSERVICEINVESTMENTS_N166: [''],
      PARTSSERVICEINVESTMENTS_O166: [''],
      PARTSSERVICEINVESTMENTS_P166: [''],

      PARTSSERVICEINVESTMENTS_C167: [''],
      PARTSSERVICEINVESTMENTS_D167: [''],
      PARTSSERVICEINVESTMENTS_E167: [''],
      PARTSSERVICEINVESTMENTS_F167: [''],
      PARTSSERVICEINVESTMENTS_G167: [''],
      PARTSSERVICEINVESTMENTS_H167: [''],
      PARTSSERVICEINVESTMENTS_I167: [''],
      PARTSSERVICEINVESTMENTS_J167: [''],
      PARTSSERVICEINVESTMENTS_K167: [''],
      PARTSSERVICEINVESTMENTS_L167: [''],
      PARTSSERVICEINVESTMENTS_M167: [''],
      PARTSSERVICEINVESTMENTS_N167: [''],
      PARTSSERVICEINVESTMENTS_O167: [''],
      PARTSSERVICEINVESTMENTS_P167: [''],

      PARTSSERVICEINVESTMENTS_C175: [''],
      PARTSSERVICEINVESTMENTS_D175: [''],
      PARTSSERVICEINVESTMENTS_E175: [''],
      PARTSSERVICEINVESTMENTS_F175: [''],
      PARTSSERVICEINVESTMENTS_G175: [''],
      PARTSSERVICEINVESTMENTS_H175: [''],
      PARTSSERVICEINVESTMENTS_I175: [''],
      PARTSSERVICEINVESTMENTS_J175: [''],
      PARTSSERVICEINVESTMENTS_K175: [''],
      PARTSSERVICEINVESTMENTS_L175: [''],
      PARTSSERVICEINVESTMENTS_M175: [''],
      PARTSSERVICEINVESTMENTS_N175: [''],
      PARTSSERVICEINVESTMENTS_O175: [''],
      PARTSSERVICEINVESTMENTS_P175: [''],
    });

    this.setEnterKeyHandler();
  }

  setEnterKeyHandler() {
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_H8', 'PARTSSERVICEINVESTMENTS_I8');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_I8', 'PARTSSERVICEINVESTMENTS_J8');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_J8', 'PARTSSERVICEINVESTMENTS_K8');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_K8', 'PARTSSERVICEINVESTMENTS_L8');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_L8', 'PARTSSERVICEINVESTMENTS_M8');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_M8', 'PARTSSERVICEINVESTMENTS_N8');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_N8', 'PARTSSERVICEINVESTMENTS_O8');

    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_O8', 'PARTSSERVICEINVESTMENTS_C12');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_C9', 'PARTSSERVICEINVESTMENTS_H8');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_C12', 'PARTSSERVICEINVESTMENTS_F12');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_F12', 'PARTSSERVICEINVESTMENTS_G12');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_G12', 'PARTSSERVICEINVESTMENTS_O12');

    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_O12', 'PARTSSERVICEINVESTMENTS_C13');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_C13', 'PARTSSERVICEINVESTMENTS_C16');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_C16', 'PARTSSERVICEINVESTMENTS_F16');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_F16', 'PARTSSERVICEINVESTMENTS_G16');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_G16', 'PARTSSERVICEINVESTMENTS_O16');

    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_O16', 'PARTSSERVICEINVESTMENTS_C17');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_C17', 'PARTSSERVICEINVESTMENTS_F24');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_F24', 'PARTSSERVICEINVESTMENTS_G24');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_G24', 'PARTSSERVICEINVESTMENTS_O24');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_O24', 'PARTSSERVICEINVESTMENTS_C25');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_C25', 'PARTSSERVICEINVESTMENTS_O29');
    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_O29', 'PARTSSERVICEINVESTMENTS_C32');

    this.addEnterKeyHandler('PARTSSERVICEINVESTMENTS_C32', 'PARTSSERVICEINVESTMENTS_H8');
  }

  resetForm() {
    this.partsServiceForm.reset();
    this.patchDefaultValue();
  }
  beforeClick() {
    this.cdr.detectChanges();

    this.removeBorder= true;
    this.addHeading = true;
    this.cdr.detectChanges();
    this.captureScreenshot();

 }

  nextPage() {
    if (this.partsServiceForm.valid) {


      // ------- Convert percentage to number
      const value_c9 = this.partsServiceForm.get(
        'PARTSSERVICEINVESTMENTS_C9'
      )?.value;
      this.partsServiceForm
        .get('PARTSSERVICEINVESTMENTS_C9')
        ?.setValue(parseFloat(value_c9) / 100);

      const value_c13 = this.partsServiceForm.get(
        'PARTSSERVICEINVESTMENTS_C13'
      )?.value;
      this.partsServiceForm
        .get('PARTSSERVICEINVESTMENTS_C13')
        ?.setValue(parseFloat(value_c13) / 100);

      const value_c17 = this.partsServiceForm.get(
        'PARTSSERVICEINVESTMENTS_C17'
      )?.value;
      this.partsServiceForm
        .get('PARTSSERVICEINVESTMENTS_C17')
        ?.setValue(parseFloat(value_c17) / 100);

      const value_c25 = this.partsServiceForm.get(
        'PARTSSERVICEINVESTMENTS_C25'
      )?.value;
      this.partsServiceForm
        .get('PARTSSERVICEINVESTMENTS_C25')
        ?.setValue(parseFloat(value_c25) / 100);
      //--------------------------------------

      // ------ Save previous form data ----
      this.apiService.savePrevFormData(
        'partsServiceInvestment',
        this.partsServiceForm.value
      );
      const formArray = Object.entries(this.partsServiceForm.value);
      formArray.forEach(function (obj: any) {
        obj[0] = obj[0].replace(
          'PARTSSERVICEINVESTMENTS_',
          'PARTS&SERVICEINVESTMENTS_'
        );
      });

      if (!this.loaderService.isVisible()) {
        this.loaderService.show();
      }

      const formObject = Object.fromEntries(formArray);
      this.apiService.saveFormData(formObject);

      const userdata = this.authService.getCurrentUser().data;
      const requestData = {
        userId: userdata.userId,
        draft: this.apiService.formData,
        createdBy: userdata.username,
        updatedBy: userdata.username,
      };
      this.apiService.nextPageNavigation(requestData).subscribe(
        (response) => {
          if (response.status === 'success') {
            this.apiService.saveFormData(response.data.partial);
           setTimeout(() => {
             this.router.navigate(['/charging-investment']);
       }, 1000);
            this.toast.success('Values saved successfully in Draft');
            if (this.loaderService.isVisible()) {
              this.loaderService.hide();
            }
          }
        },
        (err) => {
          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
          //console.log(err);
          this.toast.error(
            err?.error?.errorDescription ||
              'Something went wrong in saving the values in Draft'
          );
        }
      );
    }
  }

  prevPage() {
    this.apiService.savePrevFormData(
      'partsServiceInvestment',
      this.partsServiceForm.value
    );
    this.router.navigate(['/interactive-tab']);
  }

  async captureScreenshot() {
    const annualPreparednessElementToCapture =
      this.annualPreparednessContentElement.nativeElement;
    const annualBevCostElementToCapture =
      this.annualBevCostContentElement.nativeElement;
    const annualIncrementalCostElementToCapture =
      this.annualIncrementalCostContentElement.nativeElement;

    const annualPreparednessCanvas = await this.screenshotService
      .captureScreenshot(annualPreparednessElementToCapture)
      .then((canvas) => {
        this.annualPreparednessCapturedImage = canvas.toDataURL('image/png');
      });

    const annualBevCostCanvas = await this.screenshotService
      .captureScreenshot(annualBevCostElementToCapture)
      .then((canvas) => {
        this.annualBevCostCapturedImage = canvas.toDataURL('image/png');
      });

    const annualIncrementalCostCanvas = await this.screenshotService
      .captureScreenshot(annualIncrementalCostElementToCapture)
      .then((canvas) => {
        this.annualIncrementalCostCapturedImage = canvas.toDataURL('image/png');
      });

    const imgObject = [
      {
        id: 'annualPreparednessContent',
        imgCaptured: this.annualPreparednessCapturedImage,
      },
      {
        id: 'annualBevCostContent',
        imgCaptured: this.annualBevCostCapturedImage,
      },
      {
        id: 'annualIncrementalCostContent',
        imgCaptured: this.annualIncrementalCostCapturedImage,
      },
    ];

    const pageObject = {
      page: 'BEV Readiness',
      imgCapture: imgObject,
    };

    this.apiService.pageScreenshot['BEV Readiness'] = pageObject;
    setTimeout(() => {
      this.router.navigate(['/charging-investment']);
    }, 1000);
    this.nextPage()
  }

  updatedValueForm(event: { formControlName: any; value: any }) {
    this.partsServiceForm.get(event.formControlName)?.setValue(event.value);
  }

  enterSubmitted(event: any) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  focusNextField(fieldName: string) {
    const nextField = document.getElementById(fieldName);
    const nextFieldSelectAll = document.getElementById(fieldName) as HTMLInputElement
    nextFieldSelectAll?.select();
    if (nextField) {
      nextField.focus();
    }
  }

  private addEnterKeyHandler(currentField: string, nextField: string) {
    const currentFieldElement = this.el.nativeElement.querySelector(`#${currentField}`);
    this.renderer.listen(currentFieldElement, 'keydown.enter', (event) => {
      event.preventDefault();
      this.focusNextField(nextField);
    });

    this.renderer.listen(currentFieldElement, 'keydown.tab', (event) => {
      event.preventDefault();
      this.focusNextField(nextField);
    });
  }
  getFinalCount (){
    const parseValue = (value: any): number => {
      if (typeof value === 'string') {
        return parseFloat(value.replace(/\$|,/g, ''));
      }
      // If the value is not a string, return it as it is
      return value;
    };
  const  sum = (
      parseValue((this.partsServiceForm.get("PARTSSERVICEINVESTMENTS_H33")?.value || 0)) +
      parseValue((this.partsServiceForm.get("PARTSSERVICEINVESTMENTS_K33")?.value || 0)) +
      parseValue((this.partsServiceForm.get("PARTSSERVICEINVESTMENTS_O33")?.value || 0))
    );
    return '$' + sum.toLocaleString();
  }
}
