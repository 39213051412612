// breadcrumb.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private breadcrumbsSubject = new BehaviorSubject<any[]>([]);
  breadcrumbs$ = this.breadcrumbsSubject.asObservable();

  setBreadcrumbs(breadcrumbs: any[]): void {
    this.breadcrumbsSubject.next(breadcrumbs);
  }

  addBreadcrumbs() {
    this.setBreadcrumbs([
      { label: 'Home', link: '' },
      { label: 'Instructions', link: 'instructions' },
      { label: 'Interactive Tab', link: 'interactive-tab' },
      {
        label: 'Parts & Services Investment',
        link: 'parts-service-investments',
      },
      { label: 'Charging Investment', link: 'charging-investment' },
      {
        label: 'Full Service Leasing',
        link: 'full-service-leasing',
      },
      { label: 'Revenue and Gross Profit Projections', link: 'purse-opportunity' },
      { label: 'Scoring Worksheet', link: 'scoring-worksheet' },
      { label: 'Output Graph', link: 'output-graph' },
    ]);
  }
}
