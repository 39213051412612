import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { BreadcrumbService } from '../services/breadcumbs.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent implements OnInit {

  constructor(public apiService: ApiService, private breadCrumbsSvc: BreadcrumbService,     private router: Router,
    ) {}
  ngOnInit(): void {
    if(localStorage.getItem("myScenario") =="true"){
      localStorage.setItem("myScenario","false")
      this.router.navigate(['/interactive-tab'] );

    }
    this.apiService.pageTitle = 'Instructions';
    this.breadCrumbsSvc.addBreadcrumbs();
  }
  prevPage() {
    this.router.navigate(['']);
  }
  
}
