import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

const SECRET_KEY = environment.SECRET_KEY;

@Injectable({
  providedIn: 'root',
})
export class EncryptDecryptService {
  constructor() {}

  encrypt(content: any): string {
    const encryptedContent = CryptoJS.AES.encrypt(
      content,
      SECRET_KEY
    ).toString();
    return encryptedContent;
  }

  decrypt(content: any): any {
    const bytes = CryptoJS.AES.decrypt(content, SECRET_KEY);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  }
}
