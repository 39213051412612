<div class="mt-5" style="max-width:100%;display: flex;flex-direction: column;align-items: center;">
    <form [formGroup]="fullServiceLeasingForm" style="max-width:1350px;width: 100%;" class="full-service-leasing-form" (keydown)="enterSubmitted($event)">
        <input type="hidden" id="REFRESH_PAGE" value="" />
        <div #content>
            <div class="mt-4">
                <p class="font-weight-500">Number Of Trucks In Full Service Lease Fleet
                    <span class="bi-info-circle ml-2 w-auto" placement="top"
                    ngbTooltip="Unless you choose a specific number of lease vehicles for 2026 and 2030, the tool assumes a 1.8% and 3.5% of new truck sales respectively.  See  &quot;Total units in full service lease fleet &quot; at the bottom of this section.">
                    </span>
                </p>
                <div class="ice-bev-section p-4">
                    <div>
                        <p class="font-weight-500">ICE</p>
                        <div>
                            <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                                <span>ICE Medium Duty</span>
                                <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="Default assumes 3.5% penetration rate of new retail untis for 2030 and 1.8% in 2026"></span>
                            </p>
                            <div class="row">
                                <div class="col-6">
                                    <div class="input-box">
                                        <div>
                                            <label class="text-uppercase" style="margin-left: 10px;">2026</label>
                                            <input type="text" appAllowOnlyNumbers id ="FULLSVCLEASINGINV_C20" value="1" formControlName="FULLSVCLEASINGINV_C20" />
                                            <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex input-error" *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C20.invalid && (fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C20.dirty || fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C20.touched)">
                                        <div *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C20.errors?.required">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter the value for 2026</span>
                                        </div>
                                    </div> -->
                                </div>

                                <div class="col-6">
                                    <div class="input-box">
                                        <div>
                                            <label class="text-uppercase" style="margin-left: 10px;">2030</label>
                                            <input type="text" appAllowOnlyNumbers id ="FULLSVCLEASINGINV_D20" value="1" formControlName="FULLSVCLEASINGINV_D20" />
                                            <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex input-error" *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D20.invalid && (fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D20.dirty || fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D20.touched)">
                                        <div *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D20.errors?.required">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter the value for 2030</span>
                                        </div>
                                    </div> -->
                                </div>

                            </div>
                        </div>
                        <div>
                            <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                                <span>ICE Class 8 long haul</span>
                                <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="Default assumes 3.5% penetration rate of new retail untis for 2030 and 1.8% in 2026"></span>
                            </p>
                            <div class="row">
                                <div class="col-6">
                                    <div class="input-box">
                                        <div>
                                            <label class="text-uppercase" style="margin-left: 10px;">2026</label>
                                            <input type="text" appAllowOnlyNumbers id ="FULLSVCLEASINGINV_C21" value="1" formControlName="FULLSVCLEASINGINV_C21" />
                                            <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex input-error" *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C21.invalid && (fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C21.dirty || fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C21.touched)">
                                        <div *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C21.errors?.required">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter the value for 2026</span>
                                        </div>
                                    </div> -->
                                </div>

                                <div class="col-6">
                                    <div class="input-box">
                                        <div>
                                            <label class="text-uppercase" style="margin-left: 10px;">2030</label>
                                            <input type="text" appAllowOnlyNumbers id ="FULLSVCLEASINGINV_D21" value="1" formControlName="FULLSVCLEASINGINV_D21" />
                                            <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex input-error" *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D21.invalid && (fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D21.dirty || fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D21.touched)">
                                        <div *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D21.errors?.required">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter the value for 2030</span>
                                        </div>
                                    </div> -->
                                </div>

                            </div>
                        </div>
                        <div>
                            <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                                <span>ICE Class 8 short haul (and voc.)</span>
                                <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="Default assumes 3.5% penetration rate of new retail untis for 2030 and 1.8% in 2026"></span>
                            </p>
                            <div class="row">
                                <div class="col-6">
                                    <div class="input-box">
                                        <div>
                                            <label class="text-uppercase" style="margin-left: 10px;">2026</label>
                                            <input type="text" appAllowOnlyNumbers id ="FULLSVCLEASINGINV_C22" value="1" formControlName="FULLSVCLEASINGINV_C22" />
                                            <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex input-error" *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C22.invalid && (fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C22.dirty || fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C22.touched)">
                                        <div *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C22.errors?.required">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter the value for 2026</span>
                                        </div>
                                    </div> -->
                                </div>

                                <div class="col-6">
                                    <div class="input-box">
                                        <div>
                                            <label class="text-uppercase" style="margin-left: 10px;">2030</label>
                                            <input type="text" appAllowOnlyNumbers id ="FULLSVCLEASINGINV_D22" value="1" formControlName="FULLSVCLEASINGINV_D22" />
                                            <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex input-error" *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D22.invalid && (fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D22.dirty || fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D22.touched)">
                                        <div *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D22.errors?.required">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter the value for 2030</span>
                                        </div>
                                    </div> -->
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="mt-4">
                        <p class="font-weight-500">BEV</p>
                        <div>
                            <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                                <span>BEV Medium Duty</span>
                                <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="Default assumes 3.5% penetration rate of new retail untis for 2030 and 1.8% in 2026"></span>
                            </p>
                            <div class="row">
                                <div class="col-6">
                                    <div class="input-box">
                                        <div>
                                            <label class="text-uppercase" style="margin-left: 10px;">2026</label>
                                            <input type="text" appAllowOnlyNumbers id ="FULLSVCLEASINGINV_C24" value="1" formControlName="FULLSVCLEASINGINV_C24" />
                                            <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex input-error" *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C24.invalid && (fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C24.dirty || fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C24.touched)">
                                        <div *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C24.errors?.required">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter the value for 2026</span>
                                        </div>
                                    </div> -->
                                </div>

                                <div class="col-6">
                                    <div class="input-box">
                                        <div>
                                            <label class="text-uppercase" style="margin-left: 10px;">2030</label>
                                            <input type="text" appAllowOnlyNumbers id ="FULLSVCLEASINGINV_D24" value="1" formControlName="FULLSVCLEASINGINV_D24" />
                                            <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex input-error" *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D24.invalid && (fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D24.dirty || fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D24.touched)">
                                        <div *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D24.errors?.required">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter the value for 2030</span>
                                        </div>
                                    </div> -->
                                </div>

                            </div>
                        </div>
                        <div>
                            <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                                <span>BEV Class 8 long haul</span>
                                <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="Default assumes 3.5% penetration rate of new retail untis for 2030 and 1.8% in 2026"></span>
                            </p>
                            <div class="row">
                                <div class="col-6">
                                    <div class="input-box">
                                        <div>
                                            <label class="text-uppercase" style="margin-left: 10px;">2026</label>
                                            <input type="text" appAllowOnlyNumbers id ="FULLSVCLEASINGINV_C25" value="1" formControlName="FULLSVCLEASINGINV_C25" />
                                            <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex input-error" *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C25.invalid && (fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C25.dirty || fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C25.touched)">
                                        <div *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C25.errors?.required">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter the value for 2026</span>
                                        </div>
                                    </div> -->
                                </div>

                                <div class="col-6">
                                    <div class="input-box">
                                        <div>
                                            <label class="text-uppercase" style="margin-left: 10px;">2030</label>
                                            <input type="text" appAllowOnlyNumbers id ="FULLSVCLEASINGINV_D25" value="1" formControlName="FULLSVCLEASINGINV_D25" />
                                            <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex input-error" *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D25.invalid && (fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D25.dirty || fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D25.touched)">
                                        <div *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D25.errors?.required">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter the value for 2030</span>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div>
                            <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                                <span>BEV Class 8 short haul (and voc.)</span>
                                <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="Default assumes 3.5% penetration rate of new retail untis for 2030 and 1.8% in 2026"></span>
                            </p>
                            <div class="row">
                                <div class="col-6">
                                    <div class="input-box">
                                        <div>
                                            <label class="text-uppercase" style="margin-left: 10px;">2026</label>
                                            <input type="text" appAllowOnlyNumbers id ="FULLSVCLEASINGINV_C26" value="1" formControlName="FULLSVCLEASINGINV_C26" />
                                            <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex input-error" *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C26.invalid && (fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C26.dirty || fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C26.touched)">
                                        <div *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C26.errors?.required">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter the value for 2026</span>
                                        </div>
                                    </div> -->
                                </div>

                                <div class="col-6">
                                    <div class="input-box">
                                        <div>
                                            <label class="text-uppercase" style="margin-left: 10px;">2030</label>
                                            <input type="text" appAllowOnlyNumbers id ="FULLSVCLEASINGINV_D26" value="1" formControlName="FULLSVCLEASINGINV_D26" />
                                            <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex input-error" *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D26.invalid && (fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D26.dirty || fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D26.touched)">
                                        <div *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D26.errors?.required">
                                            <span class="bi-exclamation-circle mr-2"></span>
                                            <span>Please enter the value for 2030</span>
                                        </div>
                                    </div> -->
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="mt-4">
                        <p class="mb-0 mt-3 font-weight-500">Total units in full service lease fleet</p>
                        <div class="row">
                            <div class="input-box col-6 align-items-start calculated-cell">
                                <label class="text-uppercase">2026</label>
                                <span class="calculated-cell">{{partialData?.FULLSVCLEASINGINV_C29 | number: '1.0-0'}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isNumber]="true" [dataScope]="partialData" [formGroup]="fullServiceLeasingForm" id="FULLSVCLEASINGINV_D105"></span> -->
                            </div>
                            <div class="input-box col-6 align-items-start calculated-cell">
                                <label class="text-uppercase">2030</label>
                                <span class="calculated-cell">{{partialData?.FULLSVCLEASINGINV_D29 | number: '1.0-0'}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isNumber]="true" [dataScope]="partialData" [formGroup]="fullServiceLeasingForm" id="FULLSVCLEASINGINV_E105"></span> -->
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="mt-4">
                <div style="display: flex;justify-content: space-between;margin-right: 35px;">
                    <p class="font-weight-500">Full Service Leasing Cost Items</p>
                    <div style="display: flex;flex-direction: column;align-items: flex-end;">
                    <p *ngIf="removeRecalculateButton" style="margin-bottom: 5px;" class="font-weight-500 font-size-small text-capitalize">To reflect results on this page from input provided, press the "Calculate"</p>
                     <button *ngIf="removeRecalculateButton" style="margin-left: 0px !important;max-width: 200px;" class="btn link-btn ml-3" (click)="updateForm()" [disabled]="fullServiceLeasingForm.invalid">Calculate
                        <span class="bi-info-circle ml-2 w-auto" placement="top"
                        ngbTooltip="Click on 'Calculate' to calculate results based on input provided on this and previous sections
                        .">
                    </span></button>
                    </div>
                </div>
                                    <!-- <p class="font-weight-500">Full Service Leasing Cost Items</p> -->
                                    <div class="ml-4">
                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                            <span>Cost of acquiring ICE trucks</span>
                            <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="Assumes a 3.5% penetration rate for 2030 and 1.8% in 2026;"></span>
                        </p>
                        <div class="row">
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2026</label>
                                <span class="calculated-cell">{{partialData?.FULLSVCLEASINGINV_C9 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="fullServiceLeasingForm" id="FULLSVCLEASINGINV_C9"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2030 Incremental</label>
                                <span class="calculated-cell">{{partialData?.FULLSVCLEASINGINV_D9 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="fullServiceLeasingForm" id="FULLSVCLEASINGINV_D9"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">Total for 2030 assets in use/Cumulative facility</label>
                                <span class="calculated-cell">{{partialData?.FULLSVCLEASINGINV_E9 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="fullServiceLeasingForm" id="FULLSVCLEASINGINV_E9"></span> -->
                            </div>
                        </div>
                    </div>

                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                            <span>Cost of acquiring BEV trucks</span>
                            <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="Assumes a 3.5% penetration rate for 2030 and 1.8% in 2026;"></span>
                        </p>
                        <div class="row">
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2026</label>
                                <span class="calculated-cell">{{partialData?.FULLSVCLEASINGINV_C10 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="fullServiceLeasingForm" id="FULLSVCLEASINGINV_C10"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2030 Incremental</label>
                                <span class="calculated-cell">{{partialData?.FULLSVCLEASINGINV_D10 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="fullServiceLeasingForm" id="FULLSVCLEASINGINV_D10"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">Total for 2030 assets in use/Cumulative facility</label>
                                <span class="calculated-cell">{{partialData?.FULLSVCLEASINGINV_E10 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="fullServiceLeasingForm" id="FULLSVCLEASINGINV_E10"></span> -->
                            </div>
                        </div>
                    </div>

                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Real estate - land acquisition and construction cost for separate facility</p>
                        <div class="row">
                            <div class="col-4">
                                <div class="input-box">
                                    <div>
                                        <label class="text-uppercase">2026</label>
                                        <input placeholder="" type="text" appAllowOnlyCost appMinMaxValue [minValue]="0" [maxValue]="2500000000" appNonNegative [currency]="true" id ="FULLSVCLEASINGINV_C11" value="" formControlName="FULLSVCLEASINGINV_C11" />
                                        <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                                    </div>
                                </div>
                                <!-- <div class="d-flex input-error" *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C11.invalid && (fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C11.dirty || fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C11.touched)">
                                    <div *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C11.errors?.required">
                                        <span class="bi-exclamation-circle mr-2"></span>
                                        <span>Please enter the value for 2026</span>
                                    </div>
                                </div> -->
                            </div>

                            <div class="col-4">
                                <div class="input-box">
                                    <div>
                                        <label class="text-uppercase">2030 Incremental</label>
                                        <input placeholder="" type="text" appAllowOnlyCost appMinMaxValue [minValue]="0" [maxValue]="2500000000" appNonNegative [currency]="true" id ="FULLSVCLEASINGINV_D11" value="" formControlName="FULLSVCLEASINGINV_D11" />
                                        <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                                    </div>
                                </div>
                                <!-- <div class="d-flex input-error" *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D11.invalid && (fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D11.dirty || fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D11.touched)">
                                    <div *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D11.errors?.required">
                                        <span class="bi-exclamation-circle mr-2"></span>
                                        <span>Please enter the value for 2030 Incremental</span>
                                    </div>
                                </div> -->
                            </div>

                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">Total for 2030 assets in use/Cumulative facility</label>
                                <span class="calculated-cell">{{partialData?.FULLSVCLEASINGINV_E11 | currencyFormat}}</span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Tooling and equipment for potential separate full service leasing facility</p>
                        <div class="row">
                            <div class="col-4">
                                <div class="input-box">
                                    <div>
                                        <label class="text-uppercase">2026</label>
                                        <input placeholder="" type="text" appAllowOnlyCost appMinMaxValue [minValue]="0" [maxValue]="2500000000" appNonNegative [currency]="true" id="FULLSVCLEASINGINV_C12" value="" formControlName="FULLSVCLEASINGINV_C12" />
                                        <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                                    </div>
                                </div>
                                <!-- <div class="d-flex input-error" *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C12.invalid && (fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C12.dirty || fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C12.touched)">
                                    <div *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_C12.errors?.required">
                                        <span class="bi-exclamation-circle mr-2"></span>
                                        <span>Please enter the value for 2026</span>
                                    </div>
                                </div> -->
                            </div>

                            <div class="col-4">
                                <div class="input-box">
                                    <div>
                                        <label class="text-uppercase">2030 Incremental</label>
                                        <input placeholder="" type="text" appAllowOnlyCost appMinMaxValue [minValue]="0" [maxValue]="2500000000" appNonNegative [currency]="true" id="FULLSVCLEASINGINV_D12" value="" formControlName="FULLSVCLEASINGINV_D12" />
                                        <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                                    </div>

                                </div>
                                <!-- <div class="d-flex input-error" *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D12.invalid && (fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D12.dirty || fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D12.touched)">
                                    <div *ngIf="fullServiceLeasingForm.controls.FULLSVCLEASINGINV_D12.errors?.required">
                                        <span class="bi-exclamation-circle mr-2"></span>
                                        <span>Please enter the value for 2030 Incremental</span>
                                    </div>
                                </div> -->
                            </div>

                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">Total for 2030 assets in use/Cumulative facility</label>
                                <span class="calculated-cell">{{partialData?.FULLSVCLEASINGINV_E12 | currencyFormat}}</span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                            <span>Total investment</span>
                            <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="Residual value for Gen 1 BEV trucks after first life estimated to be 9%, Gen 2 BEV 44%, ICE 40%"></span>
                        </p>
                        <p class="text-info font-italic font-size-small mb-0 mt-2">** A deflator is applied to the investment cost of the trucks to account for the fact that they can be remarketed at the end of their first life (remarketing is not accounted for in FSL revenue)</p>
                        <div class="row">
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2026</label>
                                <span class="calculated-cell">{{partialData?.FULLSVCLEASINGINV_C14 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="fullServiceLeasingForm" id="FULLSVCLEASINGINV_C14"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2030 Incremental</label>
                                <span class="calculated-cell">{{partialData?.FULLSVCLEASINGINV_D14 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="fullServiceLeasingForm" id="FULLSVCLEASINGINV_D14"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">Total for 2030 assets in use/Cumulative facility</label>
                                <span class="calculated-cell">{{partialData?.FULLSVCLEASINGINV_E14 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="fullServiceLeasingForm" id="FULLSVCLEASINGINV_E14"></span> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end mt-4">
            <button class="btn link-btn" (click)="prevPage()">Back</button>
            <button class="btn link-btn ml-3" (click)="resetForm()">Reset</button>
            <button class="btn link-btn ml-3" (click)="updateForm()" [disabled]="fullServiceLeasingForm.invalid">Calculate
                <span class="bi-info-circle ml-2 w-auto" placement="top"
                ngbTooltip="Click on 'Calculate' to have this page reflect input from previous pages.">
            </span></button>
            <button class="btn link-btn ml-3" (click)="nextPage()" [disabled]="fullServiceLeasingForm.invalid">Next Step</button>
        </div>
    </form>
</div>

<!-- <div #draftModalRef class="modal fade" id="draftModal" tabindex="-1" aria-labelledby="userProfileModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <span type="button" class="close" (click)="draftModal.hide()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="bi-x-circle-fill"></i></span>
                </span>
            </div>
            <div class="modal-body">
                <div>
                    <p class="text-capitalize">Would you like to resume from your Last Draft</p>
                    <div class="d-flex justify-content-end mt-4">
                        <button class="btn link-btn" (click)="yesDraft()">Resume</button>
                        <button class="btn link-btn ml-3" (click)="deleteDraft()">Fresh Input</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
