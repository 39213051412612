import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-point-of-contact',
  templateUrl: './point-of-contact.component.html',
  styleUrls: ['./point-of-contact.component.scss']
})
export class PointOfContactComponent implements OnInit{

  constructor(public apiService: ApiService) {}

  ngOnInit(): void {
    this.apiService.pageTitle = 'Point Of Contact';
  }

}
