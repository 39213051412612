import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
  Renderer2,
  AfterContentChecked,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { Modal } from 'bootstrap';
import { ScreenshotService } from '../services/screenshot.service';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { cloneDeep, isEmpty, merge } from 'lodash';
import { BreadcrumbService } from '../services/breadcumbs.service';
import { FormulaList } from '../directives/formulaList';
import { FormulaEvaluationService } from '../services/formula-evaluation.service';
import { LoaderService } from '../shared/loader/loader.service';

@Component({
  selector: 'app-charging-investment',
  templateUrl: './charging-investment.component.html',
  styleUrls: ['./charging-investment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChargingInvestmentComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked
{
  formulaList: any = FormulaList;
  chargingInvestmentForm: FormGroup;
  chargerSectionChecked = false;
  draftData: any;
  partialData: any;
  draftModal: Modal;
  formChangeSubscription: Subscription;
  requireRefresh = true;
  modifyCell=false;
  @ViewChild('draftModalRef') draftModalRef: ElementRef;

  @ViewChild('content') contentElement: ElementRef;
  @ViewChild('costContent') costContentElement: ElementRef;

  capturedImage: any;
  costCapturedImage: any;
  removeRecalculateButton=true;

  constructor(
    public apiService: ApiService,
    private fb: FormBuilder,
    private router: Router,
    private toast: ToastrService,
    private authService: AuthService,
    private screenshotService: ScreenshotService,
    private breadCrumbsSvc: BreadcrumbService,
    private renderer: Renderer2,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.apiService.pageTitle = 'Charging Investment';
    this.breadCrumbsSvc.addBreadcrumbs();
    this.createChargingInvestmentForm();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
  ngAfterViewInit(): void {
    if (Object.keys(this.apiService.formData).length === 0) {
      // this.redirectToLandingPage();
    } else {
      const prevFormData = this.apiService.getPrevFormData();
      if (!isEmpty(prevFormData.chargingInvestment)) {
        this.partialData = merge(prevFormData.chargingInvestment, this.apiService.getFormData());
      } else {
        this.partialData = this.apiService.getFormData();
      }
      this.assignDraftData();
    }
    const randomVal = Math.random() * 100;
    document.getElementById('REFRESH_PAGE')?.setAttribute('value', randomVal.toString());
    this.updateForm(); // Update page on load
  }

  ngOnDestroy(): void {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
  }

  redirectToLandingPage(): void {
    this.router.navigate(['/']);
  }

  getDraftData() {
    if (!this.loaderService.isVisible()) {
      this.loaderService.show();
    }
    const userdata = this.authService.getCurrentUser().data;

    const requestData = {
      userId: userdata.userId,
      draft: this.apiService.formData,
      createdBy: userdata.username,
      updatedBy: userdata.username,
    };

    this.apiService.previousPageData(requestData).subscribe(
      (response) => {
        if (response.status === 'success') {
          this.draftData = response?.data?.draft
            ? JSON.parse(response?.data?.draft?.draft)
            : {};
          this.partialData = isEmpty(response.data.partial)
            ? this.draftData
            : response.data.partial;
          if (!isEmpty(this.draftData)) {
            this.draftModal = new Modal(this.draftModalRef.nativeElement, {});
            this.draftModal.show();
          } else {
            this.chargingInvestmentForm.reset();
          }
          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
        }
      },
      (err) => {
        if (this.loaderService.isVisible()) {
          this.loaderService.hide();
        }
        //console.log(err);
      }
    );
  }

  yesDraft() {
    this.apiService.loadFromDraft();
    this.apiService.saveFormData(this.draftData);
    this.assignDraftData();
    this.draftModal.hide();

    const chargingSectionToggle = document.querySelector(
      '#toggleButton'
    ) as HTMLFormElement;
    chargingSectionToggle.checked = true;
    this.chargerSectionChecked = true;
  }

  deleteDraft() {
    this.apiService.discardDraft();
    this.chargingInvestmentForm.reset();
    this.draftModal.hide();
  }

  createChargingInvestmentForm() {
    this.chargingInvestmentForm = this.fb.group({
      CHARGINGINVESTMENT_C8: [''],
      CHARGINGINVESTMENT_D8: [''],

      CHARGINGINVESTMENT_C9: [''],
      CHARGINGINVESTMENT_D9: [''],

      CHARGINGINVESTMENT_C10: [''],
      CHARGINGINVESTMENT_D10: [''],

      CHARGINGINVESTMENT_C23: [''],
      CHARGINGINVESTMENT_D23: [''],
      CHARGINGINVESTMENT_C24: [''],

      CHARGINGINVESTMENT_C26: [''],
      CHARGINGINVESTMENT_D26: [''],

      CHARGINGINVESTMENT_C27: [''],

      CHARGINGINVESTMENT_C29: [''],
      CHARGINGINVESTMENT_D29: [''],

      CHARGINGINVESTMENT_C30: [''],

      CHARGINGINVESTMENT_C32: [''],
      CHARGINGINVESTMENT_D32: [''],

      CHARGINGINVESTMENT_C33: [''],

      CHARGINGINVESTMENT_C48: [''],
      CHARGINGINVESTMENT_D48: [''],
    });

    this.setEnterKeyHandler();
  }

  setEnterKeyHandler() {
    this.addEnterKeyHandler('CHARGINGINVESTMENT_C23', 'CHARGINGINVESTMENT_D23');
    this.addEnterKeyHandler('CHARGINGINVESTMENT_D23', 'CHARGINGINVESTMENT_C24');

    this.addEnterKeyHandler('CHARGINGINVESTMENT_C24', 'CHARGINGINVESTMENT_C26');
    this.addEnterKeyHandler('CHARGINGINVESTMENT_C26', 'CHARGINGINVESTMENT_D26');
    this.addEnterKeyHandler('CHARGINGINVESTMENT_D26', 'CHARGINGINVESTMENT_C27');

    this.addEnterKeyHandler('CHARGINGINVESTMENT_C27', 'CHARGINGINVESTMENT_C29');
    this.addEnterKeyHandler('CHARGINGINVESTMENT_C29', 'CHARGINGINVESTMENT_D29');
    this.addEnterKeyHandler('CHARGINGINVESTMENT_D29', 'CHARGINGINVESTMENT_C30');

    this.addEnterKeyHandler('CHARGINGINVESTMENT_C30', 'CHARGINGINVESTMENT_C32');
    this.addEnterKeyHandler('CHARGINGINVESTMENT_C32', 'CHARGINGINVESTMENT_D32');
    this.addEnterKeyHandler('CHARGINGINVESTMENT_D32', 'CHARGINGINVESTMENT_C33');

    this.addEnterKeyHandler('CHARGINGINVESTMENT_C33', 'CHARGINGINVESTMENT_C48');
    this.addEnterKeyHandler('CHARGINGINVESTMENT_C48', 'CHARGINGINVESTMENT_D48');

    this.addEnterKeyHandler('CHARGINGINVESTMENT_D48', 'CHARGINGINVESTMENT_C23');
  }

  createFormChangeSubscription() {
    if (this.chargingInvestmentForm) {
      this.formChangeSubscription = this.chargingInvestmentForm.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(() => {
          if (this.chargingInvestmentForm.dirty) {
            this.requireRefresh = true;
          }
        });
    }
  }

  updateForm() {
    if (!this.loaderService.isVisible()) {
      this.loaderService.show();
    }
    const upDatedFormData = this.slicInputs(this.chargingInvestmentForm.value)
    this.apiService.saveFormData(upDatedFormData);
    // this.apiService.saveFormData(this.chargingInvestmentForm.value);
    const userdata = this.authService.getCurrentUser().data;
    const requestData = {
      userId: userdata.userId,
      draft: this.apiService.formData,
      createdBy: userdata.username,
      updatedBy: userdata.username,
    };
    this.apiService.updatePage(requestData).subscribe(
      (response) => {
        if (response.status === 'success') {
          // Patch values to the form

          this.apiService.saveFormData(response.data.partial);
          this.cdr.detectChanges();
          this.partialData = response.data.partial;
          this.requireRefresh = true;
          const randomVal = Math.random() * 100;
          setTimeout(() => {
            document.getElementById('REFRESH_PAGE')?.setAttribute('value', randomVal.toString());
            this.chargingInvestmentForm.updateValueAndValidity({emitEvent: true})
            this.toast.success('Page values updated');
            if (this.loaderService.isVisible()) {
              this.loaderService.hide();
            }
          }, 1000);
        }
      },
      (err) => {
        if (this.loaderService.isVisible()) {
          this.loaderService.hide();
        }
        //console.log(err);
        this.toast.error(err?.error?.errorDescription || 'Error updating page');
      }
    );
  }

  resetForm() {
    this.chargingInvestmentForm.reset();
    this.partialData = {};
    this.updateForm();
  }

  assignDraftData() {
    this.draftData = this.apiService.formData;
    this.chargingInvestmentForm.patchValue({
      CHARGINGINVESTMENT_C8: this.partialData?.CHARGINGINVESTMENT_C8,
      CHARGINGINVESTMENT_D8: this.partialData?.CHARGINGINVESTMENT_D8,

      CHARGINGINVESTMENT_C9: this.partialData?.CHARGINGINVESTMENT_C9,
      CHARGINGINVESTMENT_D9: this.partialData?.CHARGINGINVESTMENT_D9,

      CHARGINGINVESTMENT_C10: this.partialData?.INTERACTIVETAB_G86 || 'YES',
      CHARGINGINVESTMENT_D10: this.partialData?.INTERACTIVETAB_J86 || 'YES',

      CHARGINGINVESTMENT_C23: this.partialData?.CHARGINGINVESTMENT_C23,
      CHARGINGINVESTMENT_D23: this.partialData?.CHARGINGINVESTMENT_D23,
      CHARGINGINVESTMENT_C24:
        this.partialData?.CHARGINGINVESTMENT_C24?.toString() != "0"
          ? this.prependDollarSign(this.partialData?.CHARGINGINVESTMENT_C24)
          : '',
      CHARGINGINVESTMENT_C26: this.partialData?.CHARGINGINVESTMENT_C26,
      CHARGINGINVESTMENT_D26: this.partialData?.CHARGINGINVESTMENT_D26,
      CHARGINGINVESTMENT_C27:
        this.partialData?.CHARGINGINVESTMENT_C27?.toString()
          ? this.prependDollarSign(this.partialData?.CHARGINGINVESTMENT_C27)
          : '',
      CHARGINGINVESTMENT_C29: this.partialData?.CHARGINGINVESTMENT_C29,
      CHARGINGINVESTMENT_D29: this.partialData?.CHARGINGINVESTMENT_D29,
      CHARGINGINVESTMENT_C30:
        this.partialData?.CHARGINGINVESTMENT_C30?.toString()
          ? this.prependDollarSign(this.partialData?.CHARGINGINVESTMENT_C30)
          : '',
      CHARGINGINVESTMENT_C32: this.partialData?.CHARGINGINVESTMENT_C32,
      CHARGINGINVESTMENT_D32: this.partialData?.CHARGINGINVESTMENT_D32,
      CHARGINGINVESTMENT_C33:
        this.partialData?.CHARGINGINVESTMENT_C33?.toString()
          ? this.prependDollarSign(this.partialData?.CHARGINGINVESTMENT_C33)
          : '',
      CHARGINGINVESTMENT_C48: this.formatCurrency(this.partialData?.CHARGINGINVESTMENT_C48),
      CHARGINGINVESTMENT_D48:  this.formatCurrency(this.partialData?.CHARGINGINVESTMENT_D48),
    });
    // this.updateForm();
  }

  nextPage() {
    this.modifyCell=true;
    this.removeRecalculateButton = false
    if (this.chargingInvestmentForm.valid) {
      if (!this.loaderService.isVisible()) {
        this.loaderService.show();
      }

      const upDatedFormData = this.slicInputs(this.chargingInvestmentForm.value)
      this.apiService.saveFormData(upDatedFormData);
      Object.assign(
        this.apiService.formData,
        upDatedFormData
      );

      this.apiService.savePrevFormData(
        'chargingInvestment',
        upDatedFormData
      );

      const userdata = this.authService.getCurrentUser().data;
      const requestData = {
        userId: userdata.userId,
        draft: this.apiService.formData,
        createdBy: userdata.username,
        updatedBy: userdata.username,
      };

      const chargingSectionToggle = document.querySelector(
        '#toggleButton'
      ) as HTMLFormElement;
      chargingSectionToggle.checked = true;
      this.chargerSectionChecked = true;

      this.apiService.nextPageNavigation(requestData).subscribe(
        (response) => {
          if (response.status === 'success') {
            this.apiService.saveFormData(response.data.partial);
            this.captureScreenshot();
            if (this.loaderService.isVisible()) {
              this.loaderService.hide();
            }
            this.toast.success('Values saved successfully in Draft');
          }
        },
        (err) => {
          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
          //console.log(err);
          this.toast.error(
            err?.error?.errorDescription ||
              'Something went wrong in saving the values in Draft'
          );
        }
      );
    }
  }

  prevPage() {
    this.apiService.savePrevFormData(
      'chargingInvestment',
      this.chargingInvestmentForm.value
    );
    this.router.navigate(['/parts-service-investments']);
  }

  private prependDollarSign(inputString: any) {
    if (inputString) {
      // Check if the string already starts with a dollar sign
      if (!inputString.toString().startsWith('$')) {
        // If not, prepend a dollar sign to the string
        inputString = Number(inputString).toLocaleString('en-US');
        return '$' + inputString;
      }

      // If the string already starts with a dollar sign, return the original string
      return inputString;
    } else {
      return '';
    }
  }

  onChargerSectionChecked() {
    if (this.chargerSectionChecked) {
      this.chargerSectionChecked = false;
    } else {
      this.chargerSectionChecked = true;
    }
  }

  async captureScreenshot() {
    const elementToCapture = this.contentElement.nativeElement;
    const costElementToCapture = this.costContentElement.nativeElement;
    await this.screenshotService
    .captureScreenshot(elementToCapture)
    .then((canvas) => {
      this.capturedImage = canvas.toDataURL('image/png');
    });
    await this.screenshotService
      .captureScreenshot(costElementToCapture)
      .then((canvas) => {
        this.costCapturedImage = canvas.toDataURL('image/png');
      });
    const imgObject = [
      {
        id: 'chargingInvestment',
        imgCaptured: this.capturedImage,
      },

      {
        id: 'costChargingInvestment',
        imgCaptured: this.costCapturedImage,
      },
    ];

    const pageObject = {
      page: 'Dealership Revenues & Investments',
      imgCapture: imgObject,
    };

    // if (
    //   this.apiService.pageScreenshot.filter(
    //     (screenshot: any) =>
    //       screenshot.page === 'Dealership Revenues & Investments'
    //   ).length > 0
    // ) {
    //   this.apiService.pageScreenshot.forEach((screenshot: any) => {
    //     if (screenshot.page === 'Dealership Revenues & Investments') {
    //       screenshot.imgCapture.push(imgObject);
    //     }
    //   });
    // } else {
    //   this.apiService.pageScreenshot.push(pageObject);
    // }

    if (this.apiService.pageScreenshot['Dealership Revenues & Investments']) {
      this.apiService.pageScreenshot['Dealership Revenues & Investments']['imgCapture'].push(imgObject);
    } else {
      this.apiService.pageScreenshot['Dealership Revenues & Investments'] = pageObject;
    }
    setTimeout(() => {
      this.router.navigate(['/full-service-leasing']);
    }, 200);
  }

  updatedValueForm(event: { formControlName: any; value: any }) {
    this.chargingInvestmentForm
      .get(event.formControlName)
      ?.setValue(event.value);
  }

  enterSubmitted(event: any) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  focusNextField(fieldName: string) {
    const nextField = document.getElementById(fieldName);
    const nextFieldSelectAll = document.getElementById(fieldName) as HTMLInputElement
    nextFieldSelectAll?.select();
    if (nextField) {
      nextField.focus();
    }
  }

  private addEnterKeyHandler(currentField: string, nextField: string) {
    const currentFieldElement = this.el.nativeElement.querySelector(`#${currentField}`);
    this.renderer.listen(currentFieldElement, 'keydown.enter', (event) => {
      event.preventDefault();
      this.focusNextField(nextField);
    });

    this.renderer.listen(currentFieldElement, 'keydown.tab', (event) => {
      event.preventDefault();
      this.focusNextField(nextField);
    });
  }

  private slicInputs(formdata: any){
    formdata.CHARGINGINVESTMENT_C23 =  this.checkCharAtLatPosition(formdata.CHARGINGINVESTMENT_C23);
    formdata.CHARGINGINVESTMENT_C24 =  this.checkCharAtLatPosition(formdata.CHARGINGINVESTMENT_C24);
    formdata.CHARGINGINVESTMENT_C26 =  this.checkCharAtLatPosition(formdata.CHARGINGINVESTMENT_C26);
    formdata.CHARGINGINVESTMENT_C27 =  this.checkCharAtLatPosition(formdata.CHARGINGINVESTMENT_C27);
    formdata.CHARGINGINVESTMENT_C29 =  this.checkCharAtLatPosition(formdata.CHARGINGINVESTMENT_C29);
    formdata.CHARGINGINVESTMENT_C30 =  this.checkCharAtLatPosition(formdata.CHARGINGINVESTMENT_C30);
    formdata.CHARGINGINVESTMENT_C32 =  this.checkCharAtLatPosition(formdata.CHARGINGINVESTMENT_C32);
    formdata.CHARGINGINVESTMENT_C33 =  this.checkCharAtLatPosition(formdata.CHARGINGINVESTMENT_C33);
    formdata.CHARGINGINVESTMENT_C48 =  this.checkCharAtLatPosition(formdata.CHARGINGINVESTMENT_C48);

    // formdata.CHARGINGINVESTMENT_D24 =  this.checkCharAtLatPosition(formdata.CHARGINGINVESTMENT_D24);
    formdata.CHARGINGINVESTMENT_D23 =  this.checkCharAtLatPosition(formdata.CHARGINGINVESTMENT_D23);
    formdata.CHARGINGINVESTMENT_D26 =  this.checkCharAtLatPosition(formdata.CHARGINGINVESTMENT_D26);
    formdata.CHARGINGINVESTMENT_D29 =  this.checkCharAtLatPosition(formdata.CHARGINGINVESTMENT_D29);
    formdata.CHARGINGINVESTMENT_D32 =  this.checkCharAtLatPosition(formdata.CHARGINGINVESTMENT_D32);
    formdata.CHARGINGINVESTMENT_D48 =  this.checkCharAtLatPosition(formdata.CHARGINGINVESTMENT_D48);

    return formdata
  }

  private checkCharAtLatPosition(inputValue: any){
    if(inputValue && typeof inputValue === 'string'){
      const lastChar = inputValue.charAt(inputValue.length - 1)
      const isNotDigit: boolean = /\D/.test(lastChar);
      if(isNotDigit){
        return inputValue.slice(0, -1)
      } else {
        return inputValue;
      }
    } else {
      return inputValue
    }

  }
  formatCurrency(input:any = '') {
    input = input + '';
    const value = parseFloat(input.replace(/[$,]/g, ''));

    // Check if the value is a valid number
    if (isNaN(value)) {
        // console.error('Invalid input');
        return null;
    }

    // Format the number with commas
    const formattedValue = value.toLocaleString('en-US');

    return '$' + formattedValue;
}

}
