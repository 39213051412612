import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpClient,
} from '@angular/common/http';
import { EncryptDecryptService } from '../services/encrypt-descrypt.service';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ResponseDecryptionInterceptor implements HttpInterceptor {
  constructor(
    private httpClient: HttpClient,
    private encryptDecryptService: EncryptDecryptService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        next: (ev: any) => {
          if (ev instanceof HttpResponse) {
            if(environment.ENCRYPTION_ENABLED) {
              const decryptedResponse = JSON.parse(this.encryptDecryptService.decrypt(ev.body.data));
              ev.body.data = decryptedResponse;
            }
            return ev;
          } else {
            return ev;
          }
        },
        error: (err: any) => {
          console.error(err);
        },
      })
    );
  }
}
