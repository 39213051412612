import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
const BASE_URL = environment.API_URL;
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  httpHeaders: HttpHeaders = new HttpHeaders({
  });
  authDetails: any = '';
  loggedIn: boolean = false;
  userName: any = '';
  finalUserData:any;
  private sessionId : any;

  constructor(private http: HttpClient, private cookieService: CookieService) {console.log(BASE_URL,"authservice",environment) }

  getUserDetails(cookies: any) {
    let cookies1 = {cookies}
    // const cookieValue = localStorage.getItem("idToken");
    // this.httpHeaders.append("TEX",cookieValue)
    return this.http.post<any>(`${BASE_URL}/login`, cookies1)
 
  }

  logoutUser(data: any) {
    return this.http.post<any>(environment.logoutUrl,data);
  }

  saveCurrentUser(user: any) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('currentUser') as string);
  }
  getUserData() {
    return this.http.post<any>(`${BASE_URL}/admin/userlist`, {});
  }
  saveUserData(data:any) {
    return this.http.post<any>(`${BASE_URL}/loggedInUserDetails`, data);
    
  }
  fetchUserData(): Observable<any> {
    return this.http.get(`${BASE_URL}/loggedInUserDetails`);
  }
  setSessionId(data:any){
    this.sessionId = data
  }
  getSessionId(){
    return this.sessionId
  }
}
