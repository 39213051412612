import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class PdfConverterService {
  imageUrl: any;
  imagePath: any;
  loadCount = 0;
  loadedImages:any = {};
  constructor(private santizer: DomSanitizer) {
    // if(imgData.page === 'Dealership Assumptions') {
    //   this.imagePath = 'assets/images/dealership-assumptions-print-image.png';
    // } else if(imgData.page === 'BEV Readiness') {
    //   this.imagePath = 'assets/images/bev-readines-print-image.png';
    // } else if(imgData.page === 'Dealership Revenues & Investments') {
    //   this.imagePath = 'assets/images/revenue-investments-print-image.png';
    // } else if(imgData.page === 'Scoring Worksheet') {
    //   this.imagePath = 'assets/images/scoring-worksheet-print-image.png';
    // } else {
    //   this.imagePath = 'assets/images/output-sheet-print-image.png';
    // }
    const imgOrder = [
      {
        imgurl: 'assets/images/dealership-assumptions-print-image.png',
        page: 'Dealership Assumptions',
      },
      {
        imgurl: 'assets/images/bev-readines-print-image.png',
        page: 'BEV Readiness',
      },
      {
        imgurl: 'assets/images/revenue-investments-print-image.png',
        page: 'Dealership Revenues & Investments',
      },
      {
        imgurl: 'assets/images/scoring-worksheet-print-image.png',
        page: 'Scoring Worksheet'
      },
      {
        imgurl:  'assets/images/output-sheet-print-image.png',
        page: 'Output Graph',
      }
    ];
    imgOrder.forEach(item => {
      const introImage = new Image();
    introImage.src = item.imgurl;
    this.loadedImages[item.page]=introImage
    });
  }

  convertMultipleToPdf(images: any): void {
    let pageNumber =1
    for (const iterator of images) {
      const idToLastOccurrenceMap: { [key: string]: any } = {}; // Map to store the last occurrence of each ID
      iterator.imgCapture.flat().forEach((e: any) => {
        idToLastOccurrenceMap[e.id] = e; // Store the element with the ID as the key
      });
      iterator.imgCapture = Object.values(idToLastOccurrenceMap); // Convert the values back to an array
    }
    const seqArray = [
      'Dealership Assumptions',
      'BEV Readiness',
      'Dealership Revenues & Investments',
      'Scoring Worksheet',
      'Output Graph',
    ];
    images.sort((a: any, b: any) => {
      return seqArray.indexOf(a.page) - seqArray.indexOf(b.page);
    });
    const pdf = new jsPDF('p', 'pt', 'a4');
    images.forEach((imgData: any) => {
      var width = pdf.internal.pageSize.getWidth();
      var height =1000;

      let yPosition = 10;
      // const seqArray = ['Dealership Assumptions','BEV Readiness','Dealership Revenues & Investments','Scoring Worksheet', 'Output Graph']

      const disclaimerText =
        "Disclaimer: The information provided by DTNA's Dealer Financial Simulation Tool is intended for informational purposes only and is not to be relied upon as a professional \n opinion nor should it replace Dealers' independent business planning, analysis tools or methods. While the information is thought to be accurate on the date it is provided, \n it is provided on an 'as is' basis and without warranty of any kind, either express or implied and DTNA shall have no liability for any claims arising from the use of or \n reliance on the information contained herein.";
      const introImage = this.loadedImages[imgData.page]
        pdf.addPage();
        pdf.setFontSize(7)
        pdf.setFont('verdana', '', 12);
        pdf.text(`Page ${pageNumber}`, width-30, 10);
        pageNumber++;
        pdf.addImage(
          introImage,
          'PNG',
          5,
          yPosition + 3,
          580,
          200,
          Math.random().toString(36).substring(2, 8),
          'SLOW'
        );

        pdf.setFontSize(7);
        pdf.setFont('helvetica', 'bold');

        pdf.text(disclaimerText, 5, 260);

        yPosition += 2;
        this.imagePath = '';
        this.loadCount++;
        imgData.imgCapture.forEach((img: any, index: any) => {
          pdf.addPage();
          pdf.setFontSize(7);
          pdf.setFont('verdana', '', 12);
          pdf.text(`Page ${pageNumber}`, width-30, 10);
          pageNumber++;
          try {
            pdf.addImage(
              img.imgCaptured,
              'PNG',
              5,
              yPosition,
              width,
              700,
              img.id,
              'SLOW'
            ); // Adjust dimensions as needed
            yPosition += 2;
          } catch (error) {
            //console.error('error', error);
          }

          if (index !== imgData.imgCapture.length - 1) {
            // pdf.addPage();
          }
        });
        if (this.loadCount === images.length) {
          setTimeout(() => {
            pdf.deletePage(1);
            const date = new Date();
            const formattedDate = `${date.getDate()}-${date.toLocaleString(
              'default',
              { month: 'short' }
            )}-${date.getFullYear()}`;
            const fileName = 'DFST' + '-' + formattedDate + '.pdf';
            pdf.save(fileName);
            this.loadCount = 0
          }, 2000);
        }

    });
  }
}
