import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Modal } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from '../services/auth.service';
import { isEmpty, map, merge } from 'lodash';
import { BreadcrumbService } from '../services/breadcumbs.service';
import { FormulaList } from './pursue-opportunity.formulaList';
import { ScreenshotService } from '../services/screenshot.service';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { LoaderService } from '../shared/loader/loader.service';

@Component({
  selector: 'app-purse-opportunity',
  templateUrl: './purse-opportunity.component.html',
  styleUrls: ['./purse-opportunity.component.scss'],
})
export class PurseOpportunityComponent implements OnInit, AfterViewInit {
  pursueOpportunityForm: FormGroup;
  draftData: any;
  partialData: any;
  calculatedData: any;
  draftModal: Modal;
  formulaList = FormulaList;
  formChangeSubscription: Subscription;
  removeToggle: boolean =false;
  @ViewChild('draftModalRef') draftModalRef: ElementRef;

  @ViewChild('revenueGrossProjectContent')
  revenueGrossProjectContentElement: ElementRef;
  @ViewChild('newOpportunitiesContent')
  newOpportunitiesContentElement: ElementRef;

  revenueGrossProjectCapturedImage: any;
  newOpportunitiesCapturedImage: any;
  removeRecalculateButton = true

  bevNewTruckChecked = true;
  bevUsedTruckChecked = true;
  bevPartsChecked = true;
  bevServiceChecked = true;
  bodyshopChecked = true;
  publicChargingChecked = true;
  financingLeasingChecked = true;
  extendedWarrantyChecked = true;
  fullServiceLeasingChecked = true;
  chargerSalesFinancingChecked = true;
  chargerServiceMaintenanceChecked = true;
  constructionProjectFinancingChecked = true;

  constructor(
    public apiService: ApiService,
    private fb: FormBuilder,
    private toast: ToastrService,
    private router: Router,
    private authService: AuthService,
    private breadCrumbsSvc: BreadcrumbService,
    private screenshotService: ScreenshotService,
    private cdr: ChangeDetectorRef,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.apiService.pageTitle = '';
    this.breadCrumbsSvc.addBreadcrumbs();
    this.createPursueOpportunityForm();
  }

  ngAfterViewInit(): void {
    if (Object.keys(this.apiService.formData).length === 0) {
      // this.redirectToLandingPage();
    } else {
      const prevFormData = this.apiService.getPrevFormData();

      if (!isEmpty(prevFormData.purseOpportunity)) {
        this.partialData = prevFormData.purseOpportunity;
      } else {
        this.partialData = this.apiService.getFormData();
      }
      this.calculatedData = this.partialData;
      this.assignDraftData();
    }
    this.createFormChangeSubscription();
  }

  redirectToLandingPage(): void {
    this.router.navigate(['/']);
  }

  createPursueOpportunityForm() {
    this.pursueOpportunityForm = this.fb.group({
      INTERACTIVETAB_K97: [true, Validators.required],
      INTERACTIVETAB_K101: [true, Validators.required],
      INTERACTIVETAB_K105: [true, Validators.required],
      INTERACTIVETAB_K109: [true, Validators.required],
      INTERACTIVETAB_K111: [true, Validators.required],
      INTERACTIVETAB_K118: [true, Validators.required],
      INTERACTIVETAB_K123: [true, Validators.required],
      INTERACTIVETAB_K129: [true, Validators.required],
      INTERACTIVETAB_K135: [true, Validators.required],
      INTERACTIVETAB_K137: [true, Validators.required],
      INTERACTIVETAB_K141: [true, Validators.required],
      INTERACTIVETAB_K143: [true, Validators.required],
    });

    this.onChangeHandler();

    // Transform true/false to YES/NO
    const formArray = map(
      Object.entries(this.pursueOpportunityForm.value),
      (item: any) => {
        item[1] = item[1] ? 'YES' : 'NO';
        return item;
      }
    );
    const formObject = Object.fromEntries(formArray);

    this.partialData = formObject;
  }

  onChangeHandler() {
    const K97 = this.pursueOpportunityForm.get('INTERACTIVETAB_K97')?.value;
    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K97')
      ?.setValue(K97 ? 'YES' : 'NO');

    const K101 = this.pursueOpportunityForm.get('INTERACTIVETAB_K101')?.value;
    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K101')
      ?.setValue(K101 ? 'YES' : 'NO');

    const K105 = this.pursueOpportunityForm.get('INTERACTIVETAB_K105')?.value;
    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K105')
      ?.setValue(K105 ? 'YES' : 'NO');

    const K109 = this.pursueOpportunityForm.get('INTERACTIVETAB_K109')?.value;
    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K109')
      ?.setValue(K109 ? 'YES' : 'NO');

    const K111 = this.pursueOpportunityForm.get('INTERACTIVETAB_K111')?.value;
    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K111')
      ?.setValue(K111 ? 'YES' : 'NO');

    const K118 = this.pursueOpportunityForm.get('INTERACTIVETAB_K118')?.value;
    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K118')
      ?.setValue(K118 ? 'YES' : 'NO');

    const K123 = this.pursueOpportunityForm.get('INTERACTIVETAB_K123')?.value;
    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K123')
      ?.setValue(K123 ? 'YES' : 'NO');

    const K129 = this.pursueOpportunityForm.get('INTERACTIVETAB_K129')?.value;
    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K129')
      ?.setValue(K129 ? 'YES' : 'NO');

    const K135 = this.pursueOpportunityForm.get('INTERACTIVETAB_K135')?.value;
    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K135')
      ?.setValue(K135 ? 'YES' : 'NO');

    const K137 = this.pursueOpportunityForm.get('INTERACTIVETAB_K137')?.value;
    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K137')
      ?.setValue(K137 ? 'YES' : 'NO');

    const K141 = this.pursueOpportunityForm.get('INTERACTIVETAB_K141')?.value;
    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K141')
      ?.setValue(K141 ? 'YES' : 'NO');

    const K143 = this.pursueOpportunityForm.get('INTERACTIVETAB_K143')?.value;
    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K143')
      ?.setValue(K143 ? 'YES' : 'NO');
  }

  getDraftData() {
    if (!this.loaderService.isVisible()) {
      this.loaderService.show();
    }
    const userdata = this.authService.getCurrentUser().data;

    const requestData = {
      userId: userdata.userId,
      draft: this.apiService.formData,
      createdBy: userdata.username,
      updatedBy: userdata.username,
    };

    this.apiService.previousPageData(requestData).subscribe(
      (response) => {
        if (response.status === 'success') {
          this.draftData = response?.data?.draft
            ? JSON.parse(response?.data?.draft?.draft)
            : {};

          const partialData = isEmpty(response.data.partial)
            ? this.draftData
            : response.data.partial;
          this.partialData = merge(this.partialData, partialData);

          this.calculatedData = !isEmpty(this.partialData)
            ? this.partialData
            : this.draftData;

          if (!isEmpty(this.draftData)) {
            this.draftModal = new Modal(this.draftModalRef.nativeElement, {});
            this.draftModal.show();
          } else {
            this.pursueOpportunityForm.reset();
          }
          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
        }
      },
      (err) => {
        //console.log(err);
        if (this.loaderService.isVisible()) {
          this.loaderService.hide();
        }
      }
    );
  }

  yesDraft() {
    this.apiService.loadFromDraft();
    this.apiService.saveFormData(this.partialData);
    this.assignDraftData();
    this.draftModal.hide();
  }

  deleteDraft() {
    this.apiService.discardDraft();
    this.pursueOpportunityForm.reset();
    this.draftModal.hide();
  }

  assignDraftData() {

    this.partialData["INTERACTIVETAB_K97"]==="YES"?this.bevNewTruckChecked = true:this.bevNewTruckChecked = false;
    this.partialData["INTERACTIVETAB_K101"]==="YES"?this.bevUsedTruckChecked = true:this.bevUsedTruckChecked = false;
    this.partialData["INTERACTIVETAB_K105"]==="YES"?this.bevPartsChecked = true:this.bevPartsChecked = false;
    this.partialData["INTERACTIVETAB_K109"]==="YES"?this.bevServiceChecked = true:this.bevServiceChecked = false;
    this.partialData["INTERACTIVETAB_K111"]==="YES"?this.bodyshopChecked = true:this.bodyshopChecked = false;
    this.partialData["INTERACTIVETAB_K118"]==="YES"?this.publicChargingChecked = true:this.publicChargingChecked = false;
    this.partialData["INTERACTIVETAB_K123"]==="YES"?this.financingLeasingChecked = true:this.financingLeasingChecked = false;
    this.partialData["INTERACTIVETAB_K129"]==="YES"?this.extendedWarrantyChecked = true:this.extendedWarrantyChecked = false;
    this.partialData["INTERACTIVETAB_K135"]==="YES"?this.fullServiceLeasingChecked = true:this.fullServiceLeasingChecked = false;
    this.partialData["INTERACTIVETAB_K137"]==="YES"?this.chargerSalesFinancingChecked = true:this.chargerSalesFinancingChecked = false;
    this.partialData["INTERACTIVETAB_K141"]==="YES"?this.chargerServiceMaintenanceChecked = true:this.chargerServiceMaintenanceChecked = false;
    this.partialData["INTERACTIVETAB_K143"]==="YES"?this.constructionProjectFinancingChecked = true:this.constructionProjectFinancingChecked = false;

    this.pursueOpportunityForm.patchValue(this.partialData);
    this.cdr.detectChanges();
   // this.updateControls();
   this.updateForm();
  }

  updateControls() {
    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K97')
      ?.setValue(this.partialData.INTERACTIVETAB_K97 === 'YES');

    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K101')
      ?.setValue(this.partialData.INTERACTIVETAB_K101 === 'YES');

    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K105')
      ?.setValue(this.partialData.INTERACTIVETAB_K105 === 'YES');

    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K109')
      ?.setValue(this.partialData.INTERACTIVETAB_K109 === 'YES');

    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K111')
      ?.setValue(this.partialData.INTERACTIVETAB_K111 === 'YES');

    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K118')
      ?.setValue(this.partialData.INTERACTIVETAB_K118 === 'YES');

    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K123')
      ?.setValue(this.partialData.INTERACTIVETAB_K123 === 'YES');

    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K129')
      ?.setValue(this.partialData.INTERACTIVETAB_K129 === 'YES');

    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K135')
      ?.setValue(this.partialData.INTERACTIVETAB_K135 === 'YES');

    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K137')
      ?.setValue(this.partialData.INTERACTIVETAB_K137 === 'YES');

    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K141')
      ?.setValue(this.partialData.INTERACTIVETAB_K141 === 'YES');

    this.pursueOpportunityForm
      .get('INTERACTIVETAB_K143')
      ?.setValue(this.partialData.INTERACTIVETAB_K143 === 'YES');
  }

  resetForm() {
    this.pursueOpportunityForm.reset();
    this.updateForm();
  }
GrossProfitPage (){
  let GrossRevenue={
    "INTERACTIVETAB_K97":this.bevNewTruckChecked?"YES" :"NO"  ,
    "INTERACTIVETAB_K101":this.bevUsedTruckChecked ?"YES" :"NO",
    "INTERACTIVETAB_K105":this.bevPartsChecked ?"YES" :"NO"     ,
    "INTERACTIVETAB_K109":this.bevServiceChecked ?"YES" :"NO"    ,
    "INTERACTIVETAB_K111":this.bodyshopChecked ?"YES" :"NO"       ,
    "INTERACTIVETAB_K118":this.publicChargingChecked ?"YES" :"NO"  ,
    "INTERACTIVETAB_K123":this.financingLeasingChecked ?"YES" :"NO"  ,
    "INTERACTIVETAB_K129":this.extendedWarrantyChecked ?"YES" :"NO"   ,
    "INTERACTIVETAB_K135":this.fullServiceLeasingChecked ?"YES" :"NO"  ,
    "INTERACTIVETAB_K137":this.chargerSalesFinancingChecked ?"YES" :"NO",
    "INTERACTIVETAB_K141":this.chargerServiceMaintenanceChecked ?"YES" :"NO" ,
    "INTERACTIVETAB_K143":this.constructionProjectFinancingChecked ?"YES" :"NO",
   }

    this.apiService.saveFormData(GrossRevenue);
    this.partialData=merge(this.partialData, GrossRevenue);
}
  nextPage() {
    this.removeRecalculateButton = false
    this.removeToggle = true
    if (this.pursueOpportunityForm.valid) {
      if (!this.loaderService.isVisible()) {
        this.loaderService.show();
      }
      // Transform true/false to YES/NO
      this.GrossProfitPage()
      this.apiService.savePrevFormData(
        'purseOpportunity',
        this.partialData
      );

      const userdata = this.authService.getCurrentUser().data;
      const requestData = {
        userId: userdata.userId,
        draft: this.apiService.formData,
        createdBy: userdata.username,
        updatedBy: userdata.username,
      };
      this.apiService.nextPageNavigation(requestData).subscribe(
        (response) => {
          if (response.status === 'success') {
            this.apiService.saveFormData(response.data.partial);
            this.captureScreenshot();
            this.toast.success('Values saved successfully in Draft');
            if (this.loaderService.isVisible()) {
              this.loaderService.hide();
            }
          }
        },
        (err) => {
          //console.log(err);
          this.toast.error(
            err?.error?.errorDescription ||
              'Something went wrong in saving the values in Draft'
          );
          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
        }
      );
    }
  }

  prevPage() {
    this.apiService.savePrevFormData(
      'purseOpportunity',
      this.pursueOpportunityForm.value
    );
    this.router.navigate(['/full-service-leasing']);
  }

  createFormChangeSubscription() {
    if (this.pursueOpportunityForm) {
      this.formChangeSubscription = this.pursueOpportunityForm.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(() => {
          // this.updateForm();
        });
    }
  }

  updateForm() {
    if (!this.loaderService.isVisible()) {
      this.loaderService.show();
    }
    this.GrossProfitPage()
    const userdata = this.authService.getCurrentUser().data;
    const requestData = {
      userId: userdata.userId,
      draft: this.apiService.formData,
      createdBy: userdata.username,
      updatedBy: userdata.username,
    };
    this.apiService.updatePage(requestData).subscribe(
      (response) => {
        if (response.status === 'success') {
          this.toast.success('Page values updated');

          // Patch values to the form

          this.apiService.saveFormData(response.data.partial);
          this.partialData = response.data.partial;
          this.calculatedData = response.data.partial;
          this.calculatedData.INTERACTIVETAB_H121= (this.calculatedData.INTERACTIVETAB_G86 === 'NO' && this.calculatedData.INTERACTIVETAB_J86 === 'NO') ? 0 : this.calculatedData.INTERACTIVETAB_H121;
          this.pursueOpportunityForm.patchValue(response.data.partial, {
            emitEvent: false,
          });
          //this.updateControls();

          this.cdr.detectChanges();
          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
        }
      },
      (err) => {
        //console.log(err);
        this.toast.error(err?.error?.errorDescription || 'Error updating page');
        if (this.loaderService.isVisible()) {
          this.loaderService.hide();
        }
      }
    );
  }

  async captureScreenshot() {
    const revenueGrossProjectElementToCapture =
      this.revenueGrossProjectContentElement.nativeElement;
    const newOpportunitiesElementToCapture =
      this.newOpportunitiesContentElement.nativeElement;

    const revenueGrossProjectCanavas = await this.screenshotService
      .captureScreenshot(revenueGrossProjectElementToCapture)
      .then((canvas) => {
        this.revenueGrossProjectCapturedImage = canvas.toDataURL('image/png');
      });

    const newOpportunitiesCanavas = await this.screenshotService
      .captureScreenshot(newOpportunitiesElementToCapture)
      .then((canvas) => {
        this.newOpportunitiesCapturedImage = canvas.toDataURL('image/png');
      });

    const imgObject = [
      {
        id: 'revenueGrossProjectContent',
        imgCaptured: this.revenueGrossProjectCapturedImage,
      },

      {
        id: 'newOpportunitiesContent',
        imgCaptured: this.newOpportunitiesCapturedImage,
      },
    ];

    if (this.apiService.pageScreenshot['Dealership Revenues & Investments']) {
      imgObject.forEach((obj) => {
        this.apiService.pageScreenshot['Dealership Revenues & Investments']['imgCapture'].push(obj);
      });
    }
    setTimeout(() => {
      this.router.navigate(['/scoring-worksheet']);
    }, 1000);
  }

  bevNewTruckOpportunity() {
    if (this.bevNewTruckChecked) {
      this.bevNewTruckChecked = false;
    } else {
      this.bevNewTruckChecked = true;
    }
  }

  bevUsedTruckOpportunity() {
    if (this.bevUsedTruckChecked) {
      this.bevUsedTruckChecked = false;
    } else {
      this.bevUsedTruckChecked = true;
    }
  }

  bevPartsOpportunity() {
    if (this.bevPartsChecked) {
      this.bevPartsChecked = false;
    } else {
      this.bevPartsChecked = true;
    }
  }

  bevServiceOpportunity() {
    if (this.bevServiceChecked) {
      this.bevServiceChecked = false;
    } else {
      this.bevServiceChecked = true;
    }
  }

  bodyshopOpportunity() {
    if (this.bodyshopChecked) {
      this.bodyshopChecked = false;
    } else {
      this.bodyshopChecked = true;
    }
  }

  publicChargingOpportunity() {
    if (this.publicChargingChecked) {
      this.publicChargingChecked = false;
    } else {
      this.publicChargingChecked = true;
    }
  }

  financingLeasingOpportunity() {
    if (this.financingLeasingChecked) {
      this.financingLeasingChecked = false;
    } else {
      this.financingLeasingChecked = true;
    }
  }

  extendedWarrantyOpportunity() {
    if (this.extendedWarrantyChecked) {
      this.extendedWarrantyChecked = false;
    } else {
      this.extendedWarrantyChecked = true;
    }
  }

  fullServiceLeasingOpportunity() {
    if (this.fullServiceLeasingChecked) {
      this.fullServiceLeasingChecked = false;
    } else {
      this.fullServiceLeasingChecked = true;
    }
  }

  chargerSalesFinancingOpportunity() {
    if (this.chargerSalesFinancingChecked) {
      this.chargerSalesFinancingChecked = false;
    } else {
      this.chargerSalesFinancingChecked = true;
    }
  }

  chargerServiceMaintenanceOpportunity() {
    if (this.chargerServiceMaintenanceChecked) {
      this.chargerServiceMaintenanceChecked = false;
    } else {
      this.chargerServiceMaintenanceChecked = true;
    }
  }

  constructionProjectFinancingOpportunity() {
    if (this.constructionProjectFinancingChecked) {
      this.constructionProjectFinancingChecked = false;
    } else {
      this.constructionProjectFinancingChecked = true;
    }
  }

  enterSubmitted(event: any) {
    if (event.keyCode === 13) {
      event.stopPropagation();
    }
  }
}
