<div class="breadcrumb">
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
    <div class="breadcrumb-item" *ngIf="!last" >
      <a [routerLink]="breadcrumb.link" [routerLinkActive]="['active']" [class.active]="winLocation.hash ==='#/pursue-opportunity' && breadcrumb.label === 'Revenue and Gross Profit Projections'" [routerLinkActiveOptions]="{ exact: true }">{{ breadcrumb.label }}</a>
    </div>
    <div class="breadcrumb-item OutputGraphTab" *ngIf="last">
      <a [routerLink]="breadcrumb.link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" >{{ breadcrumb.label }}</a>
    </div>
  </ng-container>
</div>
