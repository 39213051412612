<div class="mt-5" #content  style="display: flex; flex-direction: column;align-items: center;">

    <div class="mt-5" #scoringInstructionContent  >
        <p class="text-center font-weight-500 mb-4">Instructions Included Below</p>
        <div class="d-flex scoring-instruction-section">
            <div class="scoring-instruction-title"></div>
            <div class="scoring-instruction-table-title">Maturity of business model</div>
            <div class="scoring-instruction-table-title">Impact on current business</div>
            <div class="scoring-instruction-table-title">New resources & capabilities needed</div>
            <div class="scoring-instruction-table-title">Duration / time to implement</div>
            <div class="scoring-instruction-table-title">Stakeholder constraints</div>
            <div class="scoring-instruction-table-title">Anticipated customer demand</div>
        </div>
        <div class="d-flex scoring-instruction-section">
            <div class="scoring-implementation-title">Ease of implementation score</div>
            <div class="scoring-details-title">Has this opportunity been done at scale in the US CV industry? E.g., are there successful implementations by other players</div>
            <div class="scoring-details-title">How does this opportunity impact the daily operations of your group and rooftops? E.g., separate assets and processes</div>
            <div class="scoring-details-title">To what extent will you need net new resources? E.g., talent, technology, training, partners</div>
            <div class="scoring-details-title"> Consider time to receive relevant approvals as well as to implement within your group</div>
            <div class="scoring-details-title">How would you characterize the barriers to be navigated? Posed by e.g. utilities, gov'ts / regulators, large customers (ESG criteria)</div>
            <div class="scoring-details-title">What is the level of demand you anticipate from your existing customer base?</div>
        </div>
        <div class="d-flex scoring-instruction-section">
            <div class="scoring-implementation-ease">
                <div class="d-flex flex-column font-weight-500 align-items-center scoring-implementation-ease-row">
                    <span>1</span>
                    <span>(low ease)</span>
                </div>
                <div class="right-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div>

            </div>
            <div class="scoring-instruction-details">Requires business model<span class="font-weight-500">&nbsp;innovation</span></div>
            <div class="scoring-instruction-details font-weight-500">Disruptive</div>
            <div class="scoring-instruction-details"><span class="font-weight-500">Net new&nbsp;</span>talent, technology, etc.</div>
            <div class="scoring-instruction-details"><span class="font-weight-500">> 18&nbsp;</span> months</div>
            <div class="scoring-instruction-details font-weight-500">Prohibitive</div>
            <div class="scoring-instruction-details font-weight-500">None/limited</div>
        </div>
        <div class="d-flex scoring-instruction-section">
            <div class="scoring-implementation-ease">
                <div class="d-flex flex-column font-weight-500 align-items-center scoring-implementation-ease-row">
                    <span>2</span>
                    <span></span>
                </div>
                <div class="right-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div>

            </div>
            <div class="scoring-instruction-details">Requires<span class="font-weight-500">&nbsp;adjustment</span> to legacy business model</div>
            <div class="scoring-instruction-details font-weight-500">No impact</div>
            <div class="scoring-instruction-details"><span class="font-weight-500">Some upskilling&nbsp;</span>and new resources</div>
            <div class="scoring-instruction-details"><span class="font-weight-500">9-18&nbsp;</span> months</div>
            <div class="scoring-instruction-details font-weight-500">Some</div>
            <div class="scoring-instruction-details font-weight-500">Some</div>
        </div>
        <div class="d-flex scoring-instruction-section">
            <div class="scoring-implementation-ease">
                <div class="d-flex flex-column font-weight-500 align-items-center scoring-implementation-ease-row">
                    <span>3</span>
                    <span>(high ease)</span>
                </div>
                <div class="right-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div>

            </div>
            <div class="scoring-instruction-details"><span class="font-weight-500">Leverages&nbsp;</span>legacy business model</div>
            <div class="scoring-instruction-details font-weight-500">Positive impact</div>
            <div class="scoring-instruction-details font-weight-500">No new capabilities</div>
            <div class="scoring-instruction-details"><span class="font-weight-500">< 9&nbsp;</span> months</div>
            <div class="scoring-instruction-details font-weight-500">None / insignificant</div>
            <div class="scoring-instruction-details font-weight-500">Significant</div>
        </div>
   </div>

   <!-- <div class="d-flex justify-content-end">
        <a class="btn link-btn" (click)="refreshData()" [disabled]="scoringWorksheetForm.invalid">
            <span class="bi-arrow-clockwise mr-2"></span>
            <span>Refresh</span>
        </a>
   </div> -->

    <form [formGroup]="scoringWorksheetForm" [class.remove-icon]="removeArrow"  class="scoring-worksheet-form mt-5" (keydown)="enterSubmitted($event)">
        <div #scoringSectionContent>
            <div class="d-flex">
                <div class="scoring-opportunities-title"></div>
                <div class="scoring-table-title">Maturity of business model</div>
                <div class="scoring-table-title">Impact on current business</div>
                <div class="scoring-table-title">New resources & capabilities needed</div>
                <div class="scoring-table-title">Duration / time to implement</div>
                <div class="scoring-table-title">Stakeholder constraints</div>
                <div class="scoring-table-title">Anticipated customer demand</div>
                <div class="scoring-table-title scoring-table-title-average">Average score</div>
            </div>
            <!-- <div class="d-flex">
                <div class="scoring-table-weighing">Weighting</div>
                <div class="scoring-table-weighing-row">100%</div>
                <div class="scoring-table-weighing-row">100%</div>
                <div class="scoring-table-weighing-row">100%</div>
                <div class="scoring-table-weighing-row">100%</div>
                <div class="scoring-table-weighing-row">100%</div>
                <div class="scoring-table-weighing-row">100%</div>
                <div class="scoring-table-weighing-row-average"></div>
            </div> -->

            <div class="d-flex mt-3">
                <div class="scoring-opportunities">Parts and service (including mobile service)</div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_C6" formControlName="SCORINGWORKSHEET_C6">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_D6" formControlName="SCORINGWORKSHEET_D6">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_E6" formControlName="SCORINGWORKSHEET_E6">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_F6" formControlName="SCORINGWORKSHEET_F6">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_G6" formControlName="SCORINGWORKSHEET_G6">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_H6" formControlName="SCORINGWORKSHEET_H6">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input scoring-input-average" id="SCORINGWORKSHEET_J6">{{SCORINGWORKSHEET_J6}}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="scoring-opportunities">Public onsite charging operation</div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_C7" formControlName="SCORINGWORKSHEET_C7">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_D7" formControlName="SCORINGWORKSHEET_D7">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_E7" formControlName="SCORINGWORKSHEET_E7">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_F7" formControlName="SCORINGWORKSHEET_F7">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_G7" formControlName="SCORINGWORKSHEET_G7">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_H7" formControlName="SCORINGWORKSHEET_H7">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input scoring-input-average" id="SCORINGWORKSHEET_J7">{{SCORINGWORKSHEET_J7}}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="scoring-opportunities"> New truck sales(including e-consulting and extended warranty)</div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_C8" formControlName="SCORINGWORKSHEET_C8">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_D8" formControlName="SCORINGWORKSHEET_D8">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_E8" formControlName="SCORINGWORKSHEET_E8">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_F8" formControlName="SCORINGWORKSHEET_F8">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_G8" formControlName="SCORINGWORKSHEET_G8">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_H8" formControlName="SCORINGWORKSHEET_H8">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input scoring-input-average" id="SCORINGWORKSHEET_J8">{{SCORINGWORKSHEET_J8}}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="scoring-opportunities">Full-service leasing</div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_C9" formControlName="SCORINGWORKSHEET_C9">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_D9" formControlName="SCORINGWORKSHEET_D9">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_E9" formControlName="SCORINGWORKSHEET_E9">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_F9" formControlName="SCORINGWORKSHEET_F9">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_G9" formControlName="SCORINGWORKSHEET_G9">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_H9" formControlName="SCORINGWORKSHEET_H9">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input scoring-input-average" id="SCORINGWORKSHEET_J9">{{SCORINGWORKSHEET_J9}}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="scoring-opportunities">Retail financing & leasing</div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_C10" formControlName="SCORINGWORKSHEET_C10">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_D10" formControlName="SCORINGWORKSHEET_D10">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_E10" formControlName="SCORINGWORKSHEET_E10">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_F10" formControlName="SCORINGWORKSHEET_F10">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_G10" formControlName="SCORINGWORKSHEET_G10">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_H10" formControlName="SCORINGWORKSHEET_H10">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input scoring-input-average" id="SCORINGWORKSHEET_J10">{{SCORINGWORKSHEET_J10}}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="scoring-opportunities">Used truck sales (including extended warranty)</div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_C11" formControlName="SCORINGWORKSHEET_C11">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_D11" formControlName="SCORINGWORKSHEET_D11">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_E11" formControlName="SCORINGWORKSHEET_E11">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_F11" formControlName="SCORINGWORKSHEET_F11">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_G11" formControlName="SCORINGWORKSHEET_G11">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_H11" formControlName="SCORINGWORKSHEET_H11">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input scoring-input-average" id="SCORINGWORKSHEET_J11">{{SCORINGWORKSHEET_J11}}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="scoring-opportunities">Construction project financing</div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_C12" formControlName="SCORINGWORKSHEET_C12">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_D12" formControlName="SCORINGWORKSHEET_D12">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_E12" formControlName="SCORINGWORKSHEET_E12">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_F12" formControlName="SCORINGWORKSHEET_F12">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_G12" formControlName="SCORINGWORKSHEET_G12">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_H12" formControlName="SCORINGWORKSHEET_H12">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input scoring-input-average" id="SCORINGWORKSHEET_J12">{{SCORINGWORKSHEET_J12}}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="scoring-opportunities">Charger retail sales and financing</div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_C13" formControlName="SCORINGWORKSHEET_C13">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_D13" formControlName="SCORINGWORKSHEET_D13">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_E13" formControlName="SCORINGWORKSHEET_E13">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_F13" formControlName="SCORINGWORKSHEET_F13">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_G13" formControlName="SCORINGWORKSHEET_G13">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_H13" formControlName="SCORINGWORKSHEET_H13">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input scoring-input-average" id="SCORINGWORKSHEET_J13">{{SCORINGWORKSHEET_J13}}</div>
            </div>
            <div class="d-flex mt-1">
                <div class="scoring-opportunities">Charger service and maintenance</div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_C14" formControlName="SCORINGWORKSHEET_C14">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_D14" formControlName="SCORINGWORKSHEET_D14">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_E14" formControlName="SCORINGWORKSHEET_E14">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_F14" formControlName="SCORINGWORKSHEET_F14">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_G14" formControlName="SCORINGWORKSHEET_G14">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input">
                    <select class="custom-select" id="SCORINGWORKSHEET_H14" formControlName="SCORINGWORKSHEET_H14">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div class="scoring-input scoring-input-average" id="SCORINGWORKSHEET_J14">{{SCORINGWORKSHEET_J14}}</div>
            </div>
        </div>
   </form>

    <div class="d-flex justify-content-end mt-4 w-100">
        <button class="btn link-btn" (click)="prevPage()">Back</button>
        <button class="btn link-btn ml-3" (click)="resetForm()">Reset</button>
        <button class="btn link-btn ml-3" (click)="refreshData()" [disabled]="scoringWorksheetForm.invalid">Calculate</button>
        <button class="btn link-btn ml-3" (click)="nextPage()" [disabled]="scoringWorksheetForm.invalid">Next Step</button>
    </div>
</div>

<!-- <div #draftModalRef class="modal fade" id="draftModal" tabindex="-1" aria-labelledby="userProfileModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <span type="button" class="close" (click)="draftModal.hide()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="bi-x-circle-fill"></i></span>
                </span>
            </div>
            <div class="modal-body">
                <div>
                    <p class="text-capitalize">Would you like to resume from your Last Draft</p>
                    <div class="d-flex justify-content-end mt-4">
                        <button class="btn link-btn" (click)="yesDraft()">Resume</button>
                        <button class="btn link-btn ml-3" (click)="deleteDraft()">Fresh Input</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
