<div class="instruction-section mt-5 pl-4 pr-4 pb-3 pt-4">
    <div class="d-flex">
        <div class="ml-4 mr-2 w-50">
            <div class="font-weight-bold">
                <p>
                    Steps To Completing The Financial Simulation:
                </p>
                <ul class="ml-3">
                    <li>Input your Dealership's or Dealer Group's-specific information.</li>
                    <li>Select which opportunities you are interested in pursuing (if applicable).</li>
                    <li>Complete the investment input section.</li>
                    <li>Review the Profit/Loss implications based on your Dealer-specific inputs.</li>
                    <li>Complete the ease of implementation scoring worksheet</li>
                    <li>View the opportunity evaluation matrix.</li>
                </ul>
            </div>
            <div class="font-weight-bold">
                <p>Customize To Your Dealer Group:</p>
                <p>
                    The simulation tool will allow you to input your Dealer Group's revenues by department, new truck sales volumes, number of rooftops, number of service bays, etc.
                </p>
            </div>
            <div style="display: flex; flex-direction: column;">
                <a  style="max-width: 480px; margin-bottom: 0.75rem !important" class="btn link-btn financial-simulation-btn mt-5 mb-5 text-capitalize" routerLink="/interactive-tab">Customize financial simulation to my dealer group</a>
                <button style="max-width: 180px; margin-bottom: 1rem;border-radius: 12px;" class="btn link-btn" (click)="prevPage()">Back</button>

            </div>
            
        </div>
        <div class="key-points-section ml-5 w-50">
            <div class="font-weight-bold ml-2">
                <p>Key Points: </p>
                <div class="key-points-information">
                    <ul class="pt-2">
                        <li>The simulation tool uses your input, numerous parameters and assumptions, together with projected Battery Electric Vehicle (BEV) adoption rates to estimate revenue and gross profit in nine opportunity areas. The default BEV adoption rates in the tool were determined after extensive research conducted by the Boston Consulting Group. DTNA recommends not changing these default assumptions, unless you believe adoption rates in your market(s) will differ significantly.</li>
                        <li>All future financial calculations are in today's dollar and do not account for growth for pure inflation.</li>
                        <li>The tool uses DTNA's large Dealer group historical averages to calculate gross profit margins.</li>
                        <li>Please enter your dealer group's specific information in all mandatory fields (designated by a red asterix).  For any non-mandatory input cells left blank, the model uses the default value from the default input cell.</li>
                        <li>Hover over (i) for additional information or clarification.</li>
                    </ul>
                    <div>
                        <div class="d-flex justify-content-center pb-4">
                            <button style="background-color: white;" class="cell-dfn dealer-input-cell mr-4">Dealer Input Cell</button>
                            <button class="cell-dfn calculated-cell">Calculated Cell</button>
                        </div>
                        <div class="d-flex justify-content-center pb-4">
                            <button class="cell-dfn output-cell mr-4">Output Cell</button>
                            <button class="cell-dfn default-input">Default Input</button>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </div>
    <p class="notes-text">Note: This simulation tool should be used to help formulate business cases and profit/loss projections, and is not intended to replace Dealers' detailed business planning.</p>
</div>
