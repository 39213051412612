<div class="container mt-5">
  <h1 class="text-center mb-3">Application Usage</h1>
  <div class="d-flex align-items-center mb-3">
    <button class="btn btn-success btn-fixed-height" (click)="goBack()">Back</button>
    <div class="d-flex align-items-center ml-3">
      <div class="form-inline mr-3">
        <label for="startDate" class="mr-2">Start Date</label>
        <input 
          type="date" 
          id="startDate" 
          class="form-control" 
          [(ngModel)]="startDate" 
          (focus)="selectDate($event)"
          (keydown)="handleClear($event, 'startDate')"
          style="width: auto;"
          > 
      </div>
      <div class="form-inline ml-3">
        <label for="endDate" class="mr-2">End Date</label>
        <input 
          type="date" 
          id="endDate" 
          class="form-control" 
          [(ngModel)]="endDate" 
          (focus)="selectDate($event)"
          (keydown)="handleClear($event, 'endDate')"
          style="width: auto;"> 
      </div>
      <div class="ml-3">
        <button class="btn btn-success btn-fixed-height" (click)="fetchData()" [disabled]="!startDate && !endDate">Fetch</button>
      </div>
    </div>

    <div class="ml-3">
      <button class="btn btn-secondary btn-fixed-height" (click)="clearDates()">Clear</button>
    </div>

      <div *ngIf="isDataFetched" class="ml-3 d-flex align-items-center">
        <label class="switch mb-0 ml-2">
          <input type="checkbox" [(ngModel)]="toggleSwitch" (change)="onToggleChange()" checked>
          <span class="slider round"></span>
        </label>
        <span class="ml-2">{{ toggleSwitch ? 'Showing Dealer Principal' : 'Showing All Users Data' }}</span>
      </div>

    <div class="ml-auto d-flex">
      <button class="btn btn-success btn-fixed-height" (click)="exportToExcel()">Export</button>
    </div>
  </div>
  
  <p *ngIf="isDataFetched" id="timeZoneMessage" style="text-align: right; font-style: italic;">
    Note: Time is displayed in UTC <span id="userTimeZone"></span>
 </p>
  <table class="table table-bordered mt-3" *ngIf="isDataFetched" #table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Short ID</th>
        <th>Email</th>
        <th>Logged On</th>
        <th>Saved Scenarios</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of userList">
        <td>{{ user.name }}</td>
        <td>{{ user.userid }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.createdAt }}</td>
        <td>{{ user.scenariocount }}</td>
      </tr>
      <tr *ngIf="userList.length === 0">
        <td colspan="5" class="text-center">No results found</td>
      </tr>
    </tbody>
  </table>
</div>