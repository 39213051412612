import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMinMaxValue]'
})
export class MinMaxValueDirective {

  @Input() minValue: number;
  @Input() maxValue: number;

  constructor(private el: ElementRef, private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const removedCommaValue = this.el.nativeElement.value.replace(/[$,]/g, '');
    const inputValue = +removedCommaValue; // Convert input value to a number
    if (this.minValue !== undefined && inputValue < this.minValue) {
      this.el.nativeElement.value = this.minValue;
      this.ngControl.control?.setValue(this.el.nativeElement.value)
      this.el.nativeElement.parentElement.parentElement.querySelector('.show-min-max-error').classList.remove('d-none');
    } else {
      this.el.nativeElement.parentElement.parentElement.querySelector('.show-min-max-error').classList.contains('d-none') ? '' : this.el.nativeElement.parentElement.parentElement.querySelector('.show-min-max-error').classList.add('d-none');
    }
    if (this.maxValue !== undefined && inputValue > this.maxValue) {
      this.el.nativeElement.value = this.maxValue;
      this.ngControl.control?.setValue(this.el.nativeElement.value);
      this.el.nativeElement.parentElement.parentElement.querySelector('.show-min-max-error').classList.remove('d-none');
    } else {
      this.el.nativeElement.parentElement.parentElement.querySelector('.show-min-max-error').classList.contains('d-none') ? '' : this.el.nativeElement.parentElement.parentElement.querySelector('.show-min-max-error').classList.add('d-none');
    }
  }
}
