import { Component } from '@angular/core';

@Component({
  selector: 'app-user-logged-out',
  templateUrl: './user-logged-out.component.html',
  styleUrls: ['./user-logged-out.component.scss']
})
export class UserLoggedOutComponent {

}
