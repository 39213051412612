<div class="d-flex ml-3 mt-5">
    <div class="welcome-text-section">
        <p class="welcome-text-dtna">
            Welcome to DTNA's “DEALERSHIP OF THE FUTURE” 
        </p>
        <p class="font-weight-bold welcome-text-simulation-tool">Dealer Financial Simulation Tool</p>
        <p class="font-weight-bold mt-4">
            The commercial vehicle industry faces never before seen opportunity across technological, social and regulatory factors. This simulation tool provides an exploration of future profit pools for DTNA dealers as the industry pivots from Internal Combustion Engines (ICE) to Battery Electric Vehicles (BEVs) in many applications.
        </p>
        <p class="mt-5 font-italic disclaimer-text">
            Disclaimer: The information provided by DTNA's Dealer Financial Simulation Tool is intended for informational purposes only and is not to be relied upon as a professional opinion nor should it replace Dealers' independent business planning, analysis tools or methods. While the information is thought to be accurate on the date it is provided, it is provided on an "as is" basis and without warranty of any kind, either express or implied and DTNA shall have no liability for any claims arising from the use of or reliance on the information contained herein.
        </p>
        <div class="d-flex justify-content-end mt-4" *ngIf="draftaccepted">
            <a class="btn link-btn get-started-btn" routerLink="/instructions">Get Started </a>
        </div>
    </div>
    <div class="welcome-image-section d-flex align-items-center">
        <img src="../../../assets/images/daimler-after-market-image.png" title="image" class="welcome-image" />
    </div>
</div>
<div #draftModalRef class="modal" id="draftModal"  tabindex="-1" aria-labelledby="userProfileModalLabel" data-bs-backdrop="static" data-bs-keyboard="false"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <span type="button" class="close" (click)="draftModal.hide()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="bi-x-circle-fill"></i></span>
                </span>
            </div>
            <div class="modal-body">
                <div>
                    <p class="text-capitalize">Would you like to resume from your Last Draft</p>
                    <div class="d-flex justify-content-end mt-4">
                        <button class="btn link-btn" (click)="yesDraft()">Resume</button>
                        <button class="btn link-btn ml-3" (click)="deleteDraft()">Fresh Input</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" role="dialog">
    <div class="modal-dialog">
    
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
          <h4 class="modal-title" style="color: red;">Access Denied</h4>
        </div>
        <div class="modal-body">
          <p>Dear User,</p>
          <p>
            You are not authorized to acces this application, You will need your DTNA (Daimler Truck North America) issued user ID and password. If you do not have a DTNA issued user ID, or would like to designate a person(s) at your dealership to have access to the application, please contact your dealership’s IT security administrator, who can issue one to you. Please ensure they designate the role of “Dealer Principal” to the user ID.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="closemodalpopup()"  style="border: 1px solid aliceblue;" data-dismiss="modal">Close</button>
        </div>
      </div>
      
    </div>
  </div>