import { AfterViewInit, Component, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Chart } from 'chart.js';
import { AuthService } from '../services/auth.service';
import { map } from 'lodash';
import { PdfConverterService } from '../services/pdfConverter.service';
import { ScreenshotService } from '../services/screenshot.service';
import { Modal } from 'bootstrap';
import { BreadcrumbService } from '../services/breadcumbs.service';
import { LoaderService } from '../shared/loader/loader.service';

@Component({
  selector: 'app-output-graph',
  templateUrl: './output-graph.component.html',
  styleUrls: ['./output-graph.component.scss'],
})
export class OutputGraphComponent implements AfterViewInit {
  @ViewChild('outputGraphCanvas') outputGraphCanvas: any;
  @ViewChild('quadrantCanvas') quadrantCanvas: any;
  @ViewChild('netCashPolarChart') netCashPolarChartCanvas: any;
  @ViewChild('rosPolarChart') rosPolarChartCanvas: any;
  @ViewChild('outputGraphPdf') contentElement: ElementRef;
  @ViewChild('saveOutputModal') saveOutputModal: ElementRef;
  @ViewChild('outputGraphTable')  contentElementTable: ElementRef;
  quadrantCanvasContext: CanvasRenderingContext2D;
  outputGraphCanvasContext: CanvasRenderingContext2D;
  netCashPolarChartContext: CanvasRenderingContext2D;
  rosPolarChartContext: CanvasRenderingContext2D;

  capturedImage: string | null = null;
  outputName = '';
  saveModal: Modal;

  finalUIInput: any = {};
  finalUIOutput: any = {};

  backgroundColor: any = [];
  outputDataTable: any = [];
JSON: any;
contentElementTableImage: any;

  constructor(
    public apiService: ApiService,
    private authService: AuthService,
    private pdfConverterService: PdfConverterService,
    private screenshotService: ScreenshotService,
    private breadCrumbsSvc: BreadcrumbService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.apiService.pageTitle = 'Output Graph';
    this.breadCrumbsSvc.addBreadcrumbs();
        this.authService.fetchUserData().subscribe(res => {
          this.authService.setSessionId(res.recentUsers[0])
        })
  }

  ngAfterViewInit(): void {
    this.getDraftData();
  }

  async  printPdf() {
    const elementToCapture = this.contentElement.nativeElement;
    const tableToCapture = this.contentElementTable.nativeElement;

    await this.screenshotService
    .captureScreenshot(tableToCapture)
    .then((canvas) => {
      this.contentElementTableImage = canvas.toDataURL('image/png');
    });
    await this.screenshotService
      .captureScreenshot(elementToCapture)
      .then((canvas) => {
        this.capturedImage = canvas.toDataURL('image/png');

        const imgObject = [
          {
            id: 'outputGraphChart',
            imgCaptured: this.capturedImage,
          },
          {
            id: 'outputGraphTable',
            imgCaptured: this.contentElementTableImage,
          },
        ];

        const pageObject = {
          page: 'Output Graph',
          imgCapture: imgObject,
        };

        this.apiService.pageScreenshot['Output Graph'] = pageObject;
        this.pdfConverterService.convertMultipleToPdf(
          Object.values(this.apiService.pageScreenshot)
        );
        this.apiService.pageScreenshot = {};
      });
  }

  getDraftData() {
    if (!this.loaderService.isVisible()) {
      this.loaderService.show();
    }
    const userdata = this.authService.getCurrentUser().data;
    const requestData = {
      userId: userdata.userId,
      draft: this.apiService.formData,
      createdBy: userdata.username,
      updatedBy: userdata.username,
    };

    this.apiService.previousPageData(requestData).subscribe(
      (response) => {
        if (response.status === 'success') {
          const draftData = response?.data?.draft
            ? JSON.parse(response.data.draft.draft)
            : {};
          this.apiService.saveFormData(draftData);
          this.finalUIInput = this.apiService.formData;
          this.getOutputGraphData();
          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
        }
      },
      (err) => {
        console.error(err);
        if (this.loaderService.isVisible()) {
          this.loaderService.hide();
        }
      }
    );
  }

  getOutputGraphData() {
    if (!this.loaderService.isVisible()) {
      this.loaderService.show();
    }
    const userData = this.authService.getCurrentUser();

    const postData = {
      userId: userData.data.userId,
      uiInput: this.apiService.formData,
    };

    this.apiService.outputGraphData(postData).subscribe(
      (response) => {
        if (response.status === 'success') {
          this.finalUIOutput = response.data;
          // Get chart data from response data
          const chartData = this.getChartData(response.data);

          const outputGraphCanvas = this.outputGraphCanvas.nativeElement;
          this.outputGraphCanvasContext = outputGraphCanvas.getContext('2d');
          // Render bubble chart
          this.renderChart(this.outputGraphCanvasContext, chartData); //setting the axes details

          // Create the quadrants
          const quadrantCanvas = this.quadrantCanvas.nativeElement;
          quadrantCanvas.width = outputGraphCanvas.width;
          quadrantCanvas.height = outputGraphCanvas.height;
          quadrantCanvas.className = outputGraphCanvas.className;

          quadrantCanvas.style.display = outputGraphCanvas.style.display;

          const recalculatedHeight =
            parseInt(outputGraphCanvas.style.height.slice(0, -2)) - 28;
          quadrantCanvas.style.height = `${recalculatedHeight}px`;

          const recalculatedWidth =
            parseInt(outputGraphCanvas.style.width.slice(0, -2)) - 60;
          quadrantCanvas.style.width = `${recalculatedWidth}px`;

          this.quadrantCanvasContext = quadrantCanvas.getContext('2d');
          this.drawQuadrants(
            this.quadrantCanvasContext,
            this.outputGraphCanvasContext
          );

          const netCashPolarChartCanvas =
          this.netCashPolarChartCanvas.nativeElement;

          this.netCashPolarChartContext =
           netCashPolarChartCanvas.getContext('2d');
          const netCashPolarChartData = this.getPolarChartData(
            'netCash',
            response.data
          );
          this.renderPolarChart(
            this.netCashPolarChartContext,
            netCashPolarChartData
          );

          const rosPolarChartCanvas = this.rosPolarChartCanvas.nativeElement;

          this.rosPolarChartContext =  rosPolarChartCanvas.getContext('2d');
          const rosPolarChartData = this.getPolarChartData(
            'ros',
            response.data
          );
          this.renderPolarChart(this.rosPolarChartContext, rosPolarChartData);

          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
        }
      },
      (err) => {
        console.error(err);
        if (this.loaderService.isVisible()) {
          this.loaderService.hide();
        }
      }
    );
  }

  getPolarChartData(type: any, rawData: any) {
    const graphData: any = [];
    const graphBackgroundColor: any = [];
    const graphLabel: any = [];

    const outputDataArray = Object.entries(rawData);
    if (type === 'netCash') {
      outputDataArray.forEach((item: any) => {
        if (item[0] === 'chargerServiceAndMaintainance') {
          graphLabel.push('CHARGER SERVICE & MAINTAINANCE');
          graphBackgroundColor.push('#a6cad8');
        } else if (item[0] === 'chargerSales') {
          graphLabel.push('CHARGER SALES');
          graphBackgroundColor.push('#ffce42');
        } else if (item[0] === 'fullServiceLeasing') {
          graphLabel.push('FULL SERVICE LEASING');
          graphBackgroundColor.push('#e69123');
        } else if (item[0] === 'newTruckSales') {
          graphLabel.push('NEW TRUCK SALES');
          graphBackgroundColor.push('#ffff40');
        } else if (item[0] === 'retailFinancingAndLeasing') {
          graphLabel.push('RETAIL FINANCING & LEASING');
          graphBackgroundColor.push('#BA55D3');
        } else if (item[0] === 'publicCharging') {
          graphLabel.push('PUBLIC CHARGING');
          graphBackgroundColor.push('#004355');
        } else if (item[0] === 'usedTruckSales') {
          graphLabel.push('USED TRUCK SALES');
          graphBackgroundColor.push('#8A2BE2');
        } else if (item[0] === 'constructionProjectAndFinancing') {
          graphLabel.push('CONSTRUCTION PROJECT & FINANCING');
          graphBackgroundColor.push('#007a93');
        } else {
          graphLabel.push('PARTS & SERVICES');
          graphBackgroundColor.push('#6ea046');
        }

        graphData.push(item[1].netCash);
      });
    } else if (type === 'ros') {
      outputDataArray.forEach((item: any) => {
        if (item[0] === 'chargerServiceAndMaintainance') {
          graphLabel.push('CHARGER SERVICE & MAINTAINANCE');
          graphBackgroundColor.push('#a6cad8');
        } else if (item[0] === 'chargerSales') {
          graphLabel.push('CHARGER SALES');
          graphBackgroundColor.push('#ffce42');
        } else if (item[0] === 'fullServiceLeasing') {
          graphLabel.push('FULL SERVICE LEASING');
          graphBackgroundColor.push('#e69123');
        } else if (item[0] === 'newTruckSales') {
          graphLabel.push('NEW TRUCK SALES');
          graphBackgroundColor.push('#ffff40');
        } else if (item[0] === 'retailFinancingAndLeasing') {
          graphLabel.push('RETAIL FINANCING & LEASING');
          graphBackgroundColor.push('#BA55D3');
        } else if (item[0] === 'publicCharging') {
          graphLabel.push('PUBLIC CHARGING');
          graphBackgroundColor.push('#004355');
        } else if (item[0] === 'usedTruckSales') {
          graphLabel.push('USED TRUCK SALES');
          graphBackgroundColor.push('#8A2BE2');
        } else if (item[0] === 'constructionProjectAndFinancing') {
          graphLabel.push('CONSTRUCTION PROJECT & FINANCING');
          graphBackgroundColor.push('#007a93');
        } else {
          graphLabel.push('PARTS & SERVICES');
          graphBackgroundColor.push('#6ea046');
        }

        graphData.push(item[1].ROS);
      });
    }
    const graphDataObj = {
      graphLabel: graphLabel,
      graphData: graphData,
      graphBackgroundColor: graphBackgroundColor,
    };
    return graphDataObj;
  }

  renderPolarChart(el: any, chartData: any) {
    const data = {
      labels: chartData.graphLabel,
      datasets: [
        {
          data: chartData.graphData,
          backgroundColor: chartData.graphBackgroundColor,
         labels: chartData.graphLabel
        },
      ],
    };

    const config = {
      type: 'polarArea',
      data: data,
      options: {},
    };

    const chart = new Chart(el, config);
  }

  private getAverageDownsize(arr: any) {
    let total = 0;
    let count = 0;

    arr.forEach(function (item: any, index: any) {
      total += item;
      count++;
    });

    return total / count;
  }

  private getChartData(rawData:any) {


    const outputDataArray = Object.entries(rawData)

    const temp = map(outputDataArray, (item: any) => item[1].netCash);
    const temps =[...temp].sort((a,b)=>a.netCash-b.netCash)

    const chartDataSet: any = [];
    let result: any[] = [];
    for (let i = 1; i >= 0; i -= 0.05) {
        result.push(i);
    }

    outputDataArray.sort((a:any,b:any)=>a[1].netCash-b[1].netCash);
    outputDataArray.forEach((item:any,i) => {
      // item[1].netCash = Math.max(0, item[1].netCash);
      item[1].netCash = item[1].netCash;
      item[1].transparency=result[i];
  });


// Find the maximum "netcash" value
const maxNetcash = Math.max(...outputDataArray.map((item:any) => item[1].netCash));

// Define the range of radius values you want to use
const minRadius = 10; // Minimum radius
const maxRadius = 140; // Maximum radius

// Calculate the radius for each object based on its "netcash" value relative to the maximum value
outputDataArray.forEach((item:any) => {
  const radius = (item[1].netCash / maxNetcash) * (maxRadius - minRadius) + minRadius;
      const graphObj = {
        label: '',
        data: [
          {
            x: item[1].implementationScore,
            y:item[1].ROS,
            r: radius,
          },
        ],
        backgroundColor: '',
        marker: {
          fillColor:0,
          fillOpacity:1
        },
        borderColor: 'rgba(75, 192, 192, 1)',

        netCash: item[1].netCash,
      };

      // graphObj.label = `Implementation Score: ${implementationScore} ROS: ${ROS}% Net Cash: $${netCash}`
      const temp = item[0];

      switch (temp) {
        case 'newTruckSales':
          graphObj.label = 'NEW TRUCK SALES';
          graphObj.backgroundColor = `rgba(255, 255, 64, ${item[1].transparency})`;
          break;
        case 'fullServiceLeasing':
          graphObj.label = 'FULL SERVICE LEASING';
          graphObj.backgroundColor = `rgba(230, 145, 35, ${item[1].transparency})`;
          break;
        case 'chargerServiceAndMaintainance':
          graphObj.label = 'CHARGER SERVICE & MAINTAINANCE';
          graphObj.backgroundColor = `rgba(166, 202, 216, ${item[1].transparency})`;
          break;
        case 'chargerSales':
          graphObj.label = 'CHARGER SALES';
          graphObj.backgroundColor = `rgba(255, 206, 66, ${item[1].transparency})`;
          break;
        case 'retailFinancingAndLeasing':
          graphObj.label = 'RETAIL FINANCING & LEASING';
          graphObj.backgroundColor = `rgba(186, 85, 211, ${item[1].transparency})`;
          break;
        case 'publicCharging':
          graphObj.label = 'PUBLIC CHARGING';
          graphObj.backgroundColor = `rgba(0, 67, 85, ${item[1].transparency})`;
          break;
        case 'usedTruckSales':
          graphObj.label = 'USED TRUCK SALES';
          graphObj.backgroundColor = `rgba(138, 43, 226, ${item[1].transparency})`;
          break;
        case 'constructionProjectAndFinancing':
          graphObj.label = 'CONSTRUCTION PROJECT & FINANCING';
          graphObj.backgroundColor = `rgba(0, 122, 147, ${item[1].transparency})`;
          break;
        case 'partsAndServices':
          graphObj.label = 'PARTS & SERVICES';
          graphObj.backgroundColor = `rgba(110, 160, 70, ${item[1].transparency})`;
          break;
        default:
          break;
      }

      chartDataSet.push(graphObj);
    });

    this.outputDataTable = outputDataArray;

    const data = {
      datasets: chartDataSet,
    };

    return data;
  }

  private drawQuadrants(sourceCtx: any, targetCtx: any) {
    const xMid = Math.ceil(targetCtx.canvas.width / 2);
    const yMid = Math.ceil(targetCtx.canvas.height / 2);

    // Set colors for quadrants
    const colors = [
      'rgba(255,255,255,255)',
      'rgba(242,248,238,255)',
      'rgba(255,232,232,255)',
      'rgba(255,255,255,255)',
    ];

    // Draw quadrants
    sourceCtx.fillStyle = colors[0];
    sourceCtx.fillRect(0, 0, xMid, yMid);

    sourceCtx.fillStyle = colors[1];
    sourceCtx.fillRect(xMid, 0, xMid, yMid);

    sourceCtx.fillStyle = colors[2];
    sourceCtx.fillRect(0, yMid, xMid, yMid);

    sourceCtx.fillStyle = colors[3];
    sourceCtx.fillRect(xMid, yMid, xMid, yMid);
  }

  private renderChart(el: any, chartData: any) {
    // Chart options
    const options = {
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true, //adding x axes minimum value
              max: 4,
              min:0
            },
            scaleLabel: {
              display: true,
              labelString: 'Ease Of Implementation Average Score',
            },
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              min:-20, //adding y axes minimum value
              max:120
            },
            scaleLabel: {
              display: true,
              labelString: 'Return of Sales %',
            },
            gridLines: {
              display: false,
            },
          },
        ],
      },
      legend: {
        display: true,
        labels: {
          padding: 25,   // Add some padding to the legend
          position: 'right',
          textAlign: "center",
          margin: {
            left: 10,       // Add left margin
            top: 0         // Add top margin
          }
        },
      },
      tooltips: {

        callbacks: {
          label: function (tooltipItem: any, data: any) {
            const netCashLabel =
              data.datasets[tooltipItem.datasetIndex].netCash;
            return (
              data.datasets[tooltipItem.datasetIndex].label +
              ':Implementation Score:' +
              tooltipItem.xLabel +
              ', Return on Sales:' +
              tooltipItem.yLabel +
              '%,Net Cash:$' +
              netCashLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            );
          },
        },
      },
    };

    // Chart Config
    const config = {
      type: 'bubble',
      data: chartData,
      options: options,
    };

    // Chart render
    const chart = new Chart(el, config);
  }

  openSaveScenarioModal() {
    if (this.saveOutputModal !== undefined) {
      this.saveOutputModal.nativeElement.focus();
    }
    this.saveModal = new Modal(this.saveOutputModal.nativeElement, {});
    this.saveModal.show();
  }

  saveScenario() {
    if (this.outputName) {
      if (!this.loaderService.isVisible()) {
        this.loaderService.show();
      }

      const detailObject = {
        input: this.finalUIInput,
        output: this.finalUIOutput,
      };

      const userdata = this.authService.getCurrentUser().data;
      const outputName = this.outputName;
      const postData = {
        userId: userdata.userId,
        outputName: outputName,
        details: detailObject,
        createdBy: userdata.username,
        updatedBy: userdata.username,
        id:this.authService.getSessionId().id
      };
      this.apiService.saveScenario(postData).subscribe((response) => {
        if (response.status == 'success') {
          this.outputName = '';
          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
        }
      },
      (err) => {
        console.error(err);
        if (this.loaderService.isVisible()) {
          this.loaderService.hide();
        }
      });

      this.saveModal.hide();
    }
  }
}
