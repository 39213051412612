<div class="mt-5">
    <input type="hidden" id="REFRESH_PAGE" value=""/>
    <form [formGroup]="chargingInvestmentForm" class="charging-investment-form" (keydown)="enterSubmitted($event)">
        <div #content>
            <div>
                <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                    <span>Number of current-gen DC chargers across dealer group</span>
                    <span class="bi-info-circle ml-2 w-auto" placement="top"
                    ngbTooltip="These values come from the Interactive Tab.  To edit them return to the Public Charging Assumptions section"></span>
                </p>
                <div class="row">
                    <div class="input-box col-6 align-items-start calculated-cell">
                        <label class="text-uppercase">2026</label>
                        <!-- {{partialData?.CHARGINGINVESTMENT_C13 | currencyFormat}} -->
                        <span [class.modify-cell-during-print]="modifyCell" class="calculated-cell"  appFormattedValue [isNumber]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_C8"></span>
                    </div>
                    <div class="input-box col-6 align-items-start calculated-cell">
                        <label class="text-uppercase">2030</label>
                        <!-- {{partialData?.CHARGINGINVESTMENT_D13 | currencyFormat}} -->
                        <span [class.modify-cell-during-print]="modifyCell" class="calculated-cell"  appFormattedValue [isNumber]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_D8"></span>
                    </div>
                </div>
            </div>

            <div>
                <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                    <span>Number of next-gen megawatt fast chargers across dealer group</span>
                    <span class="bi-info-circle ml-2 w-auto" placement="top"
                    ngbTooltip="These values come from the Interactive Tab.  To edit them return to the Public Charging Assumptions section"></span>
                </p>
                <div class="row">
                    <div class="input-box col-6 align-items-start calculated-cell">
                        <label class="text-uppercase">2026</label>
                        <!-- {{partialData?.CHARGINGINVESTMENT_C14 | currencyFormat}} -->
                        <span [class.modify-cell-during-print]="modifyCell" class="calculated-cell"  appFormattedValue [isNumber]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_C9"></span>
                    </div>
                    <div class="input-box col-6 align-items-start calculated-cell">
                        <label class="text-uppercase">2030</label>
                        <!-- {{partialData?.CHARGINGINVESTMENT_D14 | currencyFormat}} -->
                        <span [class.modify-cell-during-print]="modifyCell" class="calculated-cell"  appFormattedValue [isNumber]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_D9"></span>
                    </div>
                </div>
            </div>

            <div>
                <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                    <span>Participate in amenity sales? (Yes/No)</span>
                    <span class="bi-info-circle ml-2 w-auto" placement="top"
                    ngbTooltip="These values come from the Interactive Tab.  To edit them return to the Public Charging Assumptions section"></span>
                </p>
                <div class="row">
                    <div class="input-box col-6 align-items-start calculated-cell">
                        <label class="text-uppercase">2026</label>
                        <!-- {{partialData?.CHARGINGINVESTMENT_C15 | currencyFormat}} -->
                        <span [class.modify-cell-during-print]="modifyCell" class="calculated-cell"  appFormattedValue [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_C10"></span>
                    </div>
                    <div class="input-box col-6 align-items-start calculated-cell">
                        <label class="text-uppercase">2030</label>
                        <!-- {{partialData?.CHARGINGINVESTMENT_D15 | currencyFormat}} -->
                        <span [class.modify-cell-during-print]="modifyCell" class="calculated-cell"  appFormattedValue [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_D10"></span>
                    </div>
                </div>
            </div>

            <div class="mt-5">
                <div class="d-flex">
                    <p class="font-weight-500">Charging Solutions</p>
                    <div class="ml-5">
                        <label class="switch">
                            <input type="checkbox" id="toggleButton" (click)="onChargerSectionChecked()">
                            <div class="slider round"></div>
                        </label>
                    </div>
                </div>
                <div class="charger-section px-3 py-4" [hidden]="!chargerSectionChecked">
                    <div>
                        <p class="font-weight-500">Cost Of Charging Solutions
                            <span class="bi-info-circle ml-2 w-auto" placement="top"
                    ngbTooltip="These data points are for reference only and cannot be altered by the user"></span>
                        </p>
                        <div class="ml-4">
                            <div>
                                <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Depot DC charging solution with 20 chargers</p>
                                <div class="row">
                                    <div class="input-box col-6 align-items-start default-cell">
                                        <label class="text-uppercase">2026</label>
                                        <!-- {{partialData?.CHARGINGINVESTMENT_C16 | currencyFormat}} -->
                                        <span  [class.modify-cell-during-print]="modifyCell" class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_C13"></span>
                                    </div>
                                    <div class="input-box col-6 align-items-start default-cell">
                                        <label class="text-uppercase">2030</label>
                                        <!-- {{partialData?.CHARGINGINVESTMENT_D16 | currencyFormat}} -->
                                        <span [class.modify-cell-during-print]="modifyCell" class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_D13"></span>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Single current-generation DC fast charger</p>
                                <div class="row">
                                    <div class="input-box col-6 align-items-start default-cell">
                                        <label class="text-uppercase">2026</label>
                                        <span [class.modify-cell-during-print]="modifyCell" class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_C15"></span>
                                    </div>
                                    <div class="input-box col-6 align-items-start default-cell">
                                        <label class="text-uppercase">2030</label>
                                        <span [class.modify-cell-during-print]="modifyCell" class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_D15"></span>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Single next-generation megawatt fast charger</p>
                                <div class="row">
                                    <div class="input-box col-6 align-items-start default-cell">
                                        <label class="text-uppercase">2026</label>
                                        <span [class.modify-cell-during-print]="modifyCell" class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_C16"></span>
                                    </div>
                                    <div class="input-box col-6 align-items-start default-cell">
                                        <label class="text-uppercase">2030</label>
                                        <span [class.modify-cell-during-print]="modifyCell" class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_D16"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-4">
                        <p class="font-weight-500">Number Of Chargers Formulated</p>
                        <div class="ml-4">
                            <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Incremental chargers</p>
                            <div class="row">
                                <div class="input-box col-6 align-items-start default-cell">
                                    <label class="text-uppercase">2026</label>
                                    <span [class.modify-cell-during-print]="modifyCell" class="calculated-cell"  appFormattedValue [isNumber]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_C19"></span>
                                </div>
                                <div class="input-box col-6 align-items-start default-cell">
                                    <label class="text-uppercase">2030</label>
                                    <span [class.modify-cell-during-print]="modifyCell" class="calculated-cell"  appFormattedValue [isNumber]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_D19"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <div style="display: flex;justify-content: space-between;margin-right: 35px;">
                    <p class="font-weight-500">Costs Associated With Public Onsite Charging</p>
                    <div style="display: flex;flex-direction: column;align-items: flex-end;">
                    <p *ngIf="removeRecalculateButton" style="margin-bottom: 5px;" class="font-weight-500 font-size-small text-capitalize">To reflect results on this page from input provided, press the "Calculate"</p>
                     <button *ngIf="removeRecalculateButton" style="margin-left: 0px !important;max-width: 200px;" class="btn link-btn ml-3" (click)="updateForm()" [disabled]="chargingInvestmentForm.invalid">Calculate
                        <span class="bi-info-circle ml-2 w-auto" placement="top"
                        ngbTooltip="Click on 'Calculate' to calculate results based on input provided on this and previous sections.">
                    </span></button>
                    </div>
                </div>

                <div class="ml-4">
                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                            <span>Number of locations offering public charging</span>
                            <span class="bi-info-circle ml-2 w-auto" placement="top"
                            ngbTooltip="Input total number of locations in your dealer group offering charging by 2026.  Input number of any additional locations offering public charging between 2026 and 2030."></span>
                        </p>
                        <div class="row">
                            <div class="input-box col-6 align-items-start">
                                <label class="text-uppercase">Total by 2026</label>
                                <input type="text" appAllowOnlyNumbers id ="CHARGINGINVESTMENT_C23" value="" formControlName="CHARGINGINVESTMENT_C23" />
                            </div>
                            <div class="input-box col-6 align-items-start">
                                <label class="text-uppercase">Incremental by 2030</label>
                                <input type="text" appAllowOnlyNumbers id ="CHARGINGINVESTMENT_D23" value="" formControlName="CHARGINGINVESTMENT_D23" />
                            </div>
                        </div>
                    </div>

                    <div class="average-cost-section">
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                            <span>Average construction cost per location ($)</span>
                            <span class="bi-info-circle ml-2 w-auto" placement="top"
                            ngbTooltip="Average cost to install chargers (breaking concrete, paving, etc.) not including electrical panel/wiring upgrades"></span>
                        </p>
                        <div class="row">
                            <div class="input-box col-12 align-items-start">
                                <input placeholder="" type="text" appAllowOnlyCost appNonNegative [currency]="true" id ="CHARGINGINVESTMENT_C24" value="" formControlName="CHARGINGINVESTMENT_C24" />
                            </div>
                            <!-- <div>
                                <label class="text-uppercase">2026</label> -->
                                <!-- <input placeholder="" type="text" appAllowOnlyCost appMinMaxValue [minValue]="0" [maxValue]="2500000000" appNonNegative [currency]="true" id="FULLSVCLEASINGINV_C12" value="" formControlName="FULLSVCLEASINGINV_C12" /> -->
                                <!-- <span class="bi-info-circle ml-2 w-auto" placement="top" ngbTooltip="Enter 0 for no sales"></span> -->
                            <!-- </div> -->
                        </div>
                    </div>

                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                            <span>Number of locations requiring upgrades to electrical panel</span>
                            <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="How many locations will require upgrades to central electrical infrastructure to accommodate public charging"></span>
                        </p>
                        <div class="row">
                            <div class="input-box col-6 align-items-start">
                                <label class="text-uppercase">Total by 2026</label>
                                <input type="text" appAllowOnlyNumbers id ="CHARGINGINVESTMENT_C26" value="" formControlName="CHARGINGINVESTMENT_C26" />
                            </div>
                            <div class="input-box col-6 align-items-start">
                                <label class="text-uppercase">Incremental by 2030</label>
                                <input type="text" appAllowOnlyNumbers id ="CHARGINGINVESTMENT_D26" value="" formControlName="CHARGINGINVESTMENT_D26" />
                            </div>
                        </div>
                    </div>

                    <div class="average-cost-section">
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                            <span>Average electrical panel upgrades cost per location ($)</span>
                            <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="Average cost to upgrade electrical panels at applicable locations"></span>
                        </p>
                        <div class="row">
                            <div class="input-box col-12 align-items-start">
                                <input placeholder="" type="text" appAllowOnlyCost appNonNegative [currency]="true" id ="CHARGINGINVESTMENT_C27" value="" formControlName="CHARGINGINVESTMENT_C27" />
                            </div>
                        </div>
                    </div>

                    <p class="text-info font-italic font-size-small mt-3">*** Approximately 8 charging stations can be installed per acre of land, can vary based on plot shape, location, access points, etc.</p>

                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                            <span>Number of locations requiring land to be acquired</span>
                            <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="Number of locations with public charging where additional land will need to be acquired to offer public charging"></span>
                        </p>
                        <div class="row">
                            <div class="input-box col-6 align-items-start">
                                <label class="text-uppercase">Total by 2026</label>
                                <input type="text" appAllowOnlyNumbers id ="CHARGINGINVESTMENT_C29" value="" formControlName="CHARGINGINVESTMENT_C29" />
                            </div>
                            <div class="input-box col-6 align-items-start">
                                <label class="text-uppercase">Incremental by 2030</label>
                                <input type="text" appAllowOnlyNumbers id ="CHARGINGINVESTMENT_D29" value="" formControlName="CHARGINGINVESTMENT_D29" />
                            </div>
                        </div>
                    </div>

                    <div class="average-cost-section">
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                            <span>Average cost of land acquisition per location ($)</span>
                            <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="Average cost to acquire land for public charging at applicable locations"></span>
                        </p>
                        <div class="row">
                            <div class="input-box col-12 align-items-start">
                                <input placeholder="" type="text" appAllowOnlyCost appNonNegative [currency]="true" id ="CHARGINGINVESTMENT_C30" value="" formControlName="CHARGINGINVESTMENT_C30" />
                            </div>
                        </div>
                    </div>

                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                            <span>Number of locations requiring additional power from utility</span>
                            <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="Number of locations where there is not enough power to support public charging and utility upgrades are needed"></span>
                        </p>
                        <div class="row">
                            <div class="input-box col-6 align-items-start">
                                <label class="text-uppercase">Total by 2026</label>
                                <input type="text" appAllowOnlyNumbers id ="CHARGINGINVESTMENT_C32" value="" formControlName="CHARGINGINVESTMENT_C32" />
                            </div>
                            <div class="input-box col-6 align-items-start">
                                <label class="text-uppercase">Incremental by 2030</label>
                                <input type="text" appAllowOnlyNumbers id ="CHARGINGINVESTMENT_D32" value="" formControlName="CHARGINGINVESTMENT_D32" />
                            </div>
                        </div>
                    </div>

                    <div class="average-cost-section">
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                            <span>Average cost of utility upgrades per location ($)</span>
                            <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="Cost to the dealership for utility upgrades to bring additional power to the location for public charging"></span>
                        </p>
                        <div class="row">
                            <div class="input-box col-12 align-items-start">
                                <input placeholder="" type="text" appAllowOnlyCost appNonNegative [currency]="true" id ="CHARGINGINVESTMENT_C33" value="" formControlName="CHARGINGINVESTMENT_C33" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div #costContent>
            <div class="mt-4">
                <p class="font-weight-500">Cost Of Chargers Solutions</p>
                <div class="ml-4">
                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Cost of current-gen DC chargers across dealer group</p>
                        <div class="row">
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2026</label>
                                <span class="calculated-cell">{{partialData?.CHARGINGINVESTMENT_C36 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_C36"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2030 Incremental</label>
                                <span class="calculated-cell">{{partialData?.CHARGINGINVESTMENT_D36 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_D36"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">Cumulative by 2030</label>
                                <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_E36 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_E36"></span> -->
                            </div>
                        </div>
                    </div>

                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Cost of next-gen megawatt fast chargers across dealer group</p>
                        <div class="row">
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2026</label>
                                <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_C37 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_C37"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2030 Incremental</label>
                                <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_D37 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_D37"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">Cumulative by 2030</label>
                                <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_E37 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_E37"></span> -->
                            </div>
                        </div>
                    </div>

                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Total Charger Cost</p>
                        <div class="row">
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2026</label>
                                <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_C38 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_C38"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2030 Incremental</label>
                                <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_D38 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_D38"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">Cumulative by 2030</label>
                                <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_E38 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_E38"></span> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <p class="font-weight-500 text-capitalize">Cost of facility upgrades</p>
                <div class="ml-4">
                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Cost of construction/installation of chargers across dealer group</p>
                        <div class="row">
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2026</label>
                                <span class="calculated-cell">{{(partialData?.CHARGINGINVESTMENT_C24 * partialData?.CHARGINGINVESTMENT_C23) | currencyFormat}}</span>

                                <!-- {{partialData?.CHARGINGINVESTMENT_C41 | currencyFormat}} -->
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_C41"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2030 Incremental</label>
                                <span class="calculated-cell">{{(partialData?.CHARGINGINVESTMENT_C24 * partialData?.CHARGINGINVESTMENT_D23) | currencyFormat}}</span>

                                <!-- {{partialData?.CHARGINGINVESTMENT_D41 | currencyFormat}} -->
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_D41"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">Cumulative by 2030</label>
                                <span class="calculated-cell">{{(((partialData?.CHARGINGINVESTMENT_C23 || 0) * partialData?.CHARGINGINVESTMENT_C24) + (partialData?.CHARGINGINVESTMENT_C24 * (partialData?.CHARGINGINVESTMENT_D23 || 0))) | currencyFormat }}</span>

                                <!-- {{partialData?.CHARGINGINVESTMENT_E41 | currencyFormat}} -->
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_E41"></span> -->
                            </div>
                        </div>
                    </div>

                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Cost Of electrical panel upgrades across dealer group</p>
                        <div class="row">
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2026</label>
                                <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_C42 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_C42"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2030 Incremental</label>
                                <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_D42 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_D42"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">Cumulative by 2030</label>
                                <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_E42 | currencyFormat}}</span>
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_E42"></span> -->
                            </div>
                        </div>
                    </div>

                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Cost Of Land Acquisition Across Dealer Group</p>
                        <div class="row">
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2026</label>
                                <span class="calculated-cell">{{(partialData?.CHARGINGINVESTMENT_C30 * partialData?.CHARGINGINVESTMENT_C29) | currencyFormat}}</span>

                                <!-- <span class="calculated-cell">{{partialData?.CHARGINGINVESTMENT_C43 | currencyFormat}}</span> -->
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_C43"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2030 Incremental</label>
                                <span class="calculated-cell">{{(partialData?.CHARGINGINVESTMENT_C30 * partialData?.CHARGINGINVESTMENT_D29) | currencyFormat}}</span>

                                <!-- <span class="calculated-cell">{{partialData?.CHARGINGINVESTMENT_D43 | currencyFormat}}</span> -->
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_D43"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">Cumulative by 2030</label>
                                <span class="calculated-cell">{{((partialData?.CHARGINGINVESTMENT_C30 * (partialData?.CHARGINGINVESTMENT_C29 || 0)) + (partialData?.CHARGINGINVESTMENT_C30 * (partialData?.CHARGINGINVESTMENT_D29 || 0))) | currencyFormat}}</span>

                                <!-- <span class="calculated-cell">{{partialData?.CHARGINGINVESTMENT_E43 | currencyFormat}}</span> -->
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_E43"></span> -->
                            </div>
                        </div>
                    </div>

                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Cost Of Utility Line Upgrades Across Dealer Group</p>
                        <div class="row">
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2026</label>
                                <span class="calculated-cell">{{(partialData?.CHARGINGINVESTMENT_C32 * partialData?.CHARGINGINVESTMENT_C33) | currencyFormat}}</span>

                                <!-- <span class="calculated-cell">{{partialData?.CHARGINGINVESTMENT_C44 | currencyFormat}}</span> -->
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_C44"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2030 Incremental</label>
                                <span class="calculated-cell">{{(partialData?.CHARGINGINVESTMENT_C33 * partialData?.CHARGINGINVESTMENT_D32) | currencyFormat}}</span>

                                <!-- <span class="calculated-cell">{{partialData?.CHARGINGINVESTMENT_D44 | currencyFormat}}</span> -->
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_D44"></span> -->
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">Cumulative by 2030</label>
                                <span class="calculated-cell">{{(((partialData?.CHARGINGINVESTMENT_C32 || 0) * partialData?.CHARGINGINVESTMENT_C33) + (partialData?.CHARGINGINVESTMENT_C33 * (partialData?.CHARGINGINVESTMENT_D32 || 0))) | currencyFormat}}</span>

                                <!-- <span class="calculated-cell">{{partialData?.CHARGINGINVESTMENT_E44 | currencyFormat}}</span> -->
                                <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_E44"></span> -->
                            </div>
                        </div>
                    </div>

                    <div>
                        <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Total Facility Upgrades Cost</p>
                        <div class="row">
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2026</label>
                                <span class="calculated-cell">{{partialData?.CHARGINGINVESTMENT_C45 | currencyFormat}}</span>
                                </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">2030 Incremental</label>
                                <span class="calculated-cell">{{partialData?.CHARGINGINVESTMENT_D45 | currencyFormat}}</span>
                            </div>
                            <div class="input-box col-4 align-items-start calculated-cell">
                                <label class="text-uppercase">Cumulative by 2030</label>
                                <span class="calculated-cell">{{partialData?.CHARGINGINVESTMENT_E45 | currencyFormat}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <p class="font-weight-500">Investment Subsidies/Grants</p>
                <div class="ml-4">
                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                        <span>Total Subsidies/Grants Toward Public Charging Investment</span>
                        <span class="bi-info-circle ml-2 w-auto" placement="top"
                                ngbTooltip="Include any state-specific, local, utility, or federal grants/subsidies that will go toward investment costs"></span>
                    </p>
                    <div class="row">
                        <div class="input-box col-4 align-items-start">
                            <label class="text-uppercase">2026</label>
                            <!-- <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_C48 | currencyFormat}}</span> -->
                            <input placeholder="" type="text" appAllowOnlyCost appNonNegative [currency]="true" id ="CHARGINGINVESTMENT_C48" value="" formControlName="CHARGINGINVESTMENT_C48" />
                        </div>
                        <div class="input-box col-4 align-items-start">
                            <label class="text-uppercase">2030 Incremental</label>
                            <!-- <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_D48 | currencyFormat}}</span> -->
                            <input placeholder="" type="text" appAllowOnlyCost appNonNegative [currency]="true" id ="CHARGINGINVESTMENT_D48" value="" formControlName="CHARGINGINVESTMENT_D48" />
                        </div>
                        <div class="input-box col-4 align-items-start calculated-cell">
                            <label class="text-uppercase">Cumulative by 2030</label>
                            <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_E48 | currencyFormat}}</span>
                            <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_E48"></span> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <p class="font-weight-500">Total Investment For Public Charging</p>
                <div class="ml-4">
                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">Total Investment Required For Public Charging</p>
                    <div class="row">
                        <div class="input-box col-4 align-items-start calculated-cell">
                            <label class="text-uppercase">2026</label>
                            <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_C51 | currencyFormat}}</span>
                            <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_C51"></span> -->
                        </div>
                        <div class="input-box col-4 align-items-start calculated-cell">
                            <label class="text-uppercase">2030 Incremental</label>
                            <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_D51 | currencyFormat}}</span>
                            <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_D51"></span> -->
                        </div>
                        <div class="input-box col-4 align-items-start calculated-cell">
                            <label class="text-uppercase">Cumulative by 2030</label>
                            <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_E51 | currencyFormat}}</span>
                            <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_E51"></span> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <p class="font-weight-500">Potential Federal Income Tax Incentive</p>
                <div class="ml-4">
                    <p class="mb-0 mt-3 font-weight-500 dealer-ice-market-share-title">
                        <span>Potential Federal Tax Credit Due To Charger Investment (U.S. Only)</span>
                        <span class="bi-info-circle ml-2 w-auto" placement="top"
                        ngbTooltip="Estimate based on Inflation Reduction Act incentives, for informational purposes only and not included in investment total"></span>
                    </p>
                    <div class="row">
                        <div class="input-box col-4 align-items-start calculated-cell">
                            <label class="text-uppercase">2026</label>
                            <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_C54 | currencyFormat}}</span>
                            <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_C54"></span> -->
                        </div>
                        <div class="input-box col-4 align-items-start calculated-cell">
                            <label class="text-uppercase">2030 Incremental</label>
                            <span class="calculated-cell"> {{partialData?.CHARGINGINVESTMENT_D54 | currencyFormat}}</span>
                            <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_D54"></span> -->
                        </div>
                        <div class="input-box col-4 align-items-start calculated-cell">
                            <label class="text-uppercase">Cumulative by 2030</label>
                            <span class="calculated-cell">{{partialData?.CHARGINGINVESTMENT_E54 | currencyFormat}}</span>
                            <!-- <span class="calculated-cell"  appFormattedValue [isCurrency]="true" [dataScope]="partialData" [formGroup]="chargingInvestmentForm" id="CHARGINGINVESTMENT_E54"></span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end mt-4 mr-3">
            <button class="btn link-btn" (click)="prevPage()">Back</button>
            <button class="btn link-btn ml-3" (click)="resetForm()">Reset</button>
            <button class="btn link-btn ml-3" (click)="updateForm()" [disabled]="chargingInvestmentForm.invalid">Calculate<span class="bi-info-circle ml-2 w-auto" placement="top"
                ngbTooltip="Click on 'Calculate' to calculate results based on input provided on this and previous sections.">
            </span></button>
            <button class="btn link-btn ml-3" (click)="nextPage()" [disabled]="chargingInvestmentForm.invalid">Next Step</button>
        </div>

    </form>
</div>

<!-- <div #draftModalRef class="modal fade" id="draftModal" tabindex="-1" aria-labelledby="userProfileModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <span type="button" class="close" (click)="draftModal.hide()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="bi-x-circle-fill"></i></span>
                </span>
            </div>
            <div class="modal-body">
                <div>
                    <p class="text-capitalize">Would you like to resume from your Last Draft</p>
                    <div class="d-flex justify-content-end mt-4">
                        <button class="btn link-btn" (click)="yesDraft()">Resume</button>
                        <button class="btn link-btn ml-3" (click)="deleteDraft()">Fresh Input</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
