// currency-format.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'lodash';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {
  transform(value: number): string {
    let formattedValue = '';
    if (!value || isNaN(value)) {
      value = 0;
    }
    if (isNumber(value)){
      if (value >= 0) {
        formattedValue = '$' + Math.round(value).toFixed(0).replace(/(\d)(?=(\d{3})+$)/g, '$1,');
      } else {
        value = Math.round(value)
        formattedValue = '$(' + Math.abs(value).toFixed(0).replace(/(\d)(?=(\d{3})+$)/g, '$1,') + ")";
      }
    }
    // Format the number as currency (assuming value is in dollars)
    return formattedValue;
  }
}
