import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { map, valuesIn } from 'lodash';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-comparion-chart',
  templateUrl: './comparion-chart.component.html',
  styleUrls: ['./comparion-chart.component.scss']
})
export class ComparionChartComponent implements AfterViewInit {

  graphData: any;

  @ViewChild('partServiceCanvas') partServiceCanvas: any;
  @ViewChild('newTruckSalesCanvas') newTruckSalesCanvas: any;
  @ViewChild('constructionProjectFinancingCanvas') constructionProjectFinancingCanvas: any
  @ViewChild('chargerSalesCanvas') chargerSalesCanvas: any
  @ViewChild('ChargerServiceMaintenanceCanvas') ChargerServiceMaintenanceCanvas: any;
  @ViewChild('usedTruckSalesCanvas') usedTruckSalesCanvas: any;
  @ViewChild('retailFinancingLeasingCanvas') retailFinancingLeasingCanvas: any
  @ViewChild('fullServiceLeasingCanvas') fullServiceLeasingCanvas: any
  @ViewChild('publicChargingCanvas') publicChargingCanvas: any;

  partServiceCanvasContext: CanvasRenderingContext2D;
  newTruckSalesCanvasContext: CanvasRenderingContext2D;
  constructionProjectFinancingCanvasContext: CanvasRenderingContext2D;
  chargerSalesCanvasContext: CanvasRenderingContext2D;
  ChargerServiceMaintenanceContext: CanvasRenderingContext2D;
  usedTruckSalesCanvasContext: CanvasRenderingContext2D;
  retailFinancingLeasingCanvasContext: CanvasRenderingContext2D;
  fullServiceLeasingCanvasContext: CanvasRenderingContext2D;
  publicChargingCanvasContext: CanvasRenderingContext2D;

  constructor(private apiService: ApiService, private authService: AuthService) {}

  ngAfterViewInit(): void {
    this.getOutputGraphData();
  } 

  getOutputGraphData() {
    const userData = this.authService.getCurrentUser();

    const postData = {
      userId: userData.data.userId,
      uiInput: this.apiService.formData,
    };

    this.apiService.outputGraphData(postData).subscribe(
      (response) => {

        if (response.status === 'success') {
          // Get chart data from response data
          const chartData = response.data;

          const partServiceCanvas = this.partServiceCanvas.nativeElement;
          this.partServiceCanvasContext = partServiceCanvas.getContext('2d');

          const partServiceGraphData = this.getGraphData('partService', chartData);

          this.renderChart(this.partServiceCanvasContext, partServiceGraphData);

          const newTruckSalesCanvas = this.newTruckSalesCanvas.nativeElement;
          this.newTruckSalesCanvasContext = newTruckSalesCanvas.getContext('2d');

          const newTruckSalesGraphData = this.getGraphData('newTruckSales', chartData);

          this.renderChart(this.newTruckSalesCanvasContext, newTruckSalesGraphData);

          const constructionProjectFinancingCanvas = this.constructionProjectFinancingCanvas.nativeElement;
          this.constructionProjectFinancingCanvasContext = constructionProjectFinancingCanvas.getContext('2d');

          const constructionProjectFinancingGraphData = this.getGraphData('ConstructionProjectFinancing', chartData);

          this.renderChart(this.constructionProjectFinancingCanvasContext, constructionProjectFinancingGraphData);

          const chargerSalesCanvas = this.chargerSalesCanvas.nativeElement;
          this.chargerSalesCanvasContext = chargerSalesCanvas.getContext('2d');

          const chargerSalesCanvasGraphData = this.getGraphData('ChargerSales', chartData);

          this.renderChart(this.chargerSalesCanvasContext, chargerSalesCanvasGraphData);

          const ChargerServiceMaintenanceCanvas = this.ChargerServiceMaintenanceCanvas.nativeElement;
          this.ChargerServiceMaintenanceContext = ChargerServiceMaintenanceCanvas.getContext('2d');

          const ChargerServiceMaintenanceGraphData = this.getGraphData('ChargerService&Maintenance', chartData);

          this.renderChart(this.ChargerServiceMaintenanceContext, ChargerServiceMaintenanceGraphData);

          const usedTruckSalesCanvas = this.usedTruckSalesCanvas.nativeElement;
          this.usedTruckSalesCanvasContext = usedTruckSalesCanvas.getContext('2d');

          const usedTruckSalesGraphData = this.getGraphData('UsedTruckSales', chartData);

          this.renderChart(this.usedTruckSalesCanvasContext, usedTruckSalesGraphData);

          const retailFinancingLeasingCanvas = this.retailFinancingLeasingCanvas.nativeElement;
          this.retailFinancingLeasingCanvasContext = retailFinancingLeasingCanvas.getContext('2d');

          const retailFinancingLeasingGraphData = this.getGraphData('RetailFinancingLeasing', chartData);

          this.renderChart(this.retailFinancingLeasingCanvasContext, retailFinancingLeasingGraphData);

          const fullServiceLeasingCanvas = this.fullServiceLeasingCanvas.nativeElement;
          this.fullServiceLeasingCanvasContext = fullServiceLeasingCanvas.getContext('2d');

          const fullServiceLeasingGraphData = this.getGraphData('FullServiceLeasing', chartData);

          this.renderChart(this.fullServiceLeasingCanvasContext, fullServiceLeasingGraphData);

          const publicChargingCanvas = this.publicChargingCanvas.nativeElement;
          this.publicChargingCanvasContext = publicChargingCanvas.getContext('2d');

          const publicChargingGraphData = this.getGraphData('PublicCharging', chartData);

          this.renderChart(this.publicChargingCanvasContext, publicChargingGraphData);

        }
      });

  }

  private getAverageDownsize(arr: any) {
    let total = 0;
    let count = 0;

    arr.forEach(function (item: any, index: any) {
      total += item;
      count++;
    });

    return total / count;
  }

  getGraphData(type: any, rawData: any) {

    let graphObj: any;

    const outputDataArray = Object.entries(rawData);

    const temp = map(outputDataArray, (item: any) => item[1].netCash);

    //const offset = this.getAverageDownsize(temp);
    const offset = 16000000;

    const minRadius = 10;
    // const maxBubbleSize = 150000;
    const minBubbleSize = 20;

    if(type === 'partService') {
      const netCash = rawData.partsAndServices.netCash;

      let bubbleRadius = netCash;

      if (bubbleRadius < offset) {
        bubbleRadius = minBubbleSize;
      } else {
        bubbleRadius = Math.ceil(bubbleRadius/offset) * minRadius;
      }

      let averageBubbleRadius = 13482649;

      if (averageBubbleRadius < offset) {
        averageBubbleRadius = minBubbleSize;
      } else {
        averageBubbleRadius = Math.ceil(averageBubbleRadius/offset) * minRadius;
      }

      const chartDataSet = [
        {
        label: 'Parts & Service Data',
        data: [
          {
            x: rawData.partsAndServices.implementationScore,
            y: rawData.partsAndServices.ROS,
            r: bubbleRadius,
          }
        ],
        backgroundColor: '#6ea046'
        },
        {
          label: 'Parts & Service Market Data',
          data: [
            {
              x: '1.5',
              y: 39,
              r: averageBubbleRadius,
            }
          ],
          backgroundColor: '#343a40'
        }
      ];

      this.graphData = {
        datasets: chartDataSet,
      };

    } else if(type === 'newTruckSales') {
      const netCash = rawData.newTruckSales.netCash;

      let bubbleRadius = netCash;

      if (bubbleRadius < offset) {
        bubbleRadius = minBubbleSize;
      } else {
        bubbleRadius = Math.ceil(bubbleRadius/offset) * minRadius;
      }

      let averageBubbleRadius = 6928509;

      if (averageBubbleRadius < offset) {
        averageBubbleRadius = minBubbleSize;
      } else {
        averageBubbleRadius = Math.ceil(averageBubbleRadius/offset) * minRadius;
      }

      const chartDataSet = [
        {
          label: 'New Truck Sales Data',
          data: [
            {
              x: rawData.newTruckSales.implementationScore,
              y: rawData.newTruckSales.ROS,
              r: bubbleRadius,
            }
          ],
          backgroundColor: '#ffff40'
        },
        {
          label: 'New Truck Sales Market Data',
          data: [
            {
              x: '2.2',
              y: 6,
              r: averageBubbleRadius,
            }
          ],
          backgroundColor: '#343a40'
        }
      ];

      this.graphData = {
        datasets: chartDataSet,
      };
    } else if(type === 'ConstructionProjectFinancing') {
      const netCash = rawData.constructionProjectAndFinancing.netCash;
  
      let bubbleRadius = netCash;
  
      if (bubbleRadius < offset) {
        bubbleRadius = minBubbleSize;
      } else {
        bubbleRadius = Math.ceil(bubbleRadius/offset) * minRadius;
      }
  
      let averageBubbleRadius = 112500;
  
      if (averageBubbleRadius < offset) {
        averageBubbleRadius = minBubbleSize;
      } else {
        averageBubbleRadius = Math.ceil(averageBubbleRadius/offset) * minRadius;
      }
  
      const chartDataSet = [
        {
          label: 'Construction Project Financing Data',
          data: [
            {
              x: rawData.constructionProjectAndFinancing.implementationScore,
              y: rawData.constructionProjectAndFinancing.ROS,
              r: bubbleRadius,
            }
          ],
          backgroundColor: '#007a93'
        },
        {
          label: 'Construction Project Financing Market Data',
          data: [
            {
              x: '1.2',
              y: 100,
              r: averageBubbleRadius,
            }
          ],
          backgroundColor: '#343a40'
        }
      ];
  
      this.graphData = {
        datasets: chartDataSet,
      };
    } else if(type === 'ChargerSales') {
      const netCash = rawData.chargerSales.netCash;
  
      let bubbleRadius = netCash;
  
      if (bubbleRadius < offset) {
        bubbleRadius = minBubbleSize;
      } else {
        bubbleRadius = Math.ceil(bubbleRadius/offset) * minRadius;
      }
  
      let averageBubbleRadius = 90751;
  
      if (averageBubbleRadius < offset) {
        averageBubbleRadius = minBubbleSize;
      } else {
        averageBubbleRadius = Math.ceil(averageBubbleRadius/offset) * minRadius;
      }
  
      const chartDataSet = [
        {
          label: 'Charger Sales Data',
          data: [
            {
              x: rawData.chargerSales.implementationScore,
              y: rawData.chargerSales.ROS,
              r: bubbleRadius,
            }
          ],
          backgroundColor: '#ffce42'
        },
        {
          label: 'Charger Sales Market Data',
          data: [
            {
              x: '1.8',
              y: 7,
              r: averageBubbleRadius,
            }
          ],
          backgroundColor: '#343a40'
        }
      ];
  
      this.graphData = {
        datasets: chartDataSet,
      };
    } else if(type === 'ChargerService&Maintenance') {
      const netCash = rawData.chargerServiceAndMaintainance.netCash;
  
      let bubbleRadius = netCash;
  
      if (bubbleRadius < offset) {
        bubbleRadius = minBubbleSize;
      } else {
        bubbleRadius = Math.ceil(bubbleRadius/offset) * minRadius;
      }
  
      let averageBubbleRadius = 45943;
  
      if (averageBubbleRadius < offset) {
        averageBubbleRadius = minBubbleSize;
      } else {
        averageBubbleRadius = Math.ceil(averageBubbleRadius/offset) * minRadius;
      }
  
      const chartDataSet = [
        {
          label: 'Charger Service & Maintenance Data',
          data: [
            {
              x: rawData.chargerServiceAndMaintainance.implementationScore,
              y: rawData.chargerServiceAndMaintainance.ROS,
              r: bubbleRadius,
            }
          ],
          backgroundColor: '#a6cad8'
        },
        {
          label: 'Charger Service & Maintenance Market Data',
          data: [
            {
              x: '1.3',
              y: 71,
              r: averageBubbleRadius,
            }
          ],
          backgroundColor: '#343a40'
        }
      ];
  
      this.graphData = {
        datasets: chartDataSet,
      };
    } else if(type === 'UsedTruckSales') {
      const netCash = rawData.usedTruckSales.netCash;
  
      let bubbleRadius = netCash;
  
      if (bubbleRadius < offset) {
        bubbleRadius = minBubbleSize;
      } else {
        bubbleRadius = Math.ceil(bubbleRadius/offset) * minRadius;
      }
  
      let averageBubbleRadius = 2727520;
  
      if (averageBubbleRadius < offset) {
        averageBubbleRadius = minBubbleSize;
      } else {
        averageBubbleRadius = Math.ceil(averageBubbleRadius/offset) * minRadius;
      }
  
      const chartDataSet = [
        {
          label: 'Used Truck Sales Data',
          data: [
            {
              x: rawData.usedTruckSales.implementationScore,
              y: rawData.usedTruckSales.ROS,
              r: bubbleRadius,
            }
          ],
          backgroundColor: '#6787a6'
        },
        {
          label: 'Used Truck Sales Market Data',
          data: [
            {
              x: '1.8',
              y: 10,
              r: averageBubbleRadius,
            }
          ],
          backgroundColor: '#343a40'
        }
      ];
  
      this.graphData = {
        datasets: chartDataSet,
      };
    } else if(type === 'RetailFinancingLeasing') {
      const netCash = rawData.retailFinancingAndLeasing.netCash;
  
      let bubbleRadius = netCash;
  
      if (bubbleRadius < offset) {
        bubbleRadius = minBubbleSize;
      } else {
        bubbleRadius = Math.ceil(bubbleRadius/offset) * minRadius;
      }
  
      let averageBubbleRadius = 1023687;
  
      if (averageBubbleRadius < offset) {
        averageBubbleRadius = minBubbleSize;
      } else {
        averageBubbleRadius = Math.ceil(averageBubbleRadius/offset) * minRadius;
      }
  
      const chartDataSet = [
        {
          label: 'Retail Financing & Leasing Data',
          data: [
            {
              x: rawData.retailFinancingAndLeasing.implementationScore,
              y: rawData.retailFinancingAndLeasing.ROS,
              r: bubbleRadius,
            }
          ],
          backgroundColor: '#5097ab'
        },
        {
          label: 'Retail Financing & Leasing Market Data',
          data: [
            {
              x: '2.2',
              y: 100,
              r: averageBubbleRadius,
            }
          ],
          backgroundColor: '#343a40'
        }
      ];
  
      this.graphData = {
        datasets: chartDataSet,
      };
    } else if(type === 'FullServiceLeasing') {
         const netCash = rawData.fullServiceLeasing.netCash;
     
         let bubbleRadius = netCash;
     
         if (bubbleRadius < offset) {
           bubbleRadius = minBubbleSize;
         } else {
           //bubbleRadius = Math.ceil(bubbleRadius/offset) * minRadius;
           bubbleRadius = Math.ceil(bubbleRadius/offset) * minRadius;
         }
     
         let averageBubbleRadius = 271357;
     
         if (averageBubbleRadius < offset) {
           averageBubbleRadius = minBubbleSize;
         } else {
           averageBubbleRadius = Math.ceil(averageBubbleRadius/offset) * minRadius;
         }
     
         const chartDataSet = [
           {
             label: 'Full Service Leasing Data',
             data: [
               {
                 x: rawData.fullServiceLeasing.implementationScore,
                 y: rawData.fullServiceLeasing.ROS,
                 r: bubbleRadius,
               }
             ],
             backgroundColor: '#e69123'
           },
           {
             label: 'Full Service Leasing Market Data',
             data: [
               {
                 x: '2',
                 y: 27,
                 r: averageBubbleRadius,
               }
             ],
             backgroundColor: '#343a40'
           }
         ];
     
         this.graphData = {
           datasets: chartDataSet,
         };
       } else if(type === 'PublicCharging') {
        const netCash = rawData.publicCharging.netCash;
    
        let bubbleRadius = netCash;
    
        if (bubbleRadius < offset) {
          bubbleRadius = minBubbleSize;
        } else {
          bubbleRadius = Math.ceil(bubbleRadius/offset) * minRadius;
        }
    
        let averageBubbleRadius = 9763620;
    
        if (averageBubbleRadius < offset) {
          averageBubbleRadius = minBubbleSize;
        } else {
          averageBubbleRadius = Math.ceil(averageBubbleRadius/offset) * minRadius;
        }
    
        const chartDataSet = [
          {
            label: 'Public Charging Data',
            data: [
              {
                x: rawData.publicCharging.implementationScore,
                y: rawData.publicCharging.ROS,
                r: bubbleRadius,
              }
            ],
            backgroundColor: '#004355'
          },
          {
            label: 'Public Charging Market Data',
            data: [
              {
                x: '1',
                y: 66,
                r: averageBubbleRadius,
              }
            ],
            backgroundColor: '#343a40'
          }
        ];
    
        this.graphData = {
          datasets: chartDataSet,
        };
      }
     
 
    return this.graphData;
  }

  renderChart(el: any, chartData: any) {

    const config = {
      type: 'bubble',
      data: chartData,
      options: {},
    };

     // Chart render
     const chart = new Chart(el, config);
  }
}
