import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appAllowDecimalInputs]'
})
export class AllowDecimalInputsDirective {

  @Input() decimal: Boolean;
  @Input() percentage: Boolean;
  @Input() currency: Boolean;
  @Output() valueChanged = new EventEmitter();
  @Input() formatNumber: Boolean;
  @Input() allowedDecimalValidation: Boolean = false;
  formattedCurrency: any;

  constructor(private el: ElementRef) {}
  public getSanitized(event:any) {
    const params = event.target.value
    const splitedText = params.split('.');

    event.target.value =  splitedText[0]+ (splitedText.length>1 ? '.' + splitedText[1].slice(0,2): '')

}
  // ^\$?\d+(\.\d+)?%?$

  @HostListener('keyup', ['$event']) onKeyDown(event: any): void {

    if(this.allowedDecimalValidation ){
      this.getSanitized(event)
    }
    if (event.key === 'Backspace' || event.key === ',') {
      // Allow backspace without formatting
      return;
    }
    let initialValue = this.el.nativeElement.value;

    this.el.nativeElement.value = initialValue.replace(/[^0-9.]/g, '');


    if(this.percentage) {
      if(this.el.nativeElement.value !== '') {
        this.el.nativeElement.value = this.el.nativeElement.value + '%';
      }
    }

    if(this.currency) {
      if(this.el.nativeElement.value !== '') {
        if(this.el.nativeElement.value.slice(-1) === '.') {
          this.formattedCurrency = this.el.nativeElement.value.toLocaleString("en-US");
        } else {
          this.formattedCurrency = Number(this.el.nativeElement.value).toLocaleString("en-US");
        }
        this.el.nativeElement.value = '$' + this.formattedCurrency;
      }
    }

    if(this.formatNumber) {
      this.el.nativeElement.value = Number(this.el.nativeElement.value).toLocaleString("en-US");
    }

    this.valueChanged.emit({formControlName: this.el.nativeElement.id, value: this.el.nativeElement.value});

    if (this.el.nativeElement.value !== initialValue) {
      event.stopPropagation();
    }

  }

}

