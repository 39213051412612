import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appAllowNegative]'
})
export class AllowNegativeDirective {

  @Input() decimal: Boolean;
  @Input() percentage: Boolean;
  @Input() currency: Boolean;
  @Output() valueChanged = new EventEmitter();
  @Input() formatNumber: Boolean;
  formattedCurrency: any;

  constructor(private el: ElementRef) {}

  @HostListener('keyup', ['$event']) onKeyDown(event: any): void {
    if (event.key === 'Backspace' || event.key === ',') {
      // Allow backspace without formatting
      return;
    }
    let initialValue = this.el.nativeElement.value;

    /*const currencyMatch = this.el.nativeElement.value?.match(/\$/g)?.length;

    if(currencyMatch > 0) {
      initialValue = initialValue.replace(/\$/g, '');
    } */

    this.el.nativeElement.value = initialValue.replace(/[^0-9]/g, '');

    const minusCount = (this.el.nativeElement.value.match(/-/g) || []).length;

    if(minusCount > 1) {
      this.el.nativeElement.value = this.el.nativeElement.value.slice(0, this.el.nativeElement.value.lastIndexOf('-'));
    }
     
    if(this.decimal) {
      const decimalCount = (this.el.nativeElement.value.match(/\./g) || []).length;
    
      if (decimalCount > 1) {
        this.el.nativeElement.value = this.el.nativeElement.value.slice(0, this.el.nativeElement.value.lastIndexOf('.'));
      }
    } else {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/\./g, '');
    }

    if(this.percentage) {
      if(this.el.nativeElement.value !== '') {
        this.el.nativeElement.value = '-' + this.el.nativeElement.value + '%';
      }
    }

    // if(this.negative) 

    if(this.currency) {
      if(this.el.nativeElement.value !== '') {
        if(this.el.nativeElement.value.slice(-1) === '.') {
          this.formattedCurrency = this.el.nativeElement.value.toLocaleString("en-US");
        } else {
          this.formattedCurrency = Number(this.el.nativeElement.value).toLocaleString("en-US");
        }
        this.el.nativeElement.value = '$' + this.formattedCurrency;
      }
    }

    if(this.formatNumber) {
      this.el.nativeElement.value ='-' + Number(this.el.nativeElement.value).toLocaleString("en-US");
    }
    
    this.valueChanged.emit({formControlName: this.el.nativeElement.id, value: this.el.nativeElement.value});

    if (this.el.nativeElement.value !== initialValue) {
      event.stopPropagation();
    }
  } 

}
