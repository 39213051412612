import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpClient,
} from '@angular/common/http';
import { EncryptDecryptService } from '../services/encrypt-descrypt.service';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RequestEncryptionInterceptor implements HttpInterceptor {
  constructor(
    private httpClient: HttpClient,
    private encryptDecryptService: EncryptDecryptService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.method === 'POST') {
      if (req.body || req.body.length > 0) {
        let cloneReq = req;
        if (environment.ENCRYPTION_ENABLED) {
          cloneReq = req.clone({
            body: {
              data: this.encryptDecryptService.encrypt(JSON.stringify(req.body)),
            },
          });
        }
        return next.handle(cloneReq);
      }
    }

    return next.handle(req);
  }
}
