import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { Modal } from 'bootstrap';
import { ScreenshotService } from '../services/screenshot.service';
import { BreadcrumbService } from '../services/breadcumbs.service';
import { isEmpty, merge } from 'lodash';
import { LoaderService } from '../shared/loader/loader.service';

@Component({
  selector: 'app-scoring-worksheet',
  templateUrl: './scoring-worksheet.component.html',
  styleUrls: ['./scoring-worksheet.component.scss'],
})
export class ScoringWorksheetComponent implements OnInit, AfterViewInit {
  scoringWorksheetForm: FormGroup;
  draftModal: Modal;
  partialData: any;
  removeArrow=false
  @ViewChild('draftModalRef') draftModalRef: ElementRef;

  @ViewChild('scoringInstructionContent')
  scoringInstructionContentElement: ElementRef;
  @ViewChild('scoringSectionContent') scoringSectionContentElement: ElementRef;

  scoringInstructionCapturedImage: any;
  scoringSectionCapturedImage: any;

  SCORINGWORKSHEET_J6: number = 0;
  SCORINGWORKSHEET_J7: number = 0;
  SCORINGWORKSHEET_J8: number = 0;
  SCORINGWORKSHEET_J9: number = 0;
  SCORINGWORKSHEET_J10: number = 0;
  SCORINGWORKSHEET_J11: number = 0;
  SCORINGWORKSHEET_J12: number = 0;
  SCORINGWORKSHEET_J13: number = 0;
  SCORINGWORKSHEET_J14: number = 0;

  draftData: any;

  constructor(
    public apiService: ApiService,
    private fb: FormBuilder,
    private router: Router,
    private toast: ToastrService,
    private authService: AuthService,
    private screenshotService: ScreenshotService,
    private breadCrumbsSvc: BreadcrumbService,
    private loaderService: LoaderService,
    private cdr : ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.apiService.pageTitle =
      'Complete The Ease Of Implementation Scoring Worksheet';
    this.breadCrumbsSvc.addBreadcrumbs();
    this.createScoringWorksheetForm();
  }

  ngAfterViewInit(): void {
    if (Object.keys(this.apiService.formData).length === 0) {
      // this.redirectToLandingPage();
    } else {
      const prevFormData = this.apiService.getPrevFormData();
      if (!isEmpty(prevFormData.scoringWorksheet)) {
        this.partialData = prevFormData.scoringWorksheet;
      } else {
        this.partialData = this.apiService.getFormData();
      }
      this.assignDraftData();
    }
    this.cdr.detectChanges();
  }


  redirectToLandingPage(): void {
    this.router.navigate(['/']);
  }

  getDraftData() {
    if (!this.loaderService.isVisible()) {
      this.loaderService.show();
    }
    const userdata = this.authService.getCurrentUser().data;

    const requestData = {
      userId: userdata.userId,
      draft: this.apiService.formData,
      createdBy: userdata.username,
      updatedBy: userdata.username,
    };

    this.apiService.previousPageData(requestData).subscribe(
      (response) => {
        if (response.status === 'success') {
          this.draftData = response?.data?.draft
            ? JSON.parse(response.data.draft.draft)
            : {};
          this.partialData = isEmpty(response.data.partial)
            ? this.draftData
            : response.data.partial;

          if (!isEmpty(this.draftData)) {
            this.draftModal = new Modal(this.draftModalRef.nativeElement, {});
            this.draftModal.show();
          } else {
            this.scoringWorksheetForm.reset();
          }

          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
        }
      },
      (err) => {
        if (this.loaderService.isVisible()) {
          this.loaderService.hide();
        }
        //console.log(err);
      }
    );
  }

  yesDraft() {
    this.apiService.loadFromDraft();
    this.apiService.saveFormData(this.partialData);
    this.assignDraftData();
    this.draftModal.hide();
  }

  deleteDraft() {
    this.apiService.discardDraft();
    this.scoringWorksheetForm.reset();
    this.draftModal.hide();
  }

  createScoringWorksheetForm() {
    this.scoringWorksheetForm = this.fb.group({
      SCORINGWORKSHEET_C6: ['', Validators.required],
      SCORINGWORKSHEET_D6: ['', Validators.required],
      SCORINGWORKSHEET_E6: ['', Validators.required],
      SCORINGWORKSHEET_F6: ['', Validators.required],
      SCORINGWORKSHEET_G6: ['', Validators.required],
      SCORINGWORKSHEET_H6: ['', Validators.required],
      SCORINGWORKSHEET_C7: ['', Validators.required],
      SCORINGWORKSHEET_D7: ['', Validators.required],
      SCORINGWORKSHEET_E7: ['', Validators.required],
      SCORINGWORKSHEET_F7: ['', Validators.required],
      SCORINGWORKSHEET_G7: ['', Validators.required],
      SCORINGWORKSHEET_H7: ['', Validators.required],
      SCORINGWORKSHEET_C8: ['', Validators.required],
      SCORINGWORKSHEET_D8: ['', Validators.required],
      SCORINGWORKSHEET_E8: ['', Validators.required],
      SCORINGWORKSHEET_F8: ['', Validators.required],
      SCORINGWORKSHEET_G8: ['', Validators.required],
      SCORINGWORKSHEET_H8: ['', Validators.required],
      SCORINGWORKSHEET_C9: ['', Validators.required],
      SCORINGWORKSHEET_D9: ['', Validators.required],
      SCORINGWORKSHEET_E9: ['', Validators.required],
      SCORINGWORKSHEET_F9: ['', Validators.required],
      SCORINGWORKSHEET_G9: ['', Validators.required],
      SCORINGWORKSHEET_H9: ['', Validators.required],
      SCORINGWORKSHEET_C10: ['', Validators.required],
      SCORINGWORKSHEET_D10: ['', Validators.required],
      SCORINGWORKSHEET_E10: ['', Validators.required],
      SCORINGWORKSHEET_F10: ['', Validators.required],
      SCORINGWORKSHEET_G10: ['', Validators.required],
      SCORINGWORKSHEET_H10: ['', Validators.required],
      SCORINGWORKSHEET_C11: ['', Validators.required],
      SCORINGWORKSHEET_D11: ['', Validators.required],
      SCORINGWORKSHEET_E11: ['', Validators.required],
      SCORINGWORKSHEET_F11: ['', Validators.required],
      SCORINGWORKSHEET_G11: ['', Validators.required],
      SCORINGWORKSHEET_H11: ['', Validators.required],
      SCORINGWORKSHEET_C12: ['', Validators.required],
      SCORINGWORKSHEET_D12: ['', Validators.required],
      SCORINGWORKSHEET_E12: ['', Validators.required],
      SCORINGWORKSHEET_F12: ['', Validators.required],
      SCORINGWORKSHEET_G12: ['', Validators.required],
      SCORINGWORKSHEET_H12: ['', Validators.required],
      SCORINGWORKSHEET_C13: ['', Validators.required],
      SCORINGWORKSHEET_D13: ['', Validators.required],
      SCORINGWORKSHEET_E13: ['', Validators.required],
      SCORINGWORKSHEET_F13: ['', Validators.required],
      SCORINGWORKSHEET_G13: ['', Validators.required],
      SCORINGWORKSHEET_H13: ['', Validators.required],
      SCORINGWORKSHEET_C14: ['', Validators.required],
      SCORINGWORKSHEET_D14: ['', Validators.required],
      SCORINGWORKSHEET_E14: ['', Validators.required],
      SCORINGWORKSHEET_F14: ['', Validators.required],
      SCORINGWORKSHEET_G14: ['', Validators.required],
      SCORINGWORKSHEET_H14: ['', Validators.required],
    });
  }

  resetForm() {
    this.scoringWorksheetForm.reset();
    this.refreshData();
  }

  assignDraftData() {
    this.scoringWorksheetForm.patchValue({
      SCORINGWORKSHEET_C6: this.partialData.SCORINGWORKSHEET_C6,
      SCORINGWORKSHEET_D6: this.partialData.SCORINGWORKSHEET_D6,
      SCORINGWORKSHEET_E6: this.partialData.SCORINGWORKSHEET_E6,
      SCORINGWORKSHEET_F6: this.partialData.SCORINGWORKSHEET_F6,
      SCORINGWORKSHEET_G6: this.partialData.SCORINGWORKSHEET_G6,
      SCORINGWORKSHEET_H6: this.partialData.SCORINGWORKSHEET_H6,

      SCORINGWORKSHEET_C7: this.partialData.SCORINGWORKSHEET_C7,
      SCORINGWORKSHEET_D7: this.partialData.SCORINGWORKSHEET_D7,
      SCORINGWORKSHEET_E7: this.partialData.SCORINGWORKSHEET_E7,
      SCORINGWORKSHEET_F7: this.partialData.SCORINGWORKSHEET_F7,
      SCORINGWORKSHEET_G7: this.partialData.SCORINGWORKSHEET_G7,
      SCORINGWORKSHEET_H7: this.partialData.SCORINGWORKSHEET_H7,

      SCORINGWORKSHEET_C8: this.partialData.SCORINGWORKSHEET_C8,
      SCORINGWORKSHEET_D8: this.partialData.SCORINGWORKSHEET_D8,
      SCORINGWORKSHEET_E8: this.partialData.SCORINGWORKSHEET_E8,
      SCORINGWORKSHEET_F8: this.partialData.SCORINGWORKSHEET_F8,
      SCORINGWORKSHEET_G8: this.partialData.SCORINGWORKSHEET_G8,
      SCORINGWORKSHEET_H8: this.partialData.SCORINGWORKSHEET_H8,

      SCORINGWORKSHEET_C9: this.partialData.SCORINGWORKSHEET_C9,
      SCORINGWORKSHEET_D9: this.partialData.SCORINGWORKSHEET_D9,
      SCORINGWORKSHEET_E9: this.partialData.SCORINGWORKSHEET_E9,
      SCORINGWORKSHEET_F9: this.partialData.SCORINGWORKSHEET_F9,
      SCORINGWORKSHEET_G9: this.partialData.SCORINGWORKSHEET_G9,
      SCORINGWORKSHEET_H9: this.partialData.SCORINGWORKSHEET_H9,

      SCORINGWORKSHEET_C10: this.partialData.SCORINGWORKSHEET_C10,
      SCORINGWORKSHEET_D10: this.partialData.SCORINGWORKSHEET_D10,
      SCORINGWORKSHEET_E10: this.partialData.SCORINGWORKSHEET_E10,
      SCORINGWORKSHEET_F10: this.partialData.SCORINGWORKSHEET_F10,
      SCORINGWORKSHEET_G10: this.partialData.SCORINGWORKSHEET_G10,
      SCORINGWORKSHEET_H10: this.partialData.SCORINGWORKSHEET_H10,

      SCORINGWORKSHEET_C11: this.partialData.SCORINGWORKSHEET_C11,
      SCORINGWORKSHEET_D11: this.partialData.SCORINGWORKSHEET_D11,
      SCORINGWORKSHEET_E11: this.partialData.SCORINGWORKSHEET_E11,
      SCORINGWORKSHEET_F11: this.partialData.SCORINGWORKSHEET_F11,
      SCORINGWORKSHEET_G11: this.partialData.SCORINGWORKSHEET_G11,
      SCORINGWORKSHEET_H11: this.partialData.SCORINGWORKSHEET_H11,

      SCORINGWORKSHEET_C12: this.partialData.SCORINGWORKSHEET_C12,
      SCORINGWORKSHEET_D12: this.partialData.SCORINGWORKSHEET_D12,
      SCORINGWORKSHEET_E12: this.partialData.SCORINGWORKSHEET_E12,
      SCORINGWORKSHEET_F12: this.partialData.SCORINGWORKSHEET_F12,
      SCORINGWORKSHEET_G12: this.partialData.SCORINGWORKSHEET_G12,
      SCORINGWORKSHEET_H12: this.partialData.SCORINGWORKSHEET_H12,

      SCORINGWORKSHEET_C13: this.partialData.SCORINGWORKSHEET_C13,
      SCORINGWORKSHEET_D13: this.partialData.SCORINGWORKSHEET_D13,
      SCORINGWORKSHEET_E13: this.partialData.SCORINGWORKSHEET_E13,
      SCORINGWORKSHEET_F13: this.partialData.SCORINGWORKSHEET_F13,
      SCORINGWORKSHEET_G13: this.partialData.SCORINGWORKSHEET_G13,
      SCORINGWORKSHEET_H13: this.partialData.SCORINGWORKSHEET_H13,

      SCORINGWORKSHEET_C14: this.partialData.SCORINGWORKSHEET_C14,
      SCORINGWORKSHEET_D14: this.partialData.SCORINGWORKSHEET_D14,
      SCORINGWORKSHEET_E14: this.partialData.SCORINGWORKSHEET_E14,
      SCORINGWORKSHEET_F14: this.partialData.SCORINGWORKSHEET_F14,
      SCORINGWORKSHEET_G14: this.partialData.SCORINGWORKSHEET_G14,
      SCORINGWORKSHEET_H14: this.partialData.SCORINGWORKSHEET_H14,
    });
    this.refreshData();
  }

  nextPage() {
    if (this.scoringWorksheetForm.valid) {
      if (!this.loaderService.isVisible()) {
        this.loaderService.show();
      }
      this.apiService.saveFormData(this.scoringWorksheetForm.value);
      this.apiService.savePrevFormData(
        'scoringWorksheet',
        this.scoringWorksheetForm.value
      );

      const userdata = this.authService.getCurrentUser().data;
      const requestData = {
        userId: userdata.userId,
        draft: this.apiService.formData,
        createdBy: userdata.username,
        updatedBy: userdata.username,
      };
      this.apiService.nextPageNavigation(requestData).subscribe(
        (response) => {
          if (response.status === 'success') {
            if (this.loaderService.isVisible()) {
              this.loaderService.hide();
            }
            this.apiService.saveFormData(response.data.partial);
            this.captureScreenshot();
            // this.scoringWorksheetForm.reset();
            this.removeArrow = true
            this.toast.success('Values saved successfully in Draft');
          }
        },
        (err) => {
          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
          //console.log(err);
          this.toast.error(
            err?.error?.errorDescription ||
              'Something went wrong in saving the values in Draft'
          );
        }
      );
    }
  }

  prevPage() {
    this.apiService.savePrevFormData(
      'scoringWorksheet',
      this.scoringWorksheetForm.value
    );
    this.router.navigate(['/pursue-opportunity']);
  }

  async captureScreenshot() {
    const scoringInstructionElementToCapture =
      this.scoringInstructionContentElement.nativeElement;
    const scoringSectionElementToCapture =
      this.scoringSectionContentElement.nativeElement;

    const scoringInstructionCanvas = await this.screenshotService
      .captureScreenshot(scoringInstructionElementToCapture)
      .then((canvas) => {
        this.scoringInstructionCapturedImage = canvas.toDataURL('image/png');
      });

    const scoringSectionCanvas = await this.screenshotService
      .captureScreenshot(scoringSectionElementToCapture)
      .then((canvas) => {
        this.scoringSectionCapturedImage = canvas.toDataURL('image/png');
      });

    const imgObject = [
      {
        id: 'scoringInstruction',
        imgCaptured: this.scoringInstructionCapturedImage,
      },
      {
        id: 'scoringSection',
        imgCaptured: this.scoringSectionCapturedImage,
      },
    ];

    const pageObject = {
      page: 'Scoring Worksheet',
      imgCapture: imgObject,
    };

    if(this.apiService.pageScreenshot['Scoring Worksheet']){
      this.apiService.pageScreenshot['Scoring Worksheet']['imgCapture'].push(imgObject)
    } else {
      this.apiService.pageScreenshot['Scoring Worksheet'] = pageObject
    }
    setTimeout(() => {
      this.router.navigate(['/output-graph']);
    }, 1000);
  }

  refreshData() {
    if (!this.loaderService.isVisible()) {
      this.loaderService.show();
    }
    const userdata = this.authService.getCurrentUser().data;
    const scoringData = this.transformStringToInt(
      this.scoringWorksheetForm.value
    );

    const postData = {
      userId: userdata.userId,
      draft: merge(this.apiService.formData, scoringData),
      scoringData: scoringData
    };

    this.apiService.scoringWorksheet(postData).subscribe(
      (response) => {
        if (response.status === 'success') {
          this.SCORINGWORKSHEET_J6 =
            response.data.SCORINGWORKSHEET_J6?.toFixed(2);
          this.SCORINGWORKSHEET_J7 =
            response.data.SCORINGWORKSHEET_J7?.toFixed(2);
          this.SCORINGWORKSHEET_J8 =
            response.data.SCORINGWORKSHEET_J8?.toFixed(2);
          this.SCORINGWORKSHEET_J9 =
            response.data.SCORINGWORKSHEET_J9?.toFixed(2);
          this.SCORINGWORKSHEET_J10 =
            response.data.SCORINGWORKSHEET_J10?.toFixed(2);
          this.SCORINGWORKSHEET_J11 =
            response.data.SCORINGWORKSHEET_J11?.toFixed(2);
          this.SCORINGWORKSHEET_J12 =
            response.data.SCORINGWORKSHEET_J12?.toFixed(2);
          this.SCORINGWORKSHEET_J13 =
            response.data.SCORINGWORKSHEET_J13?.toFixed(2);
          this.SCORINGWORKSHEET_J14 =
            response.data.SCORINGWORKSHEET_J14?.toFixed(2);

          this.toast.success('Values refreshed successfully');
          if (this.loaderService.isVisible()) {
            this.loaderService.hide();
          }
        }
      },
      (err) => {
        if (this.loaderService.isVisible()) {
          this.loaderService.hide();
        }
        console.error(err);
        this.toast.error(
          err?.error?.errorDescription ||
            'Something went wrong in refreshing the values'
        );
      }
    );
  }

  private transformStringToInt(data: any): any {
    // Recursive function to transform null values to empty string
    if (data === null) {
      return '';
    } else if (typeof data === 'object') {
      // If it's an object, iterate through its properties
      const transformedObject: any = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          transformedObject[key] = this.transformToInt(data[key]);
        }
      }
      return transformedObject;
    } else {
      return data;
    }
  }

  private transformToInt(input: any) {
    if (!isNaN(input)) {
      // Check if the string can be converted to a number
      return parseFloat(input);
    } else {
      return input;
    }
  }

  enterSubmitted(event: any) {
    if (event.keyCode === 13) {
      event.stopPropagation();
    }
  }
}
