<div class="mt-5">
    <div class="row">
        <div class="col-6">
            <canvas id="newTruckSalesCanvas" #newTruckSalesCanvas></canvas>
        </div>
        <div class="col-6">
            <canvas id="partServiceCanvas" #partServiceCanvas></canvas>
        </div>     
    </div>
    <div class="row">
        <div class="col-6">
            <canvas id="constructionProjectFinancingCanvas" #constructionProjectFinancingCanvas></canvas>
        </div>
        <div class="col-6">
            <canvas id="chargerSalesCanvas" #chargerSalesCanvas></canvas>
        </div>
    </div>

    <div class="row">
        <div class="col-6">
            <canvas id="ChargerServiceMaintenanceCanvas" #ChargerServiceMaintenanceCanvas></canvas>
        </div>
        <div class="col-6">
            <canvas id="usedTruckSalesCanvas" #usedTruckSalesCanvas></canvas>
        </div>
    </div>
    
    <div class="row">
        <div class="col-6">
            <canvas id="retailFinancingLeasingCanvas" #retailFinancingLeasingCanvas></canvas>
        </div>
        <div class="col-6">
            <canvas id="fullServiceLeasingCanvas" #fullServiceLeasingCanvas></canvas>
        </div>
    </div>    
    <div class="row">
        <div class="col-6">
            <canvas id="publicChargingCanvas" #publicChargingCanvas></canvas>
        </div>
    </div>
</div>