// loader.service.ts

import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  constructor(private spinner: NgxSpinnerService) {}

  visible = false;

  show() {
    this.spinner.show();
    this.visible = true;
  }

  hide() {
    this.spinner.hide();
    this.visible = false;
  }

  isVisible() {
    return this.visible
  }
}
